import { Organization } from '../../types';
import { Injectable } from '@angular/core';
import {
  AsyncCallResponse,
  HttpService,
  Status,
  handleAsyncError,
} from '../../../http';
import { Observable, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { compose } from 'ramda';
import { orgsResponseHandler } from './handlers';
import { ConfigService } from '@zeotap-ui/config';

@Injectable({
  providedIn: 'root',
})
export class OrgApiService {
  constructor(
    private httpService: HttpService,
    private config: ConfigService
  ) {}

  getOrgsForProduct(
    productIds: string[]
  ): Observable<AsyncCallResponse<Organization[]>> {
    const url = `${this.config.appConfig.userSvcUrlV3}product/organizations/`;
    const payload = {
      product_ids: productIds,
    };

    return from(this.httpService.doPost(url, payload)).pipe(
      map(orgsResponseHandler),
      catchError(
        compose(
          of,
          handleAsyncError<Organization[]>(
            'Something bad happened. Please try again later.' // TODO : Make this localized string
          )
        )
      )
    );
  }

  getProductOrgsMap(
    productIds: string[]
  ): Observable<AsyncCallResponse<Record<string, Organization[]>>> {
    const url = `${this.config.appConfig.userSvcUrlV3}product/organizations/`;
    const payload = {
      product_ids: productIds,
    };

    return from(this.httpService.doPost(url, payload)).pipe(
      map((res) => ({ status: Status.Success, data: res })),
      catchError(
        compose(
          of,
          handleAsyncError<Record<string, Organization[]>>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }
}
