import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofocusDirective } from './autoFocus/autofocus.directive';
import {
  ContentTemplateDirective,
  FooterTemplateDirective,
  HeaderTemplateDirective,
  IconTemplateDirective,
} from './template/template.directive';
import { ReplaceWithNullDirective } from './replaceWithNull/replace-with-null.directive';
import { FormValueTransformerDirective } from './formValueTransformer/form-value-transformer.directive';
import { ScrollToTopDirective } from './table/scrollToTop.directive';

@NgModule({
  declarations: [
    AutofocusDirective,
    ContentTemplateDirective,
    IconTemplateDirective,
    FooterTemplateDirective,
    HeaderTemplateDirective,
    ReplaceWithNullDirective,
    FormValueTransformerDirective,
    ScrollToTopDirective,
  ],
  imports: [CommonModule],
  exports: [
    AutofocusDirective,
    ContentTemplateDirective,
    IconTemplateDirective,
    FooterTemplateDirective,
    HeaderTemplateDirective,
    ReplaceWithNullDirective,
    FormValueTransformerDirective,
    ScrollToTopDirective,
  ],
})
export class CoreDirectivesModule {}
