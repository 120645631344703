import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'zap-stage-tracker',
  templateUrl: './stage-tracker.component.html',
})
export class StageTrackerComponent implements OnInit {
  @Input() stages: string[];
  @Input() currentStage: number;
  constructor() {}

  ngOnInit() {}
}
