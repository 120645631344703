import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { BaseControl, getControlValueAccessor } from '../base';
import * as d3 from 'd3';

@Component({
  selector: 'zui-sunburst-chart',
  styleUrls: ['./sunburst.scss'],
  templateUrl: './sunburst.html',
  providers: [getControlValueAccessor(SunburstChartComponent)]
})
export class SunburstChartComponent extends BaseControl
  implements AfterViewInit, OnChanges, OnDestroy {
  public defaultChartConfig = {
    title: 'Interests',
    margin: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0
    },
    display: {
      gap: 1
    },
    series: {},
    breadcrumDimensions: {
      width: 85,
      height: 30,
      spacing: 3,
      tail: 10
    },
    colors: ['#3CBAC8', '#93EDD4', '#F3F5C4', '#F9CB8F', '#F19181', '#D4F5C4']
  };
  @Input() public title: string;
  @Input() public data: any;
  @Input() public options = this.defaultChartConfig;
  @Input() public percentage? = false;
  public chartSvg;
  public width;
  public height;
  public seriesData;
  public radius;
  public colors = [
    '#3CBAC8',
    '#93EDD4',
    '#F3F5C4',
    '#F9CB8F',
    '#F19181',
    '#D4F5C4'
  ];
  public totalSize;
  public legendTotal: number;
  public arc;
  public partition;
  public path;
  public colorMap = {};
  public selected = [];
  public containerAdded: Boolean = false;

  constructor(public eleRef: ElementRef, private cdRef:ChangeDetectorRef) {
    super();
  }

  public ngAfterViewInit() {
    this.options = Object.assign(this.defaultChartConfig || {}, this.options);
    this.getChartConfig();
  }

  public ngOnChanges() {
    if (
      this.chartSvg &&
      this.containerAdded &&
      this.data &&
      this.data.children &&
      this.data.children.length
    ) {
      this.createVisualization(this.data);
    } else if (
      this.chartSvg &&
      !this.containerAdded &&
      this.data &&
      this.data.children &&
      this.data.children.length
    ) {
      this.draw();
    }
  }

  public addSvgContainer() {
    this.chartSvg = d3
      .select(`#${this.id}`)
      .append('svg')
      .style('fill', '#fff');
    let breadcrumDimensions = this.options['breadcrumDimensions'];
    this.colors = this.options['colors'] || this.options.colors;
  }

  public initializeChartDimensions() {
    let container = d3.select(this.chartSvg.node().parentNode);
    let margin = this.options['margin'];
    this.width =
      parseInt(container.style('width'), 10) -
        margin['left'] -
        margin['right'] || '750';
    this.height =
      parseInt(container.style('height'), 10) -
        margin['top'] -
        margin['bottom'] || '600';
    this.radius = Math.min(this.width, this.height) / 2;
    this.seriesData = this.data;
    this.partition = d3
      .partition()
      .size([2 * Math.PI, this.radius * this.radius]);
  }

  public setChartDimensions() {
    this.chartSvg
      .attr('width', '100%')
      .attr('height', '100%')
      .call(() => this.responsivefy(this.chartSvg));
  }

  public responsivefy(svg) {
    let container = d3.select(svg.node().parentNode.parentNode.parentNode);
    let margin = this.options['margin'];
    let width =
      parseInt(svg.style('width'), 10) - margin['left'] - margin['right'];
    let height =
      parseInt(svg.style('height'), 10) - margin['top'] - margin['bottom'];
    let aspect = width / height;
    svg.attr('viewBox', '0 0 ' + width + ' ' + height);
  }

  public draw() {
    this.containerAdded = true;
    this.chartSvg = this.chartSvg
      .append('g')
      .attr('id', 'container')
      .attr('transform', `translate(${this.width / 2},${this.height / 2})`);

    this.partition = d3
      .partition()
      .size([2 * Math.PI, this.radius * this.radius]);

    this.arc = d3
      .arc()
      .startAngle(function(d) {
        return d.x0;
      })
      .endAngle(function(d) {
        return d.x1;
      })
      .innerRadius((d, i) => {
        if (i == 1) {
          let innerRadiusLength;
          innerRadiusLength = Math.sqrt(d.y0);
        }
        return Math.sqrt(d.y0);
      })
      .outerRadius(d => {
        return Math.sqrt(d.y1) - this.options['display']['gap'];
      });
    // Bounding circle underneath the sunburst
    this.chartSvg.append('svg:circle').attr('r', this.radius);
    this.createVisualization(this.data);
  }

  public createVisualization(json) {
    d3.select('#togglelegend').on('click', this.toggleLegend);
    // Turn the data into a d3 hierarchy and calculate the sums.
    let root = d3
      .hierarchy(json)
      .sum(function(d) {
        return d.size;
      })
      .sort(function(a, b) {
        return b.value - a.value;
      });

    // For efficiency, filter nodes to keep only those large enough to see.
    let nodes = this.partition(root)
      .descendants()
      .filter(d => {
        if (d.data && d.data.name == 'data') {
          this.totalSize = d.value;
          d3.select('#percentage').text(this.formatter(this.totalSize));
        }
        return (
          d.x1 - d.x0 > 0.01 &&
          d.data.name.toLowerCase() != 'male' &&
          d.data.name.toLowerCase() != 'female' &&
          d.data.name != 'data'
        ); // 0.005 radians = 0.29 degrees
      });

    this.prepareColorsMap(nodes);
    var update = this.chartSvg
      .data([json])
      .selectAll('path')
      .data(nodes);
    update.exit().remove();
    let enter = update.enter().append('svg:path');

    this.path = update
      .merge(enter)
      .attr('display', function(d) {
        return d.depth ? null : 'none';
      })
      .attr('d', this.arc)
      .style('stroke-width', 1)
      .style('stroke', '#fff')
      .attr('fill-rule', 'evenodd')
      .style('opacity', 1)
      .style('fill', d => {
        return this.colorMap[d['data']['name']];
      });

    this.chartSvg.on('mouseleave', d => {
      this.mouseleave(d);
    });

    this.path.on('mouseover', d => {
      this.mouseenter(d);
    });
  }
  public numberFormatter(number) {
    if (isNaN(number)) {
      return '';
    } else if (number > 999999999) {
      return (number / 1000000000).toFixed(1).replace(/\.0+$/, '') + ' B';
    } else if (number > 999999) {
      return (number / 1000000).toFixed(1).replace(/\.0+$/, '') + ' M';
    } else if (number > 999) {
      return (number / 1000).toFixed(1).replace(/\.0+$/, '') + ' K';
    } else if (number <= 0) {
      return number.toFixed(0);
    }
    return number;
  }

  public formatter(d, gender?) {
    if (isNaN(d)) {
      return '';
    }
    if (this.percentage) {
      let totalSize = gender ? this.legendTotal : this.totalSize;
      if (isNaN(totalSize)) {
        return '';
      }
      const val = (d * 100) / totalSize;
      const remainder = (d * 100) % totalSize;
      return remainder ? `${val.toFixed(1)} %` : `${val}%`;
    } else {
      let format = this.options['numberFormatter'];
      if (d) {
        return format ? format.call({ d }) : this.numberFormatter(d);
      } else {
        return '';
      }
    }
  }

  public prepareColorsMap(data) {
    let uniqueKeys = data
      .map(item => item['data']['name'])
      .filter((value, index, self) => self.indexOf(value) === index);
    while (uniqueKeys.length > this.colors.length) {
      this.colors = this.colors.concat(this.colors);
    }
    for (let i = 0; i < uniqueKeys.length; i++) {
      this.colorMap[uniqueKeys[i]] = this.colors[i];
    }
  }

  public updateBreadcrumbs(nodeArray, d) {
    let g = d3
      .select('#trail')
      .selectAll('.slice')
      .data(nodeArray, function(d) {
        return d.name + d.depth;
      });

    // Add breadcrumb and label for entering nodes.
    let entering = g
      .enter()
      .append('div')
      .attr('class', 'slice zui-content');

    entering
      .append('div')
      .attr('class', 'slice-body zui-content')
      .text(d => {
        if (d.data.name == 'data') {
          return '';
        }
        return `${d.data.name} > `;
      })
      .style('color', d => this.colorMap[d.data.name]);

    // Remove exiting nodes from breadcrum.
    g.exit().remove();
    d3.select('#tip').html(this.formatter(d.value));

    // Make the breadcrumb trail visible, if it's hidden.
    d3.select('#trail').style('visibility', '');
    d3.select('#tip').style('visibility', '');
  }

  public mouseenter(d) {
    this.selected = d.data.children;
    this.selected = Object.assign([], this.selected).reverse();
    this.legendTotal = d.value;
    d3.select('#percentage').text(this.formatter(d.value));
    d3.select('#name').text(d.data.name);
    this.cdRef.detectChanges();

    let sequenceArray = d.ancestors().reverse();
    this.updateBreadcrumbs(sequenceArray, d);

    // Fade all the segments on mouseenter
    d3.selectAll(`#${this.id} path`).style('opacity', 0.3);
    // Then highlight only those that are an ancestor of the current segment.
    this.path
      .filter(function(node) {
        return sequenceArray.indexOf(node) >= 0;
      })
      .style('opacity', 1);

  }
  public addColor() {
    this.path.style('fill', (d, i) => {
      if (d.data.color) {
        return d.data.color;
      } else if (d.parent) {
        if (d.parent.data && d.parent.data.color) {
          return d.parent.data.color;
        } else if (
          d.parent.parent &&
          d.parent.parent.data &&
          d.parent.parent.data.color
        ) {
          return d.parent.parent.data.color;
        }
      }
      return 'grey';
    });
  }

  public addOpacity() {
    this.path.style('opacity', (d, i) => {
      if (d.data.color) {
        return 1;
      } else if (d.parent) {
        if (d.parent.data && d.parent.data.color) {
          return 0.4;
        } else if (
          d.parent.parent &&
          d.parent.parent.data &&
          d.parent.parent.data.color
        ) {
          return 0.2;
        }
      }
      return 0.1;
    });
  }

  public mouseleave(d) {
    // Hide the breadcrumb trail
    this.selected = [];
    this.cdRef.detectChanges();
    d3.select('#trail').style('visibility', 'hidden');
    d3.select('#tip').style('visibility', 'hidden');
    this.path.on('mouseenter', null);

    d3.selectAll(`#${this.id} path`)
      .transition()
      .duration(100)
      .style('opacity', 1)
      .on('end', (d, i, points) => {
        d3.select(points[i]).on('mouseenter', d => {
          this.mouseenter(d);
        });
      });
    d3.select('#percentage').text(this.formatter(this.totalSize));
    d3.select('#name').text('Interests');
  }

  public toggleLegend() {
    let legend = d3.select('#legend');
    if (legend.style('visibility') === 'hidden') {
      legend.style('visibility', '');
    } else {
      legend.style('visibility', 'hidden');
    }
  }

  public ngOnDestroy() {
    window.removeEventListener('resize', null);
    d3.selectAll('path').on('mouseover', null);
    d3.selectAll('path').on('mouseleave', null);
    d3.selectAll('path').on('end', null);
  }

  public getChartConfig() {
    this.addSvgContainer();
    this.initializeChartDimensions();
    this.setChartDimensions();
    if (this.data && this.data.children && this.data.children.length) {
      this.draw();
    }
  }
}
