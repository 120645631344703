import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DisplayMessageService } from '../display-message.service';
import { DisplayMessage } from '../type';

@Component({
  selector: 'lib-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, OnDestroy {
  stateColors = {
    error: 'state-error-700',
    success: 'state-success',
    warning: 'state-warning-1',
  };
  constructor(private messageService: DisplayMessageService) {
    this.messageObserver$ = this.messageService.listenToDisplayMessage();
  }

  messageObserver$: Observable<DisplayMessage>;

  ngOnDestroy(): void {}

  ngOnInit(): void {}
}
