<section class="card-container" [ngClass]="{'disabled disabledCheck': flag === true && disableFlag === true , 'disabled': flag === false && disableFlag === true , 'active' : flag === true }">
  <div *ngIf="flag === true" class="active-container">
  <label class="circle"></label>
		<span class="icon tick check-icon"></span>
	</div>
	<div class="image-container" [ngClass]="{'rotate': flip}">
		<img class="side" src="{{logoUrl}}" />
		<div class="side back" >
			<ng-content></ng-content>
		</div>
	</div>
	<div class="zui-gray1-text-color zui-mediumtext card-title">{{title}}</div>
</section>
