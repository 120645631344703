import {
  Component,
  Input,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  EventEmitter,
} from '@angular/core';

// import { DropdownComponent } from './dropdown';

@Component({
  selector: 'zui-dropdown-option',
  styleUrls: ['./dropdown.scss'],
  template: `
    <ng-template>
      <div
        class="zui-option-content"
        (click)="toggleSelection($event)"
        [ngStyle]="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
      >
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
})
export class DropdownOptionsComponent {
  public selected = false;
  public selectionChange = new EventEmitter<DropdownOptionsComponent>();
  @Input() public data: any;
  @Input() public disabled: Boolean = false;
  @ViewChild(TemplateRef) public template: TemplateRef<any>;

  constructor(private viewContainer: ViewContainerRef) {}

  public showChild() {
    this.viewContainer.createEmbeddedView(this.template);
  }

  public destroyChild() {
    this.viewContainer.clear();
  }

  public toggleSelection(event) {
    if (!this.disabled) {
      this.selectionChange.emit(this);
      event.stopPropagation();
    }
  }
}
