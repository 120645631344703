import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'zap-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
})
export class DisclaimerModalComponent implements OnInit {
  @Input() headerIcon: string;
  @Input() headerIconStyle: string;
  @Input() actionButtonLabel: string;
  @Input() disclaimerTitle: string;
  @Output() ctaFunction = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onCtaButtonClicked() {
    this.ctaFunction.emit();
  }

  readButtonClicked() {
    window.open('https://zeotap.com/product-privacy-policy/', '_blank');
    return false;
  }
}
