import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTimePickerComponent } from './custom-time-picker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CustomTimePickerComponent],
  imports: [CommonModule, NgSelectModule, FormsModule],
  exports: [CustomTimePickerComponent],
})
export class CustomTimePickerModule {}
