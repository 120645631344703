import { AsyncCallResponse, Status } from '../../../http';
import { Organization } from '../../types';
import { map, sortBy, uniq, flatten, values, prop, compose } from 'ramda';

export const getOrgListForMultipleProducts = compose(
  sortBy(prop('name')),
  uniq,
  flatten,
  values
);
export const orgsResponseHandler = (
  orgs: any[]
): AsyncCallResponse<Organization[]> => ({
  status: Status.Success,
  data: compose(
    map(
      (org) =>
        ({
          name: org.name,
          id: org.zeo_org_id,
          parent_org_id: org.parent_org_id,
          zeo_org_uuid: org.zeo_org_uuid,
          currency: org.currency,
          config: {},
          org_type: [],
          view_uuid: null,
          vertical: org.vertical,
          regions: org?.regions,
        } as Organization)
    ),
    getOrgListForMultipleProducts
  )(orgs),
}); // TODO: Implement the conversion of response to UI model
