import { Pipe, PipeTransform } from '@angular/core';
import { toLower, toString, trim, propOr } from 'ramda';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], properties: string[], searchText: string): any[] {
    if (!items) {
      return [];
    } else {
      if (!searchText || properties.length === 0) {
        return items;
      } else {
        const text = toLower(trim(searchText));
        return items.filter((item) => {
          return properties.some((property) => {
            const value = propOr('', property, item);
            return toLower(trim(toString(value))).includes(text);
          });
        });
      }
    }
  }
}
