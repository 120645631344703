import { Component, Input } from '@angular/core';

import { BaseControl, getControlValueAccessor } from '../base';

export type labelPositions = 'top' | 'left' | 'right';

@Component({
  selector: 'zui-checkbox',
  templateUrl: './checkbox.html',
  styleUrls: ['./checkbox.scss'],
  providers: [getControlValueAccessor(CheckboxComponent)]
})
export class CheckboxComponent extends BaseControl {
  @Input() public labelPosition: labelPositions = 'top';
  @Input() public label = '';
  @Input() public intermediateState = false;
}
