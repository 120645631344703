import {
  Component,
  Directive,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';

@Directive({
  selector: '.tooltip-container'
})
export class TooltipContainerDirective {}
