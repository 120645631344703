<div class="milestone-container" *ngIf="labels">
  <div class="track" [ngClass]="{disabled: disabled}" (click)="updateActiveMilestone($event)"></div>
  <div class="progress-bar" [ngClass]="{disabled: disabled}" [ngStyle]="{width: progressBarWidth}" (click)="updateActiveMilestone($event)"></div>
  <div *ngIf="showTooltip"
        class="tooltip-container"
        [ngStyle]="{left: calculateOffsetForLabel(activeMilestone), width: distanceBetweenMilestones + 'px'}">
    <ng-content></ng-content>
  </div>
  <ng-container *ngFor="let milestone of labels;let i=index">
    <div class="tick" 
      [ngClass]="{covered: isMilestoneCovered(i), active: isActiveMilestone(i), disabled: disabled}" 
      [ngStyle]="{left: calculateOffsetForMilestone(i)}"
      (click)="activateMilestone(i)"
      ></div>
    <div class="label zui-content"
      [ngClass]="{hilight: isActiveMilestone(i), disabledFont: disabled}"
      [ngStyle]="{width: distanceBetweenMilestones + 'px', left: calculateOffsetForLabel(i)}"
      (click)="activateMilestone(i)"
      >{{labels[i]}}</div>
  </ng-container>
</div>