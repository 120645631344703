import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  NumberFormatterPipe,
  isInteger,
  removeWhiteSpaceInBetween,
  replaceWhiteSpaceWithHyphen,
} from '@zeotap-ui/core';
import { isReportNonEmpty } from '../utils';
import { xAxisTickFormatting } from './utils/line-chart.helper';

@Component({
  selector: 'zap-custom-line-chart',
  templateUrl: './custom-line-chart.component.html',
  styleUrls: ['./custom-line-chart.component.scss'],
})
export class CustomLineChartComponent
  implements OnInit, AfterViewChecked, OnChanges {
  @Input() view: number[];
  @Input() results = [];
  scheme;
  @Input() set colors(colors: string[]) {
    this.scheme = { domain: [...colors] };
  }
  @Input() showLegend: boolean = false;
  @Input() legendTitle: string;
  @Input() legendPosition: string; // right/below
  @Input() xAxis: boolean = true;
  @Input() yAxis: boolean = true;
  @Input() showGridLines: boolean = false;
  @Input() showXAxisLabel: boolean = false;
  @Input() showYAxisLabel: boolean = false;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() yScaleMax: number;
  @Input() yScaleMin: number;
  @Input() xScaleMax: number;
  @Input() xScaleMin: number;
  @Input() timeline: boolean = false;
  @Input() maxXAxisTickLength: number; // default 16 - length of the label to be displayed
  @Input() maxYAxisTickLength: number; // default 16 - length of the label to be displayed
  @Input() showCustomLegend: boolean = true;
  @Input() showCustomLabels: boolean = true;
  @Input() noDataText: string = '';
  @Input() showCustomEmptyData = false;
  @Input() noDataImgSrc;
  @Input() showLegendAtBottom: boolean;
  @Input() xAxisTickDateFormat = 'DD MMM';
  @Input() tooltipDateFormat = 'EEE, MMM d';
  @Input() useXAxisFormatting = true;
  @Input() xAxisTicks: any[];
  xAxisTickFormattingFunc = xAxisTickFormatting(this.xAxisTickDateFormat);

  @Output() select = new EventEmitter<any>();
  @Output() activate = new EventEmitter<any>();
  @Output() deactivate = new EventEmitter<any>();

  tooltipColors: any;
  removeWhiteSpaceInBetween = removeWhiteSpaceInBetween;
  replaceWhiteSpaceWithHyphen = replaceWhiteSpaceWithHyphen;
  shouldDisplayReport: boolean = true;
  @ViewChild('chart', { read: ElementRef, static: false }) chartRef: ElementRef;
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.tooltipColors = this.scheme.domain.reduce(
      (acc, curr, i) => ({ ...acc, [this.results[i]?.name]: curr }),
      {}
    );
    this.shouldDisplayReport = isReportNonEmpty(this.results);
    this.xAxisTickFormattingFunc = this.useXAxisFormatting
      ? xAxisTickFormatting(this.xAxisTickDateFormat)
      : null;
  }

  yAxisTickFormatting(value) {
    const numberFormatter = new NumberFormatterPipe();
    return isInteger(value) ? numberFormatter.transform(value) : '';
  }

  ngOnChanges(changes) {
    if (changes.results)
      this.shouldDisplayReport = this.results.reduce(
        (isNotEmpty, currentSeries) =>
          isNotEmpty || currentSeries.series?.length,
        false
      );
  }
  ngAfterViewChecked() {
    this.addSingleDataPoint(this.chartRef?.nativeElement);
    this.addAxesLines(this.chartRef?.nativeElement);
  }
  addSingleDataPoint(chartElem) {
    if (!chartElem) return;
    [...(chartElem.querySelectorAll('g.line-series path') || [])]
      .filter((dataPoint) => dataPoint?.getTotalLength() === 0)
      .forEach((dataPoint) => {
        this.renderer.addClass(dataPoint, 'single-data-point');
      });
  }

  addAxesLines(chartElem: HTMLElement) {
    if (!chartElem) return;

    const yAxis = chartElem.querySelector('[ngx-charts-y-axis-ticks]');
    const xAxis = chartElem.querySelector('[ngx-charts-x-axis-ticks]');

    if (!this.hasAxesBorder(yAxis))
      this.renderer.appendChild(yAxis, this.getAxisLine(chartElem, true));

    if (!this.hasAxesBorder(xAxis))
      this.renderer.appendChild(xAxis, this.getAxisLine(chartElem, false));
  }

  getAxisLine(chart: HTMLElement, isYAxis: boolean) {
    const axis = this.renderer.createElement('g', 'svg');
    const axisLine = this.renderer.createElement('line', 'svg');
    const axisLength =
      (chart.querySelector('.tooltip-area') as SVGGraphicsElement)?.getBBox()?.[
        isYAxis ? 'height' : 'width'
      ] || 0;
    this.renderer.setAttribute(axisLine, 'x1', isYAxis ? '4' : '0');
    this.renderer.setAttribute(
      axisLine,
      'x2',
      isYAxis ? '4' : axisLength.toString()
    );
    this.renderer.setAttribute(axisLine, 'y1', isYAxis ? '-10' : '-4');
    this.renderer.setAttribute(
      axisLine,
      'y2',
      isYAxis ? (axisLength - 8).toString() : '-4'
    );
    this.renderer.addClass(axis, 'axes-border');
    this.renderer.appendChild(axis, axisLine);
    return axis;
  }

  hasAxesBorder(axis: Element) {
    return axis.querySelector('.axes-border');
  }

  onSelect(data): void {
    this.select.emit(data);
  }

  onActivate(data): void {
    this.activate.emit(data);
  }

  onDeactivate(data): void {
    this.deactivate.emit(data);
  }
}
