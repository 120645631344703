<!-- <div class="preview-table-container"> -->
<ngx-datatable class="custom-scroll material h-full !rounded !shadow-none !mt-[0.31rem] !font-primary !text-primary-1-900 !text-sm" [rows]="previewData" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true">
  <ngx-datatable-column [sortable]="false" *ngFor="let col of colHearders" [name]="col.name" [prop]="col.name">
    <ng-template let-column="column" ngx-datatable-header-template>
      <span class="sortable-column-header-text table-header-font">{{ column.name }}</span>
    </ng-template>
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <ng-container *ngIf="isNotObject(value); else Object">
        <span title="{{ value }}">{{ value }}</span>
      </ng-container>
      <ng-template #Object>
        <span title="{{ value | json }}">{{ value | json }}</span>
      </ng-template>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
<!-- </div> -->
