import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
} from '@angular/forms';
import { isNotNullOrEmpty, isString } from '@zeotap-ui/core';
import { compose, flatten, includes, map, uniq } from 'ramda';
import { updateList } from './utils';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxGroupComponent),
  multi: true,
};

type option = {
  label: string;
  value: any;
  disabled?: boolean;
};

@Component({
  selector: 'zap-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR],
})
export class CheckboxGroupComponent implements ControlValueAccessor {
  @Input() options: string[] | option[];
  @Input() disabled: boolean = false; //for disabling all options
  selectedOptions: string[] = [];
  onChanged = (_: any) => {};
  onTouched = () => {};
  isString = isString;

  constructor() {}

  onOptionSelected(option: string) {
    if (isNotNullOrEmpty(option)) {
      this.selectedOptions = updateList(this.selectedOptions)(option);
      this.onChanged(this.selectedOptions);
      this.onTouched();
    }
  }

  isSelected = (option: string) => includes(option, this.selectedOptions);

  writeValue(obj: any): void {
    if (isNotNullOrEmpty(obj)) {
      const getPreSelectedOptionsList = compose(
        uniq,
        flatten,
        map(updateList(this.selectedOptions))
      )(obj);
      this.selectedOptions = getPreSelectedOptionsList;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
