import {
  equals,
  compose,
  prop,
  either,
  find,
  values,
  propEq,
  curry,
  includes,
  without,
  keys,
  ifElse,
  always,
  propOr,
  hasPath,
  __,
} from 'ramda';
import { isNotNullOrEmpty } from '../../utils';
import { PRODUCTS, PRODUCT_PRIORITY_LIST } from '../product.const';
import { ProductGroup } from '../types';
import { toProductConfiguration } from './product-configuration';

export const isRBAC = compose(equals(+PRODUCTS.RBAC.id), prop('id'));

export const isDestinations = compose(
  equals(+PRODUCTS.DESTINATIONS.id),
  prop('id')
);

export const isRBACOrDestinations = either(isRBAC, isDestinations);

export const toProductGroup = (pg: any): ProductGroup => ({
  product: pg.product_id,
  id: pg.pg_id,
  name: pg.group_name,
});

export const getProductPathByName = (name: string, productDetails: any) =>
  compose(prop('path'), find(propEq('name', name)), values)(productDetails);

export const getProductDetailsById = curry((productDetails, id) =>
  compose(
    find((product) => equals(id, product['id'])),
    values
  )(productDetails)
);

export const getHighestPriorityProduct = curry(
  (productDetails, filteredProductIds) =>
    compose(
      propOr('error', 'name'),
      getProductDetailsById(productDetails),
      find((id) => includes(id, filteredProductIds))
    )(PRODUCT_PRIORITY_LIST)
);

// removeNonNavBarProducts :: string[] -> string[]
export const removeNonNavBarProducts = without([
  PRODUCTS.AGENCY_API.id,
  PRODUCTS.ID_PLUS.id,
]);

export const getProductByProductPriority = (productDetails) =>
  compose(
    getHighestPriorityProduct(productDetails),
    removeNonNavBarProducts,
    keys
  );

export const getInitialRoutingProduct = (productDetails) =>
  ifElse(
    isNotNullOrEmpty,
    getProductByProductPriority(productDetails),
    always('error')
  );

// isLastSelectedProductAvailable :: string -> AuthResponseData -> boolean
export const isLastSelectedProductAvailable = (id, userInfo) =>
  hasPath(['value', 'user', 'product_info', id])(userInfo);

// getProductIdByPath :: string -> (PRODUCTS -> string)
export const getProductIdByPath = (path) =>
  compose(prop('id'), find(propEq('path', path)), values);

export const toPermissionConfig = (apiPermission) => {
  if (isNotNullOrEmpty(apiPermission.country_id)) {
    return prop(
      apiPermission.country_id,
      toProductConfiguration([apiPermission], `${apiPermission.product_id}`)
    );
  } else if (isNotNullOrEmpty(apiPermission.preferred_region)) {
    return prop(
      apiPermission.preferred_region,
      toProductConfiguration([apiPermission], `${apiPermission.product_id}`)
    );
  }
  return toProductConfiguration([apiPermission], `${apiPermission.product_id}`);
};

export const getProductNameById = (id: number) =>
  Object.values(PRODUCTS).find((p) => +p.id === id)?.name;
