import { Injectable } from '@angular/core';
import { UserService } from './user.service';

//TODO: Get rid of UserRoleFacade and derive roles from user permissions itself
@Injectable({
  providedIn: 'root',
})
export class UserRoleFacade {
  private readonly USERROLE = {
    ZEOTAP_ADMIN: 'zeotap_admin',
    ZEOTAP_USER: 'zeotap_user',
    ZEOTAP_ADVISOR: 'zeotap_advisor',
    TELCO_USER: 'telco_user',
    NON_TELCO_USER: 'non_telco_user',
    TELCO_USER_AIRCEL: 'telco_user_aircel',
    AGENCY_ADMIN: 'agency_admin',
    AGENCY_USER: 'agency_user',
    AGENCY_ADMIN_US: 'agency_admin_us',
    AGENCY_USER_US: 'agency_user_us',
    AGENCY_ADMIN_PREMIUM: 'agency_admin_premium',
    AGENCY_USER_PREMIUM: 'agency_user_premium',
    PUBLISHER_ADMIN: 'publisher_admin',
    PUBLISHER_USER: 'publisher_user',
    DATAPARTNER_ADMIN_TELCO: 'datapartner_admin_telco',
    DATAPARTNER_USER_TELCO: 'datapartner_user_telco',
    DATAPARTNER_ADMIN_NONTELCO: 'datapartner_admin_nontelco',
    DATAPARTNER_USER_NONTELCO: 'datapartner_user_nontelco',
    AGENCY_ADMIN_EU: 'agency_admin_eu',
    AGENCY_USER_EU: 'agency_user_eu',
    AGENCY_USER_EU_PREMIUM: 'agency_user_eu_premium',
    AGENCY_ADMIN_EU_PREMIUM: 'agency_admin_eu_premium',
    AGENCY_ADMIN_INDIA: 'agency_admin_india',
    AGENCY_US_PREMIUM: 'agency_us_premium',
    INTERNAL_USER: 'Global_Premium_User',
  };

  constructor(public userService: UserService) {}

  isZeotapUser(productName: string): boolean {
    const role = this.userService.getUserRole(productName);
    switch (role) {
      case this.USERROLE.ZEOTAP_ADMIN:
      case this.USERROLE.ZEOTAP_USER:
      case this.USERROLE.ZEOTAP_ADVISOR:
        return true;
      default:
        return false;
    }
  }

  isAgencyUserNonUS(productName: string): boolean {
    const role = this.userService.getUserRole(productName);
    switch (role) {
      case this.USERROLE.AGENCY_ADMIN:
      case this.USERROLE.AGENCY_USER:
        return true;
      default:
        return false;
    }
  }

  isAgencyUser(productName: string): boolean {
    const role = this.userService.getUserRole(productName);
    switch (role) {
      case this.USERROLE.AGENCY_ADMIN:
      case this.USERROLE.AGENCY_USER:
      case this.USERROLE.AGENCY_ADMIN_US:
      case this.USERROLE.AGENCY_USER_US:
      case this.USERROLE.AGENCY_ADMIN_PREMIUM:
      case this.USERROLE.AGENCY_USER_PREMIUM:
      case this.USERROLE.AGENCY_US_PREMIUM:
        return true;
      default:
        return false;
    }
  }

  isAgencyUserUS(productName: string): boolean {
    const role = this.userService.getUserRole(productName);
    switch (role) {
      case this.USERROLE.AGENCY_ADMIN_US:
      case this.USERROLE.AGENCY_USER_US:
      case this.USERROLE.AGENCY_ADMIN_PREMIUM:
      case this.USERROLE.AGENCY_USER_PREMIUM:
      case this.USERROLE.AGENCY_US_PREMIUM:
        return true;
      default:
        return false;
    }
  }

  isAgencyUserEU(productName: string): boolean {
    const role = this.userService.getUserRole(productName);
    switch (role) {
      case this.USERROLE.AGENCY_ADMIN_EU:
      case this.USERROLE.AGENCY_USER_EU:
      case this.USERROLE.AGENCY_USER_EU_PREMIUM:
      case this.USERROLE.AGENCY_ADMIN_EU_PREMIUM:
        return true;
      default:
        return false;
    }
  }

  isAgencyUserIN(productName: string): boolean {
    const role = this.userService.getUserRole(productName);
    switch (role) {
      case this.USERROLE.AGENCY_ADMIN_INDIA:
        return true;
      default:
        return false;
    }
  }

  isZeotapInternalUser(productName: string): boolean {
    const role = this.userService.getUserRole(productName);
    switch (role) {
      case this.USERROLE.INTERNAL_USER:
        return true;
      default:
        return false;
    }
  }
  isZeotapAdmin(productName: string): boolean {
    const role = this.userService.getUserRole(productName);
    return this.userService.getUserOrg().id === 0 && role === 'Admin';
  }
}
