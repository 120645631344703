import { Pipe, PipeTransform } from '@angular/core';
import { has } from 'ramda';
import { defaultToEmptyObject } from '../../utils';
// TODO: Imporve pipe to do contains using a property of the objects
@Pipe({
  name: 'contains',
})
export class ContainsPipe implements PipeTransform {
  transform(items: any[], item: any): boolean {
    if (!items) {
      return false;
    }
    return items.includes(item);
  }
}

// TODO: Imporve pipe to do contains using a property of the objects
@Pipe({
  name: 'containsKey',
})
export class ContainsKeyPipe implements PipeTransform {
  transform(collection: Record<string, any>, key: string): boolean {
    return has(key, defaultToEmptyObject(collection));
  }
}
