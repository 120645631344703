import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';
import { defaultTo } from 'ramda';

import { DisplayMessage } from './type';
import { isNotNullOrEmpty } from '../utils';

export const TIMER_TIME_TOKEN = new InjectionToken<number>(
  'display_timer_time'
);

@Injectable({
  providedIn: 'root',
})
export class DisplayMessageService {
  _displayMessage$: Subject<DisplayMessage> = new Subject<DisplayMessage>();
  displayMessage$: Observable<DisplayMessage>;
  clearMessage$: Observable<DisplayMessage>;
  _timer: number = 5000;
  constructor(@Optional() @Inject(TIMER_TIME_TOKEN) timer) {
    if (timer) {
      this._timer = timer;
    }
    this.clearMessage$ = this._displayMessage$.pipe(
      delay(this._timer),
      map(() => ({
        message: '',
        msgType: 'success',
        showSnackBar: false,
      }))
    );

    this.displayMessage$ = merge(
      this._displayMessage$,
      this.clearMessage$
    ).pipe(
      startWith({
        message: '',
        msgType: 'success',
        showSnackBar: false,
      } as DisplayMessage)
    );
  }

  displayMessage(message, msgType, customClass?) {
    this._displayMessage$.next({
      msgType,
      message,
      showSnackBar: true,
      ...(isNotNullOrEmpty(customClass) ? { customClass } : {}),
    });
  }

  displaySuccessMsg(message, customClass?) {
    this.displayMessage(message, 'success', customClass);
  }

  displayWarningMsg(message, customClass?) {
    this.displayMessage(message, 'warning', customClass);
  }

  displayErrorMsg(message, customClass?) {
    this.displayMessage(message, 'error', customClass);
  }
  listenToDisplayMessage(): Observable<DisplayMessage> {
    return this.displayMessage$;
  }
}
