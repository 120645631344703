import { Component } from '@angular/core';

@Component({
  selector: 'zui-poster-content',
  styleUrls: ['./poster.component.scss'],
  template: `
    <div class="poster-content">
      <ng-content></ng-content>
    </div>
  `
})
export class PosterContentComponent {}
