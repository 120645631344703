<div class="slider-container" [ngClass]="{'disabled': disabled}" [ngStyle]="style">
    <input type="range" (mousedown)="onMouseDown()" (mouseup)="onMouseUp()" [min]="min" [max]="max" [(ngModel)]="value" [ngClass]="{'disableCursor': disabled}"/>
    <div>
        <div>
            <div class="track"></div>
            <div class="selected-track" [style.width]="(value - min) * 100 / (max-min) + '%'"></div>
            <div class="handle" [style.left]="(value - min) * 100 / (max-min) + '%'" [ngClass]="{'pressed': pressedHandle}"></div>
            <div class="slider-tooltip" *ngIf="!disabled" [style.left.%]="(value - min) * 100 / (max-min)">
                <span class="icon down-triangle"></span>
                <label class="value zui-mediumtext zui-gray2-text-color">{{getFormattedValue(value)}}</label>
            </div>
        </div>
    </div>
</div>