import { ValidatorFn, Validators } from '@angular/forms';
import { isNotNullOrEmpty } from '@zeotap-ui/core';

export interface DisplayConditionFn {
  (formValue: any): boolean;
}
export class QuestionBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  maxLength: number;
  validations: ValidatorFn[];
  displayConditionFn: DisplayConditionFn;
  displayConditionDependentFieldId?: string;
  tooltip: string;
  subtext: string;
  valueOptions?: any[];
  disabled?: boolean;
  configRule?: string;
  asyncConfigRule?: string;
  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      maxLength?: number;
      order?: number;
      controlType?: string;
      displayConditionFn?: DisplayConditionFn;
      displayConditionDependentFieldId?: string;
      tooltip?: string;
      subtext?: string;
      valueOptions?: any[];
      disabled?: boolean;
      configRule?: string;
      asyncConfigRule?: string;
    } = {},
    validations: ValidatorFn[] = []
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.maxLength = !!options.maxLength ? options.maxLength : -1;
    this.validations = [
      ...(this.required ? [Validators.required] : []),
      ...(this.maxLength > -1 ? [Validators.maxLength(this.maxLength)] : []),
      ...validations,
    ];
    this.displayConditionFn = !!options.displayConditionFn
      ? options.displayConditionFn
      : (f) => true;
    this.displayConditionDependentFieldId = !!options.displayConditionDependentFieldId
      ? options.displayConditionDependentFieldId
      : null;
    this.tooltip = !!options.tooltip ? options.tooltip : null;
    this.subtext = !!options.subtext ? options.subtext : null;
    this.valueOptions = !!options.valueOptions ? options.valueOptions : [];
    this.disabled = !!options.disabled ? options.disabled : false;
    this.configRule = options?.configRule;
    this.asyncConfigRule = options?.asyncConfigRule;
  }
}
