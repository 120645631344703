import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, share, shareReplay } from 'rxjs/operators';
import { compose, filter, keys, map } from 'ramda';

export interface ContentState {
  [detectorName: string]: {
    [detectedElementName: string]: number;
    properties?: any;
  };
}

export const regexTest = (regex: RegExp) => (str: string) => regex.test(str);
const getNumberFromName = (name: string, nameRegex: RegExp) => {
  const match = name.match(nameRegex);
  return match ? parseInt(match[1]) : 0;
};

@Injectable({
  providedIn: 'root',
})
export class ContentDetectorService {
  private contentState$ = new BehaviorSubject<ContentState>({});

  detectableContentChanges$ = this.contentState$
    .asObservable()
    .pipe(distinctUntilChanged(), shareReplay(1));

  // arrow getter function for a ts class
  get detectedContentSnapshot() {
    return this.contentState$.getValue();
  }

  // count the detected elements for a detector
  updateContentChanges(detectorName: string, detectedElements: any[]) {
    const currentState = this.contentState$.getValue();
    const currentDetectorState = {};
    const newDetectorState = detectedElements.reduce((acc, element) => {
      const currentCount = currentDetectorState[element?.name]?.count || 0;
      return {
        ...acc,
        [element?.name]: {
          count: currentCount + 1,
          properties: element?.contentProps,
        },
      };
    }, {});
    this.contentState$.next({
      ...currentState,
      [detectorName]: newDetectorState,
    });
  }

  // returns 'default' if not present in state or default-$next-default-number if present
  getDefaultName(): string {
    const currentState = this.contentState$.getValue();
    const defaultName = 'default';
    const defaultNameRegex = new RegExp(`^${defaultName}-(\\d+)$`);
    const defaultNameTest = regexTest(defaultNameRegex);
    const getDefaultNames = compose(filter(defaultNameTest), keys);
    const defaultNames = getDefaultNames(currentState);
    if (defaultNames.length > 0) {
      const defaultNamesNumbers = map(getNumberFromName, defaultNames);
      const nextNumber = Math.max(...defaultNamesNumbers) + 1;
      return `${defaultName}-${nextNumber}`;
    }
    return defaultName;
  }

  constructor() {}
}
