import { Injectable } from '@angular/core';
import { RBACPermissionState } from '../types/rbac-permission';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { StorageService } from '../../storage';
import { isSuccess } from '../../http';
import { UserPermissionAPIV2Response, UserService } from '../../user';
import { PRODUCTS } from '../../product';

const RBAC_PERMISSION_INITIAL_STATE: RBACPermissionState = {
  rbacPermissions: [],
  inProgress: true,
  error: null,
};

@Injectable({
  providedIn: 'any',
})
export class RBACPermissionFacade {
  private store$ = new BehaviorSubject<RBACPermissionState>(
    RBAC_PERMISSION_INITIAL_STATE
  );
  private state$ = this.store$.asObservable();

  loading$ = this.state$.pipe(
    map((state) => state.inProgress),
    distinctUntilChanged(),
    shareReplay(1)
  );

  error$ = this.state$.pipe(
    map((state) => state.error),
    distinctUntilChanged(),
    shareReplay(1)
  );

  rbacPermissions$ = this.state$.pipe(
    map((state) => state.rbacPermissions),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private storageService: StorageService,
    private userService: UserService
  ) {
    const userOrgId = this.storageService.getUserInfo().organization;

    this.userService
      .getUserPermissionV2(userOrgId, [+PRODUCTS.RBAC.id])
      .subscribe((res: UserPermissionAPIV2Response) => {
        if (isSuccess(res.status)) {
          this.store$.next({
            rbacPermissions: res.data.permissions,
            inProgress: false,
            error: null,
          });
        } else {
          this.store$.next({
            rbacPermissions: [],
            inProgress: false,
            error: res.error,
          });
        }
      });
  }
}
