<ng-container
  *ngIf="{
    allowedProductIds: allowedProductIds$ | async,
    userHasAccessToProduct: userHasAccessToProduct$ | async,
    cdpWidgets: cdpWidgets$ | async,
    orgHasAccessToProduct: orgHasAccessToProduct$ | async,
    loading: loading$ | async,
    eventsDateRange: eventsDateRange$ | async,
    selectedEventsPeriod: selectedEventsPeriod$ | async,
    toggleCumulative: toggleCumulative$ | async,
    childOrgs: childOrgs$ | async
  } as data"
>
  <div class="cdp-container bg-white" [ngClass]="{ 'invisible h-0': !data.userHasAccessToProduct }">
    <div class="component-loader" *ngIf="data.loading">
      <zap-status [text]="'UU_LOADING_TEXT' | translate" [showText]="true" [type]="'progress'"></zap-status>
    </div>
    <div class="flex items-center font-normal text-sm gap-2 mb-2" *ngIf="!data.loading && data.childOrgs?.length > 1 && showToggle">
      <div>Show Cumulative Usage Data</div>
      <mat-slide-toggle class="toggle" [checked]="data?.toggleCumulative" (change)="toggleCumulative($event)"> </mat-slide-toggle>
    </div>
    <br />
    <section class="metrics-box mb-4 flex w-full flex-wrap 2xl:justify-between" [ngClass]="{ 'invisible !h-0': data.loading }">
      <ng-container *ngIf="widgetIdsMap['totalInboundEvents'] as totalInboundEventsId">
        <span *ngIf="data?.cdpWidgets?.[totalInboundEventsId]" [id]="'uu-widget-' + totalInboundEventsId" class="w-1/5"></span>
      </ng-container>
      <ng-container *ngIf="widgetIdsMap['apiHits'] as apiHits">
        <span *ngIf="data?.cdpWidgets?.[apiHits]" [id]="'uu-widget-' + apiHits" class="w-1/5"></span>
      </ng-container>
      <ng-container *ngIf="widgetIdsMap['totalUniqueUsers'] as totalUniqueUsersId">
        <span *ngIf="data?.cdpWidgets?.[totalUniqueUsersId]" [id]="'uu-widget-' + totalUniqueUsersId" class="w-1/5"></span>
      </ng-container>
      <ng-container *ngIf="widgetIdsMap['totalUnityUsers'] as totalUnityUsersId">
        <span *ngIf="data?.cdpWidgets?.[totalUnityUsersId]" [id]="'uu-widget-' + totalUnityUsersId" class="w-1/5"></span>
      </ng-container>
      <ng-container *ngIf="widgetIdsMap['last30DaysLogins'] as last30DaysLoginsId">
        <span *ngIf="data?.cdpWidgets?.[last30DaysLoginsId]" [id]="'uu-widget-' + last30DaysLoginsId" class="w-1/5"></span>
      </ng-container>
    </section>
    <section class="metrics-container" [ngClass]="{ 'invisible !h-0': data.loading }">
      <zap-date-range *ngIf="data?.cdpWidgets" [startDate]="data?.eventsDateRange?.startDate" [endDate]="data?.eventsDateRange?.endDate" [minStartDate]="minStartDate" (dateRangeClosed)="updateDateRange($event)"></zap-date-range>

      <!-- collect EVENTS-->
      <ng-container>
        <div class="font-primary-medium">
          <div class="flex items-center justify-between">
            <ng-container *ngIf="widgetIdsMap['inboundEventsBreakdown'] as inboundEventsBreakdownId">
              <div *ngIf="data?.cdpWidgets?.[inboundEventsBreakdownId] as inboundEventWidget" class="widget-container w-1/4 border border-solid border-grey-300/30 inbound-chart" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.DATA_MANAGER.id]}">
                <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0 font-primary-medium">
                  <div class="font-primary-medium">{{ inboundEventWidget.title }}</div>
                </div>
                <div class="cdp-widget" [id]="'uu-widget-' + inboundEventsBreakdownId"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="widgetIdsMap['inboundEventsOvertime'] as inboundEventsOvertimeId">
              <div *ngIf="data?.cdpWidgets?.[inboundEventsOvertimeId] as inboundEventsOvertimeWidget" class="widget-container w-3/4 border border-solid border-grey-300/30" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.DATA_MANAGER.id]}">
                <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0">
                  <div class="font-primary-medium">{{ inboundEventsOvertimeWidget.title }}</div>
                  <zap-date-filters [selectedEventsPeriod]="data?.selectedEventsPeriod" (onEventsPeriodicalChange)="updateEventsPeriod($event)"></zap-date-filters>
                </div>
                <div class="cdp-widget" [id]="'uu-widget-' + inboundEventsOvertimeId"></div>
              </div>
            </ng-container>
          </div>
          <div class="mt-4 border border-solid border-grey-300/30 sources-widget">
            <ng-container *ngIf="widgetIdsMap['top5sources'] as top5sourcesId">
              <div *ngIf="data?.cdpWidgets?.[top5sourcesId] as top5sourcesIdWidget" class="widget-container" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.DATA_MANAGER.id]}">
                <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0 font-primary-medium">{{ top5sourcesIdWidget.title }}</div>
                <div class="cdp-widget" [id]="'uu-widget-' + top5sourcesId"></div>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="!data.orgHasAccessToProduct?.[products.DATA_MANAGER.id]">
            <ng-container *ngTemplateOutlet="upgradeCollect"></ng-container>
          </ng-container>
        </div>
      </ng-container>

      <!-- Audience -->
      <ng-container>
        <div class="flex items-center justify-between gap-4">
          <ng-container *ngIf="widgetIdsMap['audienceCount'] as audienceCountId">
            <div *ngIf="data?.cdpWidgets?.[audienceCountId] as audienceCountWidget" class="widget-container w-1/2 bg-white border border-solid border-grey-300/30" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.AUDIENCE.id]}">
              <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0 font-primary-medium">{{ audienceCountWidget.title }}</div>
              <div class="cdp-widget" [id]="'uu-widget-' + audienceCountId"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="widgetIdsMap['audienceStatus'] as audienceStatusId">
            <div *ngIf="data?.cdpWidgets?.[audienceStatusId] as audienceStatusWidget" class="widget-container w-1/2 bg-white border border-solid border-grey-300/30" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.AUDIENCE.id]}">
              <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0 font-primary-medium">{{ audienceStatusWidget.title }}</div>
              <div class="cdp-widget" [id]="'uu-widget-' + audienceStatusId"></div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="!data.orgHasAccessToProduct?.[products.AUDIENCE.id]">
          <ng-container *ngTemplateOutlet="upgradeAudience"></ng-container>
        </ng-container>
      </ng-container>

      <!-- connect -->
      <ng-container>
        <div class="flex items-center justify-between gap-4">
          <ng-container *ngIf="widgetIdsMap['segmentsSuccess'] as segmentsSuccessId">
            <div *ngIf="data?.cdpWidgets?.[segmentsSuccessId] as segmentsSuccessWidget" class="widget-container w-1/3 bg-white border border-solid border-grey-300/30" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.CONNECT.id]}">
              <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0 font-primary-medium">{{ segmentsSuccessWidget.title }}</div>
              <div class="cdp-widget" [id]="'uu-widget-' + segmentsSuccessId"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="widgetIdsMap['segmentsStatus'] as segmentsStatusId">
            <div *ngIf="data?.cdpWidgets?.[segmentsStatusId] as segmentsStatusWidget" class="widget-container w-1/3 bg-white border border-solid border-grey-300/30" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.CONNECT.id]}">
              <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0 font-primary-medium">{{ segmentsStatusWidget.title }}</div>
              <div class="cdp-widget" [id]="'uu-widget-' + segmentsStatusId"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="widgetIdsMap['noOfSegmentsByExtension'] as noOfSegmentsByExtensionId">
            <div *ngIf="data?.cdpWidgets?.[noOfSegmentsByExtensionId] as noOfSegmentsWidget" class="widget-container w-1/3 bg-white border border-solid border-grey-300/30" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.CONNECT.id]}">
              <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0 font-primary-medium">{{ noOfSegmentsWidget.title }}</div>
              <div class="cdp-widget" [id]="'uu-widget-' + noOfSegmentsByExtensionId"></div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Collect CA -->
      <ng-container>
        <ng-container *ngIf="widgetIdsMap['noOfCAbyState'] as noOfCAByStateId">
          <div *ngIf="data?.cdpWidgets?.[noOfCAByStateId] as noOfCAByStateWidget" class="widget-container border border-solid border-grey-300/30" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.DATA_MANAGER.id]}">
            <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0 font-primary-medium">{{ noOfCAByStateWidget.title }}</div>
            <div class="cdp-widget" [id]="'uu-widget-' + noOfCAByStateId"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!data.orgHasAccessToProduct?.[products.DATA_MANAGER.id]">
          <ng-container *ngTemplateOutlet="upgradeCalculatedAttributes"></ng-container>
        </ng-container>
      </ng-container>

      <!-- Symphony -->
      <ng-container>
        <ng-container *ngIf="widgetIdsMap['noOfWorkflowsByState'] as noOfWorkflowsByStateId">
          <div *ngIf="data?.cdpWidgets?.[noOfWorkflowsByStateId] as noOfWorkflowsWidget" class="widget-container border border-solid border-grey-300/30" [ngClass]="{'invisible h-0': !data.orgHasAccessToProduct?.[products.SYMPHONY_V2.id]}">
            <div class="bg-white h-16 flex items-center justify-between border-solid border-b border-x-0 border-t-0 border-grey-300/30 px-[1.875rem] py-0 font-primary-medium">{{ noOfWorkflowsWidget.title }}</div>
            <div class="cdp-widget" [id]="'uu-widget-' + noOfWorkflowsByStateId"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!data.orgHasAccessToProduct?.[products.SYMPHONY_V2.id]">
          <ng-container *ngTemplateOutlet="upgradeSymphony"></ng-container>
        </ng-container>
      </ng-container>
      <ng-template #upgradeAudience>
        <div class="upgrade">
          <div class="text-xl mt-4 mb-[1.625rem] mx-0 font-secondary-medium">{{ 'UU_AUDIENCE_SEGMENTS' | translate | uppercase }}</div>
          <div class="upgrade-content w-full h-60 flex flex-col items-center justify-center opacity-[0.85] border border-solid border-grey-300/30 rounded text-white not-italic font-primary">
            <div class="upgrade-heading leading-[1.6rem] xl:leading-[3.6rem] text-xl xl:text-4xl font-semibold">{{ 'UU_UPGRADE_TO_ZEOTAP_AUDIENCE_PREMIUM' | translate }}</div>
            <div class="upgrade-subtext text-[0.7rem] leading-5 xl:leading-9 xl:text-2xl not-italic font-normal">{{ 'UU_UPGRADE_TO_ZEOTAP_PREMIUM_SUBTEXT' | translate }}</div>
          </div>
        </div>
      </ng-template>
      <ng-template #upgradeSymphony>
        <div class="upgrade">
          <div class="text-xl mt-4 mb-[1.625rem] mx-0 font-secondary-medium">{{ 'UU_SYMPHONY_WORKFLOWS' | translate | uppercase }}</div>
          <div class="upgrade-content w-full h-60 flex flex-col items-center justify-center opacity-[0.85] border border-solid border-grey-300/30 rounded text-white not-italic font-primary">
            <div class="upgrade-heading leading-[1.6rem] xl:leading-[3.6rem] text-xl xl:text-4xl font-semibold">{{ 'UU_UPGRADE_TO_REAL_TIME_AUDIENCES' | translate }}</div>
            <div class="upgrade-subtext text-[0.7rem] leading-5 xl:leading-9 xl:text-2xl not-italic font-normal">{{ 'UU_UPGRADE_TO_REAL_TIME_AUDIENCES_SUBTEXT' | translate }}</div>
          </div>
        </div>
      </ng-template>
      <ng-template #upgradeCollect>
        <div class="upgrade">
          <div class="text-xl mt-4 mb-[1.625rem] mx-0 font-secondary-medium">{{ 'UU_INBOUND_EVENTS' | translate | uppercase }}</div>
          <div class="upgrade-content w-full h-60 flex flex-col items-center justify-center opacity-[0.85] border border-solid border-grey-300/30 rounded text-white not-italic font-primary">
            <div class="upgrade-heading leading-[1.6rem] xl:leading-[3.6rem] text-xl xl:text-4xl font-semibold">{{ 'UU_UPGRADE_TO_COLLECT' | translate }}</div>
            <div class="upgrade-subtext text-[0.7rem] leading-5 xl:leading-9 xl:text-2xl not-italic font-normal">{{ 'UU_UPGRADE_TO_COLLECT_SUBTEXT' | translate }}</div>
          </div>
        </div>
      </ng-template>
      <ng-template #upgradeCalculatedAttributes>
        <div class="upgrade">
          <div class="text-xl mt-4 mb-[1.625rem] mx-0 font-secondary-medium">{{ 'UU_CALCULATED_ATTRIBUTES' | translate | uppercase }}</div>
          <div class="w-full h-60 flex flex-col items-center justify-center opacity-[0.85] border border-solid border-grey-300/30 rounded text-white not-italic font-primary">
            <div class="upgrade-heading leading-[1.6rem] xl:leading-[3.6rem] text-xl xl:text-4xl font-semibold">{{ 'UU_UPGRADE_TO_CALCULATED_ATTRIBUTES' | translate }}</div>
            <div class="upgrade-subtext text-[0.7rem] leading-5 xl:leading-9 xl:text-2xl not-italic font-normal">{{ 'UU_UPGRADE_TO_CALCULATED_ATTRIBUTES_SUBTEXT' | translate }}</div>
          </div>
        </div>
      </ng-template>
    </section>
  </div>
</ng-container>
