<ng-container
  *ngIf="{
    showActions: showActions$ | async
  } as data"
>
  <div [ngClass]="{ '!cursor-not-allowed': disableOption }">
    <div class="cursor-pointer w-7 h-7 pt-[0.1875rem] pb-[0.3125rem] px-[0.3125rem] hover:bg-primary-1/10 hover:rounded-[50%]" [ngClass]="{ 'disabledDot !pointer-events-none hover:!bg-transparent': disableOption, hidden: hideDots(), disabled: disableDots() }" cdk-overlay-origin #actionTrigger="cdkOverlayOrigin" *ngIf="showDots" (click)="showActions$.next(rowId); openActionContainer(); $event.stopPropagation()">
      <div class="hover:relative hover:pt-2 hover:bottom-2">
        <span class="font-bold text-primary-1 text-sm pl-0.5 icon dotted-circles" [ngClass]="{ '!text-grey/52': disableOption }"></span>
      </div>
    </div>
  </div>
</ng-container>
<ng-template cdk-portal #actionContainer="cdkPortal">
  <div class="actions cursor-pointer overflow-hidden z-10 rounded shadow-5 h-auto max-h-48 max-w-[14rem] w-32 bg-white" [ngClass]="zapActionsClass" (mouseleave)="showActions$.next(0); closeActionContainer()">
    <ng-container *ngFor="let action of actions; let i = index">
      <div *ngIf="action.show" class="font-primary text-sm text-primary-1-900 action h-9 hover:bg-[#ececf1]" [ngClass]="{ disabled: action.disable }" (click)="actionClicked(action.value)">
        <!--TODO: FIX HARDCODED COLOR-->
        <span class="icon inline-block !text-xs text-grey/52 !mx-2.5 my-0 px-0 py-2.5" [ngClass]="localClassMap[action.icon] || action.icon"></span>
        <span class="link inline-block capitalize">{{ action.value }}</span>
      </div>
    </ng-container>
  </div>
</ng-template>
