import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SUPPORT_URL } from '../const';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {
  supportUrl = SUPPORT_URL;
  docsUrl = 'http://docs.zeotap.com/';
  constructor(private router: Router) {}
  ngOnInit(): void {}

  routeToHome() {
    this.router.navigate(['']);
  }
}
