import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(value: number, notInBytes?: boolean): any {
    if (!value) return notInBytes ? '0 KB' : '0 B';
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let size = parseInt(`${Math.floor(Math.log(value) / Math.log(1024))}`, 10);
    size = size === 0 && notInBytes ? 1 : size;
    return `${(value / 1024 ** size).toFixed(2).replace(/\.0+$/, '')} ${
      sizes[size]
    }`;
  }
}
