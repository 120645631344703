<div class="checkbox-group">
  <ng-container *ngFor="let option of options; let ind = index">
    <div>
      <ng-container *ngIf="isString(option)">
        <input class="font-primary text-sm text-grey-900 checkbox-btn icon" [value]="option" type="checkbox" [ngClass]="isSelected(option) ? 'checked' : 'unchecked'" [disabled]="disabled" (click)="onOptionSelected(option)" />
        <label class="font-primary text-sm text-grey-900 checkbox-value" (click)="onOptionSelected(option)">{{ option }}</label>
      </ng-container>
      <ng-container *ngIf="!isString(option)">
        <input class="font-primary text-sm text-grey-900 checkbox-btn icon" [value]="option.value" type="checkbox" [ngClass]="isSelected(option.value) ? 'checked' : 'unchecked'" [disabled]="option?.disabled" (click)="onOptionSelected(option.value)" />
        <label class="font-primary text-sm text-grey-900 checkbox-value" (click)="onOptionSelected(option.value)">{{ option.label }}</label>
      </ng-container>
    </div>
  </ng-container>
</div>
