import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth, AuthState } from '@okta/okta-auth-js';
import { filter, takeUntil } from 'rxjs/operators';
import {
  AuthFacadeService,
  LoggingService,
  isNotNullOrEmpty,
} from '@zeotap-ui/core';
import { RoutingEventsFacadeService } from './routing-events-facade.service';
import { Subject } from 'rxjs';
import { ConfigService } from '@zeotap-ui/config';
import { environment } from '@cdp/environments/environment';

declare let amplitude;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [RoutingEventsFacadeService],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'cdp';
  routingFacade = inject(RoutingEventsFacadeService);
  unsubscribe$ = new Subject();
  constructor(
    private authFacade: AuthFacadeService,
    private oktaAuthService: OktaAuthStateService,
    private loggingService: LoggingService,
    private configService: ConfigService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this.hideTCModal$.next(false);
    this.oktaAuthService.authState$
      .pipe(
        filter(
          (authState: AuthState) =>
            !!authState?.isAuthenticated &&
            isNotNullOrEmpty(this.oktaAuth.getAccessToken())
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.authFacade.initAuthState();
      });
    amplitude.getInstance().init(this.configService.appConfig.amplitudeAPIKey);
    this.loggingService.logContext({
      buildVersion: environment.buildVersion,
      appVersion: environment.version,
    });

    console.debug('buildVersion: ', environment.buildVersion);
  }

  appUserState$ = this.authFacade.state$;
  appUserInfo$ = this.authFacade.userInfo$;
  hideTCModal$ = new Subject<boolean>();
  onRetryUserInit() {
    this.authFacade.initAuthState();
  }
  ngOnInit() {}
  ngOnDestroy(): void {}

  sendUserAcceptanceOfTermsAndConditions() {
    this.authFacade.sendTermsAndConditionsAcceptance(true);
    this.hideTCModal$.next(true);
  }
}
