<div *ngIf="chips?.length" class="chips-container grid grid-cols-[max-content_1fr]" [ngClass]="{ 'grid-cols-[minmax(0,max-content)_1fr]': displayText }">
  <div class="chips-info flex" [ngClass]="{ 'inline-block': displayText }">
    <div class="chip-container" *ngFor="let chip of chips | slice: 0:maxDisplayCount; let i = index; let lastChip = last" [ngClass]="{ 'last-chip': lastChip, 'ellipses-text': displayText }">
      <img *ngIf="!displayText" [src]="chip.icon" class="bg-primary-1-100/50 border border-solid border-grey-300/30 w-[1.3125rem] h-[1.3125rem] inline-block relative box-border rounded-[50%] chip" [ngClass]="{ 'drop-shadow-1': i !== 0 && !lastChip }" customToolTip [text]="chip.displayName" [customTooltipClass]="'hover-tooltip'" [ngStyle]="{ 'right.rem': i * 0.375, 'z-index': maxDisplayCount - i }" />
      <label *ngIf="displayText" class="text-xs font-primary text-grey/52 inline-block chip-text ellipses-text" customToolTip [text]="chip.displayName" [customTooltipClass]="'hover-tooltip'">{{ chip.displayName }} <span *ngIf="!lastChip">,</span></label>
    </div>
  </div>
  <div class="more-chip-div inline-block ml-2" *ngIf="chips?.length > maxDisplayCount" [ngClass]="{ 'bg-primary-1-100/50 w-5 h-5 flex items-center ml-[-0.3125rem] p-[0.15rem] rounded-[50%]': !displayText }">
    <label class="text-grey/52 more-chips text-xs font-primary-medium inline-block" customToolTip [contentTemplate]="moreInfoTooltip" [ngClass]="{ '!text-2xs !text-grey-800': !displayText }" [customTooltipClass]="'more-chip-tooltip'" [positions]="tooltipPositions">+{{ chips.length - maxDisplayCount }} <span *ngIf="displayText">More</span></label>
  </div>
</div>
<ng-container *ngIf="!chips?.length">
  <div class="font-primary text-sm text-primary-1-900">-</div>
</ng-container>
<ng-template #moreInfoTooltip>
  <div *ngFor="let chip of moreChips" class="font-primary text-xs text-white more-chip-container">
    {{ chip.displayName }}
  </div>
</ng-template>
