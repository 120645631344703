import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { any, propEq, values, not } from 'ramda';

@Component({
  selector: 'zap-custom-filter',
  templateUrl: './custom-filter.component.html',
})
export class CustomFilterComponent implements OnInit {
  @Input() filtersObject: Object;
  @Input() loading: boolean;
  @Input() error: string;
  @Input() normalHeader: boolean = false;
  @Input() count: boolean = true;
  @Output() filterSelectionChanged = new EventEmitter();
  @Output() reloadFilters = new EventEmitter();
  @Output() clearAllFilters = new EventEmitter();
  @Output() clearSelectedFilter = new EventEmitter();

  ngOnInit() {}

  onClickClearAll() {
    this.clearAllFilters.emit();
  }

  onReloadFilters() {
    this.reloadFilters.emit();
  }

  showClearAll() {
    return (
      not(any(any(propEq('selected', true)), values(this.filtersObject))) ||
      this.loading
    );
  }

  showClear(header) {
    return not(
      any(propEq('selected', true), values(this.filtersObject[header]))
    );
  }

  onClearSelection(selectedFilterKey) {
    this.clearSelectedFilter.emit(selectedFilterKey);
  }

  toggleFilter(filterKey, filterValue) {
    this.filterSelectionChanged.emit({
      selectedFilterValue: filterValue,
      selectedFilterKey: filterKey,
    });
  }
}
