import { Status } from '../../http';

export interface CustomToken {
  token: string;
}

export interface CustomTokenAPIResponse {
  status: Status;
  data?: CustomToken;
  error?: string;
}
