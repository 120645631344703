import { NgModule } from '@angular/core';
import { CoreDirectivesModule } from '@zeotap-ui/core';
import { AccordionMenuModule } from './accordion-menu';
import { ActionMessageBoxModule } from './action-message-box/action-message-box.module';
import { ActionsOverlayModule } from './actions-overlay';
import { ZeotapButtonModule } from './button';
import { CategoryFilterModule } from './categoryFilter';
import { ChartsModule } from './charts';
import { CheckBoxGroupModule } from './checkbox-group/checkbox-group.module';
import { ClipBoardCopyModule } from './clipboard-copy';
import { CodeSnippetModule } from './code-snippet';
import { CollapseButtonModule } from './collapse-button';
import { CollapseSectionModule } from './collapse-section';
import { ColumnSelectionPopupModule } from './column-selection-popup';
import { CountChipModule } from './count-chip';
import { CustomFilterModule } from './custom-filter';
import { CustomFooterModule } from './custom-footer';
import { CustomTabsModule } from './custom-tabs/custom-tabs.module';
import { CustomTimePickerModule } from './custom-time-picker';
import { DateFiltersModule } from './date-filters';
import { DateRangePickerModule } from './date-range-picker';
import { DialogPromptLegacyModule } from './dialog-prompt-legacy';
import { DisclaimerModalModule } from './disclaimer-modal';
import { DottedMenuModule } from './dotted-menu';
import { DottedOptionsModule } from './dotted-options';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';
import { EmailModalModule } from './email-modal';
import { FileUploaderModule } from './file-uploader';
import { FloatingPanelModule } from './floating-panel';
import { ForgotPasswordModule } from './forgot-password';
import { IconModule } from './icon';
import { InfoRibbonModule } from './info-ribbon';
import { KeyValueListInputModule } from './key-value-list-input';
import { LoaderModule } from './loader';
import { MessagePromptModule } from './message-prompt';
import { ModalHolderModule } from './modal-holder';
import { MultiHeaderFilterModule } from './multi-header-filter';
import { NoContentImageTextModule } from './no-content-image-text';
import { NoPermissionModule } from './no-permission';
import { PreviewDataTableModule } from './preview-data-table';
import { SearchableHeaderLabelModule } from './searchable-header-label';
import { ShowDocumentationModule } from './show-documentation';
import { StageTrackerModule } from './stage-tracker';
import { StatusModule } from './status';
import { StepperModule } from './stepper';
import { ToggleWindowModule } from './toggle-window';
import { VerticalStepperModule } from './vertical-stepper';
import { WelcomePageModule } from './welcome-page';
import { DynamicInputFieldModule } from './dynamic-input-field';
import { DynamicGraphFormModule } from './dynamic-graph-form';

@NgModule({
  imports: [CoreDirectivesModule],
  exports: [
    MessagePromptModule,
    ModalHolderModule,
    ToggleWindowModule,
    StatusModule,
    ChartsModule,
    ClipBoardCopyModule,
    NoContentImageTextModule,
    DottedMenuModule,
    InfoRibbonModule,
    WelcomePageModule,
    CountChipModule,
    DialogPromptLegacyModule,
    ColumnSelectionPopupModule,
    DottedOptionsModule,
    CustomFooterModule,
    LoaderModule,
    CheckBoxGroupModule,
    DynamicFormModule,
    NoPermissionModule,
    CollapseButtonModule,
    CollapseSectionModule,
    ActionMessageBoxModule,
    ShowDocumentationModule,
    StepperModule,
    CategoryFilterModule,
    FileUploaderModule,
    PreviewDataTableModule,
    EmailModalModule,
    ActionsOverlayModule,
    SearchableHeaderLabelModule,
    KeyValueListInputModule,
    StageTrackerModule,
    FloatingPanelModule,
    ZeotapButtonModule,
    CustomTabsModule,
    ForgotPasswordModule,
    DisclaimerModalModule,
    AccordionMenuModule,
    CodeSnippetModule,
    VerticalStepperModule,
    DateFiltersModule,
    CustomFilterModule,
    DateRangePickerModule,
    CustomTimePickerModule,
    MultiHeaderFilterModule,
    IconModule,
    DynamicInputFieldModule,
    DynamicGraphFormModule,
  ],
})
export class UIComponentsModule {}
