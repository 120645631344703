import { PRODUCTS } from '@zeotap-ui/core';

export const LOCALE_MAP = {
  UU_ACTIVE_CA: 'Active Calculated Attributes',
  UU_CALCULATED_ATTRIBUTES: 'Calculated Attributes',
  UU_AUDIENCE_SEGMENTS: 'Audience Segments',
  UU_ACTIVE_SEGMENTS_WITH_FUEL: 'Successful Segments',
  UU_NO_OF_SEGMENTS_BY_STATE: 'Number of Segments by Extension',
  UU_NO_OF_CA_BY_STATUS: 'Number of Calculated Attributes by State',
  UU_SYMPHONY_WORKFLOWS: 'Symphony Workflows',
  UU_NO_OF_WORKFLOWS_BY_STATUS: 'Number of Workflows by State',
  UU_INBOUND_EVENTS: 'Inbound Events',
  UU_INBOUND_EVENTS_OVER_TIME: 'Total Inbound Events over Time',
  UU_NO_DATA_TO_DISPLAY: 'No Data to show',
  UU_INBOUND_EVENTS_BREAKDOWN: 'Total Inbound Events Breakdown',
  UU_TOTAL_INBOUND_EVENTS: 'Total Inbound Events',
  UU_UNIQUES_USERS: 'Overall Unique Users',
  UU_TOTAL_ACTIVE_SEGMENTS: 'Total Successful Segments',
  UU_LAST_30_DAYS_LOGINS: 'Last 30 Days Logins',
  UU_TOTAL_USER_ACCOUNTS: 'Total Unity Users',
  UU_UPGRADE_TO_ZEOTAP_AUDIENCE_PREMIUM:
    'Upgrade to our Audience Management module - Audience',
  UU_UPGRADE_TO_ZEOTAP_PREMIUM_SUBTEXT:
    'Leverage powerful segmentation capabilities to create customer cohorts',
  UU_UPGRADE_TO_REAL_TIME_AUDIENCES: 'Upgrade to Real Time Orchestration',
  UU_UPGRADE_TO_REAL_TIME_AUDIENCES_SUBTEXT:
    'Offer consistent, real-time personalisation',
  UU_UPGRADE_TO_COLLECT: 'Upgrade to our data ingestion module - Collect',
  UU_UPGRADE_TO_COLLECT_SUBTEXT: 'Ingest and unify data from disparate sources',
  UU_UPGRADE_TO_CALCULATED_ATTRIBUTES: 'Upgrade to Calculated Attributes',
  UU_UPGRADE_TO_CALCULATED_ATTRIBUTES_SUBTEXT:
    'Draw more user insights from your first party data',
  UU_NO_DATA_DOUGHNUT_CHART_SRC:
    'https://content.zeotap.com/img/no_data_doughnut.svg',
  UU_NO_DATA_VERTICAL_BAR_CHART_SRC:
    'https://content.zeotap.com/img/no_data_vertical_bar.svg',
  UU_NO_DATA_HORIZONTAL_BAR_CHART_SRC:
    'https://content.zeotap.com/img/no_data_horizontal_bar.svg',
  UU_NO_DATA_LINE_CHART_SRC:
    'https://content.zeotap.com/img/no_data_line_chart.svg',
  UU_LOADING_EVENTS: 'Loading Events data...',
  UU_LOADING_CALCULATED_ATTRIBUTES: 'Loading Calculated Attributes...',
  UU_LOADING_CONNECT_SEGMENTS: 'Loading Segments data...',
  UU_LOADING_WORKFLOWS: 'Loading Workflows data...',
};

export const DEFAULT_INBOUND_EVENTS_START_DATE = '2022-04-15';

export const ALLOWED_PRODUCTS = [
  PRODUCTS.DATA_MANAGER,
  PRODUCTS.CONNECT,
  PRODUCTS.SYMPHONY_V2,
];
export const USAGE_AMPLITUDE_EVENTS = {
  USAGE_MODULE_VIEW: 'USAGE_MODULE_VIEW',
};

export const CARD_LABELS = {
  events: 'UU_TOTAL_INBOUND_EVENTS',
  uniqueUsers: 'UU_UNIQUES_USERS',
  segments: 'UU_TOTAL_ACTIVE_SEGMENTS',
  userAccounts: 'UU_TOTAL_USER_ACCOUNTS',
  last30DaysLogins: 'UU_LAST_30_DAYS_LOGINS',
};

export const CDP_ORG_JAQL_FILTER = {
  jaql: {
    dim: '[public_organizations.zeo_org_id]',
    title: 'zeo_org_id',
  },
  isCascading: false,
  disabled: false,
};
