import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangePickerDirective } from './date-range-picker.directive';

@NgModule({
  declarations: [DateRangePickerDirective],
  imports: [CommonModule],
  exports: [DateRangePickerDirective],
})
export class DateRangePickerModule {}
