import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'z-tree-header',
  styleUrls: ['./tree.component.scss'],
  template: `
    <div
      (click)="headerClicked()"
      class="treeHeader icon down-triangle"
      [ngClass]="{ open: open }"
    >
      <ng-content></ng-content>
    </div>
  `
})
export class TreeHeaderComponent {
  @Input() public title: string;
  @Input() public open = true;
  @Output() public clicked: EventEmitter<any> = new EventEmitter();

  public headerClicked() {
    this.clicked.emit();
  }
}
