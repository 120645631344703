import { CountryModel } from '../../country';
import { Status } from '../../http';
import { ProductConfiguration } from '../../product/types';
import { IdName } from '../../types';

//TODO: Remove once all products are integrated with UserPermissionfacade
export type UserPermission = {
  country: CountryModel;
  group_info: IdName;
  permissions: string[];
  config?: ProductConfiguration; //TODO: Remove once ProductConfigFacade is integrated with all modules
  preferred_region?: string;
};

//TODO: Remove once all products are integrated with UserPermissionfacade
export type UserPermissionAPIResponse = {
  status: Status;
  data?: UserPermission[];
  error?: string;
};

export type UserPermissionAPIV2Response = {
  status: Status;
  data?: PermissionCountryState;
  error?: string;
};

export type UserPermissionState = {
  userPermission: string[];
  userCountries: Record<string, CountryModel>;
  inProgress: boolean;
  error?: string;
};

export type PermissionCountryState = {
  permissions: string[];
  countries: Record<string, CountryModel>;
};
