<div class="w-[25rem] flex progress-chart" [ngClass]="{ 'no-labels': !showLabels }">
  <ng-container *ngFor="let item of data | keyvalue: noSortFnPlaceholder; let i = index">
    <div [ngStyle]="{ width: getChartItemWidth(item.value) }" class="chart-item flex flex-col justify-center relative" *ngIf="i > 0 && item.value">
      <div *ngIf="showLabels" class="font-primary-medium item-label absolute w-max" [ngStyle]="{ color: colors[i] }">{{ item.value }} {{ item.key }}</div>
      <div class="item-progress w-full absolute" [ngStyle]="{ 'background-color': colors[i] }"></div>
    </div>
  </ng-container>
  <div [ngStyle]="{ width: getTotalWidth() }" class="chart-item flex flex-col justify-center relative" *ngIf="placeholderCount > 0">
    <div *ngIf="showLabels" class="font-primary-medium item-label absolute w-max text-grey-200">{{ placeholderCount }} {{ placeholderKey }}</div>
    <div class="item-progress bg-grey-200"></div>
  </div>
</div>
