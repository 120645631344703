import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BaseControl, getControlValueAccessor } from '../base';

type labelPositions = 'left' | 'right';

@Component({
  selector: 'zui-switch',
  templateUrl: './switch.html',
  styleUrls: ['./switch.scss'],
  providers: [getControlValueAccessor(SwitchComponent)]
})
export class SwitchComponent extends BaseControl {
  @Input() public labelPosition: labelPositions = 'left';
  @Input() public label = '';
  constructor() {
    super();
  }
}
