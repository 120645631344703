<div class="fixed z-[998] w-full h-full inset-0 bg-primary-1-900/60 grid overlay" *ngIf="showModal">
  <div class="fixed bg-white shadow-7" [ngClass]="{ 'w-[45%] right-0 h-full sideOverlay-container': sideOverlay, 'self-center justify-self-center border border-solid border-grey-300 rounded container !p-0': !sideOverlay }">
    <div class="header-box grid grid-cols-[1fr_3.75rem_1.875rem] h-12 items-center pr-2 shadow-13 mb-1 header-div" *ngIf="displayHeader" [ngClass]="{ '!grid-cols-[1fr_1.875rem]': !(isHelpDocEnabled && isNotNullOrEmpty(helpDocUrl)) }">
      <ng-container *ngTemplateOutlet="headerTemplate ? headerTemplate : defaultHeader"></ng-container>
    </div>
    <div class="py-6 px-8 bg-grey-50 overflow-y-scroll h-[calc(100%-7rem)] body-section no-scroll-bar">
      <ng-content></ng-content>
    </div>
    <div class="h-16 px-4 flex items-center gap-x-4 footer shadow-13 modal-holder-footer" *ngIf="displayFooter">
      <ng-container [ngTemplateOutlet]="footerTemplate ? footerTemplate : defaultFooter" [ngTemplateOutletContext]="{ state: state }"></ng-container>
    </div>
  </div>
</div>
<ng-template #defaultHeader>
  <div class="pl-4 font-secondary-bold text-base text-primary-1-900 uppercase header-label">
    <ng-container *ngTemplateOutlet="headerLabelTemplate"></ng-container>
    {{ header }}
  </div>
  <zap-show-documentation [url]="helpDocUrl" *ngIf="isHelpDocEnabled && isNotNullOrEmpty(helpDocUrl)"></zap-show-documentation>
  <ng-container *ngTemplateOutlet="closeIconTemplate ? closeIconTemplate : defaultCloseIcon"></ng-container>
</ng-template>
<ng-template #defaultFooter>
  <div class="h-16 inline-flex items-center gap-4 py-0 px-[1.125rem]">
    <div class="cursor-pointer p-3 rounded font-primary flex justify-center items-center text-sm text-black border border-solid border-grey/68 min-h-[3rem] min-w-[6.25rem] cancel-button hover:shadow-10" (click)="cancelModal()">{{ cancelLabel }}</div>
    <div *ngIf="!!nextLabel" class="cursor-pointer p-3 rounded font-primary flex justify-center items-center text-sm bg-primary-1 text-white min-h-[3rem] min-w-[6.25rem] next-button hover:shadow-11" [ngClass]="{ '!invisible': state?.invisible, '!pointer-events-none !bg-grey-100 !text-grey/52 !border-none': state?.disabled }" (click)="nextAction()">{{ nextLabel }}</div>
  </div>
</ng-template>
<ng-template #defaultCloseIcon>
  <span class="unity-icons-close !text-xxs h-6 w-6 rounded p-2 bg-grey-100 text-primary-1-900 cursor-pointer" (click)="closeModal()"></span>
</ng-template>
