import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseControl } from '../../base';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';

/**
 * PlainInputControlComponent
 * @description
 * This component is used to render an input control always binded with a parent form group.
 * It accepts the following inputs:
 * - type: 'text' | 'number'
 * - controlKey: string
 * - placeholderText: string
 * By default it has rounded edges of 0.25rem and a border of 1px solid grey-300. On error, the border color changes to red-700.
 * It's responsive to a 100% external parent width
 * @example
 * <form [formGroup]="form">
 *  <zap-plain-input-control controlKey="name" placeholderText="Enter your name"></zap-plain-input-control>
 * </form>
 */
@Component({
  selector: 'zap-plain-input-control',
  templateUrl: './plain-input-control.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class PlainInputControlComponent extends BaseControl implements OnInit {
  @Input() type: 'text' | 'number' = 'text';
  @Output() onChange = new EventEmitter<string>();
  inputControl: AbstractControl;
  constructor(private parentContainer: ControlContainer) {
    super();
  }

  ngOnInit() {
    this.inputControl = this.parentContainer?.control?.get(this.controlKey);
    this.listenToControlChanges(this.inputControl);
  }
}
