import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ElementRef,
  OnChanges,
  AfterViewChecked
} from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'zui-table',
  styleUrls: ['./table.component.scss'],
  templateUrl: './table.component.html'
})
export class TableComponent implements OnChanges, OnDestroy, AfterViewChecked {
  public tableEle: HTMLElement;
  public headerEle: HTMLElement;
  public contentEle: HTMLElement;

  @Input() public throttleTime = 1000;
  @Input() public fixedHeader = false;
  @Output() public onScrollToEnd = new EventEmitter();

  public onScrollThrottler = new Subject<Event>();

  constructor(public eleRef: ElementRef) {
    this.adjustWidth = this.adjustWidth.bind(this);
    window.addEventListener('resize', this.adjustWidth);
    this.initThrottler();
  }

  public initThrottler() {
    this.onScrollThrottler.unsubscribe();
    this.onScrollThrottler = new Subject<Event>();
    // tslint:disable-next-line:max-line-length
    this.onScrollThrottler
      .pipe(throttleTime(this.throttleTime))
      .subscribe(event => this.onScrollToEnd.emit(event));
  }

  public ngOnChanges(changes) {
    if (
      changes.hasOwnProperty('throttleTime') &&
      changes.throttleTime.currentValue !== changes.throttleTime.previousValue
    ) {
      this.initThrottler();
    }
  }

  public ngOnDestroy() {
    this.onScrollThrottler.unsubscribe();
    window.removeEventListener('resize', this.adjustWidth);
  }

  public ngAfterViewChecked() {
    this.tableEle = this.eleRef.nativeElement.getElementsByClassName(
      'table-container-comp'
    )[0];
    this.headerEle = this.eleRef.nativeElement.getElementsByClassName(
      'table-header-comp'
    )[0];
    this.contentEle = this.eleRef.nativeElement.getElementsByClassName(
      'table-content-comp'
    )[0];

    if (this.fixedHeader) {
      this.contentEle.style.height = `calc(100% - ${
        this.headerEle.offsetHeight
      }px)`;
      this.adjustWidth();
    } else {
      this.contentEle.style.height = 'auto';
    }
  }

  public adjustWidth() {
    const headerRow = <HTMLElement>(
      this.headerEle.getElementsByClassName('table-row-comp')[0]
    );
    if (
      headerRow.offsetWidth !== headerRow.scrollWidth ||
      this.tableEle.offsetWidth > headerRow.scrollWidth ||
      this.headerEle.offsetWidth !== headerRow.scrollWidth
    ) {
      this.headerEle.style.width = 'auto';
      this.contentEle.style.width = 'auto';
      const newWidth =
        Math.max(this.headerEle.offsetWidth, headerRow.scrollWidth) + 'px';
      this.headerEle.style.width = newWidth;
      this.contentEle.style.width = newWidth;
    }
  }

  public onScroll(event) {
    // tslint:disable-next-line:max-line-length
    if (
      this.contentEle &&
      this.contentEle.scrollTop + this.contentEle.offsetHeight >=
        this.contentEle.scrollHeight
    ) {
      this.onScrollThrottler.next(event);
    }
  }
}
