import { Injectable, OnDestroy } from '@angular/core';
import { equals } from 'ramda';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { FirebaseAuthService, FirebaseAuthStatus } from '../../auth';
import { isNotNullOrEmpty } from '../../utils';
import { ProductPreferences, UserPreference } from '../types';
import { checkIfEqualObjects } from '../utils';
import { UserPreferenceService } from './user-preference.service';

export interface UserPreferenceState {
  isAuthenticationDone?: boolean;
  loading: boolean;
  userPreference?: UserPreference;
  productPreferences?: ProductPreferences;
  forUserId?: number;
}
const initialState = {
  loading: true,
  isAuthenticationDone: false,
  userPreference: {},
};

export const toProductPreferenceString = (prodId) => `product_${prodId}`;
export const toProductOrgPreferenceString = (prodId, orgId) =>
  `product_${prodId}_org_${orgId}`;
@Injectable({
  providedIn: 'root',
})
export class UserPreferenceFacade implements OnDestroy {
  private store$ = new BehaviorSubject<UserPreferenceState>(initialState);
  private state$ = this.store$.asObservable();
  private destroy$ = new Subject<void>();
  preferenceState$ = this.state$.pipe(
    debounceTime(0),
    filter((d) => !d.loading && d?.isAuthenticationDone),
    distinctUntilChanged()
  );
  constructor(
    private userPreferenceService: UserPreferenceService,
    private firebaseAuthService: FirebaseAuthService
  ) {
    this.firebaseAuthService
      .authenticateStatus()
      .pipe(
        filter((status) => status !== FirebaseAuthStatus.IN_PROGRESS),
        filter((_) => {
          const userIdFromStore = this.getUserIdForPref();
          const userIdFromService = this.userPreferenceService?.getUserId();
          return userIdFromStore !== userIdFromService;
        }),
        switchMap((status) => {
          return FirebaseAuthStatus.SUCCESS === status
            ? this.userPreferenceService.getUserPreferenceDoc()
            : of({});
        }),
        tap((userPref) => {
          this.store$.next({
            loading: false,
            isAuthenticationDone: true,
            userPreference: userPref,
            forUserId: this.userPreferenceService?.getUserId(),
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getUserIdForPref() {
    return this.store$.value?.forUserId;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  updateUserPreference(data: Partial<UserPreference>) {
    this.updateUserPreferenceData(data);
    this.userPreferenceService.updateUserPreference(data);
  }

  updateProductConfig(data: any, productId: string) {
    let preferenceKey = toProductPreferenceString(productId);
    this.updateProductPreferenceData(data, preferenceKey);
    this.userPreferenceService.updateProductConfig(data, productId);
  }

  updateUserOrgLevelConfig(data: any, productId: string, orgId: string) {
    let preferenceKey = toProductOrgPreferenceString(productId, orgId);
    this.updateProductPreferenceData(data, preferenceKey);
    this.userPreferenceService.updateUserOrgLevelConfig(data, productId, orgId);
  }

  getUserPreference(): Observable<UserPreference> {
    return this.preferenceState$.pipe(
      map((pref) => pref?.userPreference),
      distinctUntilChanged((prev, curr) => equals(prev, curr))
    );
  }

  getProductPreference(productId): Observable<ProductPreferences> {
    let preferenceKey = toProductPreferenceString(productId);
    return this.preferenceState$.pipe(
      switchMap((pref) =>
        isNotNullOrEmpty(pref?.productPreferences?.[preferenceKey])
          ? of(pref?.productPreferences?.[preferenceKey])
          : this.userPreferenceService
              .getProductPreference(productId)
              .pipe(
                tap((res) =>
                  this.updateProductPreferenceData(
                    res,
                    toProductPreferenceString(productId)
                  )
                )
              )
      ),
      distinctUntilChanged((prev, curr) => equals(prev, curr))
    );
  }

  getUserOrgLevelPreference(productId, orgId): Observable<ProductPreferences> {
    let preferenceKey = toProductOrgPreferenceString(productId, orgId);
    return this.preferenceState$.pipe(
      switchMap((pref) =>
        isNotNullOrEmpty(pref?.productPreferences?.[preferenceKey])
          ? of(pref?.productPreferences?.[preferenceKey])
          : this.userPreferenceService
              .getUserOrgLevelPreference(productId, orgId)
              .pipe(
                tap((res) =>
                  this.updateProductPreferenceData(
                    res,
                    toProductOrgPreferenceString(productId, orgId)
                  )
                )
              )
      ),
      distinctUntilChanged((prev, curr) => equals(prev, curr))
    );
  }

  updateUserPreferenceData(data: Partial<UserPreference>) {
    let userPreferenceChange = {
      ...(this.store$.value?.userPreference ?? {}),
      ...data,
    };
    if (
      !checkIfEqualObjects(
        userPreferenceChange,
        this.store$.value?.userPreference ?? {}
      )
    ) {
      this.store$.next({
        ...this.store$.value,
        userPreference: {
          ...(this.store$.value?.userPreference ?? {}),
          ...data,
        },
      });
    }
  }

  updateProductPreferenceData(data, preferenceKey) {
    let preferenceChange = {
      ...(this.store$.value?.productPreferences?.[preferenceKey] ?? {}),
      ...data,
    };
    if (
      !checkIfEqualObjects(
        preferenceChange,
        this.store$.value?.productPreferences?.[preferenceKey] ?? {}
      )
    ) {
      this.store$.next({
        ...this.store$.value,
        productPreferences: {
          ...this.store$.value?.productPreferences,
          [preferenceKey]: {
            ...(this.store$.value?.productPreferences?.[preferenceKey] ?? {}),
            ...data,
          },
        },
      });
    }
  }
}
