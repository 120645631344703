export interface UserProductAccess {
  [orgId: string]: {
    [productId: string]: boolean;
  };
}

export interface EventDateRange {
  startDate: string;
  endDate: string;
}
