import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  share,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isError, isSuccess } from '../../http';
import { CurrencyService } from './currency.service';
import { Currency, CurrencyAPIResponse, CurrencyState } from '../types';

let _state: CurrencyState = {
  loading: false,
  currencies: [],
  errors: null,
};
@Injectable({
  providedIn: 'root',
})
export class CurrencyFacadeService {
  private store$ = new BehaviorSubject<CurrencyState>(_state);
  private state$ = this.store$.asObservable();
  private loadCurrencies$ = new Subject<boolean>();

  loading$ = this.state$.pipe(
    map((state) => state.loading),
    distinctUntilChanged(),
    share()
  );
  errors$ = this.state$.pipe(
    map((state) => state.errors),
    distinctUntilChanged(),
    share()
  );
  currencies$ = this.state$.pipe(
    map((state) => state.currencies),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(private currencyService: CurrencyService) {
    this.loadCurrencies$
      .pipe(
        switchMap(() => this.currencyService.getCurrencies()),
        tap((res: CurrencyAPIResponse) => {
          if (isError(res?.status)) this.onLoadCountriesError(res?.error);
        }),
        filter((res: CurrencyAPIResponse) => isSuccess(res.status)),
        tap((res: CurrencyAPIResponse) => this.onLoadCountriesSuccess(res.data))
      )
      .subscribe();
  }
  loadCurrencies() {
    this.store$.next(
      (_state = { currencies: [], loading: true, errors: null })
    );
    this.loadCurrencies$.next(true);
  }
  private onLoadCountriesError(errors) {
    this.store$.next((_state = { ..._state, errors, loading: false }));
  }
  private onLoadCountriesSuccess(currencies: Currency[]) {
    this.store$.next(
      (_state = {
        ..._state,
        currencies,
        errors: null,
        loading: false,
      })
    );
  }
}
