<ng-container *ngIf="{ showSearch: searchClicked$ | async } as data">
  <div>
    <div *ngIf="!data.showSearch && !searchTextFromParent" class="search-placeholder cursor-pointer flex items-center gap-2">
      <span class="placeholder group-[.active]:text-grey-900 truncate text-sm font-medium uppercase text" customToolTip customTooltipClass="!w-max !py-1 !px-2" [text]="label" (click)="isSortable ? onLabelClicked.emit() : search($event)">{{ label }}</span>
      <span class="icon search search-icon text-grey-200" (click)="search($event)"></span>
    </div>
    <div *ngIf="data.showSearch || searchTextFromParent" class="search-input text-xs gap-2">
      <input class="text-xs placeholder:text-grey-400 truncate" [(ngModel)]="searchText" (ngModelChange)="onSearchTextChange($event)" (click)="$event.stopPropagation()" [placeholder]="placeholder" zapAutofocus />
      <span class="icon cursor-pointer close close-search text-[0.6875rem]" (click)="onCloseSearchClicked($event)"></span>
    </div>
  </div>
</ng-container>
