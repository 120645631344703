import { Component, Input, OnInit } from '@angular/core';
import { FormEdge, FormNode, TextNodeMetadata } from './types';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { BaseControl } from './base';
import { assoc, dissoc } from 'ramda';
import { defaultToEmptyArray } from '@zeotap-ui/core';

/**
 * DynamicGraphFormComponent
 * @description
 * This component is used to render a dynamic graph form based on the formGraph input.
 * where edges are basically path that will lead to dependent form inputs to be displayed
 * For ex - if orgType dropdown is an input with options - ['Agency', 'Advertiser']. Selection of Agency should lead to two extra inputs to be filled - agencyName and agencyId
 * Therefore, Agency becomes an outgoing edge from orgType and agencyName and agencyId becomes incoming edges to Agency
 * @example
 * <form [formGroup]="form">
 * <zap-dynamic-graph-form [formGraph]="formGraph" [viewMode]="viewMode"></zap-dynamic-graph-form>
 * </form>
 */
@Component({
  selector: 'zap-dynamic-graph-form',
  templateUrl: './dynamic-graph-form.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DynamicGraphFormComponent extends BaseControl implements OnInit {
  @Input() formGraph: FormNode[] = [];
  @Input() viewMode = false;
  dynamicGraphForm: FormGroup;
  activeEdges: Record<string, FormEdge> = {};
  constructor(
    private parentContainer: ControlContainer,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.dynamicGraphForm = this.parentContainer?.control?.get(
      `${this.controlKey}`
    ) as FormGroup;
    this.initializeDynamicGraphForm();
  }

  initializeDynamicGraphForm() {
    this.formGraph.forEach((formNode: FormNode) => {
      this.dynamicGraphForm.addControl(
        formNode.details.name,
        this.fb.group({
          name: this.fb.control(formNode.details.displayName),
          value: this.fb.control(
            {
              value:
                formNode.details?.value ??
                (formNode.details.metadata as TextNodeMetadata)?.defaultValue,
              disabled: this.viewMode || formNode.details.metadata?.disabled,
            },
            {
              validators: defaultToEmptyArray(
                formNode.details.metadata?.validators
              ),
            }
          ),
          type: this.fb.control(formNode.details.type),
        })
      );
      if (formNode.details?.value && formNode?.edges?.length)
        this.setActiveEdge(formNode.details.value, formNode);
    });
  }

  setActiveEdge(value, node: FormNode) {
    const nodeForm = this.dynamicGraphForm.get(node.details.name) as FormGroup,
      currentActiveEdge = node.edges?.find((edge) => edge.activeFn(value)),
      prevActiveEdgeName = this.activeEdges?.[node.details.name]?.name;

    this.removeActiveEdgeForNode(node);
    if (prevActiveEdgeName) nodeForm.removeControl(prevActiveEdgeName);

    if (currentActiveEdge) {
      setTimeout(() => this.addActiveEdgeForNode(node, currentActiveEdge), 0); // to remove <zap-dynamic-graph-form..> from view for a second and invoke cd
      nodeForm.addControl(currentActiveEdge.name, this.fb.group({}));
    }
  }

  removeActiveEdgeForNode(node: FormNode) {
    this.activeEdges = dissoc(node.details.name, this.activeEdges);
  }

  addActiveEdgeForNode(node: FormNode, edge: FormEdge) {
    this.activeEdges = assoc(node.details.name, edge, this.activeEdges);
  }
}
