import { AsyncCallResponse } from '../http';
import { IdName } from './../types/util';

export type Product = {
  id: number;
  name: string;
  icon: string;
  selected?: boolean;
  disabled?: boolean;
  order?: number;
};

export type ProductGroup = {
  product: number;
  id: number;
  name: string;
};

export type ProductConfiguration =
  | ConnectConfiguration
  | TargetingConfiguration
  | DPUIConfiguration
  | DCRConfiguration
  | EnvisionConfiguration
  | IDPConfiguration
  | SymphonyConfiguration
  | SymphonyV2Configuration
  | SymphonyV3Configuration;

export type ConnectConfiguration = {
  country: IdName;
  channels: IdName[];
  features: ConnectFeatures;
  region?: string;
};

export interface ConnectConfigurationV2 {
  countries: ConnectCountry[];
  features: ConnectFeatures;
  doNotDisableFeatures?: any;
}

export type TargetingConfiguration = {
  country?: IdName;
  channels: IdName[];
  enable_extension?: boolean;
  enable_affinity?: boolean;
  donotDisable?: boolean;
  doNotDisableFeatures?: Object;
};

export type TargetingConfigurationV2 = {
  countries: ConnectCountry[];
};

export type DPUIConfiguration = {
  channels: IdName[];
  features: DPUIFeatures;
};

export type DCRConfiguration = {
  data_home: boolean;
  data_mart: boolean;
  data_pool_access_countries: IdName[];
  cloud_assets: any[];
  data_mart_asset_creation_status?: string;
  data_home_asset_creation_status?: string;
};

export type EnvisionConfiguration = {
  country?: IdName;
  countries?: ConnectCountry[];
  viewRFM?: boolean;
  viewCLV?: boolean;
  trainingFrequency?: string;
  predictionFrequency?: string;
  doNotDisableFeatures?: any;
};

export type IDPConfiguration = {
  IDPEntity: string;
  publisherType?: string;
};

export interface AudienceFeatures {
  enableZeotapGraph: boolean;
  enableAudienceOverlap: boolean;
  enableLookalike: boolean;
  enableABTest: boolean;
  enableAISegmentation: boolean;
  enableSegmentMembership: boolean;
  enableUserDeletion: boolean;
  enableCPM: boolean;
  audienceTrend: boolean;
  refreshUnits?: string[];
  minRefreshFrequencyWithExtension?: number;
}
export interface AudienceConfiguration {
  features: AudienceFeatures;
  disabledFeatures?: Partial<AudienceFeatures>;
}

export interface SymphonyV3Configuration {
  features: SymphonyV2Features;
  disabledFeatures?: Partial<SymphonyV2Features>;
}
export type SymphonyConfiguration = {
  region: SymphonyRegion;
};

export type SymphonyV2Configuration = {
  regions: string[];
  channels: IdName[];
  features: {
    thirdPartyLookup: boolean;
  };
  disabledFeatures?: Partial<SymphonyV2Features>;
};
export type ConnectFeatures = {
  lookalike: boolean;
  externalGraph: ConnectExternalGraphConfig;
  enableExternalGraph?: boolean;
  dashboard: ViewPreference;
  enableInputMatchRate: boolean;
  thirdPartySegment?: boolean;
  zeotapGraph: boolean;
  insightSetting?: InsightSetting;
  viewAnalytics?: boolean;
  uniqueUser?: boolean;
  viewRFM?: boolean;
  viewCLV?: boolean;
  refreshFrequency?: RefreshFrequency;
  removeUsersSegment: boolean;
  enableEstimator: boolean;
  enableCPM?: boolean;
  customRuleBasedDeletion?: boolean;
};

export type SymphonyV2Features = {
  thirdPartyLookup: boolean;
};

export type DPUIFeatures = {
  viewTargeting?: boolean;
  viewManagedCampaigns?: boolean;
  viewConnect?: boolean;
  viewCurrencyConversion?: boolean;
  viewReportTab?: boolean;
};

export type ProductConfigurationAPIResponse =
  AsyncCallResponse<ProductConfigurationMap>;

export type ProductConfig =
  | ConnectCountryConfiguration
  | ConnectConfigurationV2
  | TargetingCountryConfiguration
  | TargetingConfigurationV2
  | DPUIConfiguration
  | DestinationConfiguration
  | StudioConfiguration
  | AccessConfiguration
  | DCRConfiguration
  | DataManagerConfiguration
  | ZeotapToolsConfiguration
  | SymphonyRegionConfiguration
  | SymphonyV2Configuration
  | EnvisionCountryConfiguration
  | IDPConfiguration
  | AudienceConfiguration;

export type ProductConfigurationMap = {
  [productId: number]: ProductConfig;
};

export type AccessConfiguration = {};
export type StudioConfiguration = {};
export type DestinationConfiguration = {};
export type ZeotapToolsConfiguration = {};

export type SymphonyRegions = 'IN' | 'US' | 'UK' | 'EU' | '';

export type SymphonyRegionConfiguration = {
  [region: string]: SymphonyConfiguration;
};

export type SymphonyRegion = {
  name: SymphonyRegions;
  disabled?: boolean;
};

export type CollectRegion = {
  name: string;
  disabled: boolean;
};

export type ConnectCountryConfiguration = {
  [countryIsoAlpha3: string]: ConnectConfiguration;
};

type ViewPreference = 'abs' | 'pct';
export type InsightSetting = 'skew' | 'zeo' | 'both';

export type ConnectExternalGraphConfig = {
  enabled: boolean;
  externalGraphList: IdName[];
};

export type ConsentAnalyticsUrl<T> = {
  url: T;
  title: string;
  isMultiDatasource: boolean;
};

export type DataManagerFeatures = {
  onboardWebSDK?: boolean;
  onboardAppPixel?: boolean;
  onboardAndroidSdk?: boolean;
  onboardIosSdk?: boolean;
  onboardWebPixel?: boolean;
  onboardIFrame?: boolean;
  onboardFlatFile?: boolean;
  onboardSftp?: boolean;
  whitelistedIps?: string[];
  onboardHttpApi?: boolean;
  onboardBrazeCurrents?: boolean;
  onboardGtm?: boolean;
  onboardAdobeExt?: boolean;
  onboardBigquery?: boolean;
  onboardCRM?: boolean;
  onboardSnowflake?: boolean;
  privateGraphInstance?: string;
  privateGraphDetails?: { org_id: number; org_name: string };
  preferred_regions?: string[];
  enableConsentAnalytics?: boolean;
  consentAnalyticsUrl?: ConsentAnalyticsUrl<string>[];
  onboardDotDigital?: boolean;
  enableNCE?: boolean;
};

export type EnvisionCountryConfiguration = {
  [countryIsoAlpha3: string]: EnvisionConfiguration;
};

export type TargetingCountryConfiguration = {
  [countryIsoAlpha3: string]: TargetingConfiguration;
};

export type DataManagerConfiguration = {
  features: DataManagerFeatures;
};

export type InsightsFeatures = {
  dashboard: ViewPreference;
  insightSetting?: InsightSetting;
};

export type ApiProductConfig = {
  org_id: number;
  product_id?: number;
  country_id?: string;
  country_iso3?: string;
  country_name?: string;
  countries?: ConnectCountry[];
  preferred_region?: string;
  config?:
    | ApiConnectProductConfig
    | ApiTargetingProductConfig
    | ApiDPUIProductConfig
    | ApiDCRProductConfig
    | ApiDataManagerProductConfig
    | ApiEnvisionProductConfig
    | ApiIDPProductConfig
    | ApiSymphonyProductConfig;
  region?: string;
};

export type ApiConnectProductConfig = {
  dashboard: string;
  lookalike: number;
  channel_list: IdName[];
  refresh_frequency: RefreshFrequency;
  remove_users_from_segments: boolean;
};

export interface ConnectCountry {
  id: string;
  name: string;
  iso3: string;
  region: string;
  isTelco?: boolean;
  disabled?: boolean;
}

export type ApiTargetingProductConfig = {
  channel_list: IdName[];
  enable_extension?: boolean;
};

export type ApiDPUIProductConfig = {
  currency_conversion: boolean;
  view_connect_dashboard: boolean;
  view_zab_report: boolean;
  view_zab_rowlog_report: boolean;
  view_zam_report: boolean;
  view_zat_report: boolean;
  dp_address: string;
  channel_list: IdName[];
};
export type ApiSymphonyProductConfig = {
  channel_list?: IdName[];
  preferred_regions?: string[];
  third_party_lookup: boolean;
};

export type ApiDataManagerProductConfig = {
  collect_web_sdk: boolean;
  collect_app_pixel: boolean;
  collect_android_sdk: boolean;
  collect_ios_sdk: boolean;
  collect_web_pixel: boolean;
  collect_iframe: boolean;
  collect_flat_file: boolean;
  collect_onboard_via_sftp: boolean;
  collect_onboard_via_snowflake: boolean;
  collect_onboard_via_adobe_ext: boolean;
  sftp_source_ip_list: string[];
  collect_s2s: boolean;
  collect_gtm: boolean;
  collect_bq: boolean;
  collect_onboard_via_salesforce_crm: boolean;
  private_graph_location: string;
  private_graph_org_id: number;
  preferred_regions: string[];
  collect_onboard_via_braze_currents: boolean;
};

export type ApiDCRProductConfig = {
  data_home: boolean;
  data_mart: boolean;
  data_pool_access_countries: IdName[];
  cloud_assets: any[];
};

export type ApiEnvisionProductConfig = {
  show_rfm: boolean;
  show_clv: boolean;
  training_frequency: string;
  prediction_frequency: string;
};

export type ApiIDPProductConfig = {
  idp_entity: string;
  publisher_type?: string;
};
export type RefreshType =
  | 'Daily'
  | 'Weekly'
  | 'Monthly'
  | 'Custom'
  | 'Do Not Auto Refresh';

export type RefreshFrequency = {
  //TODO: Move to appropriate module
  type: RefreshType;
  days: number;
};

export type ProductPermission = {
  org_id: number;
  country_id: number;
  product_id: number;
  prod_group_id: number;
};

export type UserProductAccess = {
  orgId: number;
  countryId?: number;
  productAccess: Record<number, number>;
};
export type ProductId = number;

export type ProductConfigState = {
  config: ProductConfig;
  inProgress: boolean;
  error?: string;
};
