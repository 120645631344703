import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../models/question';

@Injectable()
export class QuestionControlService {
  constructor() {}

  toFormGroup(questions: QuestionBase<any>[]) {
    let group: any = {};

    questions.forEach((question) => {
      group[question.key] = new UntypedFormControl(
        question.value || '',
        question.validations
      );
    });
    return new UntypedFormGroup(group);
  }
}
