import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zap-control-wrapper',
  templateUrl: './control-wrapper.component.html',
})
export class ControlWrapperComponent implements OnInit {
  @Input() controlName: string;
  @Input() controlDescription: string;
  @Input() isMandatory = false;
  @Input() controlDescPosition: 'top' | 'bottom' = 'top';
  @Input() controlInfoTooltipValue: string;
  @Input() additionalInfo: string;
  @Input() orientation: 'row' | 'column' = 'row';

  constructor() {}

  ngOnInit() {}
}
