type productIds = '5' | '11'; // TODO : remove from here and make it configurable

export interface ConnectPreference {
  segmentDisplayType?: 'table' | 'grid';
}

export interface TargetingPreference {
  recentlySelectedDestinations?: {
    [orgId: number]: {
      [countryCode: string]: number[];
    };
  };
}

export type ProductPreference = ConnectPreference | TargetingPreference | any;

export type ProductPreferences = {
  [id in productIds]: ProductPreference;
};

export interface UserPreference {
  lastSelectedProduct?: string;
  lastSelectedOrganizationId?: number;
  lastSelectedCountryCode?: string;
  lastSelectedRegionCode?: string;
  lastActivatedPath?: string;
  isNavBarCollapsed?: boolean;
}

export interface UserConfig {
  userPref: UserPreference;
  productPreferences?: ProductPreferences;
}

export interface UserOrgLevelPreference {
  data: any;
  orgId: string;
  productId: string;
}
