import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

export interface ChipInfo {
  displayName: string;
  icon?: string;
}

@Component({
  selector: 'zap-count-chip',
  templateUrl: './count-chip.component.html',
  styleUrls: ['./count-chip.component.scss'],
})
export class CountChipComponent implements OnChanges {
  @Input() chips: ChipInfo[] = [];
  @Input() maxDisplayCount = 3;
  @Input() displayText = false;
  protected moreChips: ChipInfo[] = [];
  @Input() tooltipPositions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 2,
    },
  ];
  ngOnChanges(simpleChange): void {
    if (simpleChange.chips) {
      this.moreChips = this.chips.slice(this.maxDisplayCount);
    }
  }
}
