import { Component, ViewChild } from '@angular/core';
import {
  AuthService,
  AuthResponseData,
  isSuccess,
  DisplayMessageService,
} from '@zeotap-ui/core';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'zap-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  @ViewChild('forgotForm', { static: true }) forgotForm: NgForm;

  constructor(
    private authService: AuthService,
    private messageService: DisplayMessageService
  ) {}

  demoButtonClick() {
    window.open('https://zeotap.com/request-a-demo/', '_blank');
    return false;
  }

  recoverPassword() {
    this.authService
      .recoverPassword(this.forgotForm.value)
      .subscribe((res: AuthResponseData) =>
        isSuccess(res.status)
          ? this.messageService.displaySuccessMsg(res.value['message'])
          : this.messageService.displayErrorMsg(res.error)
      );
  }

  public onFocus(className: string) {
    document.querySelector('.' + className).classList.add('focused');
  }
  public onBlur(className: string) {
    document.querySelector('.' + className).classList.remove('focused');
  }
}
