import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { IdName } from '@zeotap-ui/core';

@Component({
  selector: 'zap-toggle-window',
  templateUrl: './toggle-window.component.html',
  styleUrls: ['./toggle-window.component.scss'],
})
export class ToggleWindowComponent implements OnInit {
  @Input() options: IdName[];
  @Input() activeSelection: number | string;
  @Input() disabled = false;
  @Output() selection: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedOption: EventEmitter<IdName> = new EventEmitter<IdName>();

  label = {
    BACKGROUND_COLOUR: {
      not_in: 'bg-state-error-700',
      or: 'bg-state-success',
      and: 'bg-state-warning-1',
    },
    TEXT_COLOUR: {
      not_in: 'text-state-error-700',
      or: 'text-state-success',
      and: 'text-state-warning-1',
    },
  };

  constructor(public elementRef: ElementRef) {}

  ngOnInit() {
    !!this.activeSelection
      ? this.select(this.options.find((o) => o.id === this.activeSelection))
      : this.select(this.options[0]);
  }

  select(option: IdName) {
    this.selection.emit({
      condition: option?.id,
      elementRef: this.elementRef,
    });
    this.selectedOption.next(option);
  }

  get activeSelectionClass() {
    const selectionClass = this.activeSelection?.toString().toLowerCase() || '';
    return this.label.BACKGROUND_COLOUR[selectionClass] ?? selectionClass;
  }

  get activeSelectionTextClass() {
    const selectionClass = this.activeSelection?.toString().toLowerCase() || '';
    return this.label.TEXT_COLOUR[selectionClass] ?? selectionClass + '-text';
  }
}
