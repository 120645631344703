import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { ConfigService } from '@zeotap-ui/config';
import { compose } from 'ramda';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  from,
  interval,
  of,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AsyncCallResponse,
  HttpService,
  Status,
  handleAsyncError,
  isSuccess,
} from '../../http';
import { isNotNullOrEmpty } from '../../utils';
import { DASHBOARD_PRODUCT_MAP } from '../consts/sisense.const';
import { ProductOrgsFacadeService } from './product-orgs.facade.service';

type TokenRequestModel = {
  dashboardIds: string[];
  acl: any[];
};

@Injectable({
  providedIn: 'root',
})
export class SisenseTokenGeneratorService {
  private sisenseConfig: any;
  sisenseWat$: Observable<string>;
  tokenError$ = new BehaviorSubject<string>(null);
  reload$ = new Subject<void>();
  regenerateToken$ = new Subject<void>();

  get isSisenseAvailable() {
    return !!window['Sisense'];
  }

  get hasValidAccessToken() {
    return isNotNullOrEmpty(this.oktaAuth.getAccessToken());
  }
  constructor(
    private config: ConfigService,
    private httpService: HttpService,
    private productOrgsService: ProductOrgsFacadeService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this.sisenseConfig = this.config.getFeatureValue(
      'SISENSE_CONFIG',
      true
    ).config;
    this.setupSisense();
    this.reload$
      .pipe(withLatestFrom(this.productOrgsService.error$, this.tokenError$))
      .subscribe(([_, productOrgMapError, tokenError]) => {
        if (productOrgMapError) {
          this.productOrgsService.reload();
          return;
        }
        if (tokenError) {
          this.regenerateToken$.next(null);
        }
      });
  }

  setupSisense() {
    this.sisenseWat$ = combineLatest([
      interval(100).pipe(
        filter(() => this.isSisenseAvailable && this.hasValidAccessToken),
        take(1)
      ),
      this.productOrgsService.productOrgIDsMap$.pipe(filter(isNotNullOrEmpty)),
      this.regenerateToken$.pipe(startWith(null)),
    ]).pipe(
      switchMap(([_, data, __]) => this.getSisenseWAT(this.buildPayload(data))),
      map((res) => {
        if (!isSuccess(res.status)) {
          this.tokenError$.next(res.error);
          return null;
        }
        this.tokenError$.next(null);
        return res.data;
      })
    );
  }

  buildPayload(productOrgIdsMap) {
    const filteredSisenseConfig = Object.keys(this.sisenseConfig).reduce(
      (acc, dashboardName) => ({
        ...acc,
        ...(this.sisenseConfig[dashboardName].enabled &&
          isNotNullOrEmpty(
            productOrgIdsMap?.[DASHBOARD_PRODUCT_MAP?.[dashboardName]]
          ) && {
            [dashboardName]: {
              ...this.sisenseConfig[dashboardName],
              config: {
                ...this.sisenseConfig[dashboardName].config,
                ...(this.sisenseConfig[dashboardName].config.acl && {
                  acl: {
                    ...this.sisenseConfig[dashboardName].config.acl,
                    members:
                      productOrgIdsMap?.[
                        DASHBOARD_PRODUCT_MAP?.[dashboardName]
                      ],
                  },
                }),
              },
            },
          }),
      }),
      {}
    );
    return {
      dashboardIds: Object.keys(filteredSisenseConfig).map(
        (k) => 'dashboards/' + filteredSisenseConfig[k].config.dashboardId
      ),
      acl: Object.keys(filteredSisenseConfig)
        .map((k) => filteredSisenseConfig?.[k].config?.acl)
        .filter(isNotNullOrEmpty),
    };
  }

  private getSisenseWAT(
    payload: TokenRequestModel
  ): Observable<AsyncCallResponse<string>> {
    return from(
      this.httpService.doPost(
        `${this.config.appConfig.sisenseTokenUrlV2}`,
        payload,
        this.httpService.getHeader('Bearer ')
      )
    ).pipe(
      map((res: any) => ({ status: Status.Success, data: res.token })),
      catchError(
        compose(
          of,
          handleAsyncError<AsyncCallResponse<string>>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }
}
