import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { compose, pluck, values, propEq, filter as rFilter } from 'ramda';

const getSelectedFiltersNames = compose(
  pluck('name'),
  rFilter(propEq('selected', true)),
  values
);

@Component({
  selector: 'zap-category-filter',
  styleUrls: ['./categoryFilter.component.scss'],
  templateUrl: './categoryFilter.component.html',
})
export class CategoryFilterComponent implements OnInit, OnChanges {
  @Input() header = '';
  @Input() filters: Object = {};
  @Input() normalHeader = false;
  @Input() multiSelect = true;
  @Input() defaultOpen = true;
  @Input() count = true;
  @Input() showDropdown = false;
  dropDownFilters = [];
  selectedDropdownFilters: string | string[];
  @ViewChild('select') dropDownSelector: NgSelectComponent;

  @Output() selectionChanged = new EventEmitter();
  @Output() clearSelection = new EventEmitter();

  protected DICTIONARY = {
    InProgress: 'In Progress',
    InActive: 'Inactive',
  };

  toggleFilter(filter) {
    this.selectionChanged.emit({
      selectedFilter: filter,
      selectionType: this.multiSelect,
    });
  }

  hasSelectedFilters() {
    return Object.keys(this.filters).find((f) => this.filters[f].selected);
  }

  clearSelections(header: string) {
    this.clearSelection.emit(header);
  }

  toggleDropdownFilter(filterName) {
    if (!this.multiSelect && filterName) {
      //clear all the previous selected filters when dropdown is single select
      this.clearSelections(this.header);
    }

    if (!!filterName) {
      const filter = Object.values(this.filters).find(
        (filter) => filter.name === filterName
      );
      this.toggleFilter(filter);
    } else {
      this.clearSelections(this.header);
    }
  }

  ngOnInit() {
    this.dropDownFilters = compose(values, pluck('name'))(this.filters);
  }

  ngOnChanges() {
    if (this.showDropdown) {
      this.selectedDropdownFilters = getSelectedFiltersNames(this.filters);
      if (!this.multiSelect) {
        this.selectedDropdownFilters = this.selectedDropdownFilters[0] ?? '';
      }
    }
  }

  closeDropDown() {
    if (!!this.dropDownSelector) {
      this.dropDownSelector.close();
    }
  }
}
