import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  pluck,
  shareReplay,
} from 'rxjs/operators';
import { CountryModel } from '../types';
import { isNotNull, isNotNullOrEmpty } from '../../utils';
import { NgEventBus } from 'ng-event-bus';
import { UserPermissionFacade } from '../../user';
import { UserPreferenceFacade } from '../../preferences';
import { values } from 'ramda';
import { getSelectedCountry } from '../utils';
import {
  COUNTRY_BASED_PRODUCTS,
  ProductId,
  SelectedProductsToken,
} from '../../product';
import { includes } from 'ramda';

interface SelectedCountryState {
  selectedCountry: CountryModel;
}

const intialCountryState: SelectedCountryState = {
  selectedCountry: null,
};

@Injectable({
  providedIn: 'any',
})
export class SelectedCountryFacade {
  private store$ = new BehaviorSubject<SelectedCountryState>(
    intialCountryState
  );
  private state$: Observable<SelectedCountryState> = this.store$.asObservable();
  selectedCountry$: Observable<CountryModel> = this.state$.pipe(
    pluck('selectedCountry'),
    filter(isNotNull),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private userPermissionFacade: UserPermissionFacade,
    private eventBus: NgEventBus,
    private userPreferenceService: UserPreferenceFacade,
    @Inject(SelectedProductsToken) private products: ProductId[]
  ) {
    const userPreference$ = this.userPreferenceService.getUserPreference();

    combineLatest([this.userPermissionFacade.userCountries$, userPreference$])
      .pipe(filter(() => includes(this.products?.[0], COUNTRY_BASED_PRODUCTS)))
      .subscribe(([userCountryRes, userPreference]) => {
        const userCountries = values(userCountryRes);
        this.setSelectedCountry(
          getSelectedCountry(
            this.store$.value.selectedCountry?.isoAlpha3,
            userPreference?.lastSelectedCountryCode,
            userCountries
          )
        );
      });
  }

  setSelectedCountry(selectedCountry: CountryModel) {
    this.store$.next({
      selectedCountry,
    });
    this.userPreferenceService.updateUserPreference({
      lastSelectedCountryCode: selectedCountry.isoAlpha3,
    });
    this.eventBus.cast('app:onCountryChanged', selectedCountry.isoAlpha3);
  }
}
