import { reduce, uniq } from 'ramda';
import { Organization } from '../../org/types';
import { PRODUCTS } from '../../product';

export const getWidget = (dashboard, widgetId) =>
  dashboard.widgets.$$widgets.find((widget) => widget.id === widgetId);

export const updateWidgetFilter = (widget, panel, filter) => {
  const panelMetadata = widget.metadata.panel(panel);
  if (!panelMetadata.isEmpty()) {
    const existingFilterIndex = panelMetadata.items.findIndex(
      (existingFilter) => existingFilter?.jaql?.dim === filter?.jaql?.dim
    );
    if (existingFilterIndex !== -1) {
      panelMetadata.items.splice(existingFilterIndex, 1, filter);
    } else {
      panelMetadata.push(filter);
    }
  } else {
    panelMetadata.push(filter);
  }
  widget.refresh();
};

export const updateDashboardFilter = (
  dashboard: any,
  filterDimension: string,
  members: any[],
  filterTitle: string
) => {
  const _filter = {
    jaql: {
      dim: filterDimension,
      title: filterTitle,
      filter: {
        explicit: true,
        multiSelection: false,
        members,
      },
    },
    isCascading: false,
    disabled: false,
  };
  dashboard.$$model.filters.update(_filter, {
    refresh: true,
    save: false,
  });
};

export const createOrgTree = (
  orgData: Organization[]
): Record<number, number[]> => {
  const orgTree = {};

  orgData.forEach((org) => {
    const parentId = org.parent_org_id;
    if (!orgTree[parentId]) {
      orgTree[parentId] = [org.zeo_org_id];
    } else {
      orgTree[parentId].push(org.zeo_org_id);
    }
    if (!orgTree[org.zeo_org_id]) orgTree[org.zeo_org_id] = [org.zeo_org_id];
    else orgTree[org.zeo_org_id].push(org.zeo_org_id);
  });
  return orgTree;
};
export const getChildIds = (
  orgTree: Record<number, number[]>,
  orgId: number
): number[] => {
  const result = [];
  const visited = new Set();
  const stack = [orgId];

  while (stack.length > 0) {
    const currentOrgId = stack.pop();
    if (visited.has(currentOrgId)) {
      continue;
    }
    visited.add(currentOrgId);

    if (orgTree[currentOrgId]) {
      result.push(...orgTree[currentOrgId]);
      stack.push(...orgTree[currentOrgId]);
    }
  }
  return uniq(result);
};
export const getChildOrgsMap = (
  productId: string,
  productOrgsMap: Record<string, Organization[]>
): Record<number, number[]> => {
  const orgs = productOrgsMap[productId];
  const orgTree = createOrgTree(orgs);
  return reduce(
    (acc, curr) => ({
      ...acc,
      [curr.zeo_org_id]: getChildIds(orgTree, curr.zeo_org_id),
    }),
    {},
    orgs
  );
};
