import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getDate } from '@zeotap-ui/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { EventDateRange } from '../../types/usage';
import { DATE_FORMATS, EVENT_DATE_FORMAT } from '@zeotap-ui/components';

@Component({
  selector: 'zap-date-range',
  templateUrl: './date-range.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } },
  ],
})
export class DateRangeComponent implements OnInit {
  dateRangePickerTouched = false;
  @Input() endDate: Date | string;
  @Input() startDate: Date | string;
  todayDate = new Date();
  @Input() minStartDate = new Date('2020-01-01');
  @Output() dateRangeClosed = new EventEmitter<EventDateRange>();
  get hasDateError() {
    return this.dateRangePickerTouched && (!this.startDate || !this.endDate);
  }
  constructor() {}

  ngOnInit(): void {}

  onDateRangeChanged() {
    if (!this.hasDateError) {
      this.dateRangeClosed.emit({
        startDate: getDate(this.startDate, EVENT_DATE_FORMAT),
        endDate: getDate(this.endDate, EVENT_DATE_FORMAT),
      });
    }
  }
  onDateRangePickerOpened() {
    this.dateRangePickerTouched = true;
  }
}
