import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export abstract class ConfigRuleEvaluator {
  abstract evaluate(rule: string): boolean;
  abstract evaluateAsync(rule: string): Observable<boolean>;

  parentEvaluator: ConfigRuleEvaluator;

  defaultEvaluate(rule: string) {
    if (this.parentEvaluator) return this.parentEvaluator.evaluate(rule);
    else true;
  }
  defaultEvaluateAsync(rule: string): Observable<boolean> {
    if (this.parentEvaluator) return this.parentEvaluator.evaluateAsync(rule);
    else of(false);
  }
}
