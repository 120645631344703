import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[footer-tmp]' })
export class ModalFooterTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[header-tmp]' })
export class HeaderTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[header-label-tmp]' })
export class HeaderLabelTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[close-icon-tmp]' })
export class CloseIconTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}
