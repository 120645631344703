import { ValidatorFn } from './interfaces';

export class Validators {
  public static required(value: string): boolean {
    return !!value;
  }

  public static min(min: number): ValidatorFn {
    const minCheck = (value: string): boolean => {
      return parseFloat(value) >= min;
    };

    return minCheck;
  }

  public static max(max: number): ValidatorFn {
    const maxCheck = (value: string): boolean => {
      return parseFloat(value) <= max;
    };

    return maxCheck;
  }

  public static email(value: string): boolean {
    // tslint:disable-next-line:max-line-length
    const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    return EMAIL_REGEXP.test(value);
  }
}
