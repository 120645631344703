import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Button } from './types';

@Component({
  selector: 'zap-custom-footer',
  templateUrl: './custom-footer.component.html',
})

export class CustomFooterComponent implements OnInit {
  @Input() footerButtons: Button[];
  @Output() onAction = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  btnStyleMap = {
    'cancel': 'bg-white border-[0.0625rem] border-solid border-grey/68 text-grey-800 hover:shadow-10',
    'intermediate': 'bg-white border border-solid border-primary-1 text-primary-1 hover:shadow-11',
    'next': 'bg-primary-1 text-white hover:shadow-11',
  }
}
