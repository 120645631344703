<ng-container
  *ngIf="{
    bgClass: !disabled ? iconStyle?.bg || '' : iconStyle?.disabled?.bg || iconStyle?.bg || '',
    colorClass: !disabled ? iconStyle?.color || '' : iconStyle?.disabled?.color || iconStyle?.color || ''
  } as ctx"
>
  <div *ngIf="icon" class="p-1 flex justify-center items-center rounded" [ngClass]="[ctx?.bgClass, customSizeCSS, customIconCSS, customIconClass]">
    <span [ngClass]="[customSizeCSS ? '' : '!text-[1.5rem]', icon, ctx.colorClass, customSizeCSS]" class="flex items-center justify-center"></span>
  </div>
</ng-container>
