import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  ContentChild
} from '@angular/core';

@Component({
  selector: 'zui-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MilestoneComponent implements AfterViewInit {
  @Input() labels: any[];
  @Input() defaultSelectedLabel: number;
  @Input() ticks: number;
  @Input() showTooltip: boolean;
  @Input() showProgress: boolean;
  @Input() disabled: boolean;
  @Input() defaultSelection: number;

  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  public activeMilestone: number;
  public progressBarWidth: string;
  public distanceBetweenMilestones: number;
  public start: number;
  private trackOffset: number;

  private circleRadius = 5;

  constructor(private eleRef: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.activeMilestone = this.defaultSelectedLabel
      ? this.defaultSelectedLabel - 1
      : 0;
    this.emitChangedSelection();
  }

  ngAfterViewInit() {
    let track = this.eleRef.nativeElement.getElementsByClassName('track')[0];
    if (!!track) {
      this.start = track.getBoundingClientRect().left;
      this.trackOffset = track.offsetLeft - this.circleRadius;
      this.distanceBetweenMilestones =
        track.clientWidth / (this.labels.length - 1);
      this.setProgressBarWidth();
    }
    this.cd.detectChanges();
  }

  public updateActiveMilestone(event) {
    this.activeMilestone = Math.round(
      (event.screenX - this.start) / this.distanceBetweenMilestones
    );
    this.setProgressBarWidth();
    this.emitChangedSelection();
  }

  public activateMilestone(index: number) {
    this.activeMilestone = index;
    this.setProgressBarWidth();
    this.emitChangedSelection();
  }

  private emitChangedSelection() {
    if (this.labels && this.labels.length) {
      this.selectionChanged.emit(this.labels[this.activeMilestone]);
    }
  }

  private setProgressBarWidth() {
    this.progressBarWidth =
      this.activeMilestone * this.distanceBetweenMilestones + 'px';
  }

  public calculateOffsetForMilestone(index: number): string {
    return this.distanceBetweenMilestones * index + this.trackOffset + 'px';
  }

  public calculateOffsetForLabel(index: number): string {
    return (
      this.distanceBetweenMilestones * (index - 0.5) + this.trackOffset + 'px'
    );
  }

  public isActiveMilestone(index: number): boolean {
    return index === this.activeMilestone;
  }

  public isMilestoneCovered(index: number) {
    return index <= this.activeMilestone;
  }
}
