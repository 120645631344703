<div class="custom-chart line-chart-container rounded" [ngClass]="{ 'column-view': showLegendAtBottom }" *ngIf="shouldDisplayReport; else noReport">
  <div class="text-sm font-primary text-grey-900 chart">
    <ng-container *ngIf="showCustomLabels">
      <div class="x-label">{{ xAxisLabel }}</div>
      <div class="y-label">{{ yAxisLabel }}</div>
    </ng-container>
    <ngx-charts-line-chart
      class="custom-line-chart"
      [view]="view"
      [results]="results"
      [scheme]="scheme"
      [animations]="false"
      [legend]="showLegend"
      [legendTitle]="legendTitle"
      [legendPosition]="legendPosition"
      [showXAxisLabel]="showXAxisLabel"
      [timeline]="timeline"
      [showYAxisLabel]="showYAxisLabel"
      [xAxis]="xAxis"
      [yAxis]="yAxis"
      [xAxisLabel]="xAxisLabel"
      [maxXAxisTickLength]="maxXAxisTickLength"
      [maxYAxisTickLength]="maxYAxisTickLength"
      [yAxisLabel]="yAxisLabel"
      [showGridLines]="showGridLines"
      [xAxisTickFormatting]="xAxisTickFormattingFunc"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [xScaleMin]="xScaleMin"
      [xScaleMax]="xScaleMax"
      [yScaleMin]="yScaleMin"
      [yScaleMax]="yScaleMax"
      [xAxisTicks]="xAxisTicks"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
      #chart
    >
      <ng-template #tooltipTemplate let-model="model">
        <div class="line-chart-tooltip">
          <div *ngIf="useXAxisFormatting" class="text-left text-grey/52 border-b-[1px] border-solid border-grey-300 py-2 px-3">{{ model?.name | date: tooltipDateFormat }}</div>
          <div *ngIf="!useXAxisFormatting" class="text-left text-grey/52 border-b-[1px] border-solid border-grey-300 py-2 px-3">{{ model?.name }}</div>
          <div class="point-details text-2xs">
            <ng-container *ngTemplateOutlet="dataPointDetail; context: { model: model }"></ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template #seriesTooltipTemplate let-model="model">
        <div class="line-chart-tooltip">
          <div *ngIf="!useXAxisFormatting" class="text-left text-grey/52 border-b-[1px] border-solid border-grey-300 py-2 px-3">{{ model[0]?.name }}</div>
          <div *ngIf="useXAxisFormatting" class="text-left text-grey/52 border-b-[1px] border-solid border-grey-300 py-2 px-3">{{ model[0]?.name | date: tooltipDateFormat }}</div>
          <div class="series-ponits-details">
            <div *ngFor="let item of model; let i = index" class="point-details text-2xs">
              <ng-container *ngTemplateOutlet="dataPointDetail; context: { model: model[i] }"></ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-charts-line-chart>
  </div>
  <div class="chart-info">
    <ng-container *ngIf="showCustomLegend">
      <div class="chart-items">
        <div *ngFor="let item of results; let i = index" class="text-sm font-primary text-grey-900 item">
          <div class="font-primary-medium itemName"><span class="itemState rounded" [ngStyle]="{ background: scheme.domain[i] }"></span>{{ item?.name }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #dataPointDetail let-model="model">
  <span class="icon series-icon text-base" [ngClass]="replaceWhiteSpaceWithHyphen(model?.series) | lowercase" [ngStyle]="{ color: model?.color || tooltipColors[model?.series] }"></span>
  <span class="text-left text-grey-800">{{ model?.series }}</span>
  <span class="text-right" [ngStyle]="{ color: model.color || tooltipColors[model.series] }">{{ model?.value | numberFormatter }}</span>
</ng-template>

<ng-template #noReport>
  <ng-container *ngIf="showCustomEmptyData">
    <div class="custom-no-data">
      <img class="custom-no-data-img" src="{{ noDataImgSrc }}" />
      <div class="text-base text-grey-400 font-primary">{{ noDataText }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showCustomEmptyData">
    <zap-no-content-image-text [showImage]="false" [text]="noDataText"> </zap-no-content-image-text>
  </ng-container>
</ng-template>
