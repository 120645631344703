import { Component, Input } from '@angular/core';

import { BaseControl, getControlValueAccessor } from '../base';

@Component({
  selector: 'zui-button',
  templateUrl: './button.html',
  styleUrls: ['./button.scss'],
  providers: [getControlValueAccessor(ButtonComponent)],
})
export class ButtonComponent extends BaseControl {
  @Input() public prefixIcon = '';
  @Input() public label = '';
  @Input() public fullWidth = false;
  @Input() public floating = false;
  @Input() public flat = false;
  @Input() public big = false;
}
