import { Inject, Injectable } from '@angular/core';
import { ProductService } from './product.service';
import { BehaviorSubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { SelectedOrgFacadeService } from '../../org';
import { SelectedProductsToken } from '../tokens';
import {
  ProductConfigState,
  ProductConfigurationAPIResponse,
  ProductConfigurationMap,
  ProductId,
} from '../types';
import { isNotNullOrEmpty } from '../../utils';
import { CONFIG_V2_PRODUCTS } from '../product.const';
import { includes } from 'ramda';

const INITIAL_PRODUCT_CONFIG = {
  config: {},
  inProgress: false,
  error: null,
};

@Injectable({
  providedIn: 'any',
})
export class ProductConfigV2Facade {
  private store$ = new BehaviorSubject<ProductConfigState>(
    INITIAL_PRODUCT_CONFIG
  );
  private state$ = this.store$.asObservable();

  error$ = this.state$.pipe(pluck('error'), distinctUntilChanged());
  loading$ = this.state$.pipe(pluck('inProgress'), distinctUntilChanged());
  productConfig$ = this.state$.pipe(
    pluck('config'),
    filter(isNotNullOrEmpty),
    distinctUntilChanged(),
    shareReplay(1)
  );
  constructor(
    private selectedOrgFacade: SelectedOrgFacadeService,
    @Inject(SelectedProductsToken) private products: ProductId[],
    private productService: ProductService
  ) {
    this.selectedOrgFacade.selectedOrg$
      .pipe(
        filter(() => includes(this.products?.[0], CONFIG_V2_PRODUCTS)), // what is this for ?
        tap(() => this.resetProductConfig()),
        switchMap((org) =>
          this.productService.getProductConfigurationsV2(
            org.id,
            true,
            this.products.map((productId) => `${productId}`)
          )
        )
      )
      .subscribe((res: ProductConfigurationAPIResponse) => {
        if (res?.error) {
          this.onLoadProductConfigError(res.error);
        } else {
          this.onLoadProductConfigSuccess(res.data);
        }
      });
  }

  resetProductConfig() {
    this.store$.next({ ...INITIAL_PRODUCT_CONFIG, inProgress: true });
  }

  private onLoadProductConfigError(error) {
    this.store$.next({
      ...this.store$.value,
      inProgress: false,
      error,
    });
  }

  private onLoadProductConfigSuccess(productConfig: ProductConfigurationMap) {
    this.store$.next({
      ...this.store$.value,
      inProgress: false,
      config: productConfig[this.products?.[0]],
    });
  }
}
