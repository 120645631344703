import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { BaseControl, getControlValueAccessor } from '../base';

@Component({
  selector: 'zui-slider',
  templateUrl: './slider.html',
  styleUrls: ['./slider.scss'],
  providers: [getControlValueAccessor(SliderComponent)]
})
export class SliderComponent extends BaseControl {
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() style: any;
  @Output() onHandleRelease: EventEmitter<any> = new EventEmitter<any>();
  pressedHandle = false;

  onMouseDown() {
    this.pressedHandle = true;
  }

  onMouseUp() {
    this.pressedHandle = false;
    this.onHandleRelease.emit(null);
  }

  getFormattedValue(number) {
    var parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  writeValue(value: any) {
    if (!value) {
      value = this.min;
    }
    this.value = Math.max(Math.min(value, this.max), this.min);
  }
}
