import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ObjNgFor', pure: false })
export class ObjNgFor implements PipeTransform {
  transform(value: any, sort = false, pickValue = false, param = false): any {
    let retval = [];
    if (value && typeof value === 'object') {
      if (pickValue) {
        if (sort) {
          if (param) {
            retval = Object.values(value);
            retval.sort(customSort);
          } else {
            if (
              value &&
              value.max &&
              value.max.object &&
              (value.max.object.name.toLowerCase() == 'max' ||
                value.max.object.name.toLowerCase() == 'min')
            ) {
              retval = Object.values(value);
            } else {
              const sortedkeys = Object.keys(value).sort();
              retval = sortedkeys.map((k) => value[k]);
            }
          }
        } else {
          retval = Object.values(value);
        }
        return retval;
      } else {
        retval = Object.keys(value);
        if (sort) {
          retval = retval.sort();
        }
      }
      return retval;
    }
  }
}

function customSort(A, B) {
  return A['name'].toLowerCase().localeCompare(B['name'].toLowerCase());
}
