import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FloatingPanelComponent } from './floating-panel.component';

@NgModule({
  declarations: [FloatingPanelComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [FloatingPanelComponent],
})
export class FloatingPanelModule {}
