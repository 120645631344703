import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortalDirective } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  HostListener,
  ElementRef,
} from '@angular/core';
import { all, compose, equals, prop } from 'ramda';
import { BehaviorSubject } from 'rxjs';
import type { ActionOptions, NgClassType } from '@zeotap-ui/core';

@Component({
  selector: 'zap-dotted-options',
  templateUrl: './dotted-options.component.html',
})
export class DottedOptionsComponent {
  showActions$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  @Input() rowId: number = 0;
  @Input() actions: ActionOptions[] = [];
  @Input() showDots: boolean = false;
  @Input() disableOption = false;
  @Input() zapActionsClass: NgClassType;
  @Output() onActionClicked: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('actionTrigger') actionTrigger: any;
  @ViewChild('actionContainer', { static: true })
  private actionContainer: TemplatePortalDirective;
  private _overlayRef: OverlayRef;
  localClassMap = {
    pause: 'unity-icons-pause_icon',
    edit: 'unity-icons-striped-edit',
    play: 'unity-icons-resume',
  };
  constructor(private _overlay: Overlay, private elementRef: ElementRef) {}

  hideDots = () => all(compose(equals(false), prop('show')))(this.actions);

  disableDots = () => all(compose(equals(true), prop('disable')))(this.actions);

  openActionContainer() {
    this.closeActionContainer();
    let strategy = this._overlay
      .position()
      .flexibleConnectedTo(this.actionTrigger.elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          offsetY: -20,
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetY: 20,
        },
      ]);

    let config = new OverlayConfig({ positionStrategy: strategy });
    this._overlayRef = this._overlay.create(config);
    this._overlayRef.attach(this.actionContainer);
  }

  actionClicked(value) {
    this.closeActionContainer();
    this.onActionClicked.emit(value);
  }

  closeActionContainer() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  @HostListener('document:scroll', ['$event'])
  onScrollClick(evt: KeyboardEvent) {
    // this.closeActionContainer();
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(evt: KeyboardEvent) {
    const modalClick =
      this.elementRef.nativeElement.getElementsByClassName(
        'actions-container'
      )[0];
    if (modalClick && !modalClick.contains(evt.target)) {
      this.closeActionContainer();
    }
  }
}
