<ng-container *ngIf="{ value: groupErrorsByType(inputControl) } as error">
  <input
    class="plain-input-control text-sm text-grey-900 font-primary w-full min-h-10 p-1 bg-white border border-solid border-grey-300 rounded-[0.25rem] placeholder:text-grey/52 outline-none transition-all ease-in-out shadow-[inset_0_1px_1px_rgba(0,0,0,0.075)] disabled:!bg-grey-100 disabled:border disabled:text-grey-700 disabled:!border-grey-300/90 focus:shadow-[inset_0_1px_1px_rgba(0,0,0,0.075),0_0_8px_rgba(102,175,233,0.6)] focus:outline-none focus:border-primary-1 truncate"
    [ngClass]="{ '!border-state-error-700': error.value?.highlight?.length }"
    [type]="type"
    [placeholder]="placeholderText"
    [formControlName]="controlKey"
    customToolTip
    [contentTemplate]="error.value?.highlight?.length ? errors : null"
    [value]="{ errors: error.value?.highlight, errorTextColor: 'primary' }"
    [customTooltipClass]="'!p-[.625rem] !w-max [&_.simpleText]:!text-xs !rounded'"
    [positions]="tooltipPositions"
  />
  <div class="mt-1" *ngIf="error.value?.message?.length">
    <ng-container *ngTemplateOutlet="errors; context: { errors: error.value?.message, errorTextColor: 'secondary' }"></ng-container>
  </div>
</ng-container>
<ng-template #errors let-errors="errors" let-errorTextColor="errorTextColor">
  <zap-control-errors [errors]="errors" [errorTextColor]="errorTextColor"></zap-control-errors>
</ng-template>
