<svg width="100%" height="100%" class="zui-content">
  <rect class="map-bg" width="100%" height="100%"></rect>
  <g class="event-group">
    <rect class="map-bg" width="100%" height="100%"></rect>
    <g class="map-group"></g>
  </g>
  <g class="control-group" transform="translate(10, 10)">
      <rect width="20" height="20" x="0" y="0" class="button zoom-in"></rect>
      <text x="10" y="15" text-anchor="middle" class="button-text zoom-in">+</text>
      <rect width="20" height="20" x="0" y="20" class="button zoom-out"></rect>
      <text x="10" y="35" text-anchor="middle" class="button-text zoom-out">-</text>
      <rect width="20" height="20" x="0" y="40" class="button reset"></rect>
      <text x="10" y="55" text-anchor="middle" class="button-text reset">R</text>
  </g>
</svg>