import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiHeaderFilterComponent } from './multi-header-filter.component';
import { CorePipesModule } from '@zeotap-ui/core';
import { CategoryFilterModule } from '../categoryFilter';
import { StatusModule } from '../status';
import { ZuiLibModule } from '@zeotap.infra/zui-lib';
import { IsFilterSelectedPipe } from './pipes/isFilterSelected.pipe';
import { AreAnyFiltersSelectedPipe } from './pipes/areAnyFiltersSelected.pipe';

@NgModule({
  declarations: [
    MultiHeaderFilterComponent,
    IsFilterSelectedPipe,
    AreAnyFiltersSelectedPipe,
  ],
  imports: [
    CommonModule,
    CorePipesModule,
    CategoryFilterModule,
    StatusModule,
    ZuiLibModule,
  ],
  exports: [MultiHeaderFilterComponent, AreAnyFiltersSelectedPipe],
})
export class MultiHeaderFilterModule {}
