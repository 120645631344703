import {
  compose,
  defaultTo,
  replace,
  toLower,
  toUpper,
  trim,
  includes,
  ifElse,
  __,
  identity,
  match,
  isEmpty,
  is,
  always,
  test,
} from 'ramda';
export const defaultToHyphen = defaultTo('-');
export const defaultToEmptyString = defaultTo('');
export const replaceWhiteSpaceWithHyphen = replace(/ +/g, '-');

export const removeWhiteSpaceInBetween = replace(/ +/g, '');

export const capitalizeWordBoundary = replace(/\b[a-z]/g, toUpper);

export const replaceWhiteSpaceWithUnderscore = replace(/ +/g, '_');

export const removeWhiteSpaceAndCapitaizeWordBoundary = compose(
  trim,
  removeWhiteSpaceInBetween,
  capitalizeWordBoundary,
  toLower
);

export const removeWhiteSpace = (inputString: string): string => {
  return inputString.replace(/\s+/g, '');
};

export const isString = (x) => typeof x === 'string';

export const includesComma = includes(',');
export const replaceCommas = replace(/,/g, '');
export const removeCommas = ifElse(includesComma, replaceCommas, identity);

export const replaceUnderscoreWithSpace = replace(/_+/g, ' ');

export const snakeCaseToPascalCase = compose(
  trim,
  capitalizeWordBoundary,
  replaceUnderscoreWithSpace,
  toLower
);

export const checkForStringMatch = (str, substr) => {
  return includes(toLower(trim(substr)), toLower(trim(str)));
};

// getContentBetweenOpenBrackets :: string -> string[]
export const getContentBetweenOpenBrackets = match(/\((.*?)\)/);

export const extensionStringReduce = (acc, curr, obj) => {
  if (obj.length == 1) return curr;
  return acc + '|' + curr;
};

export const getRegexChechStringForExtenstions = (extensions: string[]) =>
  extensions.reduce(extensionStringReduce);

export const checkExtensions = (extensions: string[]) => (filename: string) =>
  new RegExp(`\\.(${getRegexChechStringForExtenstions(extensions)})$`).test(
    filename
  );

export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getBase64EncodedString = (str: string) => {
  return btoa(JSON.stringify(JSON.parse(str)));
};

export const isEmptyString = compose(isEmpty, trim);

// searchString :: string -> (string -> boolean)
export const searchString = (searchTerm) =>
  ifElse(
    is(String),
    compose(test(new RegExp(toLowerCaseString(searchTerm))), toLower),
    always(false)
  );

export const toLowerCaseString = compose(toLower, trim);
