import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CollapseButtonComponent } from './collapse-button.component';

@NgModule({
  declarations: [CollapseButtonComponent],
  imports: [CommonModule],
  exports: [CollapseButtonComponent],
})
export class CollapseButtonModule {}
