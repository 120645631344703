import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zap-preview-data-table',
  templateUrl: './preview-data-table.component.html',
})
export class PreviewDataTableComponent implements OnInit {
  @Input() previewData;
  @Input() colHearders;
  constructor() {}

  ngOnInit() {}

  isNotObject(value) {
    return typeof value !== 'object';
  }
}
