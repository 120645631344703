import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { Organization } from '../types';
import { OrgsListFacadeService, SelectedOrgFacadeService } from '../services';
import { tap } from 'rxjs/operators';
import { NgEventBus } from 'ng-event-bus';
import { SelectedProductsToken } from '../../product';
import { UserPreferenceFacade } from '../../preferences';

@Component({
  selector: 'zui-org-selection',
  templateUrl: './org-selection.component.html',
  styleUrls: ['./org-selection.component.scss'],
})
export class OrgSelectionComponent implements OnInit, OnDestroy {
  selectedOrganization$: Observable<Organization>;
  organizations$: Observable<Organization[]>;

  @Input() disabled: boolean = false;

  constructor(
    private selectedOrgFacade: SelectedOrgFacadeService,
    private orgListFacade: OrgsListFacadeService,
    private eventBus: NgEventBus,
    private userPreferenceService: UserPreferenceFacade,
    @Inject(SelectedProductsToken) private products
  ) {
    this.selectedOrganization$ = this.selectedOrgFacade.selectedOrg$.pipe(
      tap((org) => {
        this.eventBus.cast('app:orgProductSelected', {
          organization: org,
          productId: this.products[0],
        });
      })
    );
    this.organizations$ = this.orgListFacade.orgs$;
  }

  ngOnInit(): void {}

  selectOrganization(organization: Organization) {
    this.selectedOrgFacade.setSelectedOrg(organization);
  }
  ngOnDestroy(): void {}
}
