import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PromptDescriptionTemplateDirective,
  PromptTextTemplateDirective,
} from './directives/message-prompt.directive';
import { MessagePromptComponent } from './message-prompt/message-prompt.component';
import { CoreDirectivesModule } from '@zeotap-ui/core';

@NgModule({
  declarations: [
    MessagePromptComponent,
    PromptTextTemplateDirective,
    PromptDescriptionTemplateDirective,
  ],
  imports: [CommonModule, CoreDirectivesModule],
  exports: [
    MessagePromptComponent,
    PromptTextTemplateDirective,
    PromptDescriptionTemplateDirective,
  ],
})
export class MessagePromptModule {}
