import {
  merge,
  assoc,
  __,
  ifElse,
  eqProps,
  propEq,
  compose,
  sortWith,
  ascend,
  prop,
  concat,
} from 'ramda';
import { ApiSortOrder } from '../types';

export const isAscending = propEq('sortOrder', ApiSortOrder.ASC);

export const getUpdatedSort = ifElse(
  eqProps('sortField'),
  compose(
    ifElse(
      isAscending,
      assoc('sortOrder', ApiSortOrder.DESC),
      assoc('sortOrder', ApiSortOrder.ASC)
    ),
    merge
  ),
  assoc('sortOrder', ApiSortOrder.ASC)
);
// sortByName :: IdName[] -> IdName[]
export const sortByName = sortWith([ascend(prop('name'))]);

export const getOrdering = ifElse(
  isAscending,
  prop('sortField'),
  compose(concat('-'), prop('sortField'))
);
