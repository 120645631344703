import { Injectable } from '@angular/core';
import { ConfigService } from '@zeotap-ui/config';
import { BehaviorSubject, Observable, combineLatest, from } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { DisplayMessageService } from '../../display-message';
import { isNotNullOrEmpty } from '../../utils';
import { SisenseTokenGeneratorService } from './sisense-token-generator.service';

@Injectable({
  providedIn: 'root',
})
export class SisenseEmbedSDKService {
  private sisenseConfig: any;
  private sisenseURL: string;
  private sisenseTheme: string;
  private sisenseFrame$: Observable<any>;
  private isSisenseFrameReady$ = new BehaviorSubject<boolean>(false);

  get isSisenseFrameReady(): Observable<boolean> {
    return this.isSisenseFrameReady$.asObservable();
  }

  constructor(
    private config: ConfigService,
    private sisenseTokenGeneratorService: SisenseTokenGeneratorService,
    private displayMsgService: DisplayMessageService
  ) {
    this.initSisenseConfig();

    this.sisenseFrame$ = combineLatest([
      this.sisenseTokenGeneratorService.sisenseWat$,
      this.isSisenseFrameReady$,
    ]).pipe(
      filter(
        ([wat, isSisenseFrameReady]) =>
          isNotNullOrEmpty(wat) && !isSisenseFrameReady
      ),
      map(([wat, _]) => {
        return this.createSisenseFrame(wat);
      }),
      shareReplay()
    );

    this.sisenseFrame$.subscribe((frame) =>
      this.isSisenseFrameReady$.next(true)
    );
  }

  private initSisenseConfig() {
    this.sisenseConfig = this.config.getFeatureValue(
      'SISENSE_CONFIG',
      true
    ).config;
    this.sisenseTheme = this.sisenseConfig?.themeId;
    this.sisenseURL = this.sisenseConfig?.url;
  }

  private createSisenseFrame(wat: string) {
    const { SisenseFrame, enums } = window['sisense.embed'];
    return new SisenseFrame({
      wat,
      url: this.sisenseURL,
      settings: {
        showToolbar: false,
        showLeftPane: false,
        showRightPane: false,
      },
      theme: this.sisenseTheme,
    });
  }
  loadDashboard(dashboard: string, element: HTMLElement) {
    const { enums } = window['sisense.embed'];

    return this.sisenseFrame$.pipe(
      switchMap((frame) => {
        return from(frame.render(element)).pipe(
          switchMap(() => {
            return frame.dashboard.open(dashboard);
          }),
          map(() => frame.dashboard)
        );
      })
    );
  }
}
