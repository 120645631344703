import {
  Component,
  Input,
  ViewChild,
  TemplateRef,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  EventEmitter,
  EmbeddedViewRef
} from '@angular/core';

@Component({
  selector: 'zui-dropdown-option-static',
  styleUrls: ['./dropdownStatic.scss'],
  template: `
    <div class="zui-option-content" (click)="toggleSelection($event)">
      <ng-content></ng-content>
    </div>
  `
})
export class DropdownOptionsStaticComponent {
  public selected = false;
  public view: EmbeddedViewRef<any>;
  public selectionChange = new EventEmitter<DropdownOptionsStaticComponent>();
  @Input() public selectionChangeListener: Function;
  @Input() public data: any;
  @ViewChild(TemplateRef) public template: TemplateRef<any>;

  constructor(private viewContainer: ViewContainerRef) {}

  public showChild() {
    this.viewContainer.createEmbeddedView(this.template);
  }

  public destroyChild() {
    this.viewContainer.clear();
  }

  public toggleSelection(event) {
    this.selectionChange.emit(this);
    if (this.selectionChangeListener) {
      this.selectionChangeListener(this);
    }
    event.stopPropagation();
  }
}
