import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'zui-poster-header',
  styleUrls: ['./poster.component.scss'],
  template: `
    <div class="poster-header">
      <ng-content></ng-content>
    </div>
  `
})
export class PosterHeaderComponent {}
