import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MenuIconTemplateDirective } from './directives';
import { DottedMenuComponent } from './dotted-menu/dotted-menu.component';

@NgModule({
  declarations: [MenuIconTemplateDirective, DottedMenuComponent],
  imports: [CommonModule, OverlayModule, PortalModule],
  exports: [MenuIconTemplateDirective, DottedMenuComponent],
})
export class DottedMenuModule {}
