import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMenuItemComponent } from './header-menu-item/header-menu-item.component';
import { NavbarComponent } from './navbar.component';
import { RouterModule } from '@angular/router';
import { CdkTreeModule } from '@angular/cdk/tree';
import { NavbarItemsTreeComponent } from './navbar-item-tree/navbar-items-tree.component';
import { UserMenuItemComponent } from './user-menu-item/user-menu-item.component';
import { CdkMenuModule } from '@angular/cdk/menu';

@NgModule({
  declarations: [
    NavbarComponent,
    HeaderMenuItemComponent,
    NavbarItemsTreeComponent,
    UserMenuItemComponent,
  ],
  imports: [CommonModule, RouterModule, CdkTreeModule, CdkMenuModule,],
  exports: [HeaderMenuItemComponent, NavbarComponent, UserMenuItemComponent],
})
export class NavbarModule {}
