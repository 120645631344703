import {
  Component,
  OnInit,
  AfterContentInit,
  Input,
  AfterContentChecked,
  ContentChildren,
  QueryList,
  ElementRef,
  ViewChild
} from '@angular/core';

import { BaseControl, getControlValueAccessor } from '../base';

import { RadioButtonComponent } from './radioButton';

import { Observable, fromEvent } from 'rxjs';

@Component({
  selector: 'zui-radio-group',
  template: `
    <ng-content></ng-content>
  `,
  providers: [getControlValueAccessor(RadioGroupComponent)]
})
export class RadioGroupComponent extends BaseControl
  implements AfterContentChecked {
  public radioArr: RadioButtonComponent[] = [];
  @ContentChildren(RadioButtonComponent) public radioButtons: QueryList<
    RadioButtonComponent
  >;
  @Input() data: Array<Object>;
  @Input() selected: Object;
  test: String = 'test';

  constructor(private elementRef: ElementRef) {
    super();
    this.toggleRadioSelection = this.toggleRadioSelection.bind(this);
  }

  ngAfterContentChecked() {
    this.radioButtons.forEach(o => {
      o.onSelect.subscribe(this.toggleRadioSelection);
    });
  }

  writeValue() {
    this.data.map(item => {
      if (item['selected']) {
        super.writeValue(item);
      }
    });
    return;
  }

  toggleRadioSelection(event) {
    this.data.map(item => {
      if (item['label'] == event.label) {
        item['selected'] = true;
        this.value = item;
      } else {
        item['selected'] = false;
      }
      return item;
    });
  }
}
