<div class="bg-primary-1-900/60 overlay fixed z-[1000] w-full h-full inset-0 dialog-prompt" *ngIf="showModal">
  <section class="shadow-7 dialog-box w-[600px] min-h-[280px] rounded top-[calc(50%_-_200px)] left-[calc(50%_-_320px)] fixed border-solid bg-white">
    <div class="dialog-text w-full relative p-5" align="center">
      <span class="icon" [ngClass]="!!zapIconClass ? zapIconClass : { 'text-state-error-700 text-6xl error': !zapIconClass, 'text-state-warning-1 warning': warning, 'text-state-success success': success }"></span>
      <div *ngIf="promptText !== ''; else otherPrompt" class="info-desc leading-5 p-5 align text-sm font-primary text-grey-900" [innerHTML]="promptText"></div>
      <ng-template #otherPrompt>
        <div class="info-desc leading-5 p-5 align text-sm font-primary">
          <div class="promptText px-0 py-2.5">Your Segment size has {{ promptVal1 }}.</div>
          <div class="font-primary-bold promptVal inline-block">It will take up to a maximum of {{ promptVal2 }} days for the changes to be visible.</div>
        </div>
      </ng-template>
    </div>
    <div class="bottom w-full" align="center">
      <hr class="mt-0 border-t-2 border-solid !border-t-grey-200" />
      <section *ngIf="subHeader" class="text-base text-primary-1-900 info px-2.5 py-0 align bg-white font-primary-medium">{{ subHeader }}</section>
      <section class="bottom-btn relative w-full p-5">
        <div *ngIf="cancelActionName" class="btn cursor-pointer relative inline-block w-[49%]">
          <!--TODO: change #ececf1 to use tailwind -->
          <span class="font-secondary-bold text-base text-grey-800 cancelbtn hover:bg-[#ececf1] rounded p-2" (click)="hidden('hide')">{{ cancelActionName | uppercase }}</span>
        </div>
        <div *ngIf="actionName" class="btn cursor-pointer relative inline-block w-[49%]">
          <span class="text-primary-1-900 font-secondary-bold text-base action-btn hover:rounded hover:p-2" [ngClass]="!!zapActionBtnClass ? zapActionBtnClass : { '!text-state-error-700 hover:bg-state-error-700/10 error-text': !zapActionBtnClass && !warning && !success, 'text-state-warning-1 hover:bg-state-warning-1/10 warning-text': warning, 'text-state-success hover:bg-state-success/10 success-text': success }" (click)="hidden(actionName)">{{ actionName | uppercase }}</span>
        </div>
      </section>
    </div>
  </section>
</div>
