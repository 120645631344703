<section class="h-full overflow-auto shadow-5 rounded flex welcome-page-container bg-white">
  <div class="h-full w-6/12 pl-6 pr-0 pt-10 pb-0 text-container">
    <span *ngIf="!!header" class="text-5xl uppercase pageHeader font-secondary-bold text-grey-1000">{{ header }}</span>
    <div *ngIf="!!subtext" class="text-sm font-primary text-grey-800 leading-[1.57rem] mt-6 mb-8 subtext">{{ subtext }}</div>
    <ng-content></ng-content>
    <zui-button [disabled]="isDisabled" *ngIf="isButtonEnabled" [label]="label" customToolTip [text]="disabledTooltipText" [positions]="tooltipPositions" (click)="onClick.emit()"> </zui-button>
  </div>
  <div *ngIf="imageURL" class="h-full w-6/12 image-container">
    <img class="h-full rounded w-full welcome-img" [src]="imageURL" />
  </div>
</section>
