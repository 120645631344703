import { createEnvironment } from './common';

export const environment = createEnvironment({
  env: '-demo',
  splEnv: '-alpha',
  sdkPath: 'qa/',
})({
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyB6i8STzRURfoyrQduh4zWizcQGrl-8HCk',
    authDomain: 'zeotap-demo-prod.firebaseapp.com',
    databaseURL: 'https://zeotap-demo-prod-default-rtdb.firebaseio.com',
    projectId: 'zeotap-demo-prod',
    storageBucket: 'zeotap-demo-prod.appspot.com',
    messagingSenderId: '91852607481',
    appId: '1:91852607481:web:ee142d3002ae6e2a46f728',
  },
  type: '',
  okta: {
    clientId: '0oa3ye417hwMih3Nv0x7',
    issuer: 'https://login-staging.zeotap.com/oauth2/default',
    redirectUri: `${window.location.origin}/app/token`,
    postLogoutRedirectUri: `${window.location.origin}/app`,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    tokenManager: {
      autoRenew: true,
    },
  },
  sisenseConfig: {
    url: 'https://sisense.zeotap.com',
    themeId: '63ef1a76d25369003500bfc4',
  },
  unityConfig: {
    unityConfigPath: 'assets/config/config.json',
    userSvcUrl: 'https://account-demo-prod.zeotap.com/api/v2/',
    userSvcUrlV3: 'https://account-demo-prod.zeotap.com/api/v3/',
    userSvcUrlV4: 'https://account-demo-prod.zeotap.com/api/v4/',
    userSvcUrlV5: 'https://account-demo-prod.zeotap.com/api/v5/',
    rbacUrl: 'https://account-demo-prod.zeotap.com/access/',
    symphonyStudioCdapUrl: 'https://symphony-demo-prod.zeotap.com/login',
    customDemoSegmentApiKey:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTQwNjY0NzIsImV4cCI6MTY2OTYxODQ3MCwidCI6IjYxM2ZkMjcxNDlmMGNlMTk1M2ExMGE3ZjIyNmQwNmZjIn0.p_AOIfT-JS4UDslfzgq3aKzg_dtJ_Bt6yTemGHx_QGI',
    audienceAIUrl: 'https://hermes-demo-prod.zeotap.com/',
  },
});
