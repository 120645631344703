import * as Rx from 'rxjs';
import { __ } from 'ramda';
import * as Rxop from 'rxjs/operators';
import { AsyncCallResponse, Status } from '../http';

export const printStream = Rxop.tap(console.log);

export type AsyncStateStreamEvent<ValueType, TriggerType> = {
  kind: 'error' | 'valueLoaded' | 'loadValuetriggered' | 'loadingTriggered';
  trigger?: TriggerType;
  error?: any;
  value?: ValueType;
  loading: boolean;
};

export type UseObservableForResponse<ValueType, TriggerType> = [];
// Now a function that merges the loader of async value ( react hook style )
export function watchAsyncState<ValueType, TriggerType>(
  valueStream$: Rx.Observable<AsyncCallResponse<ValueType>>,
  triggerStream$: Rx.Observable<TriggerType>
): Rx.Observable<AsyncStateStreamEvent<ValueType, TriggerType>> {
  var lastEmittedValue = null;
  const valueObservable = valueStream$.pipe(
    Rxop.map((value) =>
      value?.status === Status.Error
        ? {
            kind: 'error',
            loading: false,
            error: value.error,
          }
        : ({
            kind: 'valueLoaded',
            loading: false,
            value: value ? value.data : null,
          } as AsyncStateStreamEvent<ValueType, TriggerType>)
    )
  );
  const triggerObservable = triggerStream$.pipe(
    Rxop.map(
      (value) =>
        ({
          kind: 'loadValuetriggered',
          trigger: value,
          loading: true,
        } as AsyncStateStreamEvent<ValueType, TriggerType>)
    )
  );
  // merging all streams to get a event log like stream for the consumer.
  const eventStreamObservable: Rx.Observable<AsyncStateStreamEvent<
    ValueType,
    TriggerType
  >> = Rx.merge(valueObservable, triggerObservable).pipe(
    Rxop.map((event) => ({
      ...(lastEmittedValue ? lastEmittedValue : { loading: false }),
      ...event,
    })),
    Rxop.tap((value) => {
      lastEmittedValue = value; // TODO : find better way to do this
    }),
    Rxop.catchError((value) => {
      return Rx.of({
        kind: 'error',
        loading: false,
        error: 'Unknown Error took place',
      } as AsyncStateStreamEvent<ValueType, TriggerType>);
    })
  );
  return eventStreamObservable;
}
