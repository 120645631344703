import {
  filter,
  either,
  not,
  hasPath,
  propOr,
  prop,
  compose,
  pluck,
  propEq,
  __,
} from 'ramda';

export const getFilterUsingToggleValues = (toggleMap: { [key: string]: any }) =>
  filter(
    either(
      compose(not, hasPath(['toggleBasedOn'])),
      compose(propOr(false, __, toggleMap), prop('toggleBasedOn'))
    )
  );
