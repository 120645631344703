import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(value: any[], separator: string = ', '): string {
    // Check if the value is an array
    if (!Array.isArray(value)) {
      return '';
    }

    // Filter out null values and join the remaining array elements with the separator
    const filteredValue = value.filter((item) => item !== null);
    const joinedValue = filteredValue.join(separator);

    return joinedValue;
  }
}
