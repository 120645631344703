import { Component, Input, Output, EventEmitter } from '@angular/core';

import { BaseControl, getControlValueAccessor } from '../base';

export type labelPositions = 'top' | 'left' | 'right';

@Component({
  selector: 'zui-radio',
  templateUrl: './radioButton.html',
  styleUrls: ['./radioButton.scss'],
  providers: [getControlValueAccessor(RadioButtonComponent)]
})
export class RadioButtonComponent extends BaseControl {
  constructor() {
    super();
  }
  @Input() label: String;
  @Input() name: String;
  @Input() data: Boolean;
  @Output() onSelect = new EventEmitter<RadioButtonComponent>();

  check() {
    if (this.disabled == true) return;
    this.onSelect.emit(this);
  }

  isChecked() {
    if (this.data) {
      return true;
    } else {
      return false;
    }
  }

  isDisabled() {
    return this.disabled == true;
  }
}
