import { Component, Input, OnInit } from '@angular/core';
import { ValidatorErrorDescription } from '@zeotap-ui/core';

/**
 * ControlErrorsComponent
 * @description
 * This component is used to render a list of errors.
 * It accepts the following inputs:
 * - errors: (ValidatorErrorDescription | string)[]
 * - errorTextColor: 'primary' | 'secondary'//primary - text-white, secondary - text-state-error-700
 * - showAsUnorderedList: boolean //if true, errors will be shown as an unordered list
 * - showAsOrderedlist: boolean //if true, errors will be shown as an ordered list
 * If showAsUnorderedList and showAsOrderedlist are both false, errors will be shown as plain text aligned vertically.
 * @example
 *  <zap-control-errors [error]="errors" [showAsUnorderedList]="true"></zap-control-errors>
 */

@Component({
  selector: 'zap-control-errors',
  templateUrl: './control-errors.component.html',
})
export class ControlErrorsComponent implements OnInit {
  @Input() errors: (ValidatorErrorDescription | string)[] = [];
  @Input() errorTextColor: 'primary' | 'secondary' = 'primary';
  @Input() showAsUnorderedList = false;
  @Input() showAsOrderedlist = false;
  constructor() {}

  ngOnInit() {}
}
