import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Data,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { ConfigRuleEvaluatorPipe } from '@zeotap-ui/config';
import { AuthFacadeService, isNotNullOrEmpty } from '@zeotap-ui/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const defaultRedirectRoute = 'redirectByDefault';
@Injectable()
export class PermissionGuard implements CanActivate, CanLoad, CanActivateChild {
  router = inject(Router);
  ruleEvaluatorPipe = inject(ConfigRuleEvaluatorPipe);

  constructor(private authFacade: AuthFacadeService) {}

  permissionAccessGuard = (data: Data) => {
    const { product, permissions } = data;
    if (isNotNullOrEmpty(permissions)) {
      const hasPermissionToRoute = permissions?.every((permission) =>
        this.ruleEvaluatorPipe.transform(permission)
      );
      if (hasPermissionToRoute) {
        return true;
      }
      return this.router.parseUrl(data.redirectRoute || defaultRedirectRoute);
    }
    return true;
  };

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const permissionAccess = this.permissionAccessGuard(route.data);
    if (
      permissionAccess === true &&
      isNotNullOrEmpty(route.data.userInfoPermission)
    ) {
      return this.authFacade.userInfo$.pipe(
        map((userInfo) => {
          if (
            userInfo?.user?.permissions?.includes(route.data.userInfoPermission)
          ) {
            return true;
          }
          return this.router.parseUrl('/unauthorized');
        })
      );
    }
    return permissionAccess;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.permissionAccessGuard(route.data);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.permissionAccessGuard(route.data);
  }
}
