import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { Organization } from '../../types';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AsyncCallResponse, isError } from '../../../http';
import { OrgApiService } from '../org-api/org-api.service';
import { SelectedProductsToken } from '../../../product';
import { ProductId } from '../../../product/types';
import { isNotNull } from '../../../utils';

export interface OrgListState {
  orgs: Organization[]; // list of all orgs
  isOrgsLoading: boolean; // state that says we are loading the list of orgs
  orgLoadingError?: string;
}

let _orgsState: OrgListState = {
  orgs: null,
  isOrgsLoading: false,
};

@Injectable({
  providedIn: 'any',
})
export class OrgsListFacadeService {
  // private variables/subjects to manage state
  private store$ = new BehaviorSubject<OrgListState>(_orgsState);
  private state$: Observable<OrgListState> = this.store$.asObservable(); // keeping this private as this need not be a open to everyone
  private reloadOrgs$ = new Subject();

  orgs$ = this.state$.pipe(
    map((state) => state.orgs),
    filter((org) => isNotNull(org)),
    distinctUntilChanged(),
    shareReplay(1) // to make sure all pipe functions only run once
  );

  isOrgsLoading$ = this.state$.pipe(
    map((state) => state.isOrgsLoading),
    distinctUntilChanged(),
    shareReplay(1)
  );
  error$ = this.state$.pipe(
    map((state) => state.orgLoadingError),
    distinctUntilChanged(),
    shareReplay(1)
  );
  constructor(
    private orgService: OrgApiService,
    @Inject(SelectedProductsToken) products: ProductId[]
  ) {
    this.reloadOrgs$
      .pipe(
        startWith(''),
        tap(() => {
          this.store$.next({
            isOrgsLoading: true,
            orgs: null,
          });
        }),
        switchMap(() =>
          this.orgService.getOrgsForProduct(
            products.map((product) => `${product}`)
          )
        )
      )
      .subscribe((orgsResponse: AsyncCallResponse<Organization[]>) => {
        if (isError(orgsResponse.status)) {
          // an error occurred
          this.store$.next({
            ...this.store$.value,
            ...{
              isOrgsLoading: false,
              orgLoadingError: orgsResponse.error,
            },
          });
        } else {
          this.store$.next({
            ...this.store$.value,
            ...{
              orgs: orgsResponse.data,
              isOrgsLoading: false,
              orgLoadingError: null,
            },
          });
        }
      });
  }

  reload() {
    this.reloadOrgs$.next(null);
  }
}
