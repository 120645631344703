import { Injectable, Optional, SkipSelf } from '@angular/core';
import { logEvent } from './logger';

@Injectable()
export class LoggingService {
  private context: any = {};

  constructor(@SkipSelf() @Optional() private parent: LoggingService) {}

  logEvent(event: string, eventProp?: any) {
    if (this.parent) {
      this.parent.logEvent(event, { ...this.context, ...eventProp });
      return;
    }
    logEvent(event, { ...this.context, ...eventProp });
    console.debug(
      'event logged -> ',
      'event name: ',
      event,
      'event properties: ',
      { ...this.context, ...eventProp }
    );
  }

  logContext(properties: any) {
    this.context = { ...this.context, ...properties };
    console.debug('context updated -> ', this.context);
  }
}
