import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateFiltersComponent } from './date-filters.component';

@NgModule({
  imports: [CommonModule, NgSelectModule, FormsModule],
  exports: [DateFiltersComponent],
  declarations: [DateFiltersComponent],
})
export class DateFiltersModule {}
