<form [formGroup]="form">
  <div class="dyanamic-form-container">
    <ng-container *ngFor="let question of questions">
      <ng-container
        *ngIf="{
          shouldDisplay: question?.displayConditionDependentFieldId ? form.get(question.key) : question.displayConditionFn(form),
          shouldBeEnabled: question?.configRule | ruleEvaluate,
          shouldBeAsyncEnabled: question?.asyncConfigRule | asyncRuleEvaluate | async
        } as data"
      >
        <ng-container *ngIf="!!question?.configRule || !!question?.asyncConfigRule ? ((!!question?.configRule && data.shouldBeEnabled) || (!!question?.asyncConfigRule && data.shouldBeAsyncEnabled)) && data.shouldDisplay : data.shouldDisplay">
          <ng-container *ngTemplateOutlet="hasAnyQuestionTemplates && getQuestionView(question) ? getQuestionView(question) : defaultQuestionTemplate; context: { form: form, question: question }"> </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</form>
<ng-template #defaultQuestionTemplate let-form="form" let-question="question">
  <div class="form-row">
    <zap-dyanamic-form-question [question]="question" [form]="form"></zap-dyanamic-form-question>
  </div>
</ng-template>
