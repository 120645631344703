import { emailValidator } from '../../utils';
import { ProductInfo, UserInfo } from '../types';
import { prop, all, has, compose, test, tail, split } from 'ramda';

export const isValidUser = (user: UserInfo): boolean =>
  isValidUserName(user) && isValidEmail(user);

export const isValidUserName = (user: UserInfo): boolean =>
  !!user.name && user.name.trim().length <= 100;

export const isValidEmail = (user: UserInfo): boolean =>
  !!user.email && emailValidator(user.email);

export const isUserSelected = prop('selected');

export const isAllUserSelected = all(isUserSelected);

export const checkForUserProductAccess = (product_name) => (
  data: ProductInfo
) => has(product_name, data);

export const isZeotapDomainUser = compose(test(/zeotap.com/), tail, split('@'));
