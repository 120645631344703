import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicInputFieldComponent } from './dynamic-input-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CorePipesModule } from '@zeotap-ui/core';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [DynamicInputFieldComponent],
  imports: [CommonModule, ReactiveFormsModule, CorePipesModule, NgSelectModule],
  exports: [DynamicInputFieldComponent],
})
export class DynamicInputFieldModule {}
