import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  BarChartModule,
  LineChartModule,
  PieChartModule,
} from '@swimlane/ngx-charts';
import { CorePipesModule } from '@zeotap-ui/core';
import { CustomLineChartComponent } from './custom-line-chart/custom-line-chart.component';
import { CustomPieChartComponent } from './custom-pie-chart/custom-pie-chart.component';
import { CustomVerticalBarChartComponent } from './custom-vertical-bar-chart';
import { ChartTemplateDirective } from './directives';
import { ProgressChartComponent } from './progress-chart/progress-chart.component';
import { NoContentImageTextModule } from '../no-content-image-text';

const COMPONENTS = [
  CustomPieChartComponent,
  CustomLineChartComponent,
  CustomVerticalBarChartComponent,
  ProgressChartComponent,
];
@NgModule({
  declarations: [...COMPONENTS, ChartTemplateDirective],
  imports: [
    CommonModule,
    PieChartModule,
    LineChartModule,
    BarChartModule,
    CorePipesModule,
    NoContentImageTextModule,
  ],
  exports: [...COMPONENTS, ChartTemplateDirective],
})
export class ChartsModule {}
