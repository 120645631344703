<ng-container *ngFor="let menuParent of menuData | keyvalue">
  <ng-container *ngTemplateOutlet="menuItemTemplate; context: { menuItems: menuParent.value, parentKey: menuParent.key }"></ng-container>
</ng-container>
<ng-template #menuItemTemplate let-menuItems="menuItems" let-parentKey="parentKey">
  <div class="pb-2">
    <div class="border-solid border border-primary-1-100 rounded-md">
      <div class="border-solid p-5 flex items-center cursor-pointer" (click)="toggleAccordion(parentKey)">
        <div class="flex-1">
          <span class="font-primary-bold text-sm text-grey-800">{{ menuItems[0]?.menuParentDisplayName }}</span>
        </div>
        <div class="flex-1 flex">
          <div class="flex-1 flex justify-center ml-4" *ngIf="!!selectedChildren[parentKey] && selectedChildren[parentKey].length">
            <span class="bg-state-success-100 text-state-success-900 text-xs px-3.5 py-1 rounded-3xl text-center">{{ selectedChildren[parentKey].length }} selected</span>
          </div>
          <div class="flex-1 flex justify-end">
            <span class="flex items-center unity-icons-dropdown"></span>
          </div>
        </div>
      </div>
      <div class="font-primary" *ngIf="parentKey === openMenuItem">
        <div *ngFor="let menuItem of menuItems">
          <div [ngClass]="{ 'bg-primary-1-100 text-primary-1-500': menuItem?.menuChildName === activeItem?.menuChildName }" class="py-2.5 px-5 flex items-center text-grey-800 cursor-pointer" (click)="onChildItemClick(menuItem)">
            <ng-container *ngIf="menuItem?.menuChildType === 'radio'">
              <input type="radio" class="w-3.5 h-3.5 rounded-full bg-clip-content border border-solid border-grey-400" [ngClass]="{ '!border-state-success-900 !bg-state-success-900 p-0.5': selectedChildren[parentKey] | contains: menuItem?.menuChildName }" />
            </ng-container>
            <ng-container *ngIf="menuItem?.menuChildType === 'default' || !menuItem?.menuChildType">
              <span [class]="(selectedChildren[parentKey] | contains: menuItem?.menuChildName) ? 'rounded-sm bg-state-success-900 text-white !text-lg unity-icons-unie906' : menuItem?.menuChildName === activeItem?.menuChildName ? 'rounded-sm bg-white text-grey-900 !text-lg unity-icons-unie9d4' : 'rounded-sm bg-grey-100 text-grey-900 !text-lg unity-icons-unie9d4'"></span>
            </ng-container>
            <span class="pl-2.5 text-xs font-medium" [ngClass]="{ '!font-semibold': menuItem?.menuChildName === activeItem?.menuChildName, 'text-state-success-900': selectedChildren[parentKey] | contains: menuItem?.menuChildName }">{{ menuItem?.menuChildDisplayName }}</span>
          </div>
        </div>
        <div *ngIf="!!additionalInformation[parentKey]" class="p-5">
          <div class="font-bold text-grey-1000 text-xs">{{ additionalInformation[parentKey]?.additionalInformationHeader }}</div>
          <div class="text-grey-800 text-xs">{{ additionalInformation[parentKey]?.additionalInformationBody }}</div>
          <div class="pt-1.5">
            <a [href]="additionalInformation[parentKey]?.additionalInformationLink" target="_blank" class="text-primary-1-500 text-xs">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
