<section>
  <div class="key-value-input-list-container form-holder">
    <form [formGroup]="formGroup">
      <div class="form-row grid grid-cols-[1fr_1fr_2rem] pb-4" [formArrayName]="arrayName" *ngFor="let field of formArray?.controls; let ind = index">
        <ng-container [formGroupName]="ind">
          <!-- left input field -->
          <div class="control-holder pl-4">
            <ng-container [ngSwitch]="leftInputField.type">
              <ng-container *ngSwitchCase="'dropdownObject'">
                <ng-select #selector [ngClass]="{ 'disabled bg-grey-100 pointer-events-none': isViewState }" class="font-primary text-sm text-grey-900 key-value-dropdown placeholder:text-grey/52" [formControlName]="leftInputField.formKey" [placeholder]="leftInputField.placeholder" appendTo="body" [items]="leftInputFieldOptions" [bindLabel]="leftInputField.itemLabel" [bindValue]="leftInputField.itemValue" (change)="dropDownChanged(ind, 'left')" (open)="onDropDownOpen(selector)" (close)="onDropDownClose()"> </ng-select>
              </ng-container>
              <ng-container *ngSwitchCase="'dropdown'">
                <ng-select [ngClass]="{ 'disabled bg-grey-100 pointer-events-none': isViewState }" class="font-primary text-sm text-primary-1 placeholder:text-grey/52" [formControlName]="leftInputField.formKey" [placeholder]="leftInputField.placeholder" [items]="leftInputFieldOptions" (change)="dropDownChanged(ind, 'left')"> </ng-select>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                <input
                  class="textBox font-primary text-sm text-grey-900 disabled:!bg-grey-100 w-full h-10 rounded border p-[0.3125rem] border-solid border-[#ccc] outline-0 placeholder:text-grey/52"
                  type="text"
                  [formControlName]="leftInputField.formKey"
                  [placeholder]="leftInputField.placeholder"
                  [replaceWithNull]="''"
                  customToolTip
                  [contentTemplate]="showLatestTooltip ? (showLeftInputFieldErrors(ind) ? validationTooltip : showNullTooltip(ind, leftInputField.formKey) ? nullValueTooltip : null) : null"
                  [value]="{ value: getErrorMessage(ind, leftInputField.formKey) }"
                  [customTooltipClass]="tooltipClass"
                  [positions]="tooltipPositions"
                  [ngClass]="{ 'error-border !border !border-state-error-700 !border-solid': showLatestTooltip && showLeftInputFieldErrors(ind), 'disabled  bg-grey-100 pointer-events-none': isViewState }"
                />
                <div class="errorMessage text-state-error-700 font-primary text-[13px] mt-1" *ngIf="!showLatestTooltip && showLeftInputFieldErrors(ind)">{{ getErrorMessage(ind, leftInputField.formKey) }}</div>
              </ng-container>
            </ng-container>
          </div>
          <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>

          <!-- right input field -->
          <div>
            <ng-container [ngSwitch]="rightInputField.type">
              <ng-container *ngSwitchCase="'text'">
                <input
                  class="textBox font-primary text-sm text-grey-900 disabled:!bg-grey-100 w-full h-10 rounded border p-[0.3125rem] border-solid border-[#ccc] outline-0 placeholder:text-grey/52"
                  type="text"
                  [formControlName]="rightInputField.formKey"
                  [placeholder]="rightInputField.placeholder"
                  [replaceWithNull]="''"
                  customToolTip
                  [contentTemplate]="showLatestTooltip ? (shouldShowErrorMessage(ind, rightInputField.formKey) ? validationTooltip : showNullTooltip(ind, rightInputField.formKey) ? nullValueTooltip : null) : null"
                  [value]="{ value: getErrorMessage(ind, rightInputField.formKey) }"
                  [customTooltipClass]="tooltipClass"
                  [positions]="tooltipPositions"
                  [ngClass]="{ 'error-border !border !border-state-error-700 !border-solid': showLatestTooltip && shouldShowErrorMessage(ind, rightInputField.formKey), 'disabled  bg-grey-100 pointer-events-none': isViewState }"
                />
                <div class="errorMessage text-state-error-700 font-primary text-[13px] mt-1" *ngIf="!showLatestTooltip && shouldShowErrorMessage(ind, rightInputField.formKey)">{{ getErrorMessage(ind, rightInputField.formKey) }}</div>
              </ng-container>
              <ng-container *ngSwitchCase="'dropdownObject'">
                <ng-select
                  #selector
                  class="font-primary text-sm text-grey-900 key-value-dropdown placeholder:text-grey/52"
                  [formControlName]="rightInputField.formKey"
                  [placeholder]="rightInputField.placeholder"
                  appendTo="body"
                  [items]="rightInputFieldOptions"
                  [bindLabel]="rightInputField.itemLabel"
                  [bindValue]="rightInputField.itemValue"
                  (change)="dropDownChanged(ind, 'right')"
                  (open)="onDropDownOpen(selector)"
                  (close)="onDropDownClose()"
                  customToolTip
                  [contentTemplate]="showLatestTooltip && shouldShowErrorMessage(ind, rightInputField.formKey) ? validationTooltip : null"
                  [value]="{ value: getErrorMessage(ind, rightInputField.formKey) }"
                  [customTooltipClass]="tooltipClass"
                  [positions]="tooltipPositions"
                  [ngClass]="{ 'error-border !border !border-state-error-700 !border-solid': showLatestTooltip && shouldShowErrorMessage(ind, rightInputField.formKey), 'disabled bg-grey-100 pointer-events-none': isViewState }"
                >
                </ng-select>
              </ng-container>
              <ng-container *ngSwitchCase="'dropdown'">
                <ng-select
                  class="font-primary text-sm text-grey-900 key-value-dropdown placeholder:text-grey/52"
                  [formControlName]="rightInputField.formKey"
                  [placeholder]="rightInputField.placeholder"
                  [items]="rightInputFieldOptions"
                  (change)="dropDownChanged(ind, 'right')"
                  customToolTip
                  [contentTemplate]="showLatestTooltip && shouldShowErrorMessage(ind, rightInputField.formKey) ? validationTooltip : null"
                  [value]="{ value: getErrorMessage(ind, rightInputField.formKey) }"
                  [customTooltipClass]="tooltipClass"
                  [positions]="tooltipPositions"
                  [ngClass]="{ 'error-border !border !border-state-error-700 !border-solid': showLatestTooltip && shouldShowErrorMessage(ind, rightInputField.formKey), 'disabled  bg-grey-100 pointer-events-none': isViewState }"
                >
                </ng-select>
              </ng-container>
            </ng-container>
          </div>
          <ng-container *ngFor="let prop of properties">
            <ng-container *ngIf="prop.type === 'checkbox'">
              <div>
                <mat-checkbox [formControlName]="prop.key" class="checkbox mt-1 mb-0 mx-4" [disabled]="isViewState || shouldShowErrorMessage(ind, prop.key)" (change)="onPropertyChange(ind)"> </mat-checkbox>
              </div>
            </ng-container>
            <ng-container *ngIf="prop.type === 'toggle'">
              <div>
                <mat-slide-toggle [formControlName]="prop.key" class="toggle mt-1 mb-0 mx-4" [disabled]="isViewState || shouldShowErrorMessage(ind, prop.key)" (change)="onPropertyChange(ind)"> </mat-slide-toggle>
              </div>
            </ng-container>
            <ng-container *ngIf="prop.type === 'radio'">
              <div>
                <mat-radio-group class="radio font-primary text-sm text-grey-900 mt-1 mb-0 mx-4" aria-label="Select an option" [formControlName]="prop.key" (change)="onPropertyChange(ind)">
                  <mat-radio-button [disabled]="isViewState || shouldShowErrorMessage(ind, prop.key)" [value]="true">True</mat-radio-button>
                  <mat-radio-button [disabled]="isViewState || shouldShowErrorMessage(ind, prop.key)" [value]="false">False</mat-radio-button>
                </mat-radio-group>
              </div>
            </ng-container>
          </ng-container>
          <div class="remove-holder pl-2 pt-3.5" *ngIf="ind >= minRow && !isViewState">
            <span [class]="'icon ' + closeIcon" (click)="removeRow(ind)"></span>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <ng-container *ngIf="formArray?.length !== maxRow && !isViewState">
    <span #add class="add-btn font-secondary text-primary-1 text-sm cursor-pointer pl-4" (click)="addRow(add)">+ADD {{ addBtnName | uppercase }} </span>
  </ng-container>
</section>
<ng-template #validationTooltip let-value="value">
  <div class="error-container">
    <span class="icon error"></span>
    <span class="font-primary text-sm text-grey-900 error-text">{{ value }}</span>
  </div>
</ng-template>
<ng-template #nullValueTooltip>
  <div class="error-container">
    <span class="icon informationIcon info-icon-tooltip"></span>
    <span class="font-primary text-sm text-grey-900 error-text">{{ 'UU_NULL_VALUE_MSG' | translate }}</span>
  </div>
</ng-template>
