import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'zui-poster-footer',
  styleUrls: ['./poster.component.scss'],
  template: `
    <div class="poster-footer">
      <ng-content></ng-content>
    </div>
  `
})
export class PosterFooterComponent {
  @Input() public title: string;
  @Input() public open = true;
  @Output() public clicked: EventEmitter<any> = new EventEmitter();

  public headerClicked() {
    this.clicked.emit();
  }
}
