import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AccordianMenuOptions,
  AdditionalInformationOptions,
} from './accordion-menu.types';

@Component({
  selector: 'zap-accordion-menu',
  templateUrl: './accordion-menu.component.html',
  styleUrls: ['./accordion-menu.component.scss'],
})
export class AccordionMenuComponent implements OnInit {
  @Input() menuData: {
    [key: string]: AccordianMenuOptions[];
  } = {};
  @Input() selectedChildren: { [key: string]: string[] } = {};
  @Input() additionalInformation: {
    [key: string]: AdditionalInformationOptions;
  } = {};
  @Output() childItemActive = new EventEmitter<AccordianMenuOptions>();

  ngOnInit(): void {}

  openMenuItem: AccordianMenuOptions;

  toggleAccordion(menuItem: AccordianMenuOptions) {
    if (menuItem === this.openMenuItem) {
      this.openMenuItem = null;
    } else {
      this.openMenuItem = menuItem;
    }
  }

  activeItem: AccordianMenuOptions;

  onChildItemClick(childItem: AccordianMenuOptions) {
    if (childItem !== this.activeItem) {
      this.activeItem = childItem;
      this.childItemActive.emit(this.activeItem);
    }
  }
}
