import { Component, OnInit, TemplateRef, Input } from '@angular/core';

/**
 * This component will be used to show custom tooltip
 *
 * This component will be rendered using OverlayModule of angular material
 * This component will be rendered by the directive on an Overlay
 *
 * CONSUMER will not be using this component directly
 * This component will be hosted in an overlay by CustomTooltipDirective directive
 * This component exposes two properties. These two properties will be filled by CustomTooltipDirective directive
 * 1. text - This is a simple string which is to be shown in the tooltip; This will be injected in the CustomTooltipDirective directive
 *    by the consumer
 * 2. contentTemplate - This gives finer control on the content to be shown in the tooltip
 *
 * NOTE - ONLY one should be specified; If BOTH are specified then "template" will be rendered and "text" will be ignored
 */

@Component({
  selector: 'custom-tool-tip',
  template: `
    <div *ngIf="!!text || !!contentTemplate">
      <div class="tool-tip-custom" [ngClass]="customTooltipClass">
        <ng-template #simpleText>
          <span class="zui-content simpleText">{{ text }}</span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="contentTemplate || simpleText; context: value"
        >
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements OnInit {
  /**
   * This is simple text which is to be shown in the tooltip
   */
  @Input() text: string;

  /**
   * This provides finer control on the content to be visible on the tooltip
   * This template will be injected in Tooltip directive in the consumer template
   * <ng-template #template>
   *  content.....
   * </ng-template>
   */
  @Input() contentTemplate: TemplateRef<any>;
  @Input() value;
  @Input() customTooltipClass:
    | string
    | string[]
    | Set<string>
    | { [key: string]: any };

  constructor() {}

  ngOnInit() {}
}
