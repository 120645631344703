import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VerticalStepperComponent } from './vertical-stepper.component';
import { StepContent } from './step.directive';

@NgModule({
  imports: [CommonModule],
  exports: [VerticalStepperComponent, StepContent],
  declarations: [VerticalStepperComponent, StepContent],
})
export class VerticalStepperModule {}
