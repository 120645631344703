import { ValidatorFn } from '@angular/forms';

export type NgClassType =
  | string
  | string[]
  | Set<string>
  | { [key: string]: any };

export type IdName = {
  id: number | string;
  name: string;
  code?: string;
  donotDisable?: boolean;
  disabled?: boolean;
  selected?: boolean;
};

export enum ApiSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SortBy = {
  sortField: string;
  sortOrder?: ApiSortOrder;
};

//ListingColumn - used in Collect and symphony to specifiy all configs to render ngx-datatable with given props
export interface ListingColumn {
  id: number;
  name: string;
  prop: string;
  width?: number;
  type?: string;
  sortable?: boolean;
  headerIcon?: string;
  mandatory?: boolean;
  sortField?: string;
  align?: string;
  minWidth?: number;
  maxWidth?: number;
  fixed?: boolean;
  selected: boolean;
  headerClass?: string;
  cellClass?: string;
  flexGrow?: number;
  searchable?: boolean;
  filterField?: string;
  toggleBasedOn?: string;
}

export interface ActionOptions {
  value: string;
  icon: string;
  disable: boolean;
  show: boolean;
}

export type TabLink = {
  id: string;
  name: string;
  routerLink: string;
  permission?: string;
  url?: string;
  children?: TabLink[];
  checkPermission?: boolean;
  feature?: string;
};

export type Nullable<T> = T | null;

export type CommonInputFormat = {
  id?: number;
  name: string;
  displayName: string;
  limitReached?: boolean;
};

export type Limits = Record<
  string,
  {
    min: number;
    max: number;
  }
>;

export type PositionCSS = {
  position: string;
  top: string;
  left: string;
  bottom: string;
  right: string;
};

export type SortOrder = {
  sortField: string;
  sortOrder: ApiSortOrder;
};

export interface InputProperties {
  key: string;
  type: string;
  validations?: ValidatorFn[];
}

export type InputFormFieldType = 'text' | 'dropdown' | 'dropdownObject';

export interface InputFormField {
  placeholder: string;
  validations?: ValidatorFn[];
  formKey: string;
  type: InputFormFieldType;
  itemLabel?: string;
  itemValue?: string;
  options?: any[];
  isRequired?: boolean;
}

export interface PermissionByCountry {
  [countryId: string]: string[];
}

export type ValidatorErrorDescription = {
  value: string;
  type: string;
  showOnceTouched?: boolean;
};
