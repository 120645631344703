import { PRODUCTS } from '../product';

export const PRODUCTS_ORG_AND_COUNTRY_ACCESS = {
  [PRODUCTS.CONNECT.path]: {
    hasOrgAccess: true,
    hasCountryAccess: true,
  },
  [PRODUCTS.DATA_MANAGER.path]: {
    hasOrgAccess: true,
    hasCountryAccess: false,
  },
  [PRODUCTS.DCR.path]: {
    hasOrgAccess: true,
    hasCountryAccess: false,
  },
  [PRODUCTS.DESTINATIONS.path]: {
    hasOrgAccess: true,
    hasCountryAccess: false,
  },
  [PRODUCTS.DPUI.path]: {
    hasOrgAccess: true,
    hasCountryAccess: false,
  },
  [PRODUCTS.ENVISION.path]: {
    hasOrgAccess: true,
    hasCountryAccess: true,
  },
  [PRODUCTS.RBAC.path]: {
    hasOrgAccess: false,
    hasCountryAccess: false,
  },
  [PRODUCTS.STUDIO.path]: {
    hasOrgAccess: false,
    hasCountryAccess: false,
  },
  [PRODUCTS.TOOLS.path]: {
    hasOrgAccess: false,
    hasCountryAccess: false,
  },
  [PRODUCTS.ZAT.path]: {
    hasOrgAccess: true,
    hasCountryAccess: true,
  },
};
