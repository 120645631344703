import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import {
  UserInfo,
  isValidUser,
  isValidUserName,
  isValidEmail,
  isAllUserSelected,
} from '@zeotap-ui/core';
import { EMAIL_MODAL_LABELS } from './consts';

@Component({
  selector: 'zap-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss'],
})
export class EmailModalComponent implements OnInit {
  @Input() headerText: string;
  @Input() users: UserInfo[];
  @Output() select: EventEmitter<UserInfo> = new EventEmitter<UserInfo>();
  @Output() selectAll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() email: EventEmitter<UserInfo[]> = new EventEmitter<UserInfo[]>();
  @Output() create: EventEmitter<UserInfo> = new EventEmitter<UserInfo>();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  get isAllUserSelected(): boolean {
    return isAllUserSelected(this.users);
  }
  protected addedUser: UserInfo;
  protected selectAllToggle = false;
  protected showCreateError = false;
  protected createError: string;
  protected messages = EMAIL_MODAL_LABELS;

  constructor() {}

  ngOnInit() {
    this.sortUsers();
  }

  sortUsers() {
    this.users = !!this.users
      ? this.users.sort((user1, user2) =>
          user1.name.toLowerCase() < user2.name.toLowerCase() ? -1 : 1
        )
      : [];
  }
  updateUserSelection(user: UserInfo): void {
    // this.select.emit(user);
  }

  updateAllUserSelection() {
    this.selectAll.emit(this.selectAllToggle);
  }

  addUser(addUser: HTMLElement): void {
    this.addedUser = { name: '', email: '' };
    setTimeout(() => {
      addUser.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 0);
  }

  createUser(user: UserInfo): void {
    if (!isValidUserName(user) && isValidEmail(user)) {
      this.showCreateError = true;
      this.createError = this.messages.INVALID_USER_NAME;
    } else if (!isValidEmail(user) && isValidUserName(user)) {
      this.showCreateError = true;
      this.createError = this.messages.INVALID_USER_EMAIL;
    } else if (!isValidUser(user)) {
      this.showCreateError = true;
      this.createError = this.messages.INVALID_USER_ERROR;
    } else if (this.users.find((u) => u.email === user.email)) {
      this.showCreateError = true;
      this.createError = this.messages.DUPLICATE_USER_ERROR;
    } else {
      this.cancelUser();
      this.create.emit(user);
    }
  }

  cancelUser(): void {
    this.addedUser = null;
    this.showCreateError = false;
  }

  cancelEmail(): void {
    this.cancel.emit(true);
  }

  sendEmail(): void {
    this.email.emit(this.users.filter((u) => u.selected));
  }

  isAnyUserSelected(): boolean {
    return !!this.users.find((u) => u.selected);
  }

  setAction(event: string) {
    this[event]();
  }
}
