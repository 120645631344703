import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTooltipModule } from '@zeotap-ui/core';
import { CollapseButtonModule } from '../collapse-button';
import { CollapseSectionComponent } from './collapse-section.component';

@NgModule({
  declarations: [CollapseSectionComponent],
  imports: [
    CommonModule,
    CustomTooltipModule,
    CollapseButtonModule,
  ],
  exports: [CollapseSectionComponent]
})
export class CollapseSectionModule { }