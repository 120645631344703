import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuTabComponent } from './menu-tab/menu-tab.component';

@NgModule({
  declarations: [MenuTabComponent],
  imports: [CommonModule, RouterModule],
  exports: [MenuTabComponent],
})
export class ShowMenuTabModule {}
