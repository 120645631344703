import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[content-tmp]' })
export class ContentTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[icon-tmp]' })
export class IconTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[footer-tmp]' })
export class FooterTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[header-tmp]' })
export class HeaderTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}
