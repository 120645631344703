import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoPermissionComponent } from './no-permission.component';

@NgModule({
  declarations: [NoPermissionComponent],
  imports: [CommonModule],
  exports: [NoPermissionComponent],
})
export class NoPermissionModule {}
