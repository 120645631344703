import { InjectionToken } from '@angular/core';
import { Route } from '@angular/router';

export const MainMenuItemsInjectionToken = new InjectionToken<MainMenuItem[]>(
  'MainMenuItems'
);
export type CheckFuntion = (values: any) => boolean;

export interface HelpInfo {
  label: string;
  link: string;
  icon?: string;
}
export interface MainMenuItem {
  id: number;
  iconClass?: string;
  label: string;
  selected?: boolean;
  children?: MainMenuItem[];
  description?: string;
  featureToggle?: string;
  route?: Route;
  helpInfo?: HelpInfo[];
  isExternal?: boolean;
}
