import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'zap-error-status',
  templateUrl: './error-status.component.html',
})
export class ErrorStatusComponent implements OnInit {
  @Input() code = '';
  @Input() title = '';
  @Input() message = 'Something wrong happened. Please try again.';
  @Input() allowReload = true;
  @Output() onReload = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
