import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { isNotNullOrEmpty } from '@zeotap-ui/core';
import {
  EventsUsagePeriod,
  AVAILABLE_EVENTS_PERIODICAL,
} from './constants/const';
import { getAvailableDateRange } from './date-filters.helper';
import { TimestampRange } from './types';

@Component({
  selector: 'zap-date-filters',
  templateUrl: './date-filters.component.html',
})
export class DateFiltersComponent implements OnChanges {
  @Input() showLastNDaysFilter = false;
  @Input() selectedEventsPeriod: EventsUsagePeriod;
  @Input() availableDateRange: TimestampRange[] = [];
  @Output() onEventsPeriodicalChange = new EventEmitter<EventsUsagePeriod>();
  @Output() onEventsPeriodRangeChange = new EventEmitter<any>();
  @Input() selectedEventsPeriodRange: TimestampRange;

  isNotNullOrEmpty = isNotNullOrEmpty;
  availableEventsPeriods = AVAILABLE_EVENTS_PERIODICAL;
  constructor() {}

  ngOnInit() {
    if (this.availableDateRange.length > 0) {
      this.selectedEventsPeriodRange = this.availableDateRange?.[0];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.selectedEventsPeriod) {
    //   this.updateEventsPeriodRangeOptions(this.selectedEventsPeriod);
    // }
  }

  eventsPeriodicalChanged(e: EventsUsagePeriod) {
    this.onEventsPeriodicalChange.emit(e);
    // this.updateEventsPeriodRangeOptions(e);
  }

  eventsPeriodRangeChanges(e: any) {
    this.onEventsPeriodRangeChange.emit(e);
  }

  updateEventsPeriodRangeOptions(type: EventsUsagePeriod) {
    // this.availableDateRange = this.availableDateRange.map((d) => ({
    //   ...d,
    //   disabled: d.type !== type,
    // }));
  }
}
