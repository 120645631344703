import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { BaseControl, getControlValueAccessor } from '../base';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';

const getTip = () =>
  d3Tip()
    .attr('class', 'd3-tip tooltip zui-bgcolor-white zui-content zui-tip')
    .attr('id', 'lineChartToolTip')
    .direction('e');

@Component({
  selector: 'zui-line-chart',
  styleUrls: ['./lineChart.scss'],
  templateUrl: './lineChart.html',
  providers: [getControlValueAccessor(LineChartComponent)],
})
export class LineChartComponent
  extends BaseControl
  implements AfterViewInit, OnChanges, OnDestroy
{
  public defaultChartConfig = {
    title: {
      enable: false,
      text: '',
    },
    type: 'dualAxis', // singleAxis : for 1 y axis, dualAxis : for 2 yaxis
    stroke: {
      strokeWidth: 4,
    },
    margin: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    tooltip: {
      display: true,
      defaultText: '',
      border: '',
      background: '',
    },
    transition: {
      transition: true,
      delay: 500,
      duration: 1000,
      ease: '',
    },
    yAxis: {
      title: {
        text: 'Users',
      },
      padding: -10,
    },
    xAxis: {
      title: {
        text: 'days',
      },
      padding: 10,
    },
  };

  @Input() public title: string;
  @Input() public options: any;
  @Input() public rawData: any[];
  @Input() public chartData: any[];
  public data: any[];
  public chartSvg;
  public margin;
  public width;
  public height;
  public axisRotationAngle;
  public xScale;
  public yScale;
  public requiredLeftMargin: number = 60;
  public requiredRightMargin: number = 0;
  public requiredBottomMargin: number = 20;
  public requiredTopMargin: number = 25;

  public minYaxis: number = 0;
  public maxYaxis: number = 1;
  public maxXaxis: number = 1;
  public index: number = 0; // track axis for dual axis graph
  public line;
  public tooltip;
  public tooltipLine;
  public tipBox;
  public singleData: Boolean;
  private transition: Boolean = true;
  constructor() {
    super();
  }

  public ngAfterViewInit() {
    this.options = Object.assign(this.defaultChartConfig || {}, this.options);
    this.getChartConfig();
  }

  public addSvgContainer() {
    this.chartSvg = d3
      .select(`#${this.id}`)
      .select('svg')
      .style('fill', 'blue');
  }

  public addLegends() {
    let legends = d3
      .selectAll(`#${this.id}legends`)
      .selectAll('div')
      .data(this.data)
      .enter()
      .append('span')
      .attr('class', 'legendName')
      .text((d) => d.name)
      .append('span')
      .style('background', (d) => d.color);
  }

  public setMargin() {
    this.margin = Object.assign({}, this.defaultChartConfig['margin']);
    this.margin.left += this.requiredLeftMargin;
    this.margin.bottom += this.requiredBottomMargin;
    this.margin.right += this.requiredRightMargin;
    this.margin.top += this.requiredTopMargin;
  }

  public initializeChartDimensions() {
    let container = d3.select(this.chartSvg.node().parentNode);
    this.width =
      parseInt(container.style('width'), 10) -
      this.margin.left -
      this.margin.right;
    this.height =
      parseInt(container.style('height'), 10) -
      this.margin.top -
      this.margin.bottom;
    this.axisRotationAngle =
      this.options['axis']['xAxisAngle'] ||
      this.defaultChartConfig['axis']['xAxisAngle'];
  }

  public setChartDimensions() {
    this.chartSvg
      .attr('width', '100%')
      .attr('height', '100%')
      .call(() => this.responsivefy(this.chartSvg));
    this.chartSvg.call(getTip());
  }

  public responsivefy(svg) {
    window.addEventListener('resize', () => {
      this.resize(svg);
    });
  }

  public resize(svg = this.chartSvg) {
    if (this.rawData.length && this.data && this.data.length) {
      this.index = 0;
      let container = d3.select(svg.node().parentNode);
      this.width =
        (parseInt(container.style('width'), 10) || 100) -
        this.margin.left -
        this.margin.right;
      this.height =
        (parseInt(container.style('height'), 10) || 100) -
        this.margin.top -
        this.margin.bottom;
      this.updateXScale();
      this.updateDivider();
      this.transition = false;
      if (this.options['type'] === 'dualAxis') {
        this.data.map((series) => {
          this.index = this.index + 1;
          this.updateYScale(series);
          let line = this.getLine();
          let path = svg.select(`.line_${this.index}`).attr('d', function (d) {
            return line(series.data);
          });
          this.updateLabels();
          this.addMouseEvents();
        });
      } else {
        this.calculateDomain();
        this.updateXScale('domain');
        this.data.map((series, index) => {
          this.updateYScale(series);
          this.updateLine(series, index);
        });
        this.updateDots();
        this.updateLabels();
      }
    }
  }
  public calculateDomain() {
    this.maxYaxis = 0;
    if (this.data && this.data.length) {
      this.data.map((series) => {
        let arr = series.data.map((item) => item.value);
        if (this.maxYaxis < d3.max(arr)) {
          this.maxYaxis = d3.max(arr);
        }
        if (this.minYaxis > d3.min(arr)) {
          this.minYaxis = d3.min(arr);
        }
        return series.data.map((d) => {
          return (d.value = +d.value);
        });
      });
    }
  }
  public update() {
    this.index = 0;
    let container = d3.select(this.chartSvg.node().parentNode);
    this.width =
      parseInt(container.style('width'), 10) -
      this.margin.left -
      this.margin.right;
    this.height =
      parseInt(container.style('height'), 10) -
      this.margin.top -
      this.margin.bottom;
    this.calculateDomain();
    if (this.options['type'] == 'dualAxis') {
      this.updateXScale('domain');
      this.addDivider();
      this.data.map((series) => {
        this.index = this.index + 1;
        this.updateYScale(series);
        this.updateLine(series);
        this.drawDots(series);
      });
    } else {
      this.updateXScale('domain');
      this.data.map((series, index) => {
        this.updateYScale(series);
        this.updateLine(series, index);
        this.drawDots(series, index);
      });
    }
    this.updateLabels();
  }

  public drawDots(data, index = 0) {
    if (this.singleData) {
      this.chartSvg
        .append('circle')
        .attr('class', 'data-circle')
        .attr('id', `#circle_${index || this.index}`)
        .style('opacity', 1)
        .attr('cx', `${this.width / 2}`)
        .attr('cy', `${this.yScale(data.data[0]['value'])}`)
        .attr('r', 4)
        .style('fill', data.color);
      this.chartSvg.selectAll('path').style('stroke-linecap', 'unset');
    } else {
      this.chartSvg.selectAll('path').style('stroke-linecap', 'round');
      this.chartSvg.select(`#circle_${this.index}`).style('opacity', 0);
    }
  }

  public updateDots() {
    if (this.singleData) {
      this.chartSvg
        .selectAll('.data-circle')
        .style('opacity', 1)
        .attr('cx', `${this.width / 2}`);
    }
  }

  public draw() {
    if (!this.rawData || !this.rawData.length) {
      return;
    }
    this.calculateDomain();
    this.drawXScale();
    if (this.options['type'] === 'dualAxis') {
      this.addDivider();
      this.data.map((series) => {
        this.index = this.index + 1;
        this.drawYscale(series);
        this.addFilters(series);
        this.drawLineChart(series);
      });
    } else {
      this.data.map((series, index) => {
        this.drawYscale(series);
        this.addFilters(series);
        this.drawLineChart(series, index);
      });
    }
    this.updateLabels();
  }
  public getTicks(axisData) {
    if (axisData[0] && axisData[axisData.length - 1]) {
      let startDate = new Date(axisData[0]['date']);
      let endDate = new Date(axisData[axisData.length - 1]['date']);
      let days =
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
      if (days >= 29) {
        return d3.timeDay.every(4);
      }
      if (days < 29 && days >= 10) {
        return d3.timeDay.every(2);
      }
    }
    return d3.timeDay.every(1);
  }

  public drawXScale() {
    let axisData = this.data[this.index].data;
    let ticks = this.getTicks(axisData);
    this.xScale = this.getXScale();

    let xaxis = this.chartSvg
      .append('g')
      .attr('class', `xscale xaxis_${this.index}`)
      .attr('transform', `translate(0, ${this.height + 5})`)
      .call(
        d3
          .axisBottom(
            this.xScale.range([
              this.margin.left + 1,
              this.width - this.margin.right,
            ])
          )
          .tickFormat(d3.timeFormat('%d'))
          .tickSize(0)
          .ticks(ticks)
      );
    xaxis.selectAll('.tick text').attr('y', this.options['xAxis']['padding']);
    if (this.axisRotationAngle) {
      this.rotateXaxis(xaxis);
    }
    this.addXAxisLabel();
  }

  public getXScale() {
    if (this.data[0]) {
      let data = this.data[0].data;
      if (this.options['xAxis'] && this.options['xAxis']['type'] == 'date') {
        return d3
          .scaleTime()
          .domain(
            d3.extent(data.map((item) => new Date(item.date).setHours(0)))
          );
      } else {
        return d3.scaleLinear().domain([0, data.length - 1]);
      }
    }
  }

  public addXAxisLabel() {
    this.chartSvg
      .append('text')
      .attr('class', 'x-axis-label label') // text label for the x axis
      .attr('x', this.width + this.margin.right)
      .attr('y', this.height)
      .style('text-anchor', 'middle')
      .text(this.options['xAxis'].title.text);
  }

  public updateXScale(type = 'range') {
    let axisData = this.data[this.index].data;
    this.xScale = this.getXScale();
    let ticks = this.getTicks(axisData);
    this.xScale.range([this.margin.left, this.width - this.margin.right]);
    let xaxis = this.chartSvg
      .select('.xscale')
      .attr('class', 'xscale')
      .call(
        d3
          .axisBottom(
            this.xScale.range([
              this.margin.left + 1,
              this.width - this.margin.right,
            ])
          )
          .tickFormat(d3.timeFormat('%d'))
          .tickSize(0)
          .ticks(ticks)
      );
    xaxis.selectAll('.tick text').attr('y', this.options['xAxis']['padding']);
  }

  public rotateXaxis(xaxis) {
    xaxis
      .selectAll('text')
      .attr('y', 10)
      .attr('x', -30)
      .attr('transform', `rotate(${this.axisRotationAngle})`)
      .style('text-anchor', 'start');
  }
  setHeight() {
    if (this.index == 1) {
      return [this.height, this.height / 2];
    } else if (this.index == 2) {
      let fillGap = 15;
      return [this.height / 2, fillGap];
    } else {
      return [this.height, 10];
    }
  }

  addDivider() {
    if (this.chartSvg.select('.divider').empty()) {
      this.chartSvg.append('line').attr('class', 'divider');
    }
    this.updateDivider();
  }

  updateDivider() {
    this.chartSvg
      .select('.divider')
      .attr('x1', this.margin.left)
      .attr('y1', this.height / 2 + 10)
      .attr('x1', this.margin.left)
      .attr('x2', this.width - this.margin.right)
      .attr('y2', this.height / 2 + 10);
  }

  public setYDomain(series) {
    let arr = series.data.map((item) => item.value);
    if (this.options['type'] === 'dualAxis') {
      if (this.index === 1) {
        this.yScale.domain([0, d3.max(arr) + d3.mean(arr)]);
      } else {
        this.yScale.domain([0, d3.max(arr)]);
      }
    } else {
      this.yScale.domain([0, this.maxYaxis]);
    }
  }

  public updateYScale(series = { data: [] }) {
    let formatter = this.options['yAxis']['formatter'];
    this.setYDomain(series);
    let range = this.setHeight();
    this.yScale.range(range);
    this.chartSvg.selectAll(`.yaxis_${this.index} .tick`).remove();
    let yaxis = this.chartSvg
      .select(`.yaxis_${this.index}`)
      .attr('class', `yaxis_${this.index}`)
      .call(
        d3
          .axisLeft(this.yScale.nice())
          .tickSize(0)
          .ticks(5)
          .tickFormat((d) => {
            let data = d;
            if (d === 0) {
              return '';
            }
            return formatter ? formatter.call({ data }) : d;
          })
      );
    this.chartSvg.selectAll(`.yaxis_${this.index} .tick text`).attr('x', -15);
  }

  public drawYscale(series = { data: [] }) {
    let formatter = this.options['yAxis']['formatter'];
    let range = this.setHeight();
    this.yScale = d3.scaleLinear();
    this.setYDomain(series);
    if (this.index === 2) {
      this.chartSvg
        .append('g')
        .attr('class', `yaxis_${this.index}`)
        .attr('transform', `translate(${this.requiredLeftMargin}, 5)`)
        .call(
          d3
            .axisLeft(this.yScale.range(range))
            .tickSize(0)
            .ticks(5)
            .tickFormat((d) => {
              let data = d;
              if (d == 0) return '';
              return formatter ? formatter.call({ data }) : d;
            })
        );
    }
    if (this.index == 1 || this.index == 0) {
      this.chartSvg
        .append('g')
        .attr('class', `axisLabel yaxis_${this.index}`)
        .attr('transform', `translate(${this.requiredLeftMargin}, 5)`)
        .call(
          d3
            .axisLeft(this.yScale.range(range).nice())
            .tickSizeOuter(0)
            .tickSize(0)
            .ticks(5)
            .tickFormat((d) => {
              let data = d;
              return formatter ? formatter.call({ data }) : d;
            })
        );
    }
    this.chartSvg.selectAll(`.yaxis_${this.index} .tick text`).attr('x', -15);
  }

  public updateLabels() {
    this.chartSvg.selectAll(`.${this.id}_label`).remove();
    let label = this.options['yAxis']['title']['text'];
    if (this.singleData) {
      this.chartSvg
        .select('.xscale .tick')
        .attr('transform', `translate(${this.width / 2}, 0)`);
    }
    this.chartSvg.selectAll('.axisLabel .tick text').each(function (d) {
      if (d === 0) {
        d3.select(this).text(label);
      }
    });
    this.chartSvg
      .selectAll('.yaxis_1 .tick text')
      .filter(function (d, i, list) {
        return i === list.length - 1;
      })
      .attr('display', 'none');

    let currentMonth = 0;
    let translateArr = [];
    this.chartSvg.selectAll(`.xscale .tick text`).each(function (d) {
      let monthName = d3.timeFormat('%b');
      let month = d3.timeFormat('%m');
      let newMonth = month(d);
      if (currentMonth != newMonth) {
        let translateX = d3
          .select(this.parentElement)
          .attr('transform')
          .replace(',0)', '')
          .replace('translate(', '');
        translateArr.push({ name: monthName(d), translate: `${translateX}` });
        currentMonth = newMonth;
      }
    });
    if (translateArr.length) {
      translateArr[0]['translate'] = this.margin.left;
    }
    translateArr.map((item) => {
      this.chartSvg
        .append('text')
        .attr('class', `${this.id}_label label`)
        .text(item.name)
        .attr(
          'transform',
          `translate(${item.translate - 6}, ${this.height + 40})`
        );
    });
    this.chartSvg
      .select('.x-axis-label')
      .attr('x', this.width + this.margin.right)
      .attr('y', this.height);
  }

  public updateLine(data, index = 0) {
    let line = this.getLine();
    let path = this.chartSvg
      .select(`.line_${index || this.index}`)
      .attr('d', function (d) {
        return line(data.data);
      });
    this.addMouseEvents();
    this.addTransition(path);
  }

  public getLine() {
    if (this.options['xAxis']['type'] == 'date') {
      return d3
        .line()
        .x((d, i) => this.xScale(new Date(d.date).setHours(0)))
        .y((d) => this.yScale(d.value))
        .curve(d3.curveCatmullRom);
    } else {
      return d3
        .line()
        .x((d, i) => this.xScale(i))
        .y((d) => this.yScale(d.value))
        .curve(d3.curveCatmullRom.alpha(0.3));
    }
  }
  public addFilters(data) {
    let filter = this.chartSvg.select(`#dropshadow_${this.index}`);
    filter
      .append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 10)
      .attr('result', 'blur');
    filter
      .append('feOffset')
      .attr('in', 'blur')
      .attr('dx', 2)
      .attr('dy', 10)
      .attr('result', 'offsetBlur');
    filter
      .append('feFlood')
      .attr('in', 'offsetBlur')
      .attr('flood-color', data.color)
      .attr('flood-opacity', '0.4')
      .attr('result', 'offsetColor');
    filter
      .append('feComposite')
      .attr('in', 'offsetColor')
      .attr('in2', 'offsetBlur')
      .attr('operator', 'in')
      .attr('result', 'offsetBlur');

    var feMerge = filter.append('feMerge');

    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
  }

  public drawLineChart(data, index = 0) {
    let line = this.getLine();
    let path = this.chartSvg
      .append('path')
      .attr('class', `line_${index || this.index}`)
      .attr('d', line(data.data))
      .attr('transform', `translate(0, -2)`)
      .style('stroke', data.color)
      .style('stroke-width', this.options['stroke']['strokeWidth'])
      .style('fill', 'none');
    if (this.index == 2) {
      path.attr('transform', `translate(0, 7)`);
    }
    if (!this.IsSafari() && this.options['type'] == 'dualAxis') {
      path.attr('filter', `url(#dropshadow_${this.index})`);
    }

    this.addTransition(path);
    this.addMouseEvents();
    this.drawDots(data);
  }

  public IsSafari() {
    return (
      navigator.userAgent.search('Safari') >= 0 &&
      navigator.userAgent.search('Chrome') < 0
    );
  }

  public addToolTip() {
    this.tooltip = d3.select('#tooltipLine');
    this.tooltipLine = this.chartSvg.append('line');
  }

  public addMouseEvents() {
    if (this.options['xAxis']['type'] == 'date') {
      this.tipBox
        .on('mousemove', () => {
          this.drawDateTooltip();
        })
        .on('mouseout', () => {
          this.removeTooltip();
        });
    } else {
      this.tipBox
        .on('mousemove', () => {
          this.drawTooltip();
        })
        .on('mouseout', () => {
          this.removeTooltip();
        });
    }
  }

  public removeTooltip() {
    if (this.tooltip) this.tooltip.style('display', 'none');
    if (this.tooltipLine) this.tooltipLine.attr('stroke', 'none');
    getTip().hide();
  }
  public drawTooltip() {
    const index = Math.round(this.xScale.invert(d3.event.offsetX));
    this.tooltipLine
      .attr('stroke', '#c1c1c5')
      .attr('stroke-dasharray', 5, 5)
      .attr('x1', this.xScale(index))
      .attr('x2', this.xScale(index))
      .attr('y1', '15px')
      .attr('y2', this.height);
    this.addTips(index);
  }
  public drawDateTooltip() {
    let parse = d3.timeFormat('%Y-%m-%d');
    let x = parse(this.xScale.invert(d3.event.offsetX));
    let index = this.data[0]['data'].findIndex((item) => item.date === x);
    if (index === -1) {
      return;
    }
    let height = 15;
    if (this.options['type'] === 'dualAxis') {
      if (
        this.data &&
        this.data[1] &&
        this.data[1]['data'] &&
        this.data[1]['data'][index]
      ) {
        height = this.yScale(this.data[1]['data'][index]['value']);
      } else if (
        this.data &&
        this.data[0] &&
        this.data[0]['data'] &&
        this.data[0]['data'][index]
      ) {
        height = this.yScale(this.data[0]['data'][index]['value']);
      } else {
        return;
      }
    }

    this.tooltipLine.attr('stroke', '#c1c1c5').attr('stroke-dasharray', '5,5');
    if (this.singleData) {
      this.tooltipLine.attr('x1', this.width / 2).attr('x2', this.width / 2);
    } else {
      this.tooltipLine
        .attr('x1', this.xScale(new Date(x).setHours(0)))
        .attr('x2', this.xScale(new Date(x).setHours(0)));
    }
    this.tooltipLine.attr('y1', height).attr('y2', this.height);
    this.addTips(index);
  }
  public addTips(index) {
    let formatter = this.options['tooltip']['formatter'];
    let tips = this.data.map((item) => {
      return {
        color: item.color,
        text: item.text,
        value: item.data[index]['value'],
        date: item.data[index]['date'],
      };
    });
    getTip()
      .show(index)
      .html(
        formatter
          ? formatter.call(tips)
          : `<span style="background: ${
              this.data[1].color
            };border-radius: 50%;display: inline-block;height: .7em;width: .7em";></span>
      <span style="color:#ababaf;font-size: 13px;padding-bottom:5px">${
        this.data[1].text
      }</span><br>
      <span style="color:#303030;font-size: 14px;margin-left:15px;" class="zui-mediumtext">${d3.format(
        '.2s'
      )(this.data[1]['data'][index]['value'])}</span><br><br>
      <span style="background: ${
        this.data[0].color
      };border-radius: 50%;display: inline-block;height: .7em;width: .7em;"></span>
      <span style="color:#ababaf;font-size: 13px;padding-bottom:5px">${
        this.data[0].text
      }</span><br>
      <span style="color:#303030;font-size: 14px;margin-left:15px;" class="zui-mediumtext">${d3.format(
        '.2s'
      )(this.data[0]['data'][index]['value'])}</span><br>
      <span>${this.data[0]['data'][index]['date']}</span>`
      );
    const tipDom = <HTMLElement>document.querySelector('#lineChartToolTip');
    tipDom.style.left = `${d3.event.clientX + 20}px`;
    // if (this.options['type'] === 'singleAxis' && this.data && this.data.length > 3) {
    //     tipDom.style.columnCount = Math.floor(this.data.length / 4);
    // }
  }

  public addTransition(path) {
    if (this.transition) {
      let totalLength = path.node().getTotalLength();
      path
        .attr('stroke-dasharray', totalLength + ' ' + totalLength)
        .attr('stroke-dashoffset', totalLength)
        .transition()
        .duration(2000)
        .attr('stroke-dashoffset', 0);
    }
  }

  public getChartConfig() {
    this.addSvgContainer();
    this.setMargin();
    this.initializeChartDimensions();
    this.setChartDimensions();
    this.addToolTip();
    this.populateData();
    this.addTipRectangle();
    if (this.data.length) {
      this.draw();
    }
  }

  public addTipRectangle() {
    this.tipBox = this.chartSvg
      .append('rect')
      .attr('class', 'tipBox')
      .attr('width', this.width - this.margin.left - this.margin.right)
      .attr('height', this.height)
      .attr('opacity', 0)
      .attr('transform', `translate(${this.margin.left}, 0)`);
  }

  public populateData() {
    this.data = [];
    if (this.chartData) {
      this.data = this.chartData.map((d) => Object.assign({}, d));
      if (this.data.length === 0) {
        return;
      }
      for (let i = 0; i < this.data.length; i++) {
        let key = this.data[i]['key'];
        let arr = this.rawData.map((data) => {
          return { value: data[key] || 0, date: data['date'] };
        });
        this.data[i]['data'] = arr;
        if (arr.length == 1) {
          this.singleData = true;
        } else {
          this.singleData = false;
        }
      }
    }
    return;
  }

  public ngOnChanges() {
    if (this.chartSvg) {
      this.chartSvg.selectAll('.data-circle').remove();
      this.populateData();
      if (this.data.length) {
        this.index === 0 && this.options['type'] === 'dualAxis'
          ? this.draw()
          : this.update();
      }
    }
  }

  public ngOnDestroy() {
    window.removeEventListener('resize', null);
    d3.selectAll('.tipBox').on('mousemove', null);
    d3.selectAll('.tipBox').on('mouseout', null);
  }
}
