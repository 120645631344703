import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'zui-flip-card',
  templateUrl: './card.html',
  styleUrls: ['./card.scss']
})
export class Card2Component {
  @Input() public logoUrl: string;
  @Input() public title: string;
  @Input() public flag: boolean;
  @Input() public disableFlag: boolean;
  @Input() public flip = true;
}
