import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from './models/question';
import { QuestionDirective } from './question.directive';
import { has, isNil } from 'ramda';
import { pairwise, startWith } from 'rxjs/operators';
import { isNotNullOrEmpty } from '@zeotap-ui/core';

@Component({
  selector: 'zap-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [],
})
export class DynamicFormComponent implements OnInit, AfterContentInit {
  constructor() {}

  @Input() questions: QuestionBase<any>[] = [];
  @Input() form: UntypedFormGroup;

  @ContentChildren(QuestionDirective)
  questionViews: QueryList<QuestionDirective>;
  questionViewByType: Record<string, number> = {};
  dependentFieldAndQuestionMap = {};

  get hasAnyQuestionTemplates(): boolean {
    return this.questionViews?.length > 0;
  }

  get templateContext() {
    {
      return { form: this.form, questions: this.questions };
    }
  }

  hasQuestionViewForQuestion(question: QuestionBase<any>): boolean {
    return has(question.controlType, this.questionViewByType);
  }

  getQuestionView(question: QuestionBase<any>): TemplateRef<any> {
    return this.questionViews?.get(
      this.questionViewByType[question.controlType]
    )?.template;
    // return this.questionViews;
  }

  loadQuestionViewsByType() {
    if (this.questionViews) {
      this.questionViewByType = this.questionViews.reduce(
        (acc, curr, currIdx) => {
          acc[curr.type] = currIdx;
          return acc;
        },
        {}
      );
    }
  }

  ngOnInit() {
    this.questions.forEach((question) => {
      if (!!question.displayConditionDependentFieldId) {
        if (
          this.dependentFieldAndQuestionMap[
            question.displayConditionDependentFieldId
          ]
        ) {
          this.dependentFieldAndQuestionMap[
            question.displayConditionDependentFieldId
          ] = [
            ...this.dependentFieldAndQuestionMap[
              question.displayConditionDependentFieldId
            ],
            question,
          ];
        } else {
          this.dependentFieldAndQuestionMap[
            question.displayConditionDependentFieldId
          ] = [question];
        }
      }
    });
    this.form.valueChanges
      .pipe(startWith(this.form.value), pairwise())
      .subscribe(([prevFormValues, currFormValues]) => {
        if (
          isNotNullOrEmpty(this.dependentFieldAndQuestionMap) &&
          !!prevFormValues
        ) {
          const changedFormKey = this.getChangedFormKey(
            prevFormValues,
            currFormValues
          );
          const questionsDependentOnChangedKey = !!this
            .dependentFieldAndQuestionMap[changedFormKey]
            ? this.dependentFieldAndQuestionMap[changedFormKey]
            : null;
          if (!!questionsDependentOnChangedKey) {
            questionsDependentOnChangedKey.forEach(
              (questionDependentOnChangedKey) => {
                if (
                  questionDependentOnChangedKey.displayConditionFn(this.form)
                ) {
                  const control = new UntypedFormControl(
                    questionDependentOnChangedKey.value || '',
                    questionDependentOnChangedKey.validations
                  );
                  this.form.addControl(
                    questionDependentOnChangedKey.key,
                    control,
                    {
                      emitEvent: false,
                    }
                  );
                } else {
                  this.form.removeControl(questionDependentOnChangedKey.key, {
                    emitEvent: false,
                  });
                }
              }
            );
          }
        }
      });
  }

  ngAfterContentInit(): void {
    this.loadQuestionViewsByType();
  }

  getChangedFormKey(prevFormValues, currFormValues) {
    for (const formKey in prevFormValues) {
      if (
        !isNil(currFormValues[formKey]) &&
        prevFormValues[formKey] != currFormValues[formKey]
      ) {
        return formKey;
      }
    }
    return null;
  }
}
