import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CorePipesModule } from '@zeotap-ui/core';

import { FileUploaderComponent } from './file-uploader.component';

@NgModule({
  imports: [CommonModule, CorePipesModule],
  exports: [FileUploaderComponent],
  declarations: [FileUploaderComponent],
})
export class FileUploaderModule {}
