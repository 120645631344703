import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, debounceTime, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'zap-searchable-header-label',
  templateUrl: './searchable-header-label.component.html',
  styleUrls: ['./searchable-header-label.component.scss'],
})
export class SearchableHeaderLabelComponent implements OnInit, OnDestroy {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() isSortable: boolean = true;

  @Input() searchTextFromParent: string;
  @Input() bufferBeforeEmit = 0;
  searchClicked$ = new BehaviorSubject<boolean>(false);
  @Output() onSearch = new EventEmitter<string>();
  @Output() onLabelClicked = new EventEmitter<void>();
  searchText = '';
  emitSearchText$: Subject<string> = new Subject<string>();
  unsubscribe$: Subject<void> = new Subject<void>();
  constructor() {}

  ngOnInit(): void {
    this.searchText = this.searchTextFromParent ?? '';
    this.emitSearchText$
      .pipe(
        debounceTime(this.bufferBeforeEmit),
        tap((string) => this.onSearch.emit(string)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  onSearchTextChange(text: string) {
    this.emitSearchText$.next(text);
  }
  onCloseSearchClicked(event) {
    event.stopPropagation();
    this.searchText = '';
    this.searchClicked$.next(false);
    this.emitSearchText$.next('');
  }
  search(event) {
    event.stopPropagation();
    this.searchClicked$.next(true);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
