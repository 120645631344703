import { Pipe, PipeTransform } from '@angular/core';
import { isTypeArray, isTypeObject } from '../../utils';
@Pipe({
  name: 'isType',
})
export class IsTypePipe implements PipeTransform {
  transform(value: any, type: string): boolean {
    switch (type) {
      case 'array':
        return isTypeArray(value);
      case 'object':
        return isTypeObject(value);
      case 'string':
        return typeof value === 'string';
      case 'number':
        return typeof value === 'number';
      case 'boolean':
        return typeof value === 'boolean';
      case 'function':
        return typeof value === 'function';
      case 'undefined':
        return typeof value === 'undefined';
      case 'null':
        return value === null;
      default:
        return false;
    }
  }
}
