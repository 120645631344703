import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActionMessageBoxComponent } from './action-message-box.component';
import { ActionContentTemplateDirective } from './directives/action-message-box.directive';
import { CollapseButtonModule } from '../collapse-button';

@NgModule({
  declarations: [ActionMessageBoxComponent, ActionContentTemplateDirective],
  imports: [CommonModule, CollapseButtonModule],
  exports: [ActionMessageBoxComponent, ActionContentTemplateDirective],
})
export class ActionMessageBoxModule {}
