import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisplayMessageModule } from './display-message/display-message.module';
import { HttpModule } from './http';
import { ShowMenuTabModule } from './show-menu-tab/show-menu-tab.module';
import { CorePipesModule } from './pipes';
import { CustomTooltipModule } from './custom-tooltip';
import { CoreDirectivesModule } from './directives';
import { AuthModule } from './auth';
import { ContentDetectionModule } from './content-detection';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DisplayMessageModule,
    HttpModule,
    ShowMenuTabModule,
    CorePipesModule,
    CustomTooltipModule,
    AuthModule,
    CoreDirectivesModule,
    ContentDetectionModule,
  ],
  exports: [
    CommonModule,
    DisplayMessageModule,
    HttpModule,
    ShowMenuTabModule,
    CorePipesModule,
    CustomTooltipModule,
    AuthModule,
    CoreDirectivesModule,
    ContentDetectionModule,
  ],
})
export class CoreModule {}
