import { Optional, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfigRuleEvaluator } from './evaluator.type';

@Pipe({
  name: 'ruleEvaluate',
})
export class ConfigRuleEvaluatorPipe implements PipeTransform {
  constructor(
    @Optional()
    private evaluator: ConfigRuleEvaluator
  ) {}
  transform(rule: string): boolean {
    return !this.evaluator || !rule || this.evaluator.evaluate(rule);
  }
}

/**
 * Usage :
 *
 * 'ruleName' |
 */

@Pipe({
  name: 'asyncRuleEvaluate',
})
export class AsyncConfigRuleEvaluatorPipe implements PipeTransform {
  constructor(
    @Optional()
    private evaluator: ConfigRuleEvaluator
  ) {}
  transform(rule: string): Observable<boolean> {
    return !this.evaluator || !rule
      ? of(true)
      : this.evaluator.evaluateAsync(rule);
  }
}
