import {
  Component,
  ContentChildren,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { StepContent } from './step.directive';

@Component({
  selector: 'zap-vertical-stepper',
  templateUrl: './vertical-stepper.component.html',
  styleUrls: ['./vertical-stepper.component.scss'],
})
export class VerticalStepperComponent implements OnInit {
  @Input() actionStepsSelected: boolean[] = [];
  @Input() viewMode: boolean = false;
  @ContentChildren(StepContent, { read: TemplateRef })
  stepTemplates: TemplateRef<StepContent>[];
  constructor() {}

  ngOnInit() {}
}
