import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnSelectionPopupComponent } from './column-selection-popup/column-selection-popup.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ZuiLibModule } from '@zeotap.infra/zui-lib';
import {
  CorePipesModule,
  CoreModule,
  CustomTooltipModule,
} from '@zeotap-ui/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleListLengthPipe } from './toggled-list-length.pipe';

@NgModule({
  declarations: [ColumnSelectionPopupComponent, ToggleListLengthPipe],
  imports: [
    CommonModule,
    OverlayModule,
    CoreModule,
    ZuiLibModule,
    CorePipesModule,
    FormsModule,
    ReactiveFormsModule,
    CustomTooltipModule,
  ],
  exports: [ColumnSelectionPopupComponent],
})
export class ColumnSelectionPopupModule {}
