import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewDataTableComponent } from './preview-data-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [PreviewDataTableComponent],
  imports: [CommonModule, NgxDatatableModule],
  exports: [PreviewDataTableComponent],
})
export class PreviewDataTableModule {}
