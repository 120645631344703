import { InjectionToken } from '@angular/core';
import { AppConstants, EnvironmentConfig } from '../types';

// to know more : https://angular.io/guide/dependency-injection-providers#using-an-injectiontoken-object
export const EnvironmentConfigToken = new InjectionToken<EnvironmentConfig>(
  'config.environment'
);

export const AppConstantsToken = new InjectionToken<AppConstants>(
  'app.constants'
);
