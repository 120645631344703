import { Component, Input } from '@angular/core';

@Component({
  selector: 'zap-show-documentation',
  templateUrl: './show-documentation.component.html',
  styleUrls: ['./show-documentation.component.scss'],
})
export class ShowDocumentationComponent {
  @Input() url: string;
  @Input() isHelpText = false;
  @Input() helpText = 'Click Here';

  goToHelpCenter() {
    window.open(this.url, '_blank');
  }
}
