import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FeatureToggledDirective,
  NotFeatureToggledDirective,
} from './directives/feature-toggled/feature-toggled.directive';
import { FeatureTogglePipe, NotFeatureTogglePipe } from './pipes';
import { EvaluatorModule } from './evaluator';
const declarations = [
  FeatureToggledDirective,
  FeatureTogglePipe,
  NotFeatureToggledDirective,
  NotFeatureTogglePipe,
];
@NgModule({
  imports: [CommonModule, EvaluatorModule],
  declarations: declarations,
  exports: [...declarations, EvaluatorModule],
})
export class ConfigModule {}
