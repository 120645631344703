import { Component, Input, ElementRef } from '@angular/core';

import { BaseControl, getControlValueAccessor } from '../base';
import { Validators } from '../base';

@Component({
  selector: 'zui-input',
  templateUrl: './input.html',
  styleUrls: ['./input.scss'],
  providers: [getControlValueAccessor(InputComponent)],
})
export class InputComponent extends BaseControl {
  @Input() public showTextarea = false;
  @Input() public hideFloatingPlaceholder = false;
  @Input() public type = 'text';
  @Input() public prefixIcon = '';
  @Input() public suffixIcon = '';
  @Input() public label = '';
  @Input() public hint = '';
  @Input() public min;
  @Input() public max;
  @Input() public required;
  @Input() public fullWidth;
  @Input() public prefixText = '';
  @Input() public prefixStyle = '';

  constructor(public elementRef: ElementRef) {
    super();
  }

  public refreshValidators() {
    super.refreshValidators();

    if (this.required === '') {
      this.validators.required = Validators.required;
    }

    if (this.type === 'email') {
      this.validators.email = null;
    }

    if (this.type === 'number' && this.min) {
      this.validators.min = Validators.min(parseFloat(this.min));
    }

    if (this.type === 'number' && this.max) {
      this.validators.max = Validators.max(parseFloat(this.max));
    }
  }

  public onFocus() {
    this.elementRef.nativeElement.firstElementChild.classList.add('focused');
  }

  public onBlur() {
    this.elementRef.nativeElement.firstElementChild.classList.remove('focused');
    this.checkForErrors();
  }
}
