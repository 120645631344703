import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserPreference, UserPreferenceFacade } from '../../preferences';
import { StorageService } from '../../storage';
import { isNilOrEmpty } from '../../utils';
import { AuthFacadeService } from './auth-facade.service';
import { AuthService } from './auth.service';

export const authGuard = (next: ActivatedRouteSnapshot) => {
  const authFacade = inject(AuthFacadeService);
  const router = inject(Router);

  return authFacade.state$.pipe(
    filter((state) => !state.yetToFetchUserData),
    map((state) => {
      if (state.userInfo) {
        // TODO : handle the look up for setting up last selected product if the next path is empty
        return true;
      }
      return router.parseUrl('/unauthorized');
    })
  );
};

export const lastSelectedProductGuard = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const { checkPreviouslySelectedProduct, defaultRedirect } = next.data;
  const router = inject(Router);
  const authService = inject(AuthService);
  const userPreferenceService = inject(UserPreferenceFacade);

  if (checkPreviouslySelectedProduct) {
    return userPreferenceService.getUserPreference().pipe(
      map((pref: UserPreference) => {
        if (
          isNilOrEmpty(pref?.lastActivatedPath) ||
          isNilOrEmpty(pref?.lastSelectedProduct) ||
          !authService.hasProductAccess(pref?.lastSelectedProduct)
        ) {
          return router.parseUrl(defaultRedirect);
        } else {
          return router.parseUrl(pref.lastActivatedPath);
        }
      })
    );
  }
  return router.parseUrl(defaultRedirect);
};

function getPathRoutingTo(snapShot: ActivatedRouteSnapshot): string {
  const { pathFromRoot } = snapShot;
  const path = pathFromRoot.map((path) => path.routeConfig.path).join('/');
  return path;
}

// Write a guard for saving the path in localstorage and returning true
export const logPathGuard = (next: ActivatedRouteSnapshot) => {
  const path = getPathRoutingTo(next);
  console.log(path);
  // const storageService = inject(StorageService);
  // storageService.setLocalState({ lastSelectedPath: path },['lastSelectedPath']);
  return of(true);
};

// get the product id as last selected product from localstorage using StorageService.getLocalState
function getLastSelectedPath(): Observable<string> {
  const storageService = inject(StorageService);
  const lastSelectedPath = storageService.getLocalState(['lastSelectedPath']);
  return of(lastSelectedPath);
}
