import { Injectable } from '@angular/core';
import { ConfigService } from '@zeotap-ui/config';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { handleAsyncError, HttpService, Status } from '../http';
import { compose } from 'ramda';
import {
  getExcelDownloadUrl,
  getPDFDownloadUrl,
  getTPLDownloadUrl,
} from './utils';
import { DownloadResponse } from './types';

@Injectable({ providedIn: 'root' })
export class DownloadService {
  constructor(public httpService: HttpService, private config: ConfigService) {}

  downloadDocument = (type: string, data: Blob, fileName: string) => {
    let downloadUrl;
    switch (type) {
      case 'pdf':
        downloadUrl = getPDFDownloadUrl(data);
        break;
      case 'excel':
        downloadUrl = getExcelDownloadUrl(data);
        break;
      case 'tpl':
        downloadUrl = getTPLDownloadUrl(data);
        break;
      default:
        downloadUrl = getPDFDownloadUrl(data);
    }
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
  };

  public downloadPdfDocument(id: number): Observable<DownloadResponse> {
    return from(
      <Promise<Blob>>(
        this.httpService.doGet(
          `${this.config.appConfig.dataManagerSvcUrl}manual/download/pdf/${id}`,
          this.httpService.getHeaderDownload('Bearer ')
        )
      )
    ).pipe(
      map((data) => ({
        status: Status.Success,
        data,
      })),
      catchError(
        compose(
          of,
          handleAsyncError<DownloadResponse>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }

  downloadTplDocument(docId: number): Observable<DownloadResponse> {
    return from(
      <Promise<Blob>>(
        this.httpService.doGet(
          `${this.config.appConfig.dataManagerSvcUrl}manual/download/templates/${docId}`,
          this.httpService.getHeaderDownload('Bearer ')
        )
      )
    ).pipe(
      map((data) => ({
        status: Status.Success,
        data,
      })),
      catchError(
        compose(
          of,
          handleAsyncError<DownloadResponse>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }

  public readLocalFileAsText(file): Promise<string> {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function () {
        var txt = reader.result as string;
        resolve(txt);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
      reader.readAsText(file);
    });
  }
}
