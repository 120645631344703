import { NgClassType } from '../types';

export interface DisplayMessage {
  msgType: SnackBarType;
  message: string;
  showSnackBar: boolean;
  customClass?: NgClassType;
}

export type SnackBarType = 'success' | 'error' | 'warning';
