import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon';
import { ZeotapButtonComponent } from './button.component';
import {
  CustomBtnLeftTemplateDirective,
  CustomBtnRightTemplateDirective,
} from './directives';

@NgModule({
  declarations: [
    ZeotapButtonComponent,
    CustomBtnLeftTemplateDirective,
    CustomBtnRightTemplateDirective,
  ],
  exports: [
    ZeotapButtonComponent,
    CustomBtnLeftTemplateDirective,
    CustomBtnRightTemplateDirective,
  ],
  imports: [CommonModule, IconModule],
})
export class ZeotapButtonModule {}
