import {
  findIndex,
  equals,
  compose,
  ifElse,
  includes,
  append,
  remove,
} from 'ramda';

export const getIndexInList = (element, list) =>
  findIndex(equals(element), list);
export const removeElementOnIndex = (list) => (index) => remove(index, 1, list);
export const removeElementInList = (element, list) =>
  compose(removeElementOnIndex(list), getIndexInList)(element, list);
export const updateList = (list) => (element) =>
  ifElse(includes, removeElementInList, append)(element, list);
