import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ListingColumn, NgClassType, isNotNullOrEmpty } from '@zeotap-ui/core';
import { equals, filter, length, propEq } from 'ramda';

@Component({
  selector: 'zap-column-selection-popup',
  templateUrl: './column-selection-popup.component.html',
})
export class ColumnSelectionPopupComponent {
  @Input() listingColumns: ListingColumn[] = [];
  @Input() showColumnList: boolean;
  @Input() selectionClass: NgClassType;
  @Output() toggleColumn = new EventEmitter();
  @Output() openColumnToggleList = new EventEmitter();
  @Input() positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom', //tooltip towards bottom
      overlayX: 'end',
      overlayY: 'top',
      offsetX: -5,
      offsetY: 8,
    },
    {
      originX: 'end',
      originY: 'top', //tooltip towards top
      overlayX: 'end',
      overlayY: 'bottom',
      offsetX: -5,
      offsetY: -5,
    },
    {
      originX: 'start',
      originY: 'top', //tooltip towards left
      overlayX: 'end',
      overlayY: 'top',
      offsetX: -10,
    },
    {
      originX: 'end',
      originY: 'top', //tooltip towards right
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 10,
    },
  ];
  @Input() tooltipPositions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom', //tooltip towards bottom
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 10,
      offsetY: 8,
    },
  ];
  @Input() maxColumnCount = null;
  isNotNullOrEmpty = isNotNullOrEmpty;
  @HostListener('document:click', ['$event'])
  onOutsideClick(event) {
    if (
      !event.target.closest('.column-listing') &&
      !event.target.closest('.column-list') &&
      !event.target.classList?.contains('popup-content') &&
      this.showColumnList
    ) {
      this.showList();
    }
  }
  showList() {
    this.openColumnToggleList.emit();
  }
  toggle(column: ListingColumn) {
    this.toggleColumn.emit(column);
  }

  checkSelectedCount(): boolean {
    return equals(
      length(filter(propEq('selected', true), this.listingColumns)),
      this.maxColumnCount
    );
  }
}
