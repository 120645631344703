import {
  Inject,
  Injectable,
  InjectionToken,
  Optional,
  Pipe,
  PipeTransform,
  inject,
} from '@angular/core';
import { ConfigService } from '../services/config.service';

@Pipe({
  name: 'zapFeature',
})
export class FeatureTogglePipe implements PipeTransform {
  constructor(private featureConfigService: ConfigService) {}

  transform(featureName): boolean {
    return this.featureConfigService.getFeatureValue(featureName);
  }
}

@Pipe({
  name: 'zapAltToFeature',
})
export class NotFeatureTogglePipe implements PipeTransform {
  constructor(private featureConfigService: ConfigService) {}

  transform(featureName): boolean {
    return !this.featureConfigService.getFeatureValue(featureName);
  }
}
