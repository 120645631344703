import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { path, compose } from 'ramda';
import { from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigService } from '@zeotap-ui/config';
import { StorageService } from '../../storage';
import { HttpService, Status, handleAsyncError, isSuccess } from '../../http';
import { FirebaseAuthService } from './firebase-auth.service';
import { AuthResponseData } from '../types';
import { LOG_EVENTS } from '../auth.const';
import {
  logEvent,
  resetUserProperties,
  setUserId,
  setUserProperties,
} from '../../log';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  logoutStatus = false;

  constructor(
    public http: HttpClient,
    public storageService: StorageService,
    public httpService: HttpService,
    private config: ConfigService,
    private firebaseAuth: FirebaseAuthService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {}

  getUserProperty() {
    const userInfo = this.storageService.getUserInfo();
    return {
      email: userInfo.email,
      name: userInfo.first_name,
      id: userInfo.id,
    };
  }

  logout() {
    this.oktaAuth
      .signOut()
      .then(() => {
        this.firebaseAuth.signOut();
        this.storageService.clearPageState();
        // reset amplitude event
        resetUserProperties();
      })
      .catch((err) => {
        console.error('Unable to signOut the user');
      });
  }

  hasProductAccess(prodName = '') {
    return !!this.storageService.getProdInfo(prodName);
  }

  recoverPassword(emailData: object): Observable<AuthResponseData> {
    return this.http
      .post(`${this.config.appConfig.userSvcUrlV4}recover-password/`, emailData)
      .pipe(
        map((res) => new AuthResponseData(Status.Success, res)),
        catchError(
          compose(
            of,
            handleAsyncError<AuthResponseData>(
              'Some error occurred. Please try again.'
            )
          )
        )
      );
  }

  hasPermissionAccess(prodName, permission) {
    const prodInfo = this.storageService.getProdInfo(prodName);
    return !!(
      prodInfo &&
      prodInfo.details &&
      prodInfo.details[0] &&
      prodInfo.details[0].permissions &&
      prodInfo.details[0].permissions.find(
        (item) => item === 'global_access_all' || item === permission
      )
    );
  }

  getOrgnizationList(org_id, product_id) {
    const url = `${this.config.appConfig.userSvcUrlV3}product/organizations/?org_type=pub,agn,dsp,adv`;
    const payload = {
      product_ids: [product_id],
    };
    return this.httpService.doPost(url, payload);
  }

  getChildOrganizationList(org_id) {
    const url = `${this.config.appConfig.userSvcUrlV3}organization/child_org_info/`;
    return this.httpService.doPost(url, JSON.stringify({ org_ids: [org_id] }));
  }

  fetchUserData(): Observable<AuthResponseData> {
    return from(
      this.httpService.doGet(
        `${this.config.appConfig.userSvcUrlV3}userinfo/`,
        this.httpService.getHeader('Bearer ')
      )
    ).pipe(
      map((res: object) => new AuthResponseData(Status.Success, res)),
      catchError(
        compose(
          of,
          handleAsyncError<AuthResponseData>(
            'Some error occurred. Please try again.'
          )
        )
      )
    );
  }

  sendTermsAndConditionsAcceptance(response, userId) {
    const url = `${this.config.appConfig.userSvcUrlV5}user/terms/${userId}`;
    return this.httpService.doPost(url, JSON.stringify({ status: response }));
  }

  logUserInfo(res: AuthResponseData) {
    const email = path(['user', 'user_info', 'email'], res.value);
    if (!!email) {
      setUserId(email);
      setUserProperties({
        rbacOrgId: res.value['user']['org_info']['zeo_org_id'],
        userId: res.value['user']['user_info']['id'],
        userName:
          res.value['user']['user_info']['first_name'] +
          ' ' +
          res.value['user']['user_info']['last_name'],
      });
      logEvent(LOG_EVENTS.LOGIN_SUCCESS, { email });
    }
  }
  // TODO: Depricate this , does side effects in a fetch call , these actions should happen downstream to the consumer of this data
  getUserInfo(): Observable<AuthResponseData> {
    return this.fetchUserData().pipe(
      tap((res) => {
        if (!!res && isSuccess(res.status)) {
          this.logUserInfo(res);
          this.storeUserData(res);
        }
      }),
      catchError(
        compose(
          of,
          handleAsyncError<AuthResponseData>(
            'Some error occurred. Please try again.'
          )
        )
      )
    );
  }

  storeUserData(res: AuthResponseData) {
    this.storageService.setJsonItem(
      'user_info',
      res.value['user']['user_info'],
      true
    );
    this.storageService.setJsonItem(
      'org_info',
      res.value['user']['org_info'],
      true
    );
    this.storageService.setJsonItem(
      'prod_info',
      res.value['user']['product_info'],
      true
    );
  }
}
