import {
  Component,
  Input,
  Output,
  OnChanges,
  ElementRef,
  EventEmitter
} from '@angular/core';
import { BaseControl, getControlValueAccessor } from '../base';

@Component({
  selector: 'zui-range-slider',
  templateUrl: './minMaxSlider.html',
  styleUrls: ['./minMaxSlider.scss'],
  providers: [getControlValueAccessor(MinMaxSliderComponent)]
})
export class MinMaxSliderComponent extends BaseControl {
  @Input() minInfo: number | number[] | { min: number; max: number } = 0;
  @Input() maxInfo: number | number[] | { min: number; max: number } = 100;
  @Input() step: number = 1;
  @Input() tooltip: boolean = false;
  @Input() tooltipInitMsg = null;
  @Input() resetFlag: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetChange: EventEmitter<any> = new EventEmitter<any>();

  private _range: number = 100;
  private _valPrecision = 0;
  private _minInfo = { min: 0, max: 100, maxPercent: 100 };
  private _maxInfo = { min: 0, max: 100, minPercent: 0 };

  public inputError: boolean = false;
  public minBallPressed: number = 0;
  public tooltipMsgFlag = false;
  public closeFlag = false;
  public dragMeFlag = false;

  constructor(public eleRef: ElementRef) {
    super();
    this.ngOnChanges();
  }

  ngOnInit() {
    if (this.tooltipInitMsg != null) {
      this.tooltipMsgFlag = true;
      this.closeFlag = true;
    } else {
      this.tooltipMsgFlag = false;
      this.closeFlag = false;
    }
    this.resetFlag ? this.resetFn() : (this.dragMeFlag = false);
  }

  ngOnChanges() {
    super.ngOnChanges();
    const infoType =
      typeof this.minInfo === typeof this.maxInfo
        ? typeof this.minInfo
        : 'default';

    switch (infoType) {
      case 'default':
      case 'string':
      case 'number':
        this._minInfo.min = this._maxInfo.min = <number>this.minInfo;
        this._minInfo.max = this._maxInfo.max = <number>this.maxInfo;
        break;
      case 'object':
        this._minInfo.min = this.minInfo['min'];
        this._minInfo.max = this.minInfo['max'];
        this._maxInfo.min = this.maxInfo['min'];
        this._maxInfo.max = this.maxInfo['max'];
        break;
      default:
        break;
    }

    this._valPrecision = this.step.toString().split('.')[1]
      ? this.step.toString().split('.')[1].length
      : 0;
    this._range = this._maxInfo.max - this._minInfo.min;
    this._minInfo.maxPercent =
      (100 * (this._minInfo.max - this._minInfo.min)) / this._range;
    this._maxInfo.minPercent =
      100 - (100 * (this._maxInfo.max - this._maxInfo.min)) / this._range;
    this.dragMeFlag = false;
  }

  resetFn() {
    this.dragMeFlag = true;
    this.resetChange.emit(this.dragMeFlag);
  }

  getFormattedValue(number) {
    if (!!number) {
      var parts = number.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    }
    return number;
  }

  processValue(val) {
    // Convert value to nearest step and match with step's precision
    let processedVal = parseFloat(
      (Math.round(val / this.step) * this.step).toFixed(this._valPrecision)
    );
    // Fix to prevent 0.0 display
    return processedVal === 0 ? 0 : processedVal;
  }

  updateValue(minPos, maxPos) {
    this.value = this.value || { min: 0, max: 100 };

    if (minPos !== undefined) {
      this.value.min = this.processValue(
        (this._range * minPos) / 100 + this._minInfo.min
      );
    }

    if (maxPos !== undefined) {
      this.value.max = this.processValue(
        (this._range * maxPos) / 100 + this._minInfo.min
      );
    }
    this.dragMeFlag = false;
  }

  getMinBallPosition() {
    return this.value
      ? (100 * (this.value.min - this._minInfo.min)) / this._range
      : 0;
  }

  getMaxBallPosition() {
    return this.value
      ? (100 * (this.value.max - this._minInfo.min)) / this._range
      : 100;
  }

  onMouseDown(e) {
    if (this.disabled) {
      return;
    }
    e.preventDefault();
    this.activateDrag();
    const self = this;
    const isMinBall =
      e.target.classList.contains('minball') ||
      e.target.classList.contains('mintooltip');
    this.minBallPressed = isMinBall ? 1 : 0;
    const sliderContainer = self.eleRef.nativeElement.getElementsByClassName(
      'sliderContainer'
    )[0];
    const sliderPosition = sliderContainer.getBoundingClientRect();
    let minBallPosition, maxBallPosition;

    const _coerceInRange = function(value, range) {
      return value < range[0] ? range[0] : value > range[1] ? range[1] : value;
    };

    const onMouseMove = function(evt) {
      const delta = Math.min(
        sliderPosition.width,
        Math.max(0, evt.clientX - sliderPosition.x)
      );
      let percent = Math.floor((100 * delta) / sliderPosition.width);
      if (isMinBall) {
        minBallPosition = _coerceInRange(percent, [
          0,
          Math.min(self._minInfo.maxPercent, self.getMaxBallPosition())
        ]);
      } else {
        maxBallPosition = _coerceInRange(percent, [
          Math.max(self._maxInfo.minPercent, self.getMinBallPosition()),
          100
        ]);
      }

      self.updateValue(minBallPosition, maxBallPosition);
    };

    const stopProcessing = function(evt) {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', stopProcessing);
      self.change.emit(null);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', stopProcessing);
  }

  public activateDrag() {
    this.tooltipMsgFlag = false;
    this.dragMeFlag = false;
  }
}
