import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  compose,
  defaultTo,
  length,
  match,
  not,
  either,
  gte,
  lte,
  __,
  trim,
  find,
  keys,
} from 'ramda';
import { isInteger } from './numberUtils';
import { isNilOrEmpty, isNotNullOrEmpty } from './utils';
import { lengthValidation, rangeValidation, ttlValidation } from './validators';

export const ERROR_MSGS = {
  TRIM_ERROR: 'Please enter a non-empty value.',
  WHITESPACE_ERROR: 'This field cannot contain spaces.',
  LEADING_TRAILING_SPACE_ERROR:
    'This field cannot contain leading and trailing spaces.',
  DUPLICATE_VALUE_ERROR: 'An identical input value already exists',
};

export const TrimValidator =
  (
    errorMsg: string = ERROR_MSGS.TRIM_ERROR,
    type?: string,
    showOnceTouched = false
  ) =>
  (control: AbstractControl) => {
    return !!control.parent &&
      !!control.value &&
      isNilOrEmpty(control.value.trim())
      ? type
        ? { trimError: { type, value: errorMsg, showOnceTouched } }
        : { trimError: errorMsg }
      : null;
  };

export const whiteSpaceValidator =
  (errorField: string) => (control: AbstractControl) => {
    return !!control.parent &&
      !!control.value &&
      control.value.replace(/\s+/g, '').length !== control.value.length
      ? errorField
        ? { whiteSpaceError: `${errorField} cannot contain spaces.` }
        : { whiteSpaceError: ERROR_MSGS.WHITESPACE_ERROR }
      : null;
  };

export const customRequiredValidator =
  (errorMsg: string, type?: string, showOnceTouched = false) =>
  (control: AbstractControl) =>
    Validators.required(control)
      ? type
        ? { required: { value: errorMsg, type, showOnceTouched } }
        : { required: errorMsg }
      : null;

export const customMaxLengthValidator =
  (maxlength: number, errorMsg: string) => (control: AbstractControl) =>
    Validators.maxLength(maxlength)(control) ? { maxlength: errorMsg } : null;

export const customRegexValidator =
  (
    shouldAllow: boolean = true,
    regex: RegExp,
    errorMsg: string,
    type?: string,
    showOnceTouched = false
  ) =>
  (control: AbstractControl) => {
    // eg: should not allow character which is not alphabet as first character -> regex: /^[^a-zA-Z]/g, shouldAllow: false
    const errorObj = type
      ? { regexError: { type, value: errorMsg, showOnceTouched } }
      : { regexError: errorMsg };
    return !!control.parent && !!control.value
      ? shouldAllow
        ? compose(not, length, match(regex))(control.value.toString())
          ? errorObj
          : null
        : compose(length, match(regex))(control.value.toString())
        ? errorObj
        : null
      : null;
  };

export const leadingTrailingSpaceValidator = (
  errorMsg: string = ERROR_MSGS.LEADING_TRAILING_SPACE_ERROR,
  type?: string,
  showOnceTouched = false
) => customRegexValidator(false, /^\s+|\s+$/g, errorMsg, type, showOnceTouched);

export const datatypeValidator = (
  dataType: string,
  errorMsg: string,
  type?: string,
  showOnceTouched = false
) => {
  switch (dataType) {
    case 'double': {
      return customRegexValidator(
        true,
        /^(\d+\.)?\d+$/g,
        errorMsg,
        type,
        showOnceTouched
      );
    }
    case 'integer': {
      return customRegexValidator(
        false,
        /[^0-9]/g,
        errorMsg,
        type,
        showOnceTouched
      );
    }
  }
  return null;
};

export const duplicateValueValidator =
  (propName: string) => (control: AbstractControl) => {
    const formArray =
      control.parent && control.parent.parent
        ? (control.parent.parent as UntypedFormArray)
        : null;
    if (formArray && formArray.controls.length) {
      for (let i = 0; i < formArray.controls.length; i++) {
        const currentControl = (formArray.at(i) as UntypedFormGroup).get(
          propName
        );
        if (
          currentControl !== control &&
          (formArray.at(i) as UntypedFormGroup).get(propName).value ==
            control.value
        )
          return { duplicateError: ERROR_MSGS.DUPLICATE_VALUE_ERROR };
      }
    }
  };

export const customMinValueValidator =
  (errorMsg: string, minValue: number) => (control: AbstractControl) => {
    return control.value < minValue ? { minValueError: errorMsg } : null;
  };

export const customDigitValidator =
  (errorMsg: string) => (control: AbstractControl) => {
    return !isInteger(control.value) ? { digitError: errorMsg } : null;
  };

export const customMaxValueValidator =
  (errorMsg: string, maxValue: number) => (control: AbstractControl) => {
    return control.value > maxValue ? { maxValue: errorMsg } : null;
  };

export const customMinMaxValueValidator =
  (errorMsg: string, limits: any) => (control: AbstractControl) => {
    const value = control.get('value').value,
      unit = control.get('unit').value,
      minLimit = defaultTo(0, limits[unit]?.min),
      maxLimit = defaultTo(Number.MAX_SAFE_INTEGER, limits[unit]?.max);
    return value < minLimit || value > maxLimit
      ? {
          error: errorMsg,
        }
      : null;
  };

export const required = (value: string): boolean => {
  return !!value;
};

// templateFormTrimValidator :: string -> boolean
export const templateFormTrimValidator = compose(isNilOrEmpty, trim);

// templateFormMaxLengthValidator :: (number -> string) -> boolean
export const templateFormMaxLengthValidator = (maxLength: number) =>
  compose(either(lte(__, 0), gte(__, maxLength)), length);

// templateFormLeadingTrailingSpaceValidator :: string -> boolean
export const templateFormLeadingTrailingSpaceValidator = compose(
  length,
  match(/^\s+|\s+$/g)
);

export const customDataTypeValidator =
  (errorMsg: string, selectedDataType: string, options: any[]) =>
  (control: AbstractControl) => {
    const field = find((x) => x.name === control.value)(options);
    if (!!field) {
      return selectedDataType != field['dataType'] ? { error: errorMsg } : null;
    }
    return null;
  };
// TODO : Name this better , function to create a validator for the complex data type
export const customComplexDataTypeValidator =
  (errorMsg: string, selectedDataType: string, options: any[]) =>
  (control: AbstractControl) => {
    const field = find((x) => x.name === control.value)(options);
    if (!!field) {
      return selectedDataType != field['dataType'] &&
        selectedDataType != field['subDataType']
        ? { error: errorMsg }
        : null;
    }
    return null;
  };

export const selectedAttributeIsHiddenValidator =
  (errorMsg: string, options: any[]) => (control: AbstractControl) => {
    const field = find((x) => x.name === control.value)(options);
    if (!!field) {
      return field['isHidden'] ? { error: errorMsg } : null;
    }
    return null;
  };

export const LengthValidator = () => (control: AbstractControl) => {
  if (!!control.parent) {
    const error = lengthValidation(control?.value);
    return !!error ? { lengthError: error } : null;
  }
  return null;
};

export const RangeValidator = (type: string) => (control: AbstractControl) => {
  if (!!control.parent) {
    const error = rangeValidation(
      control.parent?.get('lengthMin')?.value,
      control.parent?.get('lengthMax')?.value,
      type
    );
    return !!error ? { rangeError: error } : null;
  }
  return null;
};

export const TTLValidator = () => (control: AbstractControl) => {
  if (!!control.parent) {
    const error = ttlValidation(control?.value);
    return !!error ? { ttlError: error } : null;
  }
  return null;
};

export const getError = (control: AbstractControl) => {
  const errors = control?.errors;
  return control.touched && errors && Object.values(errors)?.[0];
};

export function validatorNumber(control: AbstractControl) {
  const numberRegex = /^(\d+\.)?\d+$/;
  if (!numberRegex.test(control.value)) {
    return { invalidNumber: true };
  }
  return null;
}

export function validatorMultipleNumbers(control: AbstractControl) {
  const numberRegex = /^((\d+\.)?\d+,)*((\d+\.)?\d+){1}$/i;
  if (!numberRegex.test(control.value)) {
    return { invalidMultipleNumber: true };
  }
  return null;
}

export const validatorArrayType = (control: AbstractControl) => {
  const arrayRegex = /^([^,]+,)*([^,]+){1}$/i;
  if (!arrayRegex.test(control.value)) {
    return { invalidArray: true };
  }
  return null;
};

export const validatorStartLessThanEnd = (control: AbstractControl) => {
  if (
    Number(control.value?.timeSeriesValue?.startValue) >=
    Number(control.value?.timeSeriesValue?.endValue)
  ) {
    return { validatorStartLessThanEnd: true };
  }
  return null;
};

export const datePeriodCheck = (control: AbstractControl) => {
  if (!control.value?.timeSeriesValue?.datePeriod) {
    return { invalidDatePeriod: true };
  }
  return null;
};

export const startEndValueCheck = (control: AbstractControl) => {
  if (
    !control.value?.timeSeriesValue?.startValue ||
    !control.value?.timeSeriesValue?.endValue
  ) {
    return { invalidStartEndValue: true };
  }
  return null;
};

export const startValueCheck = (control: AbstractControl) => {
  if (!control.value?.timeSeriesValue?.startValue) {
    return { invalidStartValue: true };
  }
  return null;
};
export const startDateGreaterThanEnd = (control: AbstractControl) => {
  const startDate = new Date(control?.value?.timeSeriesValue?.startValue);
  const endDate = new Date(control?.value?.timeSeriesValue?.endValue);
  if (startDate >= endDate) {
    return { startDateGreaterThanEnd: true };
  }
  return null;
};

export const validateNumStartField = (control: AbstractControl) => {
  const numberRegex = /^(\d)?\d+$/;
  if (!numberRegex.test(control?.value?.timeSeriesValue?.startValue)) {
    return { invalidStartNumber: true };
  }
  return null;
};
export const validateNumEndField = (control: AbstractControl) => {
  const numberRegex = /^(\d)?\d+$/;
  if (!numberRegex.test(control?.value?.timeSeriesValue?.endValue)) {
    return { invalidEndNumber: true };
  }
  return null;
};
