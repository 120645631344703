import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
} from '@angular/core';
import { CustomTooltipComponent } from './custom-tooltip.component';

@Directive({
  selector: '[customToolTip]',
})
export class CustomTooltipDirective {
  /**
   * This will be used to show tooltip or not
   * This can be used to show the tooltip conditionally
   */
  @Input() showToolTip: boolean = true;

  // If this is specified then the specified text will be shown in the tooltip
  @Input() text: string;

  // If this is specified then specified template will be rendered in the tooltip
  @Input() contentTemplate: TemplateRef<any>;

  // value used inside the ng-template
  @Input() value = { $implicit: '' };

  //custom tooltip class for simpleText similar to ngClass types
  @Input() customTooltipClass:
    | string
    | string[]
    | Set<string>
    | { [key: string]: any };

  private _overlayRef: OverlayRef;

  // Ordered list of preferred positions, from most to least desirable.
  // if any part of their bounding client rectangle exceeds the
  // bounds of any one of the strategy's Scrollable's bounding client rectangle.
  @Input() positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top', //tooltip towards right
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 10,
    },
    {
      originX: 'center',
      originY: 'bottom', //tooltip towards bottom
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 10,
    },
    {
      originX: 'start',
      originY: 'top', //tooltip towards left
      overlayX: 'end',
      overlayY: 'top',
      offsetX: -20,
    },
    {
      originX: 'center',
      originY: 'top', //tooltip towards top
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -10,
    },
  ];

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef
  ) {}

  /**
   * Init life cycle event handler
   */
  ngOnInit() {
    if (!this.showToolTip) {
      return;
    }

    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions(this.positions);

    this._overlayRef = this._overlay.create({ positionStrategy });
    // this._overlayRef.addPanelClass('no-pointer-events-tooltip');   //to add any css to cdk-overlay-pane
  }

  /**
   * This method will be called whenever the mouse enters in the Host element
   * i.e. where this directive is applied
   * This method will show the tooltip by instantiating the CustomTooltipComponent and attaching to the overlay
   */
  @HostListener('mouseenter')
  show() {
    //attach the component if it has not already attached to the overlay
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<CustomTooltipComponent> = this._overlayRef.attach(
        new ComponentPortal(CustomTooltipComponent)
      );
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
      tooltipRef.instance.value = this.value;
      tooltipRef.instance.customTooltipClass = this.customTooltipClass;
    }
  }

  /**
   * This method will be called when the mouse goes out of the host element or click occurs
   * i.e. where this directive is applied
   * This method will close the tooltip by detaching the overlay from the view
   */

  @HostListener('click')
  @HostListener('mouseleave')
  hide() {
    this.detachToolTip();
  }

  /**
   * Destroy lifecycle event handler
   * This method will make sure to close the tooltip
   */
  ngOnDestroy() {
    this.disposeToolTip();
  }

  /**
   * This method will close the tooltip by detaching the component from the overlay
   */
  private detachToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }

  /**
   * This method will dispose/remove the component from the overlay
   */
  private disposeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }
}
