<section class="h-full flex flex-col gap-8 justify-center items-center overflow-y-scroll no-scroll-bar">
  <div class="w-52 h-52 mx-auto">
    <img class="w-full" src="https://content.zeotap.com/img/noData.png" />
  </div>
  <p class="font-primary-medium text-2xl text-primary-1-900">Page not found</p>
  <p class="font-primary text-lg text-grey/52">Oops! It appears that the page you are attempting to access does not exist.</p>
  <div class="bg-grey-200/30 p-5 rounded-lg flex flex-col font-primary text-sm w-2/3 gap-3 text-primary-1-900">
    <p class="font-primary-bold">Here's what you can do to resolve this:</p>
    <ol type="1" class="list-inside list-decimal">
      <li class="mb-4">Check the URL: Please double-check the URL to ensure that it is entered correctly. Typos can happen to the best of us! The most reliable approach is to use our in-product navigation.</li>
      <li class="mb-4">Navigate Back: You can return to the <span class="cursor-pointer text-primary-1-500" (click)="routeToHome()">Home Page</span> to explore other areas within our application.</li>
      <li class="mb-4">Contact Us: If you believe this is an error or require assistance, please don't hesitate to get in touch with our <a class="text-primary-1-500" [href]="supportUrl" target="_blank">support team</a>. We are here to provide assistance.</li>
    </ol>
    <p>In the meantime, feel free to explore our <a class="text-primary-1-500" [href]="docsUrl" target="_blank">Help Center</a> for more comprehensive information about our application and its features.</p>
  </div>
  <div class="font-primary-medium h-12 w-max cursor-pointer p-3 rounded flex justify-center items-center text-sm bg-primary-1 text-white font-bold" (click)="routeToHome()">{{ 'Back to Home' }}</div>
</section>
