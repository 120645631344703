import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'zap-collapse-section',
  templateUrl: './collapse-section.component.html'
})
export class CollapseSectionComponent implements OnInit {
  @Input() header: string;
  @Input() showIcon: string;
  @Input() headerIcon: string;
  @Input() headerImageUrl: string;
  @Input() openSection = true;
  @Input() actionText = '';
  @Input() showCollapse = true;
  @Input() enableDelete = false;
  @Input() actionDisabled = false;
  @Input() subtext = '';
  @Input() customIcon = '';
  @Input() customIconText = '';
  @Output() actionClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteClicked = new EventEmitter<void>();
  tooltipText: string;
  constructor() {}

  ngOnInit(): void {
    this.tooltipText =
      'These rules will apply to specified channels for ' +
      this.customIcon?.charAt(0).toUpperCase() +
      this.customIcon?.slice(1);
  }

  toggleSectionState(value: boolean) {
    this.openSection = value;
  }
}
