import { Component } from '@angular/core';

@Component({
  selector: 'z-tree-content',
  styleUrls: ['./tree.component.scss'],
  template: `
    <div [hidden]="!show" class="treeContent">
      <ng-content></ng-content>
    </div>
  `
})
export class TreeContentComponent {
  public show = true;
}
