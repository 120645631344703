import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import { BaseControl } from '../../base';

/**
 * DropdownControlComponent
 * @description
 * This component is used to render a dropdown control always binded with a parent form group.
 * It accepts the following inputs:
 * - items: any[]
 * - controlKey: string
 * - labelKey: string
 * - valueKey: string
 * By default it has rounded edges of 0.25rem and a border of 1px solid grey-300. On error, the border color changes to red-700.
 * It's responsive to a 100% external parent width
 * @example
 * <form [formGroup]="form">
 *  <zap-dropdown-control controlKey="name" [items]="items" [labelKey]="'name'" placeholderText="Enter your name"></zap-dropdown-control>
 * </form>
 */
@Component({
  selector: 'zap-dropdown-control',
  templateUrl: './dropdown-control.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DropdownControlComponent extends BaseControl implements OnInit {
  @Input() items: any[] = [];
  @Input() labelKey: string = '';
  @Input() valueKey: string = '';
  @Input() closeOnSelect = false;
  @Input() multiple = false;
  @Input() itemsLoading = false;
  @Input() clearAll = false;
  @Input() maxNoOfLabelChips = 5;
  @Input() allowSelectAllAction = false;
  @Input() actionItem: any;
  dropdownControl: AbstractControl;
  constructor(private parentContainer: ControlContainer) {
    super();
  }

  ngOnInit() {
    this.dropdownControl = this.parentContainer?.control?.get(this.controlKey);
    this.listenToControlChanges(this.dropdownControl);
  }

  selectAll() {
    this.dropdownControl.patchValue(
      this.items.map((item) => (this.valueKey ? item[this.valueKey] : item))
    );
  }
  setCustomValue(value: any) {
    this.dropdownControl.patchValue(value);
  }
}
