<section class="filterPanel mr-2.5 min-w-0 flex-shrink-0 w-64 max-w-[16.87rem] mt-3">
  <span class="arrow-color arrowbox before:border-b-grey-50 before:border-solid before:content-[''] before:block before:-mt-1.5 before:overflow-auto before:w-0 before:h-0 before:ml-4 before:border-t-[transparent] before:border-x-[transparent] before:border-2.5"></span>
  <section class="bg-grey-50 rounded h-full overflow-auto pl-4 pr-1.5 pt-6 pb-7 relative">
    <span class="text-grey-800/90 font-primary-medium"
      >Filters
      <span class="cursor-pointer text-sm relative uppercase font-secondary left-32 text-primary-1" (click)="onClickClearAllFilters()" [hidden]="!(filterItems | areAnyFiltersSelected : currentFilterState)">Clear all</span>
    </span>
    <section *ngIf="!(loading || error); else loader">
      <ng-container *ngFor="let filter of filterItems">
        <z-tree [defaultOpen]="false">
          <div class="grid grid-cols-[5fr_1fr]">
            <z-tree-header>
              <div class="headerRow font-primary-medium text-sm text-grey-900 cursor-pointer capitalize pt-[15px] pb-[5px] -top-[0.2rem] relative" [ngClass]="{ '!font-primary !text-[13px]': normalHeader }">
                <span class="headerName align-bottom font-secondary-bold text-sm text-grey-600/75 uppercase">{{ filter.displayName }}</span>
              </div>
            </z-tree-header>
            <div class="flex items-center cursor-pointer">
              <span class="clearText font-secondary text-[13px] text-primary-1" [hidden]="!(filter | isFilterSelected : currentFilterState : true)" (click)="onClickClearAllFilterOfGroup(filter)">CLEAR</span>
            </div>
          </div>
          <z-tree-content>
            <div class="filterRow font-primary-light text-[13px] cursor-pointer relative block px-0 py-[5px]" (click)="onClickOfFilter(childFilter)" *ngFor="let childFilter of filter.items">
              <span class="inline-block w-4/5 filterName" [ngClass]="{ 'font-primary-medium': childFilter | isFilterSelected : currentFilterState }">{{ childFilter.displayName }}</span>
              <ng-container *ngIf="childFilter.count">
                <span *ngIf="count" class="filterCount text-grey-800 absolute inline-block right-[15px]">{{ childFilter.count }}</span>
              </ng-container>
            </div>
          </z-tree-content>
        </z-tree>
      </ng-container>
    </section>
    <ng-template #loader>
      <zap-status class="absolute w-full h-full left-0 top-1/2 -translate-y-1/2" (reload)="onReloadFilters()" [text]="loading ? loadingText : 'Oops! Something went wrong.'" [type]="loading ? 'progress' : 'error'"></zap-status>
    </ng-template>
  </section>
</section>
