import {
  defaultTo,
  mapObjIndexed,
  mergeDeepRight,
  path,
  set,
  lensPath,
  keys,
  reduce,
  split,
} from 'ramda';

// applies defaults if the path in the input object is null
export const applyDefaults =
  (defaults: { [input_path: string]: any }) =>
  (input: any): any => {
    return reduce(
      (input_obj: any, input_path: string) => {
        const actual_path = split('.', input_path);
        const valueAtPath = path(actual_path, input);
        const defaultValue = defaults[input_path];
        return set(
          lensPath(actual_path),
          defaultTo(defaultValue, valueAtPath),
          input_obj
        );
      },
      input,
      keys(defaults)
    );
  };
