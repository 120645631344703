import { ProductInfo, checkForUserProductAccess } from '@zeotap-ui/core';
import { CheckFuntion, MainMenuItem } from './types';
import { filter, has, map, any, pipe } from 'ramda';

export const filterMenuItems = (menuItems: MainMenuItem[], data: any) => {
  // check if data has desired field
  return filter(
    pipe((item: MainMenuItem) => {
      if (item.children) {
        item.children = filterMenuItems(item.children, data);
      }
      const route = item.route;
      // below logic means that each leaf node needs {product : xyz } as part of their route
      const product = route?.data?.product;

      const userHasAccessToItem =
        (item.children && item.children.length > 0) ||
        (product && checkForUserProductAccess(product)(data));

      return userHasAccessToItem;
    }),
    menuItems
  );
};
