import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule } from '@angular/forms';
import { ZeotapButtonModule } from '../button/button.module';
import { RouterModule } from '@angular/router';
import { DisplayMessageModule } from '@zeotap-ui/core';

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ZeotapButtonModule,
    RouterModule,
    DisplayMessageModule,
  ],
  exports: [ForgotPasswordComponent],
})
export class ForgotPasswordModule {}
