import {
  compose,
  curry,
  equals,
  filter,
  flatten,
  groupBy,
  gt,
  identity,
  indexOf,
  map,
  mapObjIndexed,
  merge,
  not,
  pathOr,
  pick,
  pipe,
  prop,
  reduce,
  sortBy,
  values,
} from 'ramda';
import { toPermissionConfig } from '../../product';
import { UserPermission } from '../types';

export const toUserPermission = (apiPermission): UserPermission => ({
  country: {
    id: apiPermission.country_id,
    isoAlpha2: apiPermission.country_id,
    isoAlpha3: apiPermission.country_iso3,
    name: apiPermission.country_name,
    region: apiPermission.region,
  },
  group_info: {
    id: apiPermission.group_info.group_id,
    name: apiPermission.group_info.group_name,
  },
  permissions: apiPermission.permissions,
  preferred_region: apiPermission.preferred_region,
  config: toPermissionConfig(apiPermission), //TODO: Remove once product-config facade is merged with all products
});

export const toUserPermissions = map(toUserPermission);

//toUserPermissionsV2:: (string) -> {[productId]: {details:  [{country_id, country_name, permissions...}]}} -> [string]
export const toUserPermissionsV2 = compose(
  flatten,
  map((permissionDetail) =>
    permissionDetail.details.length === 1 &&
    !permissionDetail.details?.[0]['country_id'] //to check if permission can be grouped
      ? pathOr([], ['details', 0, 'permissions'], permissionDetail)
      : groupPermissionByKey('country_id')(permissionDetail)
  ),
  values
);

const concatPermissionAndGroupValue = (
  permission: string,
  groupValue: string,
  seperator: string = '__'
) => `${groupValue}${seperator}${permission}`;

//groupPermissionByKey:: (string) -> {details:  [{country_id, country_name, permissions...}]} -> [string]}
// [FR__permission1, FR_permissions2]
const groupPermissionByKey = (key: string) =>
  pipe(
    prop('details'),
    map((details) =>
      details.permissions.map((permission) =>
        concatPermissionAndGroupValue(permission, details[key])
      )
    ), // returns [ [] [] [] ]
    flatten,
    sortBy(identity)
  );

//getAllCountries:: {details: [{country_id, country_name, permissions...}]} -> [string]} -> Record<string, CountryModel>
export const getAllCountries = pipe(
  prop('details'),
  filter((d) => !!d.country_id),
  map(pick(['country_id', 'country_iso3', 'country_name', 'region'])),
  reduce(
    (acc, country) =>
      merge(acc, {
        [country.country_id]: {
          id: country.country_id,
          isoAlpha2: country.country_id,
          isoAlpha3: country.country_iso3,
          name: country.country_name,
          region: country.region,
        },
      }),
    {}
  )
);

export const groupUserPermissionBy = (groupByFn: Function) =>
  compose(
    mapObjIndexed((d) => d[0]),
    groupBy(groupByFn)
  );

export const notInitialPermissionState = compose(
  not,
  equals([]),
  prop('userPermission')
);

export const groupByIsoAlpha2 = (c: UserPermission) => c.country.isoAlpha2;

export const countryComparator = (
  cA: UserPermission,
  cB: UserPermission
): number => cA.country.name.localeCompare(cB.country.name);

export const customSortRegions = (
  cA: UserPermission,
  cB: UserPermission
): number => cA.preferred_region.localeCompare(cB.preferred_region);

export const groupByRegion = (c: UserPermission) => c.preferred_region;
// checkPermission :: string -> [string] -> boolean
export const checkPermission = curry(
  (permission: string, permissions: string[]): boolean =>
    gt(indexOf(permission, permissions), -1)
);
