import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ContentChild,
} from '@angular/core';
import {
  PromptDescriptionTemplateDirective,
  PromptTextTemplateDirective,
} from '../directives/message-prompt.directive';
import { ActionButtonType } from '../types';
import {
  ContentTemplateDirective,
  FooterTemplateDirective,
  IconTemplateDirective,
} from '@zeotap-ui/core';

@Component({
  selector: 'zap-message-prompt',
  templateUrl: './message-prompt.component.html',
  styleUrls: ['./message-prompt.component.scss'],
})
export class MessagePromptComponent {
  @Input() promptText = '';
  @Input() promptDesc = '';
  @Input() proceedActionName = 'Proceed';
  @Input() cancelActionName = 'Cancel';
  @Input() showModal: boolean;
  @Input() iconWarning = true;
  @Input() iconSuccess = false;
  @Input() actionButtonType = ActionButtonType.Default;
  @Input() promptClass = '';
  @Input() disableProceed = false;
  @Output() onCancel = new EventEmitter();
  @Output() onProceed = new EventEmitter();

  @ContentChild(FooterTemplateDirective, {
    read: TemplateRef,
  })
  footerTemplate: TemplateRef<any>;

  @ContentChild(ContentTemplateDirective, {
    read: TemplateRef,
  })
  contentTemplate: TemplateRef<any>;

  @ContentChild(IconTemplateDirective, {
    read: TemplateRef,
  })
  iconTemplate: TemplateRef<any>;

  @ContentChild(PromptTextTemplateDirective, {
    read: TemplateRef,
  })
  promptTextTemplate: TemplateRef<any>;

  @ContentChild(PromptDescriptionTemplateDirective, {
    read: TemplateRef,
  })
  descriptionTemplate: TemplateRef<any>;
}
