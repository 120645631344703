<div class="cursor-pointer w-full px-2.5">
  <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl" class="max-h-full overflow-x-hidden overflow-y-scroll">
    <!-- This is the tree node template for leaf nodes -->
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="block">
      <!-- use a disabled button to provide padding for tree leaf -->
      <div class="group pr-2 pl-4 w-full h-10 flex flex-row items-center font-primary text-xs" [routerLink]="node.route.path" routerLinkActive="highlight" role="link">
        <div class="group-hover:bg-white group-[.highlight]:bg-white h-5 w-0.5 rounded-sm" [routerLink]="node.route.path" routerLinkActive="bg-white" role="link"></div>
        <div class="w-8"></div>
        <li class="list-style-circle group-[.highlight]:font-bold text-white w-full">
          <a [routerLink]="node.route.path" class="text-white hover:text-white"> {{ node.label }}</a>
        </li>
      </div>
    </cdk-nested-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild">
      <ng-container *ngIf="{ isActive: treeControl.isExpanded(node) } as data">
        <div
          class="p-2.5 w-full hover:bg-white/[0.03] text-white flex justify-center items-center rounded-lg h-12"
          [routerLink]="node.isExternal || (!collapsed && (!node.children || node.children.length === 0)) ? node?.route?.path : []"
          [cdkMenuTriggerFor]="collapsed ? (!!node.children && node.children.length > 0 ? menu : empty) : empty"
          [cdkMenuPosition]="this.menuPosition"
          cdkTreeNodeToggle
          role="link"
          (click)="preventClickRootMenu($event)"
          [ngClass]="{ 'bg-opacity-[0.1]': collapsed, 'bg-opacity-[0.03]': !collapsed, 'bg-white': currentParentRoute === node?.route?.path }"
        >
          <ng-template [ngTemplateOutlet]="defaultTemplate"></ng-template>
        </div>
        <ng-template #defaultTemplate>
          <div class="flex flex-col justify-center items-center w-9">
            <span class="text-base transition-[font] ease-in-out duration-700" [className]="node.iconClass" [ngClass]="{ '!text-[20px]': collapsed }"></span>
            <div class="transition-size overflow-hidden" [ngClass]="{ 'max-h-0 ease-in-out duration-300': !collapsed, 'max-h-10 ease-in-out delay-700 duration-400': collapsed }">
              <span class="mt-2 font-primary text-[8px] h-2" [ngClass]="{ 'font-bold ': data.isActive || currentParentRoute === node.route.path }"> {{ node.label }} </span>
            </div>
          </div>
          <div class="flex-1 flex items-center w-full transition-all ease-in-out duration-400 overflow-hidden" [ngClass]="{ 'w-0': collapsed }">
            <span class="ml-2 font-primary text-xs flex-1" [ngClass]="{ 'font-bold': data.isActive || currentParentRoute === node.route.path }"> {{ node.label }} </span>
            <span *ngIf="!!node.children && node.children.length > 0" class="unity-icons-dropdown text-[10px]" [ngClass]="{ 'before-rotate-180': data.isActive }" cdkTreeNodeToggle> </span>
          </div>
        </ng-template>

        <div *ngIf="!!node.children" class="w-full transition-size overflow-hidden" [ngClass]="{ hidden: !data.isActive, 'max-h-36  ease-out delay-300  duration-400 ': !collapsed, 'max-h-0 ease-in  duration-300': collapsed }">
          <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
        <div class="my-2 h-px w-full bg-white/[0.1] transition-opacity ease-in-out duration-700" [ngClass]="{ 'opacity-0': collapsed }"></div>

        <!--Need this, otherwise cdkMenuTrigger gives portalNotAttachedError-->
        <ng-template #empty></ng-template>

        <ng-template #menu>
          <div class="floating-menu" *ngIf="collapsed">
            <div cdkMenu>
              <!--Menu Header (make this a component if used in many places in the future)-->
              <div class="flex bg-primary-3-500 py-4 pr-4 rounded-t-lg min-w-[200px]">
                <div class="flex flex-col gap-2 pl-4">
                  <div class="flex gap-2 text-white text-sm">
                    <span [ngClass]="node.iconClass"> </span>
                    <div>{{ node.label }}</div>
                  </div>
                  <!--Product Description-->
                  <div *ngIf="!!node.description && node.description.trim() !== ''" class="text-white/50 text-xs">{{ node.description }}</div>
                </div>
              </div>
              <!--Menu items-->
              <ng-container *ngFor="let child of node.children">
                <button cdkMenuItem [routerLink]="child.route.path" routerLinkActive="font-semibold" role="link">
                  <span class="text-primary-1" [ngClass]="child.iconClass"></span>
                  {{ child.label }}
                </button>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>
