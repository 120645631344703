import {
  Component,
  Input,
  AfterContentInit,
  ContentChild
} from '@angular/core';
import { PosterHeaderComponent } from './poster-header.component';
import { PosterContentComponent } from './poster-content.component';
import { PosterFooterComponent } from './poster-footer.component';

@Component({
  selector: 'zui-poster',
  styleUrls: ['./poster.component.scss'],
  template: `
    <div class="poster">
      <ng-content></ng-content>
    </div>
  `
})
export class PosterComponent implements AfterContentInit {
  @ContentChild(PosterHeaderComponent)
  public header: PosterHeaderComponent;
  @ContentChild(PosterContentComponent)
  public content: PosterContentComponent;
  @ContentChild(PosterFooterComponent)
  public footer: PosterFooterComponent;

  public ngAfterContentInit() {}
}
