import { defaultTo, isNil, includes, prop } from 'ramda';

export const emptyFeatureConfig = {
  enabled: false,
  config: {},
};

// (FeatureWithConfig) => boolean
export const isFeatureConfigEnabled = prop('enabled');

export const getFeatureValue = (
  featureName = '',
  featureToggles = {},
  withConfig = false
) => {
  let defaultValue = withConfig ? emptyFeatureConfig : false;
  return defaultTo(defaultValue, featureToggles[featureName]);
};

export const isFeatureEnabledForSelectedOrg = (orgId, featureConfig) => {
  return typeof featureConfig === 'boolean'
    ? featureConfig
    : featureConfig.enabled &&
        (isNil(featureConfig.config.enabledOrgIds) ||
          includes(orgId, featureConfig.config.enabledOrgIds));
};

export const validateConfig = (configValue, validator) => {
  return validator(configValue);
};
