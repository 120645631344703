import { Pipe, PipeTransform } from '@angular/core';
import { isNilOrEmpty } from '../../utils';

@Pipe({
  name: 'isNilOrEmpty',
})
export class isNilOrEmptyPipe implements PipeTransform {
  transform(value: any): boolean {
    return isNilOrEmpty(value);
  }
}
