import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent, OauthCallbackComponent } from './components';
const declarations = [LoginComponent, OauthCallbackComponent];

@NgModule({
  declarations: declarations,
  imports: [CommonModule],
  exports: [...declarations],
})
export class AuthModule {}
