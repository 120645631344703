<div class="p-0 categoryFilterContainer">
  <z-tree [defaultOpen]="defaultOpen">
    <z-tree-header>
      <div class="headerRow font-primary-medium text-sm text-grey-900 cursor-pointer capitalize pt-[15px] pb-[5px]" [ngClass]="{ '!font-primary !text-[13px]': normalHeader }">
        <span class="headerName">{{ header }}</span>
        <span *ngIf="!showDropdown" class="clearText font-primary text-[13px] text-primary-1 absolute top-[calc(50%_-_6px)] !right-[15px]" [hidden]="!hasSelectedFilters()" (click)="clearSelections(header)">Clear</span>
      </div>
    </z-tree-header>
    <z-tree-content>
      <ng-container *ngIf="!showDropdown">
        <div class="filterRow font-primary-light text-[13px] cursor-pointer relative block px-0 py-[5px]" (click)="toggleFilter(filter)" *ngFor="let filter of filters | ObjNgFor: false:true">
          <span class="inline-block w-4/5 filterName" [ngClass]="{ 'font-primary-medium': filter.selected }">{{ DICTIONARY[filter.name] ? DICTIONARY[filter.name] : filter.displayName || filter.name }}</span>
          <ng-container *ngIf="filter.count">
            <span *ngIf="count" class="filterCount text-grey-800 absolute inline-block right-[15px]">{{ filter.count }}</span>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="showDropdown">
        <ng-select #select *ngIf="multiSelect" class="text-sm font-primary text-grey-900 droplist ng-select-theme" [items]="dropDownFilters" [clearOnBackspace]="false" [multiple]="true" [clearSearchOnAdd]="true" [placeholder]="'Search for ' + header | titlecase" (add)="toggleDropdownFilter($event)" (remove)="toggleDropdownFilter($event.value); closeDropDown()" (clear)="clearSelections(header)" [(ngModel)]="selectedDropdownFilters">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value custom-ng-value !bg-grey-50 !border-[0.0625rem] !border-solid !border-grey-300/30 flex items-center !text-sm h-[1.625rem] max-w-[8.4375rem] !rounded-2xl" *ngFor="let item of items | slice: 0:1">
              <span class="overflow-hidden ng-value-label whitespace-nowrap text-ellipsis">{{ item }}</span>
              <span class="icon close custom-ng-icon !bg-grey-50 border border-solid border-grey-300/30 text-grey/52 text-2xs opacity-100 !p-1 rounded-[50%]" (click)="clear(item)" aria-hidden="true"></span>
            </div>
            <div class="ng-value custom-ng-more text-primary-1 !text-sm !mr-0 !bg-none" *ngIf="items.length > 1">
              <span>+{{ items.length - 1 }} More</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="option-holder">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" [disabled]="item?.disabled" />
              {{ item }}
            </div>
          </ng-template>
        </ng-select>
        <ng-select #select *ngIf="!multiSelect" class="text-sm font-primary text-grey-900 droplist ng-select-theme" [items]="dropDownFilters" [placeholder]="'Search for ' + header | titlecase" [clearOnBackspace]="false" (change)="toggleDropdownFilter($event)" [(ngModel)]="selectedDropdownFilters"> </ng-select>
      </ng-container>
    </z-tree-content>
  </z-tree>
</div>
