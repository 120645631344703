import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterWithCondition' })
export class FilterWithConditionPipe implements PipeTransform {
  transform(items: any[], conditionFn: any): any[] {
    if (!Array.isArray(items)) {
      return items;
    }

    if (!conditionFn) {
      return items;
    }

    return items.filter(conditionFn);
  }
}
