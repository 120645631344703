import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'zui-card',
  templateUrl: './card.html',
  styleUrls: ['./card.scss']
})
export class CardComponent {
  @Input() image: string;
  @Input() header: string;
  @Input() description: string;
  @Input() styleClass: string;
  @Input() styleBg: string;

  cardActiveFlag: Boolean = false;

  toggleCardSelectState(event: Event) {
    this.cardActiveFlag = !this.cardActiveFlag;
  }
}
