declare let amplitude;

const stubInstance = () => {
  let instance = {};
  const instanceMenthods = [
    'setUserId',
    'setUserProperties',
    'clearUserProperties',
    'regenerateDeviceId',
    'logEvent',
  ];
  instance = instanceMenthods.reduce((acc, curr) => {
    acc[curr] = () => {};
    return acc;
  }, instance);
  return instance;
};

const instance = stubInstance();

export const getInstance = () => {
  if (typeof amplitude === 'undefined') {
    console.log(
      "amplitude is not initialized on this app . please add amplitude to your app's index.html"
    );
    return instance;
  }

  return amplitude.getInstance();
};

export const setUserId = (id) => {
  getInstance().setUserId(id);
};

export const setUserProperties = (props) => {
  getInstance().setUserProperties(props);
};

export const resetUserProperties = () => {
  setUserId(null);
  getInstance().clearUserProperties();
  getInstance().regenerateDeviceId();
};

export const logEvent = (event, eventProp?) => {
  !!eventProp
    ? getInstance().logEvent(event, eventProp)
    : getInstance().logEvent(event);
};
