import {
  AfterContentInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { defaultToEmptyArray } from '../../utils';
import { ContentDetectorService } from '../content-detector.service';

@Directive({ selector: '[detectAs]' })
export class DetectableDirective {
  _name: string;
  _contentProps: any;
  constructor(private el: ElementRef) {}
  @Input() set detectAs(name: string) {
    this._name = name;
  }
  @Input() set contentProps(contentProps: any) {
    this._contentProps = contentProps;
  }
  get name() {
    return this._name;
  }
  get contentProps() {
    return this._contentProps;
  }
}

@Component({
  selector: 'zap-content-detector',
  templateUrl: './content-detector.component.html',
  styleUrls: ['./content-detector.component.css'],
  providers: [],
})
export class ContentDetectorComponent
  implements OnInit, OnDestroy, AfterContentInit {
  unsubscribe$ = new Subject();
  @Input() name = '';
  @Output() onContentDetected = new EventEmitter<{ [name: string]: number }>();
  constructor(private contentDetectorService: ContentDetectorService) {}

  //content children where the selector depends  on the input 'selector'
  @ContentChildren(DetectableDirective, {
    descendants: true,
    emitDistinctChangesOnly: true,
  })
  contentChildren!: QueryList<DetectableDirective>;

  ngOnInit(): void {
    if (this.name === '')
      this.name = this.contentDetectorService.getDefaultName();
  }

  onContentChange(children: DetectableDirective[]) {
    console.debug(
      'Content changes detected. Following Elements are detected in view',
      this.name,
      this.contentChildren.map((c) => c.name)
    );
    this.contentDetectorService.updateContentChanges(
      this.name,
      defaultToEmptyArray(children)
    );
  }

  ngAfterContentInit(): void {
    this.onContentChange(this.contentChildren.toArray());
    this.contentChildren.changes
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((children) => {
        this.onContentChange(children);
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    this.contentDetectorService.updateContentChanges(this.name, []);
  }
}
