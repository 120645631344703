import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ChartTemplateDirective } from '../directives/charts.directive';

@Component({
  selector: 'zap-custom-pie-chart',
  templateUrl: './custom-pie-chart.component.html',
  styleUrls: ['./custom-pie-chart.component.scss'],
})
export class CustomPieChartComponent implements OnInit {
  scheme;
  @Input() view: number[];
  @Input() results: { name: string; value: number }[];
  @Input() set colors(colors: string[]) {
    this.scheme = { domain: [...colors] };
  }
  @Input() animations: boolean = false;
  @Input() showLabels: boolean = false;
  @Input() labelFormatting: (o: any) => any;
  @Input() maxLabelLength: number = 16;
  @Input() showLegend: boolean = false;
  @Input() showCustomLegend: boolean = true;
  @Input() legendTitle: string;
  @Input() legendPosition: string = 'right'; // right or below
  @Input() doughnut: boolean = true;
  @Input() tooltipDisabled: boolean = true;
  @Input() tooltipText: (o: any) => any;
  @Input() showTooltip: boolean;
  @Input() arcWidth: number = 0.5;
  @Input() showCustomEmptyData = false;
  @Input() noDataImgSrc;
  @Input() noDataText;
  @ContentChild(ChartTemplateDirective, { read: TemplateRef })
  chartTemplate: TemplateRef<any>;
  tooltipColors: any;

  @Output() select = new EventEmitter<any>();
  @Output() activate = new EventEmitter<any>();
  @Output() deactivate = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.tooltipColors = this.scheme.domain.reduce(
      (acc, curr, i) => ({ ...acc, [this.results?.[i]?.name]: curr }),
      {}
    );
  }

  hasData() {
    return (
      this.results?.length &&
      Object.values(this.results).some((x) => x.value > 0)
    );
  }

  onSelect(data): void {
    this.select.emit(data);
  }

  onActivate(data): void {
    this.activate.emit(data);
  }

  onDeactivate(data): void {
    this.deactivate.emit(data);
  }
}
