<div class="image-chart">
  <div class="image-chart-container">
    <div class="wrap">
      <ul class='circle-container-big' [ngClass]="setClassName(1)">
        <li *ngFor="let item of data.primeApps; let i=index" title="{{item.name}}">
          <div class="affinityApps zui-content" [ngStyle]="{'background-color': colors[i%5]}" [style.width.px]="30-(2*item.size)" [style.height.px]="30-(2*item.size)" [style.line-height.px]="30-(2*item.size)"  *ngIf="!item.url">{{item.name.substring(0,1)}}</div>
          <img *ngIf="item.url" [style.width.px]="30-(2*item.size)" [src]="item.url" (error)="item.url=null">
        </li>
      </ul>
    </div>
    <div class="wrap small">
      <ul class='circle-container' [ngClass]="setClassName(2)">
        <li *ngFor="let item of data.secApps; let i=index"  title="{{item.name}}">
          <div class="affinityApps zui-content" [ngStyle]="{'background-color': colors[i%5]}" [style.width.px]="30-(2*item.size)" [style.height.px]="30-(2*item.size)" [style.line-height.px]="30-(2*item.size)"
            *ngIf="!item.url">{{item.name.substring(0,1)}}</div>
          <img *ngIf="item.url" [style.width.px]="30-(2*item.size)" [src]="item.url" (error)="item.url=null">
        </li>
      </ul>
    </div>
    <div class="center" *ngIf="data && data.selected">
      <img class="icon" *ngIf="data.selected.url" (error)="data.selected.url=null" [src]="data.selected.url" title="{{data.selected.name}}">
      <div class="icon zui-content" [ngStyle]="{'background-color': data.selected.color}" *ngIf="!data.selected.url" title="{{data.selected.name}}" >{{data.selected.name.substring(0,1)}}</div>
    </div>
  </div>
  <div class="divider zui-gray4-text-color zui-content" title="{{data.selected.name}}">{{data.selected.name}}</div>
</div>
