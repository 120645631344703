import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ConfigService } from '../../services/config.service';

@Directive({
  selector: '[zapFeature]', // zapFeature
})
export class FeatureToggledDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureConfigService: ConfigService
  ) {}
  hasView: boolean = false;

  @Input() set zapFeature(featureName: string) {
    var condition = this.featureConfigService.getFeatureValue(featureName);
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}

@Directive({
  selector: '[zapAltToFeature]', // feature
})
export class NotFeatureToggledDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureConfigService: ConfigService
  ) {}
  hasView: boolean = false;

  @Input() set zapAltToFeature(featureName: string) {
    var condition = !this.featureConfigService.getFeatureValue(featureName);
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
