import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortalDirective } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  HostListener,
  ElementRef,
  ContentChild,
  TemplateRef,
} from '@angular/core';
import { NgClassType } from '@zeotap-ui/core';
import { BehaviorSubject } from 'rxjs';
import { MenuIconTemplateDirective } from '../directives';

@Component({
  selector: 'zap-dotted-menu',
  templateUrl: './dotted-menu.component.html',
  styleUrls: ['./dotted-menu.component.scss'],
})
export class DottedMenuComponent {
  showActions$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  @Input() rowId: number = 0;
  @Input() disableOption = false;
  @Input() zapActionsClass: NgClassType;
  @Input() icon: string = 'unity-icons-options';
  @Output() onActionClicked: EventEmitter<string> = new EventEmitter<string>();
  @ContentChild(MenuIconTemplateDirective, {
    read: TemplateRef,
  })
  iconTemplate: TemplateRef<any>;

  @ViewChild('actionTrigger') actionTrigger: any;
  @ViewChild('actionContainer', { static: true })
  private actionContainer: TemplatePortalDirective;
  private _overlayRef: OverlayRef;

  constructor(private _overlay: Overlay, private elementRef: ElementRef) {}

  openActionContainer() {
    this.closeActionContainer();
    let strategy = this._overlay
      .position()
      .flexibleConnectedTo(this.actionTrigger.elementRef)
      .withPositions(
        new Array({
          originX: 'start',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        })
      );

    let config = new OverlayConfig({ positionStrategy: strategy });
    this._overlayRef = this._overlay.create(config);
    this._overlayRef.attach(this.actionContainer);
  }

  closeActionContainer() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  @HostListener('document:scroll', ['$event'])
  onScrollClick(evt: KeyboardEvent) {
    // this.closeActionContainer();
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(evt: KeyboardEvent) {
    const modalClick = this.elementRef.nativeElement.getElementsByClassName(
      'actions-container'
    )[0];
    if (modalClick && !modalClick.contains(evt.target)) {
      this.closeActionContainer();
    }
  }
}
