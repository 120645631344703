import { Status } from '../http';
import { Currency, CurrencyAPIResponse } from './types';

export const toCurrency = (c: any): Currency => ({
  id: c.currency_id,
  code: c.currency_code,
  name: c.currency_name,
});

export const toCurrencyAPIResponse = (
  currencies: any
): CurrencyAPIResponse => ({
  status: Status.Success,
  data: currencies.map(toCurrency),
});
