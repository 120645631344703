<ng-container
  *ngIf="{
    appUserState: appUserState$ | async,
    appUserInfo: appUserInfo$ | async,
    hideTCModal: hideTCModal$ | async
  } as context"
>
  <div class="flex row h-screen container">
    <div *ngIf="context?.appUserInfo && !context.appUserInfo?.tnc_status && !context.hideTCModal">
      <zap-disclaimer-modal headerIcon="unity-icons-info-2" headerIconStyle="text-white bg-primary-1 rounded w-10 h-10 rounded-[50%] !text-xl" actionButtonLabel="Accept and Continue" disclaimerTitle="Terms and Conditions" (ctaFunction)="sendUserAcceptanceOfTermsAndConditions()"></zap-disclaimer-modal>
    </div>
    <ng-container *ngIf="!context.appUserState?.inProgress">
      <!-- TODO: add error handling here -->
      <app-navbar class="h-screen top-0 left-0"> </app-navbar>
    </ng-container>
    <div id="main-content" class="h-full flex-1 py-5 pr-5 min-w-0">
      <lib-message></lib-message>
      <div class="h-full">
        <router-outlet></router-outlet>
        <div class="h-full" *ngIf="routingFacade.isRouting$ | async">
          <zap-status [text]="'Preparing for you'" [type]="'progress'"></zap-status>
        </div>
      </div>
    </div>
    <ng-template #appAccessLoader>
      <div class="h-full w-full justify-center items-center">
        <zap-status [text]="context.appUserState?.error ? context.appUserState?.error : 'Loading'" [type]="context.appUserState?.error ? 'error' : 'progress'" (reload)="onRetryUserInit()" [hideReload]="false"></zap-status>
      </div>
    </ng-template>
  </div>
</ng-container>
