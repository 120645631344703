import { Component, Input, OnInit } from '@angular/core';
import { BaseControl } from '../../base';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'zap-text-area-control',
  templateUrl: './text-area-control.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class TextAreaControlComponent extends BaseControl implements OnInit {
  @Input() enableTextCopy = true;
  @Input() enableResize = false;
  @Input() rows = 3;
  @Input() cols;
  textAreaControl: AbstractControl;
  constructor(private parentContainer: ControlContainer) {
    super();
  }

  ngOnInit() {
    this.textAreaControl = this.parentContainer?.control?.get(this.controlKey);
    this.listenToControlChanges(this.textAreaControl);
  }
}
