import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateRangePickerOptionsService {
  public settings: any;

  constructor() {
    this.settings = {};
  }
}
