import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import {
  mainMenuItems,
  postDefaultRoutes,
  predefaultRoutes,
  reduceMenuItemsToRoutes,
} from './app.config';
import { DataAppRedirectService } from './services/data-app-redirect.service';

const routes = [
  ...predefaultRoutes,
  ...reduceMenuItemsToRoutes(mainMenuItems),
  ...postDefaultRoutes,
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [DataAppRedirectService],
  exports: [RouterModule],
})
export class AppRoutingModule {}
