import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFilterComponent } from './custom-filter.component';
import { CorePipesModule } from '@zeotap-ui/core';
import { CategoryFilterModule } from '../categoryFilter';
import { StatusModule } from '../status';
import { ZuiLibModule } from '@zeotap.infra/zui-lib';

@NgModule({
  declarations: [CustomFilterComponent],
  imports: [
    CommonModule,
    CorePipesModule,
    CategoryFilterModule,
    StatusModule,
    ZuiLibModule,
  ],
  exports: [CustomFilterComponent],
})
export class CustomFilterModule {}
