<mat-form-field *ngIf="{ hasDateError: hasDateError } as error" (click)="picker.open()" appearance="fill" class="range" [ngClass]="{ 'error-datepicker-border': error.hasDateError }" customToolTip [contentTemplate]="error.hasDateError ? validationTooltip : null" [value]="{ value: 'UU_DATE_ERROR_MSG' | translate }" [customTooltipClass]="'event-date-range-error'">
  <mat-label>{{ 'UU_SELECT_DATE_RANGE' | translate }}</mat-label>
  <mat-date-range-input [rangePicker]="picker" [min]="minStartDate" [max]="todayDate">
    <input matStartDate [(ngModel)]="startDate" readonly />
    <input matEndDate [(ngModel)]="endDate" readonly (dateChange)="onDateRangeChanged()" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker yPosition="'below'" [panelClass]="'range-picker'" #picker (opened)="onDateRangePickerOpened()"></mat-date-range-picker>
</mat-form-field>
<ng-template #validationTooltip let-value="value">
  <span class="icon error"></span>
  <span class="text-sm font-primary error-text">{{ value }}</span>
</ng-template>
