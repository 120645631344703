<div class="chart-title zui-gray4-text-color zui-mediumtext" *ngIf="options.title.enable">{{options.title.text}}</div>
<div class="legends" id="legends"  *ngIf="data && data.length && options && options['legend']">
  <span class="legendName" *ngFor="let item of data">{{item.name}}<span [ngStyle]="{'background': item.color}"></span>
  </span>
</div>
<div id="{{id}}" class="lineChart">
  <svg>
    <defs>
        <filter id="dropshadow_1" y="20%" height="120%" filterUnits="userSpaceOnUse"></filter>
        <filter id="dropshadow_2" y="-20%" height="120%" filterUnits="userSpaceOnUse"></filter>
    </defs>
  </svg>
</div>
<div id='tooltipLine'></div>
