import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  NumberFormatterPipe,
  isInteger,
  replaceWhiteSpaceWithHyphen,
} from '@zeotap-ui/core';
import { ChartReportWithSeriesData, ReportData } from './types/types';
import {
  getTotalsAcrossXAxis,
  hasValueGreaterThan0,
  isBarChart,
  isStackedChart,
  shouldDisplaySeriesReport,
} from './utils/utils';

type ChartType = 'bar' | 'stacked';

@Component({
  selector: 'zap-custom-vertical-bar-chart',
  templateUrl: './custom-vertical-bar-chart.component.html',
  styleUrls: ['./custom-vertical-bar-chart.component.scss'],
})
export class CustomVerticalBarChartComponent implements OnInit, OnChanges {
  @Input() chartType: ChartType = 'bar';
  @Input() view: number[];
  @Input() results: ChartReportWithSeriesData[] | ReportData[] = [];
  scheme;
  @Input() set colors(colors: string[]) {
    this.scheme = { domain: [...colors] };
  }
  @Input() showLegend: boolean = false;
  @Input() legendTitle: string;
  @Input() legendPosition: string; // right/below
  @Input() xAxis: boolean = true;
  @Input() yAxis: boolean = true;
  @Input() showGridLines: boolean = false;
  @Input() showXAxisLabel: boolean = false;
  @Input() showYAxisLabel: boolean = false;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() maxXAxisTickLength: number = 30; // default 16 - length of the label to be displayed
  @Input() maxYAxisTickLength: number; // default 16 - length of the label to be displayed
  @Input() showCustomLegend: boolean = true;
  @Input() showCustomLabels: boolean = true;
  @Input() noDataText: string = '';
  @Input() showLegendAtBottom: boolean;
  @Input() showCustomEmptyData = false;
  @Input() noDataImgSrc;
  shouldDisplayReport: boolean = true;
  @ViewChild('chart', { read: ElementRef, static: false }) chartRef: ElementRef;
  tooltipColors: any;
  @Output() select = new EventEmitter<any>();
  @Output() activate = new EventEmitter<any>();
  @Output() deactivate = new EventEmitter<any>();
  replaceWhiteSpaceWithHyphen = replaceWhiteSpaceWithHyphen;
  totalOfYItemAcrossXAxis: { [key: string]: number };
  isBarChart = isBarChart;
  isStackedChart = isStackedChart;
  barPadding = 50;
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.updateShouldDisplayReport();
    if (isStackedChart(this.chartType))
      this.totalOfYItemAcrossXAxis = getTotalsAcrossXAxis(this.results);
    this.tooltipColors = this.scheme.domain.reduce(
      (acc, curr, i) => ({
        ...acc,
        [isBarChart(this.chartType)
          ? this.results?.[i]?.name
          : (this.results[0] as ChartReportWithSeriesData).series[i]
              .name]: curr,
      }),
      {}
    );
  }
  ngOnChanges(changes) {
    if (changes.results) {
      this.updateShouldDisplayReport();
    }
  }
  updateShouldDisplayReport() {
    if (isStackedChart(this.chartType)) {
      this.shouldDisplayReport = shouldDisplaySeriesReport(this.results);
    } else {
      this.shouldDisplayReport = hasValueGreaterThan0(this.results);
    }
  }
  yAxisTickFormatting(value) {
    const numberFormatter = new NumberFormatterPipe();
    return isInteger(value) ? numberFormatter.transform(value) : '';
  }

  ngAfterViewChecked() {
    const chartElem = this.chartRef?.nativeElement;
    if (!chartElem) return;

    this.addAxesLines(chartElem);
    this.setBarPadding(chartElem);
  }

  getExcessWidth(chartElem, xAxisLength) {
    if (this.isStackedChart(this.chartType)) {
      return 50;
    }
    const bars = chartElem.querySelectorAll('.bar');
    const lastBarBBox = (<SVGGraphicsElement>bars[bars.length - 1])?.getBBox();
    const posFromLastBar = lastBarBBox?.x + lastBarBBox?.width;
    return xAxisLength - posFromLastBar;
  }

  setBarPadding(chartElem: HTMLElement) {
    const xAxisLength = (<SVGGraphicsElement>(
      chartElem.querySelector('.x')
    ))?.getBBox().width;
    const noOfBars = this.results.length;
    const barWidth = 40;
    const excessWidth = this.getExcessWidth(chartElem, xAxisLength);
    this.barPadding = Math.floor(
      (xAxisLength - barWidth * noOfBars - excessWidth) / (noOfBars - 1)
    );
  }

  hasAxesBorder(axis: Element) {
    return axis.querySelector('.axes-border');
  }

  addAxesLines(chartElem: HTMLElement) {
    const yAxis = chartElem.querySelector('[ngx-charts-y-axis-ticks]');
    const xAxis = chartElem.querySelector('[ngx-charts-x-axis-ticks]');

    if (!this.hasAxesBorder(yAxis))
      this.renderer.appendChild(yAxis, this.getAxisLine(chartElem, true));

    if (!this.hasAxesBorder(xAxis))
      this.renderer.appendChild(xAxis, this.getAxisLine(chartElem, false));
  }

  getAxisLine(chart: HTMLElement, isYAxis: boolean) {
    const axis = this.renderer.createElement('g', 'svg');
    const axisLine = this.renderer.createElement('line', 'svg');
    const yAxisLengthBeyondXAxis = isStackedChart(this.chartType) ? 35 : 42;
    const xAxisYCoordinate = '-4';
    const xAxisX1Coordinate = '0';
    const yAxisY1Coordinate = '-10';
    const yAxisXCoordinate = '4';
    const axisLength =
      (chart.querySelector('.ngx-charts') as SVGGraphicsElement)?.getBBox()?.[
        isYAxis ? 'height' : 'width'
      ] || 0;
    this.renderer.setAttribute(
      axisLine,
      'x1',
      isYAxis ? yAxisXCoordinate : xAxisX1Coordinate
    );
    this.renderer.setAttribute(
      axisLine,
      'x2',
      isYAxis ? yAxisXCoordinate : axisLength.toString()
    );
    this.renderer.setAttribute(
      axisLine,
      'y1',
      isYAxis ? yAxisY1Coordinate : xAxisYCoordinate
    );
    this.renderer.setAttribute(
      axisLine,
      'y2',
      isYAxis
        ? (axisLength - yAxisLengthBeyondXAxis).toString()
        : xAxisYCoordinate
    );
    this.renderer.addClass(axis, 'axes-border');
    this.renderer.appendChild(axis, axisLine);
    return axis;
  }

  onSelect(data): void {
    this.select.emit(data);
  }

  onActivate(data): void {
    this.activate.emit(data);
  }

  onDeactivate(data): void {
    this.deactivate.emit(data);
  }
}
