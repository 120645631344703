import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'zap-no-content-image-text',
  templateUrl: './no-content-image-text.component.html',
  styleUrls: ['./no-content-image-text.component.scss'],
})
export class NoContentImageTextComponent implements OnInit {
  @Input() text: string;
  @Input() subtext: string;
  @Input() showImage: boolean;
  constructor() {}

  ngOnInit(): void {}
}
