// implement a angular route guard with canLoad and canActivate

import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Data,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthFacadeService } from './auth-facade.service';
import { AuthService } from './auth.service';
import { filter, map } from 'rxjs/operators';
import { UserPreferenceFacade } from '../../preferences';
@Injectable()
export class ProductAccessAuthGuard
  implements CanActivate, CanLoad, CanActivateChild {
  authFacade = inject(AuthFacadeService);
  authService = inject(AuthService);
  router = inject(Router);
  userPreferenceService = inject(UserPreferenceFacade);
  productAccessGuard = (data: Data) => {
    const { product } = data;
    // making sure that this stream is unsubscribed at the end of the function
    return this.authFacade.state$.pipe(
      filter((state) => !state.yetToFetchUserData),
      map((_) => {
        let currentNavigationUrl = this.router
          .getCurrentNavigation()
          .finalUrl?.toString();
        if (product) {
          if (this.authService.hasProductAccess(product)) {
            this.userPreferenceService.updateUserPreference({
              lastActivatedPath: currentNavigationUrl,
              lastSelectedProduct: product,
            });
            return true;
          }
          return this.router.parseUrl('/unauthorized');
        }
        return true;
      })
    );
  };

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.productAccessGuard(route.data);
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.productAccessGuard(route.data);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.productAccessGuard(route.data);
  }
}
