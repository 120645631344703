import { NgModule } from '@angular/core';
import {
  AsyncConfigRuleEvaluatorPipe,
  ConfigRuleEvaluatorPipe,
} from './evaluator.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ConfigRuleEvaluatorPipe, AsyncConfigRuleEvaluatorPipe],
  imports: [CommonModule],
  exports: [ConfigRuleEvaluatorPipe, AsyncConfigRuleEvaluatorPipe],
})
export class EvaluatorModule {}
