import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[formControlName][zapFormValueTransformer]',
  providers: [],
})
export class FormValueTransformerDirective implements OnInit, OnDestroy {
  @Input() transformer = (x) => '';
  valueSubscription: Subscription;

  constructor(public ngControl: NgControl) {}
  ngOnDestroy(): void {
    this.valueSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.valueSubscription = this.ngControl.control.valueChanges.subscribe(
      (value) => {
        const newVal = this.transformer(value);
        this.ngControl.control.setValue(newVal, { emitEvent: false });
      }
    );
  }
}
