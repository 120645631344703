import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ConfigService } from '@zeotap-ui/config';
import {
  AuthFacadeService,
  UserInfo,
  UserService,
  checkForUserProductAccess,
} from '@zeotap-ui/core';
import { Observable } from 'rxjs';
import { mainMenuItems } from '../app.config';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RedirectNavigationGuard implements CanActivate {
  // setting up redirect for a path
  constructor(
    private configService: ConfigService,
    private userInfo: UserService,
    private authFacade: AuthFacadeService
  ) {}
  router = inject(Router);
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const { redirects = [] } = route.data;
    if (redirects) {
      return this.authFacade.state$.pipe(
        filter((state) => !state.inProgress),
        map((state) => {
          const { productInfo } = this.userInfo.getUserInfo();

          for (let redirect of redirects) {
            if (checkForUserProductAccess(redirect?.product)(productInfo))
              return this.router.parseUrl(redirect.to);
          }
        })
      );
    }
    return this.router.parseUrl('/unauthorized');
  }
}
