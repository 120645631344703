import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { reduce } from 'ramda';
import { FilterItem, FilterState } from './types';

@Component({
  selector: 'zap-multi-header-filter',
  templateUrl: './multi-header-filter.component.html',
})
export class MultiHeaderFilterComponent implements OnInit {
  @Input() filterItems: FilterItem[];
  @Input() currentFilterState: FilterState = {};
  @Input() loading: boolean;
  @Input() loadingText: string;
  @Input() error: string;
  @Input() normalHeader: boolean = false;
  @Input() count: boolean = true;
  @Output() filterSelectionChanged = new EventEmitter();
  @Output() reloadFilters = new EventEmitter();

  ngOnInit() {}

  onClickOfFilter(filterItem: FilterItem) {
    if (filterItem.isSelected(this.currentFilterState)) {
      this.currentFilterState = filterItem.clearFilter(this.currentFilterState);
    } else {
      this.currentFilterState = filterItem.applyFilter(this.currentFilterState);
    }
    this.filterSelectionChanged.emit(this.currentFilterState);
  }

  onClickClearAllFilterOfGroup(filterItem: FilterItem) {
    this.currentFilterState = filterItem.clearFilter(
      this.currentFilterState,
      filterItem
    );
    this.filterSelectionChanged.emit(this.currentFilterState);
  }

  onClickClearAllFilters() {
    this.currentFilterState = reduce(
      (acc, filterItem) => {
        return filterItem.isSelected(acc, filterItem)
          ? filterItem.clearFilter(acc, filterItem)
          : acc;
      },
      this.currentFilterState,
      this.filterItems
    );
    this.filterSelectionChanged.emit(this.currentFilterState);
  }

  onReloadFilters() {
    this.reloadFilters.emit();
  }
}
