import { Pipe, PipeTransform } from '@angular/core';
import { toLower, toString, trim, propOr, length } from 'ramda';

@Pipe({
  name: 'SimpleSearchFilter',
})
export class SimpleSearchFilterPipe implements PipeTransform {
  transform(items: string[], searchText: string): any[] {
    if (!items) {
      return [];
    } else {
      if (!searchText) {
        return items;
      } else {
        return items.filter((item) => {
          return toLower(trim(toString(item))).includes(
            toLower(trim(searchText))
          );
        });
      }
    }
  }
}
