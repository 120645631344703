<section class="z-10 h-screen w-screen fixed top-0 left-0 flex justify-center bg-primary-1-900/60">
  <section class="h-fit w-fit grid grid-rows-[6.4rem_1fr_3rem] gap-y-3 rounded-[1.25rem] bg-white p-6 relative top-[30%]">
    <section class="grid grid-rows-[3.125rem_1.5rem_0.1rem] gap-y-3 justify-center items-center">
      <div class="items-center flex flex-col">
        <span class="!text-[3.125rem] flex justify-center items-center" [ngClass]="[headerIcon, headerIconStyle]"></span>
      </div>
      <span class="font-secondary-bold text-2xl text-primary-1-900 m-3 flex justify-center">{{ disclaimerTitle }}</span>
      <span class="border-solid border-b border-grey-300/30 m-3 w-[437px]"></span>
    </section>
    <section class="pb-4">
      <div class="text-xs font-primary text-grey-700 text-center whitespace-pre-line mb-3">{{ 'We have recently updated our Terms and Conditions.\n Before you proceed, please read and accept the same.' }}</div>
      <div class="p-4 grid grid-cols-[0.2fr_2fr_0.2fr] gap-x-1 bg-primary-1-500/[5%] rounded-xl border border-grey-300/25 border-solid items-center">
        <div class="bg-primary-1-100/60 w-6 h-6 rounded-[50%] items-center justify-center flex">
          <span class="unity-icons-doc-file !text-xs !font-primary-bold text-primary-1-500 font-black"></span>
        </div>
        <span class="font-primary">Terms and Conditions Document</span>
        <div class="text-primary-1-500 text-xs flex items-center cursor-pointer" (click)="readButtonClicked()">
          <span class="!font-primary-bold">Read</span>
          <span class="unity-icons-export ml-1 !text-base relative -top-[1px]"></span>
        </div>
      </div>
    </section>
    <zap-button widthClass="w-full" [text]="actionButtonLabel" (click)="onCtaButtonClicked()"></zap-button>
  </section>
</section>
