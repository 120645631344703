import { Injectable, inject } from '@angular/core';
import { ConfigRuleEvaluator, ConfigService } from '@zeotap-ui/config';
import { Observable, of } from 'rxjs';
@Injectable()
export class PermissionEvaluator extends ConfigRuleEvaluator {
  configService = inject(ConfigService);

  evaluate(rule: string): boolean {
    let rbacConfig = this.configService.getFeatureValue('RBAC_IA', true);
    switch (rule) {
      case 'enableMyUsers':
        return rbacConfig?.enabled && rbacConfig.config?.enableUserModule;
      case 'enableMyOrganisations':
        return rbacConfig?.enabled && rbacConfig.config?.enableOrgModule;
      case 'enableSegment':
        return this.configService.getFeatureValue('ENABLE_SEGMENT', true)
          ?.enabled;
      case 'enableRequestAccessModule':
        return (
          rbacConfig?.enabled && rbacConfig.config?.enableRequestAccessModule
        );
      case 'enableAdmin':
        return (
          rbacConfig?.enabled &&
          (rbacConfig.config?.enableUserModule ||
            rbacConfig.config?.enableOrgModule ||
            rbacConfig.config?.enableRequestAccessModule)
        );
      case 'enableAnalytics':
        return rbacConfig?.enabled && rbacConfig.config?.enableAnalytics;
      case 'enable1PAnalytics':
        return rbacConfig?.enabled && rbacConfig.config?.enable1PAnalytics;
      case 'enableDataLifecycle':
        return rbacConfig?.enabled && rbacConfig.config?.enableDataLifecycle;
      default:
        return true;
    }
  }

  evaluateAsync(rule: string): Observable<boolean> {
    switch (rule) {
      default:
        return of(true);
    }
  }
}
