import { Status } from '../http';
import { isNotNullOrEmpty } from '../utils';
import { CountryAPIResponse, CountryModel } from './types';
import { find, propEq } from 'ramda';

export const toCountryData = (c: any): CountryModel => ({
  id: c.country_id,
  name: c.country_name,
  isoAlpha2: c.country_code,
  isoAlpha3: c.country_alpha3_code,
});

export const getIsoAlpha3 = (iso2: string) => {
  switch (iso2) {
    case 'AR':
      return 'ARG';
    case 'AT':
      return 'AUT';
    case 'BE':
      return 'BEL';
    case 'BR':
      return 'BRA';
    case 'CA':
      return 'CAN';
    case 'CL':
      return 'CHL';
    case 'CO':
      return 'COL';
    case 'DK':
      return 'DNK';
    case 'FI':
      return 'FIN';
    case 'FR':
      return 'FRA';
    case 'DE':
      return 'DEU';
    case 'IN':
      return 'IND';
    case 'IT':
      return 'ITA';
    case 'LU':
      return 'LUX';
    case 'MX':
      return 'MEX';
    case 'NO':
      return 'NOR';
    case 'PE':
      return 'PER';
    case 'PL':
      return 'POL';
    case 'ES':
      return 'ESP';
    case 'SE':
      return 'SWE';
    case 'CH':
      return 'CHE';
    case 'CL':
      return 'CHL';
    case 'UK':
      return 'GBR';
    case 'US':
      return 'USA';
  }
};

export const toAllCountryAPIResponse = (res: any): CountryAPIResponse => {
  if (!!res) {
    return {
      status: Status.Success,
      data: res.map(toCountryData),
    };
  }
};

// findCountryByISO3 :: string -> CountryModel[] -> CountryModel
export const findCountryByISO3 = (isoAlpha3: string) =>
  find(propEq('isoAlpha3', isoAlpha3));

export const getSelectedCountry = (
  savedCountryISO3: string,
  lastSelectedCountryISO3: string,
  userCountries: CountryModel[]
) => {
  const savedCountry: CountryModel = findCountryByISO3(savedCountryISO3)(
    userCountries
  );
  if (isNotNullOrEmpty(savedCountry)) {
    return savedCountry;
  } else {
    const lastSelectedCountry: CountryModel = findCountryByISO3(
      lastSelectedCountryISO3
    )(userCountries);
    if (isNotNullOrEmpty(lastSelectedCountry)) {
      return lastSelectedCountry;
    } else {
      return userCountries[0];
    }
  }
};
