import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DottedOptionsComponent } from './dotted-options/dotted-options.component'
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { CoreModule } from '@zeotap-ui/core';


@NgModule({
  declarations: [DottedOptionsComponent],
  imports: [
    CommonModule,
    PortalModule,
    OverlayModule,
    CoreModule,
  ],
  exports: [DottedOptionsComponent]
})
export class DottedOptionsModule { }
