import { Injectable } from '@angular/core';
import { pathOr, assocPath, indexBy, prop } from 'ramda';
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _prodInfo = null;
  private logoutStatus = false;

  constructor() {
    this.migrateToEncodedLocalStorage(['prod_info', 'user_info', 'org_info']);
  }

  public getItem(key: string, isEncoded = false) {
    return localStorage.getItem(key);
  }

  public setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  public getItemSession(key: string) {
    return sessionStorage.getItem(key);
  }

  public setItemSession(key: string, value: string) {
    return sessionStorage.setItem(key, value);
  }

  public removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  public setLocalState(
    value: object,
    path = [],
    stateKey: string = 'app.state'
  ) {
    let current = this.getJsonItem(stateKey);
    this.setJsonItem(stateKey, assocPath(path, value, current));
  }

  public getLocalState(path = [], stateKey: string = 'app.state') {
    return pathOr({}, path, this.getJsonItem(stateKey));
  }

  public getJsonItem(key: string, isEncoded = false) {
    if (isEncoded) {
      return this.decodeBase64ToJson(this.getItem(this.encodedKey(key)));
    }
    return JSON.parse(this.getItem(key) || 'null');
  }

  public setJsonItem(key: string, value: object, isEncoded = false) {
    if (isEncoded) {
      return this.setItem(this.encodedKey(key), this.encodeJsonToBase64(value));
    }
    return this.setItem(key, JSON.stringify(value));
  }

  public getPageState(appName: string, pageName: string) {
    const pageState = this.getJsonItem(appName);

    if (pageState && pageState[pageName]) {
      return pageState[pageName];
    }
    return null;
  }

  public clearPageState() {
    this.logoutStatus = true;
    localStorage.clear();
  }

  public setPageState(appName: string, pageName: string, state: any) {
    if (!this.logoutStatus) {
      const pageState = this.getJsonItem(appName) || {};
      pageState[pageName] = state;
      this.setJsonItem(appName, pageState);
    }
  }

  public getUserName() {
    const userInfo = this.getUserInfo();
    return (userInfo && userInfo.first_name) || '';
  }

  public getUserRole(productname) {
    const prodInfo = this.getProdInfo(productname.toLowerCase());
    if (prodInfo && prodInfo.details && prodInfo.details.length) {
      return prodInfo.details[0].group_info.group_name;
    }
  }

  public getUserId() {
    const userInfo = this.getUserInfo();
    return userInfo?.id;
  }

  encodedKey(key: string) {
    return btoa(key);
  }
  // checks if the input string is a base64 encoded string or not
  encodeJsonToBase64(json: any): string {
    try {
      const jsonString = encodeURIComponent(JSON.stringify(json));
      const base64String = btoa(jsonString);
      return base64String;
    } catch (e) {
      console.error('Error while encoding json data', e);
    }
    return null;
  }

  decodeBase64ToJson(base64String: string): object {
    try {
      if (!base64String) return null;
      const jsonString = atob(base64String);
      let utf8Decoded = decodeURIComponent(jsonString);
      const json = JSON.parse(utf8Decoded);
      return json;
    } catch (e) {
      console.error('Error while decoding base64 data', e);
    }
  }

  migrateToEncodedLocalStorage(storageKeys: string[]) {
    const keys = Object.keys(storageKeys);
    keys.forEach((key) => {
      const value = this.getJsonItem(key);
      if (value) {
        this.setJsonItem(key, value, true);
        this.removeItem(key);
      }
    });
  }

  public getUserInfo() {
    return this.getJsonItem('user_info', true);
  }

  public getProdInfo(name = '') {
    const prodInfo = this._prodInfo || this.getJsonItem('prod_info', true);

    this._prodInfo = prodInfo;
    if (prodInfo && name) {
      return Object.values(prodInfo).find(
        (pf) => pf['name'].toLowerCase() === name.toLowerCase()
      );
    }
    return prodInfo;
  }
  public getProdInfoByName() {
    const prodInfo = this._prodInfo || this.getJsonItem('prod_info', true);

    this._prodInfo = prodInfo;
    return indexBy(prop('name'), Object.values(prodInfo));
  }

  public getOrgInfo() {
    return this.getJsonItem('org_info', true);
  }
}
