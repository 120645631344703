import { Component, OnInit } from '@angular/core';
import { AuthService } from '@zeotap-ui/core';
import { SUPPORT_URL } from '../const';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent implements OnInit {
  supportUrl = SUPPORT_URL;
  constructor(private router: Router) {}
  ngOnInit(): void {}

  routeToHome() {
    this.router.navigate(['']);
  }
}
