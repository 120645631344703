import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IconTemplateDirective,
  KeyValueListInputComponent,
} from './key-value-list-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  CoreDirectivesModule,
  CorePipesModule,
  CustomTooltipModule,
} from '@zeotap-ui/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [KeyValueListInputComponent, IconTemplateDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    CustomTooltipModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    CoreDirectivesModule,
    CorePipesModule,
  ],
  exports: [KeyValueListInputComponent, IconTemplateDirective],
})
export class KeyValueListInputModule {}
