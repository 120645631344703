<ng-container *ngIf="{ value: groupErrorsByType(textAreaControl) } as error">
  <div class="relative">
    <textarea
      class="text-area-control rounded border border-solid border-grey-300 p-2.5 pr-9 outline-none resize-none cursor-text bg-transparent h-full w-full placeholder:text-grey/52 placeholder:text-sm transition-all ease-in-out shadow-[inset_0_1px_1px_rgba(0,0,0,0.075)] disabled:!bg-grey-100 disabled:border disabled:text-grey-700 disabled:!border-grey-300/90 focus:shadow-[inset_0_1px_1px_rgba(0,0,0,0.075),0_0_8px_rgba(102,175,233,0.6)] focus:outline-none focus:border-primary-1 truncate [&:disabled]:!cursor-not-allowed break-all text-wrap"
      [placeholder]="placeholderText"
      [formControlName]="controlKey"
      [ngClass]="{ '!border-state-error-700': error.value?.highlight?.length }"
      customToolTip
      [contentTemplate]="error.value?.highlight?.length ? errors : null"
      [value]="{ errors: error.value?.highlight, errorTextColor: 'primary' }"
      [customTooltipClass]="'!p-[.625rem] !w-max [&_.simpleText]:!text-xs !rounded'"
      [positions]="tooltipPositions"
      [rows]="rows"
      [cols]="cols"
    ></textarea>
    <div class="bg-grey-100 w-6 h-6 rounded flex flex-col align-middle justify-center absolute right-3 top-3">
      <zap-clipboard-copy class="copy font-primary text-xs m-3 relative top-[10px] right-[10px]" [clipboardText]="'Copy'" [clipboardCopiedText]="'Copied'" [clipboardContent]="'ddd'"></zap-clipboard-copy>
      <span class="text-xxs font-secondary">{{ 'COPY' }}</span>
    </div>
  </div>
  <div class="mt-1" *ngIf="error.value?.message?.length">
    <ng-container *ngTemplateOutlet="errors; context: { errors: error.value?.message, errorTextColor: 'secondary' }"></ng-container>
  </div>
</ng-container>
<ng-template #errors let-errors="errors" let-errorTextColor="errorTextColor">
  <zap-control-errors [errors]="errors" [errorTextColor]="errorTextColor"></zap-control-errors>
</ng-template>
