<div class="bar-chart-container">
    <svg height="100%" width="100%">
    <g class="bar-container">
        <text class="chartTitle"></text>
        <g class="y axis">
            <text class="yLabel"></text>
        </g>
        <g class="x axis">
            <text class="xLabel"></text>
        </g>
        <g class="legend"></g>
    </g>
</svg>
</div>