import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimerModalComponent } from './disclaimer-modal.component';
import { ZuiLibModule } from '@zeotap.infra/zui-lib';
import { ZeotapButtonModule } from '../button';

@NgModule({
  declarations: [DisclaimerModalComponent],
  imports: [CommonModule, ZuiLibModule, ZeotapButtonModule],
  exports: [DisclaimerModalComponent],
})
export class DisclaimerModalModule {}
