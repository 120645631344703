import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CorePipesModule } from '@zeotap-ui/core';

import { ActionsOverlayComponent } from './actions-overlay.component';

@NgModule({
  imports: [CommonModule, OverlayModule, CorePipesModule],
  exports: [ActionsOverlayComponent],
  declarations: [ActionsOverlayComponent],
  providers: [],
})
export class ActionsOverlayModule {}
