import {
  compose,
  equals,
  not,
  __,
  both,
  is,
  complement,
  defaultTo,
} from 'ramda';
import { removeCommas } from './stringUtils';

export const isInteger = (x) => Number.isInteger(x);

// isValidNumber :: number -> boolean
export const isValidNumber = both(is(Number), complement(equals(NaN)));

export const toNumber = (x) => Number(x);

// parseNumber :: string -> number
export const parseNumber = compose(toNumber, removeCommas);

export const isFloat = compose(not, isInteger, parseNumber);

export const isPercentage = (val: number): boolean => {
  return isValidNumber(val) && val >= 0 && val <= 100;
};

export const getPercentage = (partValue: number, wholeValue: number) => {
  if (wholeValue && wholeValue > 0 && partValue) {
    return parseFloat(((partValue / wholeValue) * 100).toFixed(2));
  }
  return 0;
};

export const getPercentageValue = (partValue: number, wholeValue: number) => {
  return getPercentage(partValue, wholeValue) + '%';
};

export const defaultToMinusOne = defaultTo(-1);
export const defaultToZero = defaultTo(0);
