import { CountryState } from './../types';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  tap,
  distinctUntilChanged,
  share,
  shareReplay,
} from 'rxjs/operators';
import { isError, isSuccess } from '../../http';
import { CountryService } from './country.service';
import { CountryAPIResponse, CountryModel } from '../types';

let _state: CountryState = {
  loading: false,
  countries: [],
  errors: null,
};
@Injectable({
  providedIn: 'root',
})
export class CountryFacadeService {
  private store$ = new BehaviorSubject<CountryState>(_state);
  private state$ = this.store$.asObservable();

  loading$ = this.state$.pipe(
    map((state) => state.loading),
    distinctUntilChanged(),
    share()
  );
  errors$ = this.state$.pipe(
    map((state) => state.errors),
    distinctUntilChanged(),
    share()
  );
  countries$ = this.state$.pipe(
    map((state) => state.countries),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(private countryService: CountryService) {
    this.loading$
      .pipe(
        filter((loading) => !!loading),
        switchMap(() => this.countryService.getAllCountries()),
        tap((res: CountryAPIResponse) => {
          if (isError(res?.status)) this.onLoadCountriesError(res?.error);
        }),
        filter((res: CountryAPIResponse) => isSuccess(res.status)),
        tap((res: CountryAPIResponse) => this.onLoadCountriesSuccess(res.data))
      )
      .subscribe();
  }
  loadCountries() {
    this.store$.next((_state = { countries: [], loading: true, errors: null }));
  }
  private onLoadCountriesError(errors) {
    this.store$.next((_state = { ..._state, errors, loading: false }));
  }
  private onLoadCountriesSuccess(countries: CountryModel[]) {
    this.store$.next(
      (_state = {
        ..._state,
        countries,
        errors: null,
        loading: false,
      })
    );
  }
  getCountryIdByIsoAlpha3(isoAlpha3: string) {
    return this.countries$.pipe(
      map(
        (countries: CountryModel[]) =>
          countries.find((c) => c.isoAlpha3 === isoAlpha3).id
      )
    );
  }
}
