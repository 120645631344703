import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@zeotap-ui/core';
import { DialogPromptComponent } from './dialog-prompt-legacy/dialog-prompt-legacy.component';



@NgModule({
  declarations: [DialogPromptComponent],
  imports: [
    CommonModule,
    CoreModule,
  ],
  exports: [DialogPromptComponent]
})
export class DialogPromptLegacyModule { }
