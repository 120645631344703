import { Input, OnChanges, forwardRef, Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ValidatorFn } from './interfaces';

export function getControlValueAccessor(component) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component), // tslint:disable-line:no-forward-ref
    multi: true
  };
}

@Directive()
export class BaseControl implements ControlValueAccessor, OnChanges {
  @Input() public disabled = false;
  @Input() public validators: { [key: string]: ValidatorFn } = {};
  @Input() public errorMessages: { [key: string]: string } = {};
  @Input() public id = `zui-${Date.now().toString() +
    performance
      .now()
      .toString()
      .split('.')
      .join('')}`;

  // User interaction flags
  public hasTouched = false;
  public get hasValue() {
    return (
      this.innerValue !== undefined &&
      this.innerValue !== null &&
      this.innerValue !== ''
    );
  }

  public defaultErrorMessages = {
    required: 'This field is required',
    email: 'Not a valid email address',
    min: 'Enter a bigger number',
    max: 'Enter a smaller number'
  };
  public errors: string[] = [];

  // The internal data model
  public innerValue: any = '';
  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  public onTouchedCallback: Function;
  public onChangeCallback: Function;

  public ngOnChanges() {
    this.refreshValidators();
  }

  public refreshValidators() {
    this.validators = this.validators || {};
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      if (this.onChangeCallback) {
        this.onChangeCallback(v);
      }
      this.checkForErrors();
    }
  }

  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = () => {
      this.hasTouched = true;
      fn();
    };
  }

  public checkForErrors() {
    this.errors.length = 0;

    Object.keys(this.validators).forEach(key => {
      if (!this.validators[key](this.value)) {
        this.errors.push(
          this.errorMessages[key] || this.defaultErrorMessages[key]
        );
      }
    });
  }
}
