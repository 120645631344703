<div class="actions-container inline-block" cdkOverlayOrigin #columnFilterOption="cdkOverlayOrigin" (click)="openActionContainer()">
  <ng-content select=".column-filter"></ng-content>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="columnFilterOption" [cdkConnectedOverlayOpen]="showOptionList" [cdkConnectedOverlayPositions]="positions">
  <div class="actions bg-white cursor-pointer z-10 rounded h-auto max-w-[12rem] w-[10.5625rem] shadow-5" (mouseleave)="closeOnLeave && closeActionContainer()">
    <ng-container *ngFor="let action of actions; let i = index">
      <div *ngIf="action.show" class="font-primary text-sm text-grey-900 custom-filter-action action hover:bg-grey-50 hover:border hover:border-solid hover:border-grey-300/30 [&:first-child]:mt-[0.3125rem] [&:last-child]:mb-[0.3125rem]" [ngClass]="{ 'disabled pointer-events-none bg-grey-100 hover:bg-none': action.disabled }" (click)="actionClicked(action)">
        <span class="link flex text-[0.8125rem] leading-4 text-primary-1-900 gap-1 items-center px-2.5 py-[0.3125rem]" [ngClass]="action.class">{{ action.name | translate | titlecase }}</span>
      </div>
    </ng-container>
  </div>
</ng-template>
