import { Component, Input, Output, EventEmitter } from '@angular/core';
import type { NgClassType } from '@zeotap-ui/core';

@Component({
  selector: 'zap-dialog-prompt-legacy',
  templateUrl: './dialog-prompt-legacy.component.html',
  styleUrls: ['./dialog-prompt-legacy.component.scss'],
})
export class DialogPromptComponent {
  @Input() promptText = '';
  @Input() promptVal1;
  @Input() promptVal2;
  @Input() actionName;
  @Input() subHeader: string;
  @Input() cancelActionName;
  @Input() showModal: boolean;
  @Input() warning = false;
  @Input() success = false;
  @Output() hideModal = new EventEmitter();
  @Input() zapIconClass: NgClassType;
  @Input() zapActionBtnClass: NgClassType;

  hidden(status) {
    this.hideModal.emit(status);
  }
}
