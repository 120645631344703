<div class="custom-chart chart-container">
  <div class="chart" *ngIf="hasData(); else noReport">
    <ngx-charts-pie-chart [view]="view" [scheme]="scheme" [animations]="animations" [results]="results" [legend]="showLegend" [legendTitle]="legendTitle" [labelFormatting]="labelFormatting" [maxLabelLength]="maxLabelLength" [legendPosition]="legendPosition" [labels]="showLabels" [doughnut]="doughnut" [tooltipDisabled]="tooltipDisabled" [tooltipText]="tooltipText" [arcWidth]="arcWidth" (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
      <ng-template #tooltipTemplate let-model="model">
        <div [ngStyle]="{ color: tooltipColors[model.label] }">{{ model.value | numberFormatter }} {{ model.label }}</div>
      </ng-template>
    </ngx-charts-pie-chart>
  </div>
  <div class="chart-info">
    <ng-container *ngIf="showCustomLegend">
      <div class="chart-items">
        <div *ngFor="let item of results; let i = index" class="font-primary text-sm text-grey-900 item">
          <span class="bullet" [ngStyle]="{ background: scheme.domain[i] }"></span>
          <div class="itemName text-sm text-grey-500">{{ item.name }}</div>
          <div class="font-primary-medium">{{ item.value | numberFormatter }}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngTemplateOutlet="chartTemplate"></ng-container>
  </div>
  <ng-template #noReport>
    <ng-container *ngIf="showCustomEmptyData">
      <div class="custom-no-data">
        <img class="custom-no-data-img" src="{{ noDataImgSrc }}" />
        <div class="custom-no-data-text text-base text-grey-400 font-primary">{{ noDataText }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="!showCustomEmptyData">
      <img class="report-placeholder" src="assets/img/piechart.svg" />
    </ng-container>
  </ng-template>
</div>
