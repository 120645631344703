<div class="collapse-section p-2">
  <div class="collapse-container border border-solid border-grey-300/30 w-full rounded">
    <div class="collapse-header-box grid grid-cols-[2rem_auto] rounded items-baseline p-4 bg-white border-b border-solid border-grey-300/30">
      <div>
        <zap-collapse-button class="group larger self-center" *ngIf="showCollapse" [open]="openSection" [closeDirection]="'right'" [openDirection]="'down'" (openChange)="toggleSectionState($event)"></zap-collapse-button>
      </div>
      <div class="header-content flex flex-row items-center justify-between">
        <span *ngIf="showIcon && headerIcon" class="icon" [ngClass]="headerIcon"></span>
        <img *ngIf="headerImageUrl" class="img-content w-[1.2rem] self-center rounded mx-[0.4rem] my-auto" src="{{ headerImageUrl }}" />
        <span class="header-label !font-primary text-sm text-grey-800 font-extrabold" *ngIf="!subtext">{{ header }}</span>
        <div class="header-subtext flex flex-col gap-y-1.5" *ngIf="subtext">
          <div>
            <span class="header-label !font-primary text-sm text-grey-800 font-extrabold">{{ header }}</span>
            <span class="font-secondary-medium icon-text inline-flex items-center ml-4 pl-[0.1875rem] pr-2 pt-[0.1875rem] pb-px text-xs border border-solid border-grey-300/30 bg-grey-50 rounded text-grey/52" *ngIf="customIcon || customIconText" customToolTip [text]="tooltipText" [customTooltipClass]="'!w-40 !p-2'">
              <span *ngIf="customIcon" [class]="'icon ' + customIcon"></span>
              <span *ngIf="customIconText">{{ customIconText }}</span>
            </span>
          </div>
          <div class="text-primary-1-900 font-primary text-sm subtext" [innerHTML]="subtext"></div>
        </div>
        <div class="actions-container flex">
          <div *ngIf="!!actionText" (click)="actionClicked.emit()" class="text-base font-secondary-medium text-grey/52 edit-btn h-10 inline-flex items-center cursor-pointer px-[0.8125rem] py-2.5 bg-primary-1/10 text-primary-1 rounded" [ngClass]="{ 'action-disabled !cursor-not-allowed !pointer-events-none !bg-grey-100 !text-grey/52 ': actionDisabled }">{{ actionText }}</div>
          <div *ngIf="!!enableDelete" (click)="deleteClicked.emit()" class="icon delete delete-rule-icon cursor-pointer w-5 h-5 text-xl self-center ml-4 text-state-error-700 rounded bg-state-error-700/10"></div>
        </div>
      </div>
    </div>
    <div *ngIf="openSection" class="collapse-body-section bg-white">
      <ng-content></ng-content>
    </div>
  </div>
</div>
