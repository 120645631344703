import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { checkExtensions } from '@zeotap-ui/core';

interface FileItem {
  file: File;
  sizeError?: string;
  typeError?: string;
}
@Component({
  selector: 'zap-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnDestroy {
  @Input() acceptedMIMEtypes: string[] = [];
  @Input() acceptedExtensions: string[] = [];
  @Input() maxSize: number;
  @Input() fileUploadStatus: boolean;
  @Input() uploadInstruction: string;
  @Input() disableUploadBtn: boolean;
  @Input() uploadBottonPosition = '';
  @Output() uploadFile: EventEmitter<FormData> = new EventEmitter<FormData>();
  @ViewChild('fileInput') fileInput: ElementRef;

  accept: string = '';
  protected fileItems$ = new BehaviorSubject<FileItem[]>([]);
  protected allowUpload$: Observable<boolean>;
  private maxSizeMB: number;
  private extensionErrorMessage: string;
  private onFileUploadClicked$ = new Subject();
  private unsubscribe$ = new Subject();
  constructor() {
    this.allowUpload$ = this.fileItems$.pipe(
      map((fileItems) => {
        return fileItems.some((v) => !v.sizeError && !v.typeError);
      })
    );
    this.onFileUploadClicked$
      .pipe(withLatestFrom(this.fileItems$), takeUntil(this.unsubscribe$))
      .subscribe(([_, fileItems]) => {
        const sendFormData: FormData = new FormData();
        sendFormData.append(
          'filename',
          fileItems[0].file,
          fileItems[0].file.name
        );
        this.uploadFile.emit(sendFormData);
      });
  }
  fileChange(event) {
    if (this.disableUploadBtn) {
      event.stopPropagation();
      return;
    }
    var files = event.target.files;
    var fileItems = [],
      file;
    for (var i = 0; i < files.length; i++) {
      file = files[i];
      fileItems.push({
        file: file,
        sizeError:
          file.size > this.maxSize
            ? `The File Size should be less than ${this.maxSizeMB} MB`
            : null,
        typeError:
          this.acceptedExtensions &&
          this.acceptedExtensions.length > 0 &&
          !checkExtensions(this.acceptedExtensions)(file.name)
            ? this.extensionErrorMessage
            : null,
      });
    }
    this.fileItems$.next(fileItems);
  }
  deleteFileList() {
    this.fileItems$.next([]);
    if (
      this.fileInput &&
      this.fileInput.nativeElement &&
      this.fileInput.nativeElement.value
    ) {
      this.fileInput.nativeElement.value = '';
    }
  }
  handleFileUpload() {
    this.onFileUploadClicked$.next(null);
  }

  clearFiles() {
    this.fileItems$.next([]);
  }

  ngOnInit() {
    this.accept = [
      ...this.acceptedMIMEtypes,
      ...this.acceptedExtensions.map((v) => `.${v}`),
    ].join(',');
    this.maxSizeMB = +(this.maxSize / (1024 * 1024)).toFixed(2);
    this.extensionErrorMessage = this.acceptedExtensions
      ? `Invalid extension. Allowed Extensions are ${this.acceptedExtensions.join(
          ','
        )}`
      : null;
  }
  ngOnDestroy() {
    this.unsubscribe$.next(null);
  }
}
