<div class="stage-tracker-container">
  <div class="flex">
    <div *ngFor="let stage of stages; let i = index; let l = last">
      <div data-testid="stage" class="flex">
        <zap-stage [name]="stage" [index]="i + 1" [active]="currentStage === i + 1"></zap-stage>
        <span *ngIf="!l" data-testid="stage-divider" class="icon down-triangle stage-divider text-4xs text-primary-1 ml-1 mr-2.5 my-auto before:rotate-z-270"></span>
      </div>
    </div>
  </div>
</div>
