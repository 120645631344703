export enum EventsUsagePeriod {
  Monthly = 'months',
  Weekly = 'weeks',
}

export const AVAILABLE_EVENTS_PERIODICAL = [
  {
    name: 'Monthly',
    value: EventsUsagePeriod.Monthly,
  },
  {
    name: 'Weekly',
    value: EventsUsagePeriod.Weekly,
  },
];

export const EVENT_DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMATS = {
  parse: {
    dateInput: EVENT_DATE_FORMAT,
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
  },
};
