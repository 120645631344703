import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  ElementRef,
} from '@angular/core';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';

const defaultOptions = {
  toolTipFormatter: (d) => {
    return `${d.properties.name}`;
  },
  regionFormatter: function (d, i) {
    d3.select(this).style('fill', '#FF6496');
  },
  zoomStep: 0.2,
};

const getTip = () =>
  d3Tip().attr(
    'class',
    'd3-tip map-tooltip tooltip zui-content zui-bgcolor-white'
  );

@Component({
  selector: 'zui-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public data: any = {};
  @Input() public options: any;
  public bounds = [
    [0, 0],
    [1, 1],
  ];
  public fitTransform: any;
  public projection: any;
  public path: any;
  public svg: any;
  public zoom: any;

  constructor(public eleRef: ElementRef) {
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.resetTransform = this.resetTransform.bind(this);
    this.refresh = this.refresh.bind(this);
    this.zoomed = this.zoomed.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);

    this.projection = d3.geoEquirectangular().precision(1);
    this.path = d3.geoPath().projection(this.projection);
    this.svg = d3.select(this.eleRef.nativeElement);
    this.zoom = d3.zoom().scaleExtent([0.2, 30]).on('zoom', this.zoomed);

    this.zoom = this.zoom.bind(this);
  }

  public ngOnInit() {
    this.svg = d3.select(this.eleRef.nativeElement).select('svg');
    this.svg
      .attr('width', '100%')
      .attr('height', '100%')
      .select('.event-group')
      .call(this.zoom)
      .on('wheel.zoom', null)
      .select('.map-group')
      .call(getTip());

    this.svg.selectAll('.zoom-in').on('click', this.zoomIn);
    this.svg.selectAll('.zoom-out').on('click', this.zoomOut);
    this.svg.selectAll('.reset').on('click', this.resetTransform);

    d3.select(window).on('resize', this.refresh);

    this.refresh();
  }

  public ngOnChanges() {
    if (!this.data || !this.data.features) {
      this.data = this.data || {};
      this.data.features = [];
      this.bounds = [
        [0, 0],
        [1, 1],
      ];
    } else {
      this.bounds = this.path.bounds(this.data);
    }

    this.options = Object.assign({}, defaultOptions, this.options);
    getTip().html(this.options.toolTipFormatter);

    this.refresh();
  }

  public ngOnDestroy() {
    d3.select(window).on('resize', null);
  }

  public zoomed() {
    getTip().hide();
    this._setMapTransform(d3.event.transform);
  }

  public zoomIn() {
    const node = this.svg.node();
    let transform = this.svg.select('.event-group').node().__zoom;
    const oldScale = transform.k;
    transform = transform.scale(
      Math.min(
        (transform.k + this.options.zoomStep) / transform.k,
        30 / transform.k
      )
    );
    const diffScale = transform.k - oldScale;
    transform.x =
      transform.x - ((this.bounds[1][0] + this.bounds[0][0]) * diffScale) / 2;
    transform.y =
      transform.y - ((this.bounds[1][1] + this.bounds[0][1]) * diffScale) / 2;
    this._setMapTransform(transform);
  }

  public zoomOut() {
    const node = this.svg.node();
    let transform = this.svg.select('.event-group').node().__zoom;
    const oldScale = transform.k;
    transform = transform.scale(
      Math.max(
        (transform.k - this.options.zoomStep) / transform.k,
        0.2 / transform.k
      )
    );
    const diffScale = oldScale - transform.k;
    transform.x =
      transform.x + ((this.bounds[1][0] + this.bounds[0][0]) * diffScale) / 2;
    transform.y =
      transform.y + ((this.bounds[1][1] + this.bounds[0][1]) * diffScale) / 2;
    this._setMapTransform(transform);
  }

  public resetTransform() {
    const transform = d3.zoomIdentity.translate(0, 0);
    transform.k = 1;
    this._setMapTransform(transform);
  }

  public toggleTooltip(data) {
    const tip = getTip();

    const opacity = tip.style('opacity');
    if (opacity == '0' || tip.__data !== data) {
      tip.show(data);
      tip.__data = data;
    } else {
      tip.hide();
    }
  }

  public showToolTip(data) {
    const tip = getTip();

    tip.show(data);
    tip.__data = data;
  }

  public moveToolTip(data) {
    const tip = getTip();

    const tipDom = <HTMLElement>document.querySelector('.d3-tip.map-tooltip');
    tip.style('left', d3.event.pageX - tipDom.clientWidth / 2 + 'px');
    tip.style('top', d3.event.pageY - tipDom.clientHeight - 10 + 'px');
  }

  public hideToolTip() {
    const tip = getTip();

    tip.hide();
  }

  public renderRegions(selection, componentContext) {
    selection
      .enter()
      .append('path')
      .classed('region', true)
      .on('click', componentContext.toggleTooltip)
      .on('mouseenter', componentContext.showToolTip)
      .on('mousemove', componentContext.moveToolTip)
      .on('mouseout', componentContext.hideToolTip)
      .merge(selection)
      .attr('d', componentContext.path)
      .each(componentContext.options.regionFormatter);

    selection.exit().remove();
  }

  public refresh() {
    const width = parseInt(this.svg.style('width'), 10) || 100;
    const height = parseInt(this.svg.style('height'), 10) || 100;
    const mapWidth = parseInt(
      (this.bounds[1][0] - this.bounds[0][0]).toFixed(0),
      10
    );
    const mapHeight = parseInt(
      (this.bounds[1][1] - this.bounds[0][1]).toFixed(0),
      10
    );
    const scaleWidth = width / mapWidth;
    const scaleHeight = height / mapHeight;
    const scale = Math.min(scaleWidth, scaleHeight) * 0.8;
    // tslint:disable-next-line:max-line-length
    const transform = d3.zoomIdentity
      .translate(
        (width - scale * mapWidth) / 2 - this.bounds[0][0] * scale,
        (height - scale * mapHeight) / 2 - this.bounds[0][1] * scale
      )
      .scale(scale);
    this.fitTransform = transform;
    this.svg.select('.event-group').attr('transform', transform);

    const regions = this.svg
      .select('.map-group')
      .selectAll('.region')
      .data(this.data.features)
      .call(this.renderRegions, this);
  }

  private _coerceInRange(value, range) {
    return value < range[0] ? range[0] : value > range[1] ? range[1] : value;
  }

  private _setMapTransform(transform) {
    const dimensions = this.svg.node().getBoundingClientRect();
    const width = dimensions.width;
    const height = dimensions.height;
    const s = transform.k;
    const widthRange = [0, 0];
    const heightRange = [0, 0];

    widthRange[s >= 1 ? 0 : 1] = width - width * s;
    heightRange[s >= 1 ? 0 : 1] = height - height * s;

    transform.x = this._coerceInRange(transform.x, widthRange);
    transform.y = this._coerceInRange(transform.y, heightRange);

    this.svg.select('.event-group').property('__zoom', transform);
    this.svg.select('.map-group').attr('transform', transform);
  }
}
