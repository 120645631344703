import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  ChartsModule,
  DateFiltersModule,
  StatusModule,
} from '@zeotap-ui/components';
import {
  CorePipesModule,
  CustomTooltipModule,
  LocaleService,
  OrgModule,
} from '@zeotap-ui/core';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { UsageDashboardComponent } from './dashboard';
import { LOCALE_MAP } from './usage.const';

@NgModule({
  imports: [
    CommonModule,
    OrgModule,
    ChartsModule,
    StatusModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    CorePipesModule,
    NgSelectModule,
    CustomTooltipModule,
    DateFiltersModule,
    MatSlideToggleModule,
  ],
  declarations: [UsageDashboardComponent, DateRangeComponent],
  exports: [UsageDashboardComponent],
})
export class UsageModule {
  constructor(public localeService: LocaleService) {
    this.localeService.setLocaleData(LOCALE_MAP);
  }
}
