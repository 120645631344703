import { CountryState } from './../types';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  tap,
  distinctUntilChanged,
  share,
  shareReplay,
  withLatestFrom,
  takeUntil,
} from 'rxjs/operators';
import { isSuccess } from '../../http';
import { CountryService } from './country.service';
import { CountryAPIResponse, CountryModel } from '../types';
import { SelectedOrgFacadeService } from '../../org';
import { ProductId, SelectedProductsToken } from '../../product';

let _state: CountryState = {
  loading: false,
  countries: [],
  errors: null,
};

@Injectable({
  providedIn: 'any',
})
export class ProductAndOrgSpecificCountriesFacade implements OnDestroy {
  private store$ = new BehaviorSubject<CountryState>(_state);
  private state$ = this.store$.asObservable();
  private loadProductAndOrgSpecificCountries$ = new Subject();
  private unsubscribe$ = new Subject();

  loading$ = this.state$.pipe(
    map((state) => state.loading),
    distinctUntilChanged(),
    share()
  );
  errors$ = this.state$.pipe(
    map((state) => state.errors),
    distinctUntilChanged(),
    share()
  );
  countries$ = this.state$.pipe(
    map((state) => state.countries),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private countryService: CountryService,
    private selectedOrgFacade: SelectedOrgFacadeService,
    @Inject(SelectedProductsToken)
    private selectedProductsToken: ProductId[]
  ) {
    this.loadProductAndOrgSpecificCountries$
      .pipe(
        tap((_) => this.resetState()),
        withLatestFrom(this.selectedOrgFacade.selectedOrg$),
        switchMap(([_, org]) =>
          this.countryService.getProductAndOrgSpecificCountries({
            org_id: org.id,
            product_id: this.selectedProductsToken?.[0],
          })
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res: CountryAPIResponse) => {
        if (isSuccess(res.status)) {
          this.onLoadCountriesSuccess(res.data);
        } else {
          this.onLoadCountriesError(res.error);
        }
      });
  }

  resetState() {
    this.store$.next((_state = { countries: [], loading: true, errors: null }));
  }

  loadProductAndOrgSpecificCountries() {
    this.loadProductAndOrgSpecificCountries$.next(null);
  }
  private onLoadCountriesError(errors) {
    this.store$.next((_state = { ..._state, errors, loading: false }));
  }
  private onLoadCountriesSuccess(countries: CountryModel[]) {
    this.store$.next(
      (_state = {
        ..._state,
        countries,
        errors: null,
        loading: false,
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
  }
}
