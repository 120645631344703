import { AsyncCallResponse } from '../http';

export type CountryAPIResponse = AsyncCallResponse<CountryModel[]>;

export type CountryModel = {
  id: number;
  name: string;
  isoAlpha2: string;
  isoAlpha3: string;
  region?: string;
};

export type CountryState = {
  loading: boolean;
  countries: CountryModel[];
  errors?: string;
};
