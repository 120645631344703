export type AccordianMenuOptions = {
  menuChildDisplayName: string;
  menuChildName: string;
  menuParentDisplayName: string;
  menuParentName: string;
  menuChildType?: MenuChildType;
};

export type MenuChildType = 'radio' | 'default';

export type AdditionalInformationOptions = {
  additionalInformationHeader: string;
  additionalInformationBody: string;
  additionalInformationLink: string;
};
