<div class="custom-chart bar-chart-container" [ngClass]="{ 'column-view': showLegendAtBottom }" *ngIf="shouldDisplayReport; else noReport">
  <ng-container *ngIf="isBarChart(chartType)">
    <div class="text-sm font-primary text-grey-900 chart">
      <ng-container *ngIf="showCustomLabels">
        <div class="x-label">{{ xAxisLabel }}</div>
        <div class="y-label">{{ yAxisLabel }}</div>
      </ng-container>
      <ngx-charts-bar-vertical
        class="custom-bar-chart charts-tooltip"
        [view]="view"
        [results]="results"
        [scheme]="scheme"
        [animations]="false"
        [legend]="showLegend"
        [legendTitle]="legendTitle"
        [legendPosition]="legendPosition"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [barPadding]="barPadding"
        [xAxisLabel]="xAxisLabel"
        [maxXAxisTickLength]="maxXAxisTickLength"
        [maxYAxisTickLength]="maxYAxisTickLength"
        [yAxisLabel]="yAxisLabel"
        [showGridLines]="showGridLines"
        [yAxisTickFormatting]="yAxisTickFormatting"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
        #chart
      >
        <ng-template #tooltipTemplate let-model="model">
          <div [ngStyle]="{ color: tooltipColors[model.label] }">{{ model.value | numberFormatter }} {{ model.label }}</div>
        </ng-template>
      </ngx-charts-bar-vertical>
    </div>
    <div class="chart-info">
      <ng-container *ngIf="showCustomLegend">
        <div class="chart-items">
          <div *ngFor="let item of results; let i = index" class="item-legend text-2xl">
            <span class="item-color" [ngStyle]="{ background: scheme.domain[i] }"></span>
            <span class="text-xs font-primary-medium">{{ item?.name }}</span>
            <span class="font-primary-medium">{{ item?.value | numberFormatter }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="isStackedChart(chartType)">
    <div class="text-sm font-primary text-grey-900 chart">
      <ng-container *ngIf="showCustomLabels">
        <div class="x-label">{{ xAxisLabel }}</div>
        <div class="y-label">{{ yAxisLabel }}</div>
      </ng-container>
      <ngx-charts-bar-vertical-stacked
        class="custom-line-chart"
        [view]="view"
        [results]="results"
        [scheme]="scheme"
        [animations]="false"
        [legend]="showLegend"
        [legendTitle]="legendTitle"
        [legendPosition]="legendPosition"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [barPadding]="barPadding"
        [xAxisLabel]="xAxisLabel"
        [maxXAxisTickLength]="maxXAxisTickLength"
        [maxYAxisTickLength]="maxYAxisTickLength"
        [yAxisLabel]="yAxisLabel"
        [showGridLines]="showGridLines"
        [yAxisTickFormatting]="yAxisTickFormatting"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
        #chart
      >
        <ng-template #tooltipTemplate let-model="model">
          <div [ngStyle]="{ color: tooltipColors[model.label] }">{{ model.value | numberFormatter }} {{ model.label }}</div>
        </ng-template>
      </ngx-charts-bar-vertical-stacked>
    </div>
    <div class="chart-info">
      <ng-container *ngIf="showCustomLegend">
        <div class="chart-items">
          <div *ngFor="let item of results[0]['series']; let i = index" class="item-legend text-2xl">
            <span class="item-color" [ngStyle]="{ background: scheme.domain[i] }"></span>
            <span class="item-label text-xs font-primary-medium">{{ item?.name }}</span>
            <span class="font-primary-medium">{{ totalOfYItemAcrossXAxis[item?.name] | numberFormatter }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #noReport>
  <ng-container *ngIf="showCustomEmptyData">
    <div class="custom-no-data">
      <img class="custom-no-data-img" src="{{ noDataImgSrc }}" />
      <div class="custom-no-data-text text-base text-grey-400 font-primary">{{ noDataText }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showCustomEmptyData">
    <zap-no-content-image-text [showImage]="false" [text]="noDataText"> </zap-no-content-image-text>
  </ng-container>
</ng-template>
