import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, Observable, of, Subject, from } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mapTo,
  switchMap,
  tap,
  timeout,
} from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { compose } from 'ramda';
import { ConfigService } from '@zeotap-ui/config';
import { toCustomTokenAPIResponse } from '../utils';
import { CustomTokenAPIResponse } from '../types';
import { handleAsyncError, HttpService, isSuccess } from '../../http';

export enum FirebaseAuthStatus {
  SUCCESS = 'Success',
  FAILURE = 'Failure',
  IN_PROGRESS = 'InProgress',
}
@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  private isAuthenticated$: BehaviorSubject<
    FirebaseAuthStatus
  > = new BehaviorSubject<FirebaseAuthStatus>(FirebaseAuthStatus.IN_PROGRESS);

  constructor(
    public httpService: HttpService,
    private configService: ConfigService,
    private afAuth: AngularFireAuth
  ) {}

  signOut() {
    this.afAuth.signOut();
  }

  public isAuthenticated() {
    return this.isAuthenticated$
      .asObservable()
      .pipe(map((value) => value === FirebaseAuthStatus.SUCCESS));
  }

  public authenticateStatus() {
    return this.isAuthenticated$.asObservable();
  }

  public isError() {
    return this.isAuthenticated$
      .asObservable()
      .pipe(map((value) => value === FirebaseAuthStatus.FAILURE));
  }

  public signIn() {
    this.getFireStoreCustomToken()
      .pipe(
        exhaustMap((res: CustomTokenAPIResponse) => {
          if (isSuccess(res.status)) {
            return this.signInWithCustomToken(res?.data?.token);
          }
          return of(false);
        }),
        map((value) =>
          !!value ? FirebaseAuthStatus.SUCCESS : FirebaseAuthStatus.FAILURE
        ),
        tap((value: FirebaseAuthStatus) => this.isAuthenticated$.next(value))
      )
      .subscribe();
  }

  private signInWithCustomToken(token: string): Observable<boolean> {
    return from(this.afAuth.signInWithCustomToken(token)).pipe(
      mapTo(true),
      catchError((_) => of(false))
    );
  }

  private getFireStoreCustomToken(): Observable<CustomTokenAPIResponse> {
    return defer(() =>
      this.httpService.doPost(
        `${this.configService.appConfig.firebaseUrl}token`,
        {},
        this.httpService.getHeader('Bearer ')
      )
    ).pipe(
      timeout(5000),
      map(toCustomTokenAPIResponse),
      catchError(
        compose(
          of,
          handleAsyncError<CustomTokenAPIResponse>(
            'Something bad happened. Please try again later.' // TODO : Make this localized string
          )
        )
      )
    );
  }
}
