import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Organization } from '../../types';
@Component({
  selector: 'zap-organization-tree-selection',
  templateUrl: './organization-tree-selection.component.html',
  styleUrls: ['./organization-tree-selection.component.scss'],
})
export class OrganisationTreeSelectionComponent {
  @Input() organizations: Organization[];
  @Input() selectedOrganization: Organization;
  @Input() disabled: boolean = false;
  @Output() selectOrganization: EventEmitter<Organization> = new EventEmitter<
    Organization
  >();

  constructor() {}

  changeSelectedOrganization(organization: Organization): void {
    this.selectOrganization.emit(organization);
  }
}
