import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { mapObjIndexed, pluck, values } from 'ramda';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { isError } from '../../http';
import { OrgApiService, Organization } from '../../org';
import { PRODUCTS } from '../../product/product.const';
import { isNotNullOrEmpty } from '../../utils';
import { DASHBOARD_PRODUCT_MAP } from '../consts/sisense.const';
import { getChildOrgsMap } from '../utils/sisense.utils';

@Injectable({
  providedIn: 'root',
})
export class ProductOrgsFacadeService {
  private productOrgsMap$: Observable<Record<string, Organization[]>>;
  productOrgIDsMap$: Observable<Record<string, number[]>>;
  loadProductOrgsMap$ = new BehaviorSubject<void>(null);
  loading$ = new Subject<boolean>();
  error$ = new BehaviorSubject<string>(null);

  childOrgsMap$: Observable<Record<number, number[]>>;

  get hasValidAccessToken() {
    return isNotNullOrEmpty(this.oktaAuth.getAccessToken());
  }

  constructor(
    private orgApiService: OrgApiService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this.productOrgsMap$ = this.loadProductOrgsMap$.pipe(
      filter(() => this.hasValidAccessToken),
      tap((_) => this.loading$.next(true)),
      switchMap((_) =>
        this.orgApiService.getProductOrgsMap(values(DASHBOARD_PRODUCT_MAP))
      ),
      map((res) => {
        this.loading$.next(false);
        if (isError(res.status)) {
          this.error$.next(res.error);
          return {};
        }
        this.error$.next(null);
        return res.data;
      }),
      shareReplay()
    );

    this.productOrgIDsMap$ = this.productOrgsMap$.pipe(
      map((data) =>
        mapObjIndexed((orgs, _, __) => pluck('zeo_org_id', orgs), data)
      ),
      shareReplay()
    );

    this.childOrgsMap$ = this.productOrgsMap$.pipe(
      map((data) => getChildOrgsMap(PRODUCTS.DATA_MANAGER.id, data)),
      shareReplay()
    );
  }

  reload() {
    this.loadProductOrgsMap$.next(null);
  }
}
