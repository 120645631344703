import { Directive, Input } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Directive({
  selector: '[scrollToTop]',
})
export class ScrollToTopDirective {
  @Input('scrollToTop') pageSize: number = 50;
  @Input('rows') rows: any[] = [];
  constructor(private element: DatatableComponent) {}

  ngOnChanges(changes) {
    if (
      changes.rows &&
      changes.rows.currentValue?.length <= this.pageSize && // table should scroll to top if the first page is loaded and scrollTop is not 0
      this.element
    ) {
      setTimeout(() => {
        this.element.bodyComponent.offsetY = 0;
        this.element.recalculateColumns();
      }, 0);
    }
  }
}
