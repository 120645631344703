import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@cdp/environment/environment';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  StatusModule,
  ForgotPasswordModule,
  DisclaimerModalModule,
} from '@zeotap-ui/components';
import {
  AppConstants,
  AppConstantsToken,
  AsyncConfigRuleEvaluatorPipe,
  ConfigModule,
  ConfigRuleEvaluator,
  ConfigRuleEvaluatorPipe,
  ConfigService,
  EnvironmentConfigToken,
} from '@zeotap-ui/config';
import {
  CoreModule,
  LoggingService,
  ProductAccessAuthGuard,
  SISENSEJS_SCRIPT,
  SISENSE_EMBED_SDK_SCRIPT,
  UserPreferencePathToken,
} from '@zeotap-ui/core';
import { NgEventBus } from 'ng-event-bus';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { mainMenuItems } from './app.config';
import { DefaultPagesModule } from './default-pages/default-pages.module';
import { MainMenuItemsInjectionToken, NavbarModule } from './navbar';
import { PermissionEvaluator } from './services/permission-evaluator.service';
import { PermissionGuard } from './services/permission-guard.service';
import { GlobalErrorHandler } from './handlers/global-error.handler';

export const appLoader = (config: ConfigService): (() => Promise<boolean>) => {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      // here resolve after loading a config
      config
        .init()
        .then(() => resolve(true))
        .catch((err) => resolve(true));
      // resolve(true);
    });
  };
};

const appProviders = [
  {
    provide: UserPreferencePathToken,
    useValue: 'cdp',
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appLoader,
    deps: [ConfigService],
    multi: true,
  },
  {
    provide: EnvironmentConfigToken,
    useValue: environment,
  },
  {
    provide: AppConstantsToken,
    useValue: {} as AppConstants, // TODO : populate App Constants via this
  },
  {
    provide: MainMenuItemsInjectionToken,
    useValue: mainMenuItems,
  },
  { provide: ErrorHandler, useClass: GlobalErrorHandler },
  { provide: ConfigRuleEvaluator, useClass: PermissionEvaluator },
  NgEventBus,
  ProductAccessAuthGuard,
  PermissionGuard,
  ConfigRuleEvaluatorPipe,
  AsyncConfigRuleEvaluatorPipe,
  LoggingService,
];

const oktaAuth = new OktaAuth(environment.okta);

const appImports = [
  BrowserModule,
  AppRoutingModule,
  OktaAuthModule.forRoot({ oktaAuth }),
  NavbarModule,
  CoreModule,
  HttpClientModule,
  ConfigModule,
  AngularFireModule.initializeApp(environment.firebaseConfig),
  AngularFirestoreModule,
  DefaultPagesModule,
  BrowserAnimationsModule,
  StatusModule,
  ForgotPasswordModule,
  DisclaimerModalModule,
];

const appDeclarations = [AppComponent];

function addScriptToBody(src, async = true) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = async;
  script.src = src;
  document.body.appendChild(script);
}

@NgModule({
  declarations: appDeclarations,
  imports: appImports,
  providers: appProviders,
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    const sisenseIsLoaded =
      typeof window['Sisense'] !== 'undefined' &&
      typeof window['prism'] !== 'undefined';
    const isSisenseEmbedLoaded = typeof window['sisense.embed'] !== 'undefined';
    if (!sisenseIsLoaded) {
      addScriptToBody(environment.sisenseConfig.url + SISENSEJS_SCRIPT);
    }
    if (!isSisenseEmbedLoaded) {
      addScriptToBody(environment.sisenseConfig.url + SISENSE_EMBED_SDK_SCRIPT);
    }
  }
}
