<section>
  <div class="no-permission w-[15.62rem] mt-[15vh] m-auto">
    <img class="w-full" src="https://content.zeotap.com/img/nopermission.png" />
  </div>
  <div class="no-permission-msg text-center">
    <p class="font-primary-medium text-sm main-msg mx-0 my-2.5 text-primary-1-900">No Permissions</p>
    <p class="font-primary text-sm metadata mx-0 my-2.5 text-grey/52">Uh oh, looks like you have no permissions!</p>
    <p class="font-primary text-sm text-grey-900 metadata mx-0 my-2.5">Please contact your account administrator.</p>
  </div>
</section>
