<div class="w-full p-2">
  <div class="flex flex-col gap-2 border border-solid border-state-error rounded p-2 bg-state-error/5">
    <ng-container *ngIf="!!code || !!title">
      <div class="flex flex-row gap-2 font-primary-medium text-xs leading-4 text-state-error">
        <span>{{ code }}</span>
        <span>{{ title }}</span>
      </div>
    </ng-container>
    <div class="text-state-error font-primary text-xs">{{ message }}</div>
    <div class="cursor-pointer text-primary-1 text-xs font-primary-medium" (click)="onReload.emit()">Retry</div>
  </div>
</div>
