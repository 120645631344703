import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiLibModule } from '@zeotap.infra/zui-lib';
import { CustomTooltipModule } from '@zeotap-ui/core';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [WelcomePageComponent],
  imports: [
    CommonModule,
    ZuiLibModule,
    CustomTooltipModule,
    MatButtonModule,
    FormsModule,
    OverlayModule,
  ],
  exports: [WelcomePageComponent],
})
export class WelcomePageModule { }
