import { Pipe, PipeTransform } from '@angular/core';
import { FilterItem, FilterState } from '../types';

@Pipe({
  name: 'isFilterSelected',
})
export class IsFilterSelectedPipe implements PipeTransform {
  transform(
    filterItem: FilterItem,
    currentFilterState: FilterState,
    isSelectedOfParent?: boolean
  ): any {
    if (isSelectedOfParent) {
      return filterItem.isSelected(currentFilterState, filterItem);
    } else {
      return filterItem.isSelected(currentFilterState);
    }
  }
}
