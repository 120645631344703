import { ArrayDataSource } from '@angular/cdk/collections';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfigRuleEvaluatorPipe, ConfigService } from '@zeotap-ui/config';
import {
  AuthFacadeService,
  UserMetadata,
  UserService,
  defaultToEmptyArray,
} from '@zeotap-ui/core';
import { find, not, includes } from 'ramda';
import { Observable, Subject } from 'rxjs';
import { MainMenuItem, MainMenuItemsInjectionToken } from '../types';
import { filterMenuItems } from '../utils';
import { takeUntil } from 'rxjs/operators';

const matchRouteWithFullRoute = (fullRoute: string) => (node: MainMenuItem) =>
  !!node.route && fullRoute.startsWith(`/${node.route}`);
@Component({
  selector: 'app-navbar-items-tree',
  templateUrl: './navbar-items-tree.component.html',
  styleUrls: ['./navbar-items-tree.component.scss'],
})
export class NavbarItemsTreeComponent implements OnInit, OnDestroy {
  TREE_DATA: MainMenuItem[] = null;
  menuPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 12,
    },
  ];
  targetingLink: string;
  predictiveAudienceLink: string;
  dataSource$: Observable<ArrayDataSource<MainMenuItem>>;
  userPermissions: string[];
  dataSource: ArrayDataSource<MainMenuItem>;
  unsubscribe$ = new Subject();

  constructor(
    @Inject(MainMenuItemsInjectionToken) private mainMenuTree: MainMenuItem[],
    private router: Router,
    private configService: ConfigService,
    private userService: UserService,
    private ruleEvaluatorPipe: ConfigRuleEvaluatorPipe,
    private authFacade: AuthFacadeService
  ) {}
  ngOnInit(): void {
    // detect the active route and open the same
    this.expandCurrentPathNode(this.router.url);
    this.authFacade.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userData) => {
        this.userPermissions = defaultToEmptyArray(userData.user.permissions);
        this.dataSource = new ArrayDataSource(this.getMainMenu());
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.collapsed?.currentValue) {
      // collapse all nodes
      this.treeControl.collapseAll();
      // detect the active route and open the same
      this.expandCurrentPathNode(this.router.url);
    }
  }

  @Input() collapsed = false;

  audienceFeature = this.configService.getFeatureValue('ENABLE_SEGMENT', false);
  treeControl = new NestedTreeControl<MainMenuItem>((node) => node.children);

  filterItem(permissions: string[]) {
    return permissions?.every((permission) =>
      this.ruleEvaluatorPipe.transform(permission)
    );
  }

  getMainMenu() {
    const userInfo: UserMetadata = this.userService.getUserInfo();

    this.mainMenuTree = filterMenuItems(
      this.mainMenuTree,
      userInfo.productInfo
    );
    // TODO: move this logic in a pipe function with above.
    return this.mainMenuTree
      .map((x) => ({
        ...x,
        children:
          x?.children &&
          x?.children.filter(
            (child) =>
              !(
                child?.route?.data?.userInfoPermission &&
                not(
                  includes(
                    child?.route?.data?.userInfoPermission,
                    this.userPermissions
                  )
                )
              ) &&
              this.filterItem(child?.route?.data?.permissions ?? []) &&
              child.route.path
          ),
      }))
      .filter((x) => this.filterItem(x?.route?.data?.permissions ?? [])); //x.label !== 'Segment');
  }

  hasChild = (_: number, node: MainMenuItem) => !!node.children;

  expandCurrentPathNode(currentPath: string) {
    var expandedNode = find(
      matchRouteWithFullRoute(currentPath),
      this.mainMenuTree
    );
    if (expandedNode) {
      this.treeControl.expand(expandedNode);
    }
  }
  preventClickRootMenu(event: MouseEvent) {
    event.preventDefault();
  }

  // Gets the currently selected top-level product
  // Used to highlight the top-level product in the navbar
  get currentParentRoute() {
    let path = this.router.url.split('/');
    return path.length > 1 ? path[1] : '';
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
