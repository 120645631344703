import { getDate } from '@zeotap-ui/core';
import { EVENT_DATE_FORMAT, EventsUsagePeriod } from './constants/const';
import { DateRange } from './types';

export const toDateRange = (startDate, endDate): DateRange => ({
  startDate: getDate(startDate, EVENT_DATE_FORMAT),
  endDate: getDate(endDate, EVENT_DATE_FORMAT),
});

const calculateStartDate = (daysAgo) => {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - daysAgo
  );
};

const getTimeStampForDays = (daysAgo) =>
  toDateRange(calculateStartDate(daysAgo), new Date());

export const getAvailableDateRange = () => [
  {
    id: 30,
    name: 'Last 30 Days',
    disabled: false,
    type: EventsUsagePeriod.Weekly,
    range: getTimeStampForDays(30),
  },
  {
    id: 60,
    name: 'Last 60 Days',
    disabled: false,
    type: EventsUsagePeriod.Weekly,
    range: getTimeStampForDays(60),
  },
  {
    id: 90,
    name: 'Last 90 Days',
    disabled: false,
    type: EventsUsagePeriod.Weekly,
    range: getTimeStampForDays(90),
  },
  // {
  //   id: 365,
  //   name: 'Last 1 Year',
  //   disabled: true,
  //   type: EventsUsagePeriod.Monthly,
  //   range: getLastOneYearTimeStamp(),
  // },
  // {
  //   name: 'From Start',
  //   type: EventsUsagePeriod.Monthly,
  //   disabled: true,
  // }, // TODO: update range
];

export const getRangeFilter = (range) => ({
  jaql: {
    dim: '[date_dim.Date (Calendar)]',
    filter: {
      from: range.startDate,
      to: range.endDate,
    },
    level: 'days',
  },
});
