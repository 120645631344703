import { NgModule } from "@angular/core";
import { CustomTooltipComponent } from "./custom-tooltip.component";
import { CustomTooltipDirective } from "./custom-tooltip.directive";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CustomTooltipComponent, CustomTooltipDirective],
    imports: [CommonModule],
    exports: [CustomTooltipDirective],
})
export class CustomTooltipModule {}