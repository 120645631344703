import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateStyles, Tab } from './custom-tabs.type';
import { TabLink } from '@zeotap-ui/core';

const defaultStateStyle: StateStyles = {
  active:
    '!text-primary-1 !border-solid !border-primary-1 !border-b-2 cursor-not-allowed',
  disabled:
    'text-grey/52 border-solid border-b-2 border-grey-100 cursor-not-allowed',
  nonActive: 'text-black border-solid border-b-2 border-grey-100',
};
@Component({
  selector: 'zap-custom-tabs',
  templateUrl: './custom-tabs.component.html',
})
export class CustomTabsComponent implements OnInit {
  @Input() tabs: Tab[] | TabLink[];
  @Input() currentTab: string;
  @Input() disabled?: boolean = false;
  @Input() fullLengthBorderStyle?: string = '';
  @Input() textStyle?: string;
  @Input() stateStyles?: StateStyles;
  @Output() onActive = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {
    this.stateStyles = {
      active: defaultStateStyle.active + ' ' + this.stateStyles?.active,
      disabled: defaultStateStyle.disabled + ' ' + this.stateStyles?.disabled,
      nonActive:
        defaultStateStyle.nonActive + ' ' + this.stateStyles?.nonActive,
    };
    this.textStyle = 'font-secondary-medium text-sm ' + this.textStyle;
  }
}
