export const getTPLDownloadUrl = (data: Blob) => {
  const blob = new Blob([data]);
  return URL.createObjectURL(blob);
};

export const getPDFDownloadUrl = (data: Blob) => {
  const blob = new Blob([data], {
    type: 'application/pdf',
  });
  return URL.createObjectURL(blob);
};

export const getExcelDownloadUrl = (data: Blob) => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  return URL.createObjectURL(blob);
};
