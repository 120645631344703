import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHolderComponent } from './modal-holder/modal-holder.component';
import {
  CloseIconTemplateDirective,
  ModalFooterTemplateDirective,
  HeaderLabelTemplateDirective,
  HeaderTemplateDirective,
} from './directives/modal-holder.directive';
import { ShowDocumentationModule } from '../show-documentation';

@NgModule({
  declarations: [
    ModalHolderComponent,
    ModalFooterTemplateDirective,
    HeaderTemplateDirective,
    HeaderLabelTemplateDirective,
    CloseIconTemplateDirective,
  ],
  imports: [CommonModule, ShowDocumentationModule],
  exports: [
    ModalHolderComponent,
    ModalFooterTemplateDirective,
    HeaderTemplateDirective,
    HeaderLabelTemplateDirective,
    CloseIconTemplateDirective,
  ],
})
export class ModalHolderModule {}
