import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@zeotap-ui/config';
import { AuthService } from '@zeotap-ui/core';
import { ALLOWED_PRODUCTS } from '@zeotap-ui/usage';

@Component({
  selector: 'app-header-menu-item',
  templateUrl: './header-menu-item.component.html',
})
export class HeaderMenuItemComponent implements OnInit {
  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router
  ) {}

  @Input() collapsed: boolean = false;
  get showCDPUsage() {
    return (
      this.configService.getFeatureValue('SISENSE_CONFIG', true).config?.usage
        ?.enabled &&
      ALLOWED_PRODUCTS.some((product) =>
        this.authService.hasProductAccess(product.name)
      )
    );
  }
  ngOnInit(): void {}

  get currentParentRoute() {
    let path = this.router.url.split('/');
    return path.length > 1 ? path[1] : '';
  }
}
