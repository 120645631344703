import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { concatMap, delay, first, map } from 'rxjs/operators';
import { UserPreference, UserPreferenceFacade } from '../../preferences';
import { isNotNullOrEmpty } from '../../utils';
import { PRODUCTS_ORG_AND_COUNTRY_ACCESS } from '../../amplitude/amplitude.const';
import { logEvent } from '../../log';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  triggerEvent$ = new Subject<any>();
  constructor(private userPrefService: UserPreferenceFacade) {
    this.subscribeTriggerEvent();
  }

  subscribeTriggerEvent() {
    this.triggerEvent$
      .pipe(
        delay(50), // to avoid triggering the event with old preference data
        concatMap(({ event, eventProp }) =>
          this.userPrefService.getUserPreference().pipe(
            first(),
            map((prefData) => ({ event, eventProp, prefData }))
          )
        )
      )
      .subscribe(({ event, eventProp, prefData }) => {
        this.logEvent({ event, eventProp, prefData });
      });
  }

  private logEvent({ event, eventProp, prefData }) {
    logEvent(event, {
      org: this.getOrgId(prefData),
      countryCode: this.getCountryCode(prefData),
      ...(isNotNullOrEmpty(eventProp) ? eventProp : {}),
    });
  }

  amplitudeHelper(event, eventProp?) {
    this.triggerEvent$.next({ event, eventProp });
  }

  getOrgId(preference: UserPreference) {
    return isNotNullOrEmpty(preference) &&
      PRODUCTS_ORG_AND_COUNTRY_ACCESS?.[preference['lastSelectedProduct']]
        ?.hasOrgAccess
      ? preference['lastSelectedOrganizationId']
      : undefined;
  }

  getCountryCode(preference: UserPreference) {
    return isNotNullOrEmpty(preference) &&
      PRODUCTS_ORG_AND_COUNTRY_ACCESS?.[preference['lastSelectedProduct']]
        ?.hasCountryAccess
      ? preference['lastSelectedCountryCode']
      : undefined;
  }
}
