import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'zap-clipboard-copy',
  templateUrl: './clipboard-copy.component.html',
  styleUrls: ['./clipboard-copy.component.scss'],
})
export class ClipboardCopyComponent implements OnInit {
  @Input() clipboardContent: string;
  @Input() clipboardText: string;
  @Input() clipboardCopiedText: string;
  @Output() onClipBoardClick: EventEmitter<void> = new EventEmitter<void>();
  public text: string;

  ngOnInit() {
    this.text = this.clipboardText;
  }

  public clipboardClick(input: boolean) {
    this.text = input ? this.clipboardCopiedText : this.clipboardText;
  }
}
