import { Pipe, PipeTransform } from '@angular/core';
import { isNil, not } from 'ramda';

@Pipe({
  name: 'isNotNull',
})
export class IsNotNullPipe implements PipeTransform {
  transform(value: any): boolean {
    return not(isNil(value));
  }
}
