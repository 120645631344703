import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { isNotNullOrEmpty } from '../../utils';

export const DefaultPathToken = new InjectionToken<string>(
  'default_logged_in_path'
);

@Injectable({
  providedIn: 'root',
})
export class AuthCheckResolver {
  default_route_path = '';

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router,
    @Optional() @Inject(DefaultPathToken) default_route_path
  ) {
    if (default_route_path) {
      this.default_route_path = default_route_path;
    }
  }
  resolve(): void {
    isNotNullOrEmpty(this.oktaAuth.getAccessToken())
      ? this.oktaAuth.signInWithRedirect()
      : this.router.navigate([this.default_route_path]);
  }
}
