import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoContentImageTextComponent } from './no-content-image-text.component';

@NgModule({
  declarations: [NoContentImageTextComponent],
  imports: [CommonModule],
  exports: [NoContentImageTextComponent],
})
export class NoContentImageTextModule {}
