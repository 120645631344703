<section class="help-center">
  <ng-container *ngIf="isHelpText; else defaultTemplate">
    <span class="cursor-pointer font-primary text-primary-1" (click)="goToHelpCenter(); $event.stopPropagation()">{{ helpText }}</span>
  </ng-container>
  <ng-template #defaultTemplate>
    <div class="document-circle text-lg" [matTooltip]="'Documentation'" (click)="goToHelpCenter()">
      <span class="icon document text-grey/52 flex"></span>
    </div>
  </ng-template>
</section>
