import { HttpErrorResponse } from '@angular/common/http';
import { AsyncCallResponse, ResponseCodeHandlerMap, Status } from './types';
import { curry } from 'ramda';

export const isSuccess = (status: Status) => status === Status.Success;
export const isError = (status: Status) => status === Status.Error;

export function handleAsyncError<T>(...props) {
  const handleAsyncError = curry(
    <T>(
      defaultError: string,
      error: HttpErrorResponse
    ): AsyncCallResponse<T> => {
      if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.message);
      } else {
        console.error(
          `Backend returned code ${error.status}, ` +
            `body was: ${error.message}`
        );

        switch (error.status) {
          case 400:
          case 401:
          case 403: {
            if (typeof error.error === 'string') {
              return {
                status: Status.Error,
                error: error.error,
              };
            }
            return {
              status: Status.Error,
              error:
                error.error?.message ??
                error.error?.error ??
                error.error ??
                'Oops! Something went wrong.',
            };
          }
        }
      }
      return {
        status: Status.Error,
        error: defaultError,
      };
    }
  );

  return handleAsyncError(...props);
}
