import { Component, Input } from '@angular/core';

export enum ColorScheme {
  success = 'success',
  error = 'error',
  brand = 'brand',
}

@Component({
  selector: 'zap-info-ribbon',
  templateUrl: './info-ribbon.component.html',
})
export class InfoRibbonComponent {
  @Input() ribbonIcon: string;
  @Input() label: string;
  @Input() colorScheme: ColorScheme;

  constructor() {}
}
