import { Pipe, PipeTransform } from '@angular/core';
import { getDate } from '../../utils';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: number, format?: string): string {
    return value ? getDate(value, format) : '-';
  }
}
