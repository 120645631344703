<div class="email-container w-6/12 h-[25.625rem] bg-white rounded mt-[10%] m-auto">
  <div class="header grid grid-cols-[1fr_auto] h-10">
    <div *ngIf="headerText === 'IMPLEMENTATION GUIDE'" class="pt-4 pb-0 pl-4 pr-0 font-bold font-secondary-bold email-header text-primary-1-900">EMAIL IMPLEMENTATION GUIDE</div>
    <div *ngIf="headerText !== 'IMPLEMENTATION GUIDE'" class="pt-4 pb-0 pl-4 pr-0 text-sm font-bold font-primary email-header text-primary-1-900">Email {{ headerText }} Credit Note</div>
    <span class="icon close close-icon text-[0.62rem] rounded-[.25rem] text-primary-1-900 p-[0.37rem] bg-grey-200/20 opacity-[unset] ml-4 !mr-6 my-4" (click)="cancelEmail()"></span>
  </div>
  <div class="action-bar grid grid-cols-[2rem_1fr_8rem] gap-[0.3rem] h-6 mt-4 px-4 py-0">
    <!-- <input type="checkbox" [ngClass]="{'disable': !!addedUser}" [checked]="selectAllToggle" (change)="updateAllUserSelection()"> -->
    <zui-checkbox [intermediateState]="!isAllUserSelected" [ngClass]="{ 'disable !text-grey/52 !pointer-events-none !cursor-not-allowed': !!addedUser }" [(ngModel)]="selectAllToggle" (ngModelChange)="updateAllUserSelection()"></zui-checkbox>
    <span class="font-primary text-sm user-label text-grey/52 pt-[0.3rem]">RECIPIENTS</span>
    <div class="flex add-action">
      <div class="add-one-action cursor-pointer pt-[0.3rem]" (click)="addUser(addUserInput)" [ngClass]="{ 'disable !text-grey/52 !pointer-events-none !cursor-not-allowed': !!addedUser }">
        <span class="icon add add-icon text-primary-1 relative bottom-0.5" [ngClass]="{ 'disable !text-grey/52 !pointer-events-none !cursor-not-allowed': !!addedUser }"></span>
        <span class="font-primary text-sm add-label relative text-primary-1 pl-[0.3rem] bottom-[0.3rem]" [ngClass]="{ 'disable !text-grey/52 !pointer-events-none !cursor-not-allowed': !!addedUser }">Add Recipient</span>
      </div>
    </div>
  </div>
  <hr class="my-2 border-none" />
  <div class="user-list-container overflow-scroll h-[16.3rem] bg-grey-50 border border-solid border-grey-300/30 pt-4 pb-6 px-4">
    <div #addUserInput>
      <ng-container *ngIf="!!addedUser">
        <div class="h-16 add-user-row-container">
          <div class="add-user-row grid grid-cols-[40%_auto_4rem] gap-[0.3rem] h-12">
            <zui-input data-testid="name-input" [(ngModel)]="addedUser.name" label="Name"></zui-input>
            <zui-input data-testid="email-input" [(ngModel)]="addedUser.email" label="Email"></zui-input>
            <div class="actions grid grid-cols-[50%_50%] pl-2 pt-[0.8rem]">
              <span class="cursor-pointer text-primary-1 icon circular-tick" data-testid="create" (click)="createUser(addedUser)"></span>
              <span class="cursor-pointer text-grey-300 icon cancel" data-testid="cancelcreate" (click)="cancelUser()"></span>
            </div>
          </div>
          <span *ngIf="showCreateError" class="relative text-sm font-primary error-text text-state-error-700 left-8">{{ createError }}</span>
        </div>
        <hr class="my-1 row-separator !border-t border-solid border-grey-300/30" />
      </ng-container>
    </div>
    <ng-container *ngIf="users?.length">
      <ng-container *ngFor="let user of users">
        <div class="user-row grid grid-cols-[2rem_1fr] gap-[0.3rem]" data-testid="user">
          <div class="checkbox-comp" [ngClass]="{ 'disable !text-grey/52 !pointer-events-none !cursor-not-allowed': !!addedUser }">
            <input type="checkbox" (ngModelChange)="updateUserSelection($event)" [(ngModel)]="user.selected" class="zui-checkbox icon" [ngClass]="{ unchecked: !user.selected, checked: user.selected }" />
          </div>
          <div class="user-details grid grid-rows-[1.5rem_1.5rem]" [id]="user.name" data-testid="userDetails">
            <div class="text-sm font-primary user-name" data-testid="username">{{ user.name }}</div>
            <div class="text-sm font-primary user-email text-grey/52" data-testid="email">{{ user.email }}</div>
          </div>
        </div>
        <hr class="my-1 row-separator !border-t border-solid border-grey-300/30" />
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!users?.length && !addedUser">
      <div class="font-primary text-sm no-users text-center mt-[10%]">No recipients found for data partner.</div>
    </ng-container>
  </div>
  <zap-custom-footer
    [footerButtons]="[
    {localeLabel: 'UU_CANCEL', style: 'cancel', disabled: false, show: true, actionsFunctionName: 'cancelEmail'},
    {localeLabel: 'UU_SEND_EMAIL', style: 'next', disabled: (!isAnyUserSelected() || !!addedUser), show: true, actionsFunctionName: 'sendEmail'},
    ]"
    (onAction)="setAction($event)"
  >
  </zap-custom-footer>
</div>
