import { ERROR_MSGS } from '../../utils';

export const LOCALE_MAP = {
  ...ERROR_MSGS,
  UU_CANCEL: 'Cancel',
  UU_SAVE: 'Save',
  UU_DELETE: 'Delete',
  UU_NULL_VALUE_MSG: 'Will be treated as NULL if left blank',
  UU_ADD_RULE: 'Add a rule',
  UU_ADD_RULE_SET: 'Add ruleset',
  UU_ACTIVE: 'ACTIVE',
  UU_ARCHIVE: 'ARCHIVED',
  UU_BACK: 'BACK',
  UU_NEXT: 'Next',
  UU_SEND_EMAIL: 'SEND EMAIL',
  UU_LOADING_TEXT: 'Loading Data',
  UU_LOADING_INFO: 'Loading your info',
  UU_TIME: 'Time',
  UU_NO_OF_RECORD_TEXT: 'No of records',
  UU_SHARE: 'SHARE',
  UU_HELP_GUIDE: 'HELP GUIDE',
  UU_NO_DATA_AVAILABLE: 'No data available yet.',
  UU_ERROR: 'Error(s)',
  UU_NO_REPORT: 'No report available.',
  UU_NO_DATA: 'No Data to display.',
  UU_UNEXPECTED_ERROR: 'Something Unexpected happened',
  UU_UPLOAD: 'Upload',
  UU_UPDATE: 'Update',
  UU_COLUMNS_TO_SHOW: 'Columns to show',
  UU_ADD_FILE: '+ ADD FILE',
  UU_UPLOAD_FILE: 'UPLOAD FILE',
  UU_FILE_SIZE_ERROR:
    'The file size should be greater than 0 KB and less than {{MAX_FILE_SIZE}} MB',
  UU_FILE_EXTENSION_ERROR:
    'Invalid extension. Allowed Extensions is/are {{FILE_EXTENSIONS}}',
  UU_FILE_UPLOAD_SUCCESS: 'File Uploaded successfully.',
  UU_MAX_FILE_SIZE: 'Max file size - {{MAX_FILE_SIZE}} MB',
  UU_THAN: 'than',
  UU_CRM: 'CRM',
  UU_SEARCH: 'Search',
  UU_YES: 'Yes',
  UU_NO: 'No',
  UU_COPIED: 'Copied',
  UU_COPY: 'Copy',
  UU_SOURCES: 'SOURCES',
  UU_DETAILS: 'DETAILS',
  UU_LENGTH_VALIDATION_MSG:
    'The value should be less than or equal to {{LENGTH}} char.',
  UU_LEADING_TRAILING_SPACE_ERROR:
    'Please remove the extra space added at the start and end of the name',
  UU_EDIT: 'EDIT',
  UU_SAVE_PROMPT_TEXT: 'Are you sure, you want to save the changes?',
  UU_OPTIONAL: 'Optional',
  UU_DESCRIPTION: 'Description',
  UU_ENTER_DESCRIPTION: 'Enter Description',
  UU_ID: 'ID',
  UU_DESTINATIONS: 'Destinations',
  UU_CREATED_BY: 'Created By',
  UU_STATUS: 'Status',
  UU_CATEGORY: 'Category',
  UU_ATTRIBUTE: 'Attribute',
  UU_ADD_CUSTOM: 'Add Custom Data',
  UU_SELECT_FROM_BELOW: 'Select from below',
  UU_REQUIRED: 'Required',
  UU_SELECT_ALL: 'Select All',
  UU_VIEW_MORE: 'View {{COUNT}} more',
  UU_HIDE: 'Hide',
  UU_NO_ITEMS_FOUND: 'No items found',
  UU_PREREQUISITES: 'Prerequisites',
  UU_COMING_SOON_LABEL: 'This feature is coming soon.',
  UU_GO_BACK: 'Go back',
  UU_YES_CANCEL: 'Yes, Cancel',
  UU_OF: 'of',
  UU_MIN_MAX_ERROR: 'Value should be >= {{MIN}} and <= {{MAX}}',
  UU_SAVE_DRAFT: 'Save draft',
  UU_ENABLED: 'Enabled',
  UU_DISABLED: 'Disabled',
  UU_SELECT_ORG: 'Select Organization',
  UU_EXACTLY: 'exactly',
  UU_ATLEAST: 'atleast',
  UU_ATMOST: 'atmost',
  UU_LESS_THAN: 'less than',
  UU_GREATER_THAN: 'more than',
  UU_EXISTS: 'exists',
  UU_DOES_NOT_EXIST: "doesn't exist",
  UU_NOT_EQUAL: 'not equal to',
  UU_SELECT_DATE_RANGE: 'Select Date Range',
  UU_DATE_ERROR_MSG: 'Please enter valid date range',
  UU_TRUE: 'True',
  UU_FALSE: 'False',
  UU_CREATED_ON: 'Created Date',
  UU_UPDATED_ON: 'Last Updated Date',
  UU_PAUSE: 'PAUSE',
  UU_RESUME: 'RESUME',
  UU_PROCEED: 'PROCEED',
  UU_LOADING_PREVIEW: 'Loading preview',
  UU_LOAD_ERROR: 'Unable to load. Please try again.',
  UU_ENTER_VALUE: 'Enter value',
};
