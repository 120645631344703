import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { allPossibleHourValues } from './const';
import { amOrPmType } from './type';
import { compose, defaultTo } from 'ramda';

@Component({
  selector: 'zap-custom-time-picker',
  templateUrl: './custom-time-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTimePickerComponent),
      multi: true,
    },
  ],
})
export class CustomTimePickerComponent implements OnInit {
  protected hour: number = 12;
  protected amOrPm: amOrPmType = 'AM';
  @Input() timeStamp: FormControl;
  @Input() timeStampToSend: FormControl;
  @Input() resetHour: FormControl;
  @Input() disabled: boolean;
  @Input() createMode: boolean = false;
  protected hourValues: number[];
  protected unsubscribe$ = new Subject();

  ngOnInit() {
    this.resetHour.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        if (val) {
          this.hour = null;
          this.amOrPm = null;
        } else {
          let hourAndAmPm = this.getHourAndAmPmBasedOnTimeStamp(
            this.timeStamp.value
          );
          this.hour = hourAndAmPm.hour;
          this.amOrPm = hourAndAmPm.amOrPm;
        }
      });
    this.hourValues = allPossibleHourValues;
    if (!!this.resetHour.value) {
      this.hour = null;
      this.amOrPm = null;
    }
    this.timeStamp.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.onValueChange();
      });
  }

  toggleAmPm() {
    this.amOrPm = this.amOrPm === 'AM' ? 'PM' : 'AM';
    this.onValueChange();
  }

  getHourAndAmPmBasedOnTimeStamp(timeStamp) {
    let hour = compose(defaultTo(0), parseInt)(moment(timeStamp).format('h'));
    let amOrPm = defaultTo('AM', moment(timeStamp).format('A')) as amOrPmType;
    return { hour, amOrPm };
  }

  onValueChange() {
    let twentyFourHourFormattedHour = this.hour;
    if (this.amOrPm === 'PM' && this.hour < 12) {
      twentyFourHourFormattedHour += 12;
    } else if (this.amOrPm === 'AM' && this.hour === 12) {
      twentyFourHourFormattedHour = 0;
    }
    this.timeStampToSend.setValue(
      moment(this.timeStamp.value).set({
        hour: twentyFourHourFormattedHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
