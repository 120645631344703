<ng-container
  *ngIf="{
    showActions: showActions$ | async
  } as data"
>
  <div [ngClass]="{ '!pointer-events-none': disableOption }" cdk-overlay-origin #actionTrigger="cdkOverlayOrigin" (click)="showActions$.next(rowId); openActionContainer(); $event.stopPropagation()">
    <ng-container *ngTemplateOutlet="iconTemplate ? iconTemplate : defaultIconTemplate"></ng-container>
  </div>
</ng-container>
<ng-template cdk-portal #actionContainer="cdkPortal">
  <div class="w-max h-max relative bottom-6 left-6" [ngClass]="zapActionsClass" (mouseleave)="showActions$.next(0); closeActionContainer()" (click)="showActions$.next(0); closeActionContainer()">
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #defaultIconTemplate>
  <div class="actions-container flex w-6 h-6 items-center justify-center p-1 rounded bg-primary-1-100 cursor-pointer" [ngClass]="{ '!bg-grey/8': disableOption }">
    <span class="text-xs text-primary-1" [ngClass]="[disableOption ? '!text-grey-500' : '', icon]"></span>
  </div>
</ng-template>
