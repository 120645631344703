<section class="h-full w-full grid grid-rows-[5rem_.25rem_1fr] bg-[url('https://content.zeotap.com/img/okta-login-background.svg')] bg-no-repeat bg-cover fixed inset-0">
  <div class="flex px-[2rem] justify-between items-center">
    <img src="https://content.zeotap.com/img/zeotap_logo.svg" style="width: 180px; height: 40px;" alt="Zeotap Logo" />
    <zap-button text="Get a Demo" textStyle="!font-primary-medium !text-lg" type="primary" (onClick)="demoButtonClick()"></zap-button>
  </div>
  <div class="bg-[linear-gradient(45deg,#12DA9C,#00C5E5,#1065DB,#B526EB)]"></div>
  <div class="flex justify-evenly pt-9">
    <div class="flex flex-col gap-3 pt-[4%]">
      <img src="https://content.zeotap.com/img/zeotap-login-image1.svg" class="w-[34.375rem] h-[28.125rem]" alt="CDP image" />
    </div>
    <div class="w-[25rem] h-max p-10 pt-[8%]">
      <form #forgotForm="ngForm" class="w-full h-full flex flex-col gap-5 items-center">
        <img src="https://content.zeotap.com/img/zeotap_logo.svg" style="width: 180px; height: 40px;" alt="Zeotap Logo" />
        <div class="text-lg text-primary-1-700 font-primary-medium">Forgot Password</div>
        <div class="border-solid border border-grey-300 flex h-12 flex-col rounded w-full">
          <input class="border-0 outline-none cursor-text bg-transparent h-full w-full p-4 placeholder:text-grey-500 placeholder:text-sm rounded" name="email" type="text" pattern=".+@.+\..+" ngModel #email="ngModel" placeholder="Your registered email address" required (focus)="onFocus('input-email')" (blur)="onBlur('input-email')" />
        </div>
        <span class="font-primary text-sm text-state-error-700" *ngIf="email.invalid && email.touched">Email address is not valid</span>
        <zap-button text="SEND EMAIL" textStyle="!font-primary-medium !text-sm" type="primary" [disabled]="!forgotForm.valid" (onClick)="recoverPassword()"></zap-button>
        <div class="font-primary text-sm text-primary-1 cursor-pointer" [routerLink]="['/login']">
          Already have an account?
        </div>
      </form>
    </div>
  </div>
</section>
