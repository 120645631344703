import {
  Component,
  ContentChild,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AuthFacadeService,
  AuthService,
  StorageService,
  UserPreferenceFacade,
} from '@zeotap-ui/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { environment } from 'projects/cdp/src/environments/environment';
import { NgEventBus } from 'ng-event-bus';
import { ConfigRuleEvaluatorPipe } from '../../../../config/src/public-api';

export interface NavbarState {
  isCollapsed: boolean;
}

const _initialState: NavbarState = {
  isCollapsed: false,
};

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [ConfigRuleEvaluatorPipe],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private store$ = new BehaviorSubject<NavbarState>({
    ..._initialState,
    ...this.storageService.getLocalState(['navbar']),
  });
  state$: Observable<NavbarState> = this.store$.asObservable().pipe(
    tap((state) => {
      this.storageService.setLocalState(state, ['navbar']);
    })
  );
  private unsubscribe$ = new Subject();
  public version = '';

  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private userPreferenceService: UserPreferenceFacade,
    private authFacadeService: AuthFacadeService,
    private eventBus: NgEventBus
  ) {
    this.version = environment.version;
  }

  ngOnInit(): void {
    this.userPreferenceService
      .getUserPreference()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((pref) => {
        this.updateState({ isCollapsed: !!pref?.isNavBarCollapsed });
      });
  }

  @Input() hideProductNavigation = false;

  updateState(update: Partial<NavbarState>) {
    this.store$.next({ ...this.store$.value, ...update });
  }
  onToggleClicked() {
    const { isCollapsed } = this.store$.value;
    this.updateState({ isCollapsed: !isCollapsed });
    this.userPreferenceService.updateUserPreference({
      isNavBarCollapsed: !isCollapsed,
    });
    this.eventBus.cast('navbar:toggled');
  }
  logOut() {
    this.authService.logout();
  }
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
