import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'zap-side-indicator',
  templateUrl: './side-indicator.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideIndicatorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
