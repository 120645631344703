import { Injectable } from '@angular/core';
import { ConfigService } from '@zeotap-ui/config';
import {
  AsyncCallResponse,
  HttpService,
  Status,
  handleAsyncError,
} from '@zeotap-ui/core';
import { compose } from 'ramda';
import { Observable, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserProductAccess } from '../types/usage';
import { toUserProductAccessResponse } from './handlers';

@Injectable({
  providedIn: 'root',
})
export class UsageService {
  constructor(
    private httpService: HttpService,
    private config: ConfigService
  ) {}

  getUserProductAccessDetails(
    userId: string
  ): Observable<AsyncCallResponse<UserProductAccess>> {
    return from(
      this.httpService.doGet(
        `${this.config.appConfig.userSvcUrlV3}user/${userId}/`,
        this.httpService.getHeader('')
      )
    ).pipe(
      map(toUserProductAccessResponse),
      catchError(
        compose(
          of,
          handleAsyncError<UserProductAccess>(
            'Something bad happened. Please try again later.' // TODO : Make this localized string
          )
        )
      )
    );
  }
}
