import { PRODUCTS } from '../../product';

export const DASHBOARD_PRODUCT_MAP = {
  usage: PRODUCTS.DATA_MANAGER.id,
  audienceOverlap: PRODUCTS.AUDIENCE.id,
  audienceAnalytics: PRODUCTS.AUDIENCE.id,
  firstPartyAnalytics: PRODUCTS.DATA_MANAGER.id,
  audienceTrends: PRODUCTS.AUDIENCE.id,
};

export const SISENSEJS_SCRIPT = '/js/sisense.js?wat=true';

export const SISENSE_EMBED_SDK_SCRIPT = '/js/frame.js';
