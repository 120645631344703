import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

export interface ColumnFilterOption {
  name: any;
  value: any;
  class?: any[];
  show?: boolean;
  disabled?: boolean;
}
@Component({
  selector: 'zap-actions-overlay',
  templateUrl: './actions-overlay.component.html',
})
export class ActionsOverlayComponent {
  showOptionList: boolean;
  @Input() actions: ColumnFilterOption[] = [];
  @Input() disableOption = false;
  @Input() positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom', //tooltip towards bottom
      overlayX: 'start',
      overlayY: 'top',
      offsetX: -5,
      offsetY: 8,
    },
  ];
  @Input() closeOnLeave: boolean = true;
  @Output() onActionClicked: EventEmitter<string> = new EventEmitter<string>();

  openActionContainer() {
    this.showOptionList = !this.showOptionList;
  }

  actionClicked(action: ColumnFilterOption) {
    if (action.disabled) return;
    this.closeActionContainer();
    this.onActionClicked.emit(action.value);
  }

  closeActionContainer() {
    this.showOptionList = false;
  }

  @HostListener('document:scroll', ['$event'])
  onScrollClick(evt: KeyboardEvent) {
    this.closeActionContainer();
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(event) {
    if (
      !event.target.closest('.actions-container') &&
      !event.target.closest('.actions') &&
      this.showOptionList
    ) {
      this.closeActionContainer();
    }
  }
}
