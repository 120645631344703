<div class="info-container" *ngIf="message || actionText" [ngClass]="{ 'info-bg bg-grey-50 border border-solid border-grey-300/30': message || actionContentTemplate, 'info-box': headerMessage || actionContentTemplate }">
  <div class="" *ngIf="message" [ngClass]="{ 'info-content': showIcon }">
    <div *ngIf="showIcon" class="icon messageIcon text-grey-400 text-xs" [ngClass]="[messageIcon]"></div>
    <div [ngClass]="{ 'flex flex-col': !showIcon }">
      <div class="font-primary-medium text-sm header text-grey-800" *ngIf="headerMessage">{{ headerMessage }}</div>
      <ng-container *ngIf="!(!openInfoContainer && showToggle)">
        <ng-container *ngTemplateOutlet="actionContentTemplate ? actionContentTemplate : defaultContent"></ng-container>
      </ng-container>
    </div>
  </div>
  <zap-collapse-button class="group large justify-self-end self-start" [(open)]="openInfoContainer" *ngIf="showToggle" [openDirection]="openDirection" [closeDirection]="closeDirection"></zap-collapse-button>
  <div class="font-primary-medium text-xs action-btn bg-primary-1/10 rounded text-primary-1" *ngIf="actionText" (click)="onActionButtonClick.emit()" [ngClass]="{ 'disabled text-grey/52 bg-grey-100': disableButton }">
    <span class="icon" [ngClass]="actionIcon"></span>
    <span>{{ actionText | uppercase }}</span>
  </div>
</div>
<ng-template #defaultContent>
  <div class="font-primary text-sm" [innerHTML]="message" [ngClass]="{ 'subtext text-grey-800': headerMessage }"></div>
</ng-template>
