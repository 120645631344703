import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionMenuComponent } from './accordion-menu.component';
import { CorePipesModule } from '@zeotap-ui/core';

@NgModule({
  declarations: [AccordionMenuComponent],
  imports: [CommonModule, CorePipesModule],
  exports: [AccordionMenuComponent],
})
export class AccordionMenuModule {}
