import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CorePipesModule } from '@zeotap-ui/core';
import { ZuiLibModule } from '@zeotap.infra/zui-lib';

import { CategoryFilterComponent } from './categoryFilter.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    ZuiLibModule,
    CorePipesModule,
    FormsModule,
  ],
  exports: [CategoryFilterComponent],
  declarations: [CategoryFilterComponent],
})
export class CategoryFilterModule {}
