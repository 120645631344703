import { Component, Input, OnInit } from '@angular/core';
import { BaseControl } from '../../base';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';

/**
 * CheckboxControlComponent
 * @description
 * This component is used to render an input control always binded with a parent form group.
 * It accepts the following inputs:
 * - label: string
 * - controlKey: string
 * - labelPosition: 'left' | 'right'
 * @example
 * <form [formGroup]="form">
 *  <zzap-checkbox-control controlKey="name" label="Enter your name"></zzap-checkbox-control>
 * </form>
 */
@Component({
  selector: 'zap-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class CheckboxControlComponent extends BaseControl implements OnInit {
  @Input() label: string = '';
  @Input() labelPosition: 'left' | 'right' = 'right';
  checkboxControl: AbstractControl;
  constructor(private parentContainer: ControlContainer) {
    super();
  }

  ngOnInit() {
    this.checkboxControl = this.parentContainer?.control?.get(this.controlKey);
    this.listenToControlChanges(this.checkboxControl);
  }
}
