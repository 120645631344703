import {
  __,
  all,
  allPass,
  anyPass,
  clone,
  compose,
  concat,
  converge,
  curry,
  difference,
  equals,
  filter,
  flatten,
  groupBy,
  gt,
  head,
  identity,
  ifElse,
  includes,
  intersection,
  isNil,
  keys,
  length,
  lensPath,
  lensProp,
  map,
  mapObjIndexed,
  not,
  omit,
  over,
  path,
  pathOr,
  pipe,
  pluck,
  prop,
  reduce,
  reject,
  set,
  values,
  view,
} from 'ramda';
import { Status } from '../../http';
import {
  defaultToEmptyArray,
  ipValidator,
  isNilOrEmpty,
  isNotNullOrEmpty,
} from '../../utils';
import { AUDIENCE_PROD_CONFIG_DEFAULT, PRODUCTS } from '../product.const';
import {
  ApiEnvisionProductConfig,
  ApiProductConfig,
  AudienceConfiguration,
  ConnectConfiguration,
  ConnectConfigurationV2,
  ConnectCountryConfiguration,
  ConnectFeatures,
  DCRConfiguration,
  DPUIConfiguration,
  DPUIFeatures,
  DataManagerConfiguration,
  DataManagerFeatures,
  EnvisionConfiguration,
  EnvisionCountryConfiguration,
  IDPConfiguration,
  InsightsFeatures,
  Product,
  ProductConfig,
  ProductConfigurationAPIResponse,
  ProductConfigurationMap,
  RefreshFrequency,
  SymphonyConfiguration,
  SymphonyRegionConfiguration,
  SymphonyRegions,
  SymphonyV2Configuration,
  SymphonyV3Configuration,
  TargetingConfiguration,
  TargetingConfigurationV2,
  TargetingCountryConfiguration,
} from '../types';

export const toProductConfigurationAPIResponse = (
  res: any
): ProductConfigurationAPIResponse => ({
  status: Status.Success,
  data: toProductConfigurationMap(res),
});

export const toProductConfigurationAPIResponseV2 = (
  res: any
): ProductConfigurationAPIResponse => ({
  status: Status.Success,
  data: toProductConfigurationMapV2(res),
});

export const toProductConfigurationAPIResponseV3 = (
  res: any
): ProductConfigurationAPIResponse => ({
  status: Status.Success,
  data: toProductConfigurationMapV3(res),
});

export const isProductConfigValid = (req: ProductConfigurationMap) => {
  return (
    (req[+PRODUCTS.DPUI.id]
      ? isDPUIValid(req[+PRODUCTS.DPUI.id] as DPUIConfiguration)
      : true) && // verification for DPUI
    (req[+PRODUCTS.ZAT.id]
      ? isTargetingCountryConfigurationValid(
          req[+PRODUCTS.ZAT.id] as TargetingCountryConfiguration
        )
      : true) &&
    (req[+PRODUCTS.CONNECT.id]
      ? isConnectCountryConfigurationValid(
          req[+PRODUCTS.CONNECT.id] as ConnectCountryConfiguration
        )
      : true) &&
    (req[+PRODUCTS.DCR.id]
      ? isDCRConfigValid(req[+PRODUCTS.DCR.id] as DCRConfiguration)
      : true) &&
    (req[+PRODUCTS.DATA_MANAGER.id]
      ? isDataManagerValid(
          req[+PRODUCTS.DATA_MANAGER.id] as DataManagerConfiguration
        )
      : true) &&
    (req[+PRODUCTS.ENVISION.id]
      ? isEnvisionCountryConfigurationValid(
          req[+PRODUCTS.ENVISION.id] as EnvisionCountryConfiguration
        )
      : true) &&
    (req[+PRODUCTS.ID_PLUS.id]
      ? isIDPConfigurationValid(
          req[+PRODUCTS.ID_PLUS.id] as IDPConfiguration,
          true
        )
      : true) &&
    (isNotNullOrEmpty(req[+PRODUCTS.SYMPHONY_V2.id])
      ? isSymphonyV2ConfigValid(
          req[+PRODUCTS.SYMPHONY_V2.id] as SymphonyV2Configuration
        )
      : true)
  );
};

export const isTransformationConfigValid = (req: SymphonyRegionConfiguration) =>
  Object.keys(req).length > 0;

const validateConfig =
  (req: ProductConfigurationMap) => (validationFn, prodId) =>
    req[prodId] ? validationFn(req[prodId], true) : true;
const isAllProductConfigValid = (req: ProductConfigurationMap) =>
  compose(all(equals(true)), values, mapObjIndexed(validateConfig(req)));

export const isProductConfigValidV2 = (
  req: ProductConfigurationMap,
  productId?: number
) => {
  const productValidationMap = {
    [PRODUCTS.DPUI.id]: isDPUIValid,
    [PRODUCTS.ZAT.id]: isTargetingCountryConfigurationValid,
    [PRODUCTS.CONNECT.id]: isConnectConfigValidV2,
    [PRODUCTS.DCR.id]: isDCRConfigValid,
    [PRODUCTS.ENVISION.id]: isPredictiveAudienceConfigValid,
    [PRODUCTS.ID_PLUS.id]: isIDPConfigurationValid,
  };
  if (productId) {
    if (productValidationMap[productId] && req[productId]) {
      return productValidationMap[productId](req[+productId] as any, true);
    }
    return true;
  }
  return isAllProductConfigValid(req)(productValidationMap);
};

export const isProductConfigValidV3 = (
  req: ProductConfigurationMap,
  productId?: number
) => {
  const productValidationMap = {
    [PRODUCTS.ZAT.id]: isTargetingConfigValidV2,
    [PRODUCTS.ID_PLUS.id]: isIDPConfigurationValid,
  };
  if (productId) {
    if (productValidationMap[productId] && req[productId]) {
      return productValidationMap[productId](req[+productId] as any, true);
    }
    return true;
  }
  return isAllProductConfigValid(req)(productValidationMap);
};

export const clearThirdPartyExtension = set(
  lensPath(['features', 'thirdPartySegment']),
  false
);
export const connectLens = lensProp(PRODUCTS.CONNECT.id);

// clearAllThirdPartyExtension :: ProductConfigurationMap -> ProductConfigurationMap
export const clearAllThirdPartyExtension = (prodConfig) =>
  set(
    connectLens,
    compose(
      mapObjIndexed(clearThirdPartyExtension),
      view(connectLens)
    )(prodConfig),
    prodConfig
  );

export const includesTargeting = compose(includes(PRODUCTS.ZAT.id), keys);
export const includesConnect = compose(includes(PRODUCTS.CONNECT.id), keys);

// unAvailableCountries :: ProductConfigurationMap -> string[]
export const unAvailableCountries = converge(difference, [
  compose(keys, view(connectLens)),
  compose(keys, view(lensProp(PRODUCTS.ZAT.id))),
]);

// clearThirdPartyExtensionForUnAvailableCountry :: (string[], ProductConfigurationMap) -> ProductConfigurationMap
export const clearThirdPartyExtensionForUnAvailableCountry = (
  countryCodes,
  req
) => {
  countryCodes.forEach((cc) => {
    const CountryLens = lensPath([PRODUCTS.CONNECT.id, cc]);
    req = set(
      CountryLens,
      clearThirdPartyExtension(view(CountryLens, req)),
      req
    );
  });
  return req;
};

// targetingBasedThirdPartyExtension :: ProductConfigurationMap -> ProductConfigurationMap
export const targetingBasedThirdPartyExtension = converge(
  clearThirdPartyExtensionForUnAvailableCountry,
  [unAvailableCountries, identity]
);

// updateBasedOnTargeting :: ProductConfigurationMap -> ProductConfigurationMap
export const updateBasedOnTargeting = ifElse(
  includesTargeting,
  targetingBasedThirdPartyExtension,
  clearAllThirdPartyExtension
);

// updateThirdPartySegmentExtension :: ProductConfigurationMap -> ProductConfigurationMap
export const updateThirdPartySegmentExtension = ifElse(
  includesConnect,
  updateBasedOnTargeting,
  identity
);

// getFilteredCountries:: ({[country: string]: any}, any[]) -> string[]
export const getFilteredCountries = (
  countriesMap: Record<string, any>,
  countries: any[]
) =>
  compose(
    map(prop('name')),
    filter((country) => countriesMap?.[country.id])
  )(countries);

// updateBasedOnTargetingV2 :: ProductConfigurationMap -> ProductConfigurationMap
export const updateBasedOnTargetingV2 = (prodConfig) =>
  set(
    lensPath([PRODUCTS.CONNECT.id, 'features', 'thirdPartySegment']),
    compose(
      gt(__, 0),
      length,
      converge(getFilteredCountries, [
        prop(PRODUCTS.ZAT.id),
        path([PRODUCTS.CONNECT.id, 'countries']),
      ])
    )(prodConfig)
  )(prodConfig);

// updateThirdPartySegmentExtensionV2 :: ProductConfigurationMap -> ProductConfigurationMap
export const updateThirdPartySegmentExtensionV2 = ifElse(
  includesConnect,
  updateBasedOnTargetingV2,
  identity
);

export const hasCollectAndConnectDependency = compose(
  equals(2),
  length,
  intersection([+PRODUCTS.CONNECT.id, +PRODUCTS.DATA_MANAGER.id])
);
export const envisionLens = lensProp(PRODUCTS.ENVISION.id);
export const envisionProp = prop(PRODUCTS.ENVISION.id);
export const connectProp = prop(PRODUCTS.CONNECT.id);
// includesEnvision :: ProductConfigurationMap -> boolean
export const includesEnvision = compose(includes(PRODUCTS.ENVISION.id), keys);
// removeEnvision :: ProductConfigurationMap -> ProductConfigurationMap
export const removeEnvision = omit([PRODUCTS.ENVISION.id]);
// connectCountryIds :: ProductConfigurationMap -> string[]
export const connectCountryIds = compose(keys, connectProp);
// envisionCountryIds :: ProductConfigurationMap -> string[]
export const envisionCountryIds = compose(keys, envisionProp);
// getAccessibleCountryConfig :: ProductConfigurationMap -> EnvisionCountryConfiguration
export const getAccessibleCountryConfig = converge(omit, [
  converge(difference, [envisionCountryIds, connectCountryIds]),
  envisionProp,
]);
// updateEnvisionCountryConfig :: ProductConfigurationMap -> ProductConfigurationMap
export const updateEnvisionCountryConfig = (productConfig) =>
  set(envisionLens, getAccessibleCountryConfig(productConfig), productConfig);
// isCollectAndConnectAvailable :: ProductConfigurationMap -> boolean
export const isCollectAndConnectAvailable = compose(
  hasCollectAndConnectDependency,
  map((v) => +v),
  keys
);
// updateBasedOnConnectAndCollect :: ProductConfigurationMap -> ProductConfigurationMap
export const updateBasedOnConnectAndCollect = ifElse(
  isCollectAndConnectAvailable,
  updateEnvisionCountryConfig,
  removeEnvision
);
// updateEnvisionConfig :: ProductConfigurationMap -> ProductConfigurationMap
export const updateEnvisionConfig = ifElse(
  includesEnvision,
  updateBasedOnConnectAndCollect,
  identity
);

// predictiveAudienceCountryIdsV2 :: ProductConfigurationMap -> string[]
export const predictiveAudienceCountryIdsV2 = compose(
  prop('countries'),
  prop(PRODUCTS.ENVISION.id)
);
// connectCountryIdsV2 :: ProductConfigurationMap -> string[]
export const connectCountryIdsV2 = compose(
  pluck('id'),
  prop('countries'),
  connectProp
);

const getPAFilteredCountries = (
  predictiveAudienceCountries,
  connectCountryIds
) =>
  filter((country) => includes(country.id, connectCountryIds))(
    predictiveAudienceCountries
  );
// getPAAccessibleCountryConfig :: ProductConfigurationMap -> EnvisionCountryConfiguration
export const getPAAccessibleCountryConfig = converge(getPAFilteredCountries, [
  predictiveAudienceCountryIdsV2,
  connectCountryIdsV2,
]);
// updatePACountryConfig :: ProductConfigurationMap -> ProductConfigurationMap
export const updatePACountryConfig = (productConfig) =>
  set(
    lensPath([PRODUCTS.ENVISION.id, 'countries']),
    getPAAccessibleCountryConfig(productConfig),
    productConfig
  );
// updatePAConfigBasedOnConnectAndCollect :: ProductConfigurationMap -> ProductConfigurationMap
export const updatePAConfigBasedOnConnectAndCollect = ifElse(
  isCollectAndConnectAvailable,
  updatePACountryConfig,
  omit([PRODUCTS.ENVISION.id])
);
// updatePAConfig :: ProductConfigurationMap -> ProductConfigurationMap
export const updatePAConfig = ifElse(
  includesEnvision,
  updatePAConfigBasedOnConnectAndCollect,
  identity
);

export const collectRegionsPath = path(['features', 'preferred_regions']);
export const includesCollect = compose(
  includes(PRODUCTS.DATA_MANAGER.id),
  keys
);

export const collectProp = prop(PRODUCTS.DATA_MANAGER.id);
export const collectRegions = compose(collectRegionsPath, collectProp);

// updateSymphonyConfig :: ProductConfigurationMap -> ProductConfigurationMap

export const includesSymphonyV2 = compose(
  includes(PRODUCTS.SYMPHONY_V2.id),
  keys
);
export const symphonyV2RegionsLens = lensPath([
  PRODUCTS.SYMPHONY_V2.id,
  'regions',
]);
export const symphonyV2RegionsPath = path([PRODUCTS.SYMPHONY_V2.id, 'regions']);
export const getSymphonyV2AccessRegions = (productConfig) =>
  intersection(
    symphonyV2RegionsPath(productConfig),
    collectRegions(productConfig)
  );
export const updateSymphonyV2RegionConfig = (productConfig) =>
  set(
    symphonyV2RegionsLens,
    getSymphonyV2AccessRegions(productConfig),
    productConfig
  );
export const removeSymphonyV2 = omit([PRODUCTS.SYMPHONY_V2.id]);
export const updateSymphonyV2ConfigBasedOnCollect = ifElse(
  includesCollect,
  updateSymphonyV2RegionConfig,
  removeSymphonyV2
);
export const updateSymphonyV2Config = ifElse(
  includesSymphonyV2,
  updateSymphonyV2ConfigBasedOnCollect,
  identity
);

const getCountryKeysToOmit = (
  selectedCollectRegions: string[],
  connectConfig: object
): string[] => {
  return Object.keys(connectConfig).filter(
    (key) => !selectedCollectRegions.includes(connectConfig[key].region)
  );
};

const curriedGetOmitCountryKeys = curry(getCountryKeysToOmit);

export const updateConnectCountryConfig = (
  productConfigMap: ProductConfigurationMap
): ProductConfigurationMap => {
  const selectedCollectRegions = compose(
    collectRegionsPath,
    prop(PRODUCTS.DATA_MANAGER.id)
  )(productConfigMap);
  return over(
    lensProp(PRODUCTS.CONNECT.id),
    converge(omit, [
      curriedGetOmitCountryKeys(selectedCollectRegions),
      identity,
    ]),
    productConfigMap
  );
};
// removeConnect :: ProductConfigurationMap -> ProductConfigurationMap
export const removeConnect = omit([PRODUCTS.CONNECT.id]);
// updateConnectConfigBasedOnCollect :: ProductConfigurationMap -> ProductConfigurationMap
export const updateConnectConfigBasedOnCollect = ifElse(
  includesCollect,
  updateConnectCountryConfig,
  removeConnect
);
// updateConnectConfig :: ProductConfigurationMap -> ProductConfigurationMap
export const updateConnectConfig = ifElse(
  includesConnect,
  updateConnectConfigBasedOnCollect,
  identity
);

export const updateConnectCountryConfigV2 =
  (orgRegions: string[]) =>
  (productConfigMap: ProductConfigurationMap): ProductConfigurationMap => {
    if (productConfigMap[PRODUCTS.CONNECT.id].countries) {
      productConfigMap[PRODUCTS.CONNECT.id].countries = productConfigMap[
        PRODUCTS.CONNECT.id
      ].countries.filter((c) => orgRegions.includes(c.region));
    }
    return productConfigMap;
  };
// updateConnectConfigBasedOnOrgRegions :: (ProductConfigurationMap -> string[]) -> ProductConfigurationMap
export const updateConnectConfigBasedOnOrgRegions = (orgRegions: string[]) =>
  ifElse(
    includesCollect,
    updateConnectCountryConfigV2(orgRegions),
    removeConnect
  );
// updateConnectConfig :: (ProductConfigurationMap -> string[]) -> ProductConfigurationMap
export const updateConnectConfigV2 = (orgRegions: string[]) =>
  ifElse(
    includesConnect,
    updateConnectConfigBasedOnOrgRegions(orgRegions),
    identity
  );

const getFeature = (feature) => pathOr(false, ['features', feature]);
export const isAnyExtensionSelected = anyPass([
  getFeature('zeotapGraph'),
  getFeature('tapad'),
  getFeature('lookalike'),
]);

export const isSelectedProductConfigValid = (
  productId: number,
  req: ProductConfigurationMap,
  toggledFeature?: any
) => {
  switch (productId) {
    case 3:
      return req[+PRODUCTS.DPUI.id]
        ? isDPUIValid(req[+PRODUCTS.DPUI.id] as DPUIConfiguration)
        : true;
    case 5:
      return req[+PRODUCTS.ZAT.id]
        ? isTargetingCountryConfigurationValid(
            req[+PRODUCTS.ZAT.id] as TargetingCountryConfiguration
          )
        : true;
    case 11:
      return req[+PRODUCTS.CONNECT.id]
        ? isConnectCountryConfigurationValid(
            req[+PRODUCTS.CONNECT.id] as ConnectCountryConfiguration
          )
        : true;
    case 14:
      return req[+PRODUCTS.DCR.id]
        ? isDCRConfigValid(req[+PRODUCTS.DCR.id] as DCRConfiguration)
        : true;
    case 15:
      return req[+PRODUCTS.DATA_MANAGER.id]
        ? toggledFeature[+PRODUCTS.DATA_MANAGER.id]
            ?.ENABLE_COLLECT_REGION_CONFIG
          ? isDataManagerValid(
              req[+PRODUCTS.DATA_MANAGER.id] as DataManagerConfiguration
            )
          : isCollectSourceSettingsValid(
              req[+PRODUCTS.DATA_MANAGER.id] as DataManagerConfiguration
            )
        : true;
    case 18:
      return req[+PRODUCTS.ENVISION.id]
        ? isEnvisionCountryConfigurationValid(
            req[+PRODUCTS.ENVISION.id] as EnvisionCountryConfiguration
          )
        : true;
    case 19:
      return req[+PRODUCTS.ID_PLUS.id]
        ? isIDPConfigurationValid(
            req[+PRODUCTS.ID_PLUS.id] as IDPConfiguration,
            toggledFeature[+PRODUCTS.ID_PLUS.id]?.ENABLE_IDP_PUBLISHER_TYPE
          )
        : true;
    case +PRODUCTS.SYMPHONY_V2.id:
      return isNotNullOrEmpty(req[+PRODUCTS.SYMPHONY_V2.id])
        ? isSymphonyV2ConfigValid(
            req[+PRODUCTS.SYMPHONY_V2.id] as SymphonyV2Configuration
          )
        : true;
    default:
      return true;
  }
};

export const isTargetingCountryConfigurationValid = (
  req: TargetingCountryConfiguration
) => {
  return (
    Object.keys(req).length > 0 &&
    Object.keys(req).filter((c) => isTargetingValid(req[c])).length ===
      Object.keys(req).length
  );
};

export const isTargetingConfigValidV2 = (req: TargetingConfigurationV2) => {
  if (isNilOrEmpty(req)) {
    return false;
  }
  if (!req.countries.length) {
    return false;
  }
  return true;
};

export const isConnectCountryConfigurationValid = (
  req: ConnectCountryConfiguration
) => {
  return (
    Object.keys(req).length > 0 &&
    Object.keys(req).filter((c) => isConnectValid(req[c])).length ===
      Object.keys(req).length
  );
};

export const isTargetingValid = (req: TargetingConfiguration) => {
  return req.channels.length > 0;
};
export const isConnectValid = (req: ConnectConfiguration) => {
  return (
    !!req.channels.length &&
    (req.features.externalGraph.enabled
      ? !!req.features.externalGraph.externalGraphList.length
      : true) &&
    (isNotNullOrEmpty(req.features.refreshFrequency)
      ? isNotNullOrEmpty(req.features.refreshFrequency.days) &&
        (req.features.refreshFrequency.type === 'Custom'
          ? req.features.refreshFrequency.days > 0 &&
            req.features.refreshFrequency.days <= 999
          : true)
      : true)
  );
};
export const isConnectConfigValidV2 = (req: ConnectConfigurationV2) => {
  if (isNilOrEmpty(req)) {
    return false;
  }
  if (!req.countries.length) {
    return false;
  }
  if (isNotNullOrEmpty(req.features.refreshFrequency)) {
    if (isNilOrEmpty(req.features.refreshFrequency.days)) {
      return false;
    }
    if (
      req.features.refreshFrequency.type === 'Custom' &&
      (req.features.refreshFrequency.days <= 0 ||
        req.features.refreshFrequency.days > 999)
    ) {
      return false;
    }
  }
  return true;
};

export const isDPUIValid = (req: DPUIConfiguration) => {
  return (
    !!req.features.viewTargeting ||
    !!req.features.viewManagedCampaigns ||
    !!req.features.viewConnect
  );
};

export const isDataManagerValid = (req: DataManagerConfiguration) => {
  return isCollectSourceSettingsValid(req) && isCollectRegionsSettingValid(req);
};

export const isCollectSourceSettingsValid = (req: DataManagerConfiguration) => {
  if (
    req.features.onboardSftp &&
    req.features.whitelistedIps &&
    req.features.whitelistedIps.length > 0
  ) {
    return (
      ipValidator.isIpListValid(req.features.whitelistedIps) &&
      ipValidator.isDistinct(req.features.whitelistedIps)
    );
  }
  return (
    !!req.features.onboardWebSDK ||
    !!req.features.onboardAppPixel ||
    !!req.features.onboardWebPixel ||
    !!req.features.onboardIFrame ||
    !!req.features.onboardFlatFile ||
    !!req.features.onboardSftp ||
    (!!req.features.onboardSftp &&
      req.features.whitelistedIps &&
      ipValidator.isIp(req.features.whitelistedIps) &&
      ipValidator.isDistinct(req.features.whitelistedIps)) ||
    !!req.features.onboardHttpApi ||
    !!req.features.onboardBrazeCurrents ||
    !!req.features.onboardGtm ||
    !!req.features.onboardBigquery ||
    !!req.features.onboardAndroidSdk ||
    !!req.features.onboardIosSdk ||
    !!req.features.onboardCRM ||
    !!req.features.onboardSnowflake ||
    !!req.features.onboardAdobeExt ||
    !!req.features.onboardDotDigital
  );
};

export const isCollectRegionsSettingValid = (req: DataManagerConfiguration) => {
  return !!req.features.preferred_regions?.length;
};

export const isDCRConfigValid = (req: DCRConfiguration): boolean => {
  return (
    req.data_home && req.data_mart && !!req.data_pool_access_countries.length
  );
};

export const getInitProductConfig = (
  id: number,
  product_config?: ProductConfigurationMap
): ProductConfig => {
  switch (id) {
    case 3:
      return { channels: [], features: {} };
    case 14:
      return {
        data_home: true,
        data_mart: true,
        data_pool_access_countries: [],
        cloud_assets: [],
      };
    case 15:
      return !!(product_config && product_config[id])
        ? product_config[id]
        : { features: { preferred_regions: [] } };
    case +PRODUCTS.SYMPHONY_V2.id:
      return {
        channels: [],
        regions: [],
        features: { thirdPartyLookup: false },
      };
    case +PRODUCTS.AUDIENCE.id:
      return AUDIENCE_PROD_CONFIG_DEFAULT;
    default:
      return {};
  }
};

export const getInitProductConfigV2 = (id: number): ProductConfig => {
  switch (id) {
    case +PRODUCTS.DPUI.id:
      return { channels: [], features: {} };
    case +PRODUCTS.DCR.id:
      return {
        data_home: true,
        data_mart: true,
        data_pool_access_countries: [],
        cloud_assets: [],
      };
    case +PRODUCTS.AUDIENCE.id:
      return AUDIENCE_PROD_CONFIG_DEFAULT;
    case +PRODUCTS.SYMPHONY_V2.id:
      return {
        features: { thirdPartyLookup: false },
      };
    case +PRODUCTS.CONNECT.id:
      return initialConnectFeatureState();
    case +PRODUCTS.ENVISION.id:
      return initialEnvisionState();
    default:
      return {};
  }
};

export const getInitProductConfigV3 = (id: number): ProductConfig => {
  switch (id) {
    case +PRODUCTS.ZAT.id:
      return initialTargetingFeatureState();
    default:
      return {};
  }
};

export const toTargetingConfiguration = (
  c: ApiProductConfig
): TargetingConfiguration => {
  return {
    country: { id: c.country_id, name: c.country_name },
    channels: c.config['channel_list'] ? c.config['channel_list'] : [],
    enable_extension: prop('enable_extension', c.config) || false,
    enable_affinity: prop('affinity_360', c.config) || false,
  };
};

export const toConnectConfiguration = (
  c: ApiProductConfig
): ConnectConfiguration => {
  return {
    country: { id: c.country_id, name: c.country_name },
    channels: c.config['channel_list'] ? c.config['channel_list'] : [],
    features: toConnectFeatures(c.config),
    region: c.region,
  };
};

const toConnectCountries = map((country) => ({
  id: country.id,
  region: country.region,
  iso3: country.iso3,
  isTelco: country.is_telco,
  name: country.name,
}));

export const toConnectConfigurationV2 = (
  c: ApiProductConfig
): ConnectConfigurationV2 => {
  return {
    countries: toConnectCountries(c.countries),
    features: toConnectFeatures(c.config),
  };
};

export const toTargetingConfigurationV2 = (
  c: ApiProductConfig
): TargetingConfigurationV2 => {
  return {
    countries: toConnectCountries(c.countries),
  };
};

export const getTelcoCountries = compose(
  map(prop('name')),
  filter(prop('isTelco')),
  values
);

export const initialConnectFeatureState = (
  refreshFrequency?: RefreshFrequency
) => {
  return clone({
    features: {
      lookalike: false,
      externalGraph: { enabled: false, externalGraphList: [] },
      dashboard: 'pct',
      enableInputMatchRate: false,
      thirdPartySegment: 0,
      insightSetting: 'zeo',
      uniqueUser: false,
      refreshFrequency,
      removeUsersSegment: false,
    },
    countries: [],
    doNotDisableFeatures: {},
  });
};

export const initialTargetingFeatureState = () => {
  return clone({
    countries: [],
    doNotDisableFeatures: {},
  });
};

export const initialInsightsFeatureState = () => ({
  features: {
    dashboard: 'pct',
    insightSetting: 'zeo',
  },
});

export const initialEnvisionState = () => ({
  viewRFM: false,
  viewCLV: false,
  trainingFrequency: 'monthly',
  predictionFrequency: 'monthly',
  countries: [],
  doNotDisableFeatures: {},
});

const testRefreshFrequency: RefreshFrequency = {
  type: 'Do Not Auto Refresh',
  days: -1,
};

// To test Refresh Frequency change refreshFrequency from {} to testRefreshFrequency
export const toConnectFeatures = (config: any): ConnectFeatures => {
  const connectFeature = {
    lookalike: config.lookalike === 1,
    externalGraph:
      config.external_graph_partner_list &&
      config.external_graph_partner_list.length
        ? {
            enabled: true,
            externalGraphList: config.external_graph_partner_list,
          }
        : { enabled: false, externalGraphList: [] },
    enableExternalGraph: config.enable_external_graph,
    dashboard: config.dashboard,
    enableInputMatchRate: config.enable_input_match_rate,
    thirdPartySegment: config.third_party_extension,
    zeotapGraph: config.enable_zeotap_graph,
    viewAnalytics: config.show_analytics,
    uniqueUser: config.unique_users,
    viewRFM: config.show_rfm,
    viewCLV: config.show_clv,
    refreshFrequency: !!config.refresh_frequency
      ? config.refresh_frequency
      : {},
    removeUsersSegment: config.remove_users_from_segments ?? false,
    enableEstimator: config.enable_estimator,
    enableCPM: config.enable_cpm,
    customRuleBasedDeletion: config.custom_rule_based_deletion,
  };
  if (!!config.insights_type) {
    connectFeature['insightSetting'] = config.insights_type;
  }
  return connectFeature;
};

export const toInsightsFeatures = (config: any): InsightsFeatures => {
  const connectFeature = {
    dashboard: config.dashboard,
  };
  if (!!config.insights_type) {
    connectFeature['insightSetting'] = config.insights_type;
  }
  return connectFeature;
};

export const toDPUIFeatures = (config: any): DPUIFeatures => {
  return {
    viewCurrencyConversion: config.currency_conversion,
    viewManagedCampaigns: config.view_zab_report,
    viewTargeting: config.view_zat_report,
    viewConnect: config.view_connect_dashboard,
    viewReportTab: config.view_reports_tab,
  };
};

// Data Manager
export const toDataManagerFeatures = (config: any): DataManagerFeatures => {
  return {
    onboardWebSDK: config.collect_web_sdk,
    onboardAppPixel: config.collect_app_pixel,
    onboardAndroidSdk: config.collect_android_sdk,
    onboardIosSdk: config.collect_ios_sdk,
    onboardWebPixel: config.collect_web_pixel,
    onboardIFrame: config.collect_iframe,
    onboardFlatFile: config.collect_flat_file,
    onboardSftp: config.collect_onboard_via_sftp,
    whitelistedIps: config.sftp_source_ip_list,
    onboardHttpApi: config.collect_s2s,
    onboardBrazeCurrents: config.collect_onboard_via_braze_currents,
    onboardGtm: config.collect_gtm,
    onboardAdobeExt: config.collect_onboard_via_adobe_ext,
    onboardBigquery: config.collect_onboard_via_gbq,
    onboardCRM: config.collect_onboard_via_salesforce_crm,
    privateGraphInstance: config.private_graph_location,
    privateGraphDetails: config.private_graph_org,
    preferred_regions: config.preferred_regions,
    onboardSnowflake: config.collect_onboard_via_snowflake,
    enableConsentAnalytics: config.enable_consent_analytics,
    consentAnalyticsUrl: config.consent_analytics_url_list,
    onboardDotDigital: config.collect_onboard_via_dot_digital,
    enableNCE: config.enable_non_customer_data_source,
  };
};

// toProductConfigurationMap :: ApiProductConfig[] -> TargetingCountryConfiguration
const toTargetingCountryConfiguration = compose(
  map(compose(toTargetingConfiguration, head)),
  groupBy(prop('country_id'))
);

// toProductConfigurationMap :: ApiProductConfig[] -> ConnectCountryConfiguration
const toConnectCountryConfiguration = compose(
  map(compose(toConnectConfiguration, head)),
  groupBy(prop('country_id'))
);

const toSymphonyConfiguration = (
  config: ApiProductConfig
): SymphonyConfiguration => ({
  region: {
    name: config.preferred_region as SymphonyRegions,
  },
});

// toSymphonyRegionConfiguration :: ApiProductConfig[] -> SymphonyRegionConfiguration
const toSymphonyRegionConfiguration = compose(
  map(compose(toSymphonyConfiguration, head)),
  groupBy(prop('preferred_region'))
);

const toSymphonyV2Configuration = (
  productConfig: ApiProductConfig
): SymphonyV2Configuration => ({
  channels: productConfig.config['channel_list'],
  regions: productConfig.config['preferred_regions'],
  features: {
    thirdPartyLookup: productConfig.config['third_party_lookup'],
  },
});

const toSymphonyV3Configuration = (
  productConfig: ApiProductConfig
): SymphonyV3Configuration => ({
  features: {
    thirdPartyLookup: productConfig.config['third_party_lookup'],
  },
});

const toDPUIConfiguration = (
  productConfig: ApiProductConfig
): DPUIConfiguration => {
  return {
    channels: productConfig.config['channel_list']
      ? productConfig.config['channel_list']
      : [],
    features: toDPUIFeatures(productConfig.config),
  };
};

const toDataManagerConfiguration = (
  productConfig: ApiProductConfig
): DataManagerConfiguration => {
  return {
    features: toDataManagerFeatures(productConfig.config),
  };
};

export const toDCRConfiguration = (c: ApiProductConfig): DCRConfiguration => {
  return {
    data_mart: c.config['data_mart'],
    data_home: c.config['data_home'],
    data_pool_access_countries: c.config['data_pool_countries'],
    cloud_assets: c.config['cloud_assets'],
    data_mart_asset_creation_status:
      c.config['data_mart_asset_creation_status'],
    data_home_asset_creation_status:
      c.config['data_home_asset_creation_status'],
  };
};

export const toDCRCountryConfiguration = compose(
  map(compose(toDCRConfiguration, head)),
  groupBy(prop('country_id'))
);

export const toEnvisionConfiguration = (
  c: ApiProductConfig
): EnvisionConfiguration => {
  return {
    country: { id: c.country_id, name: c.country_name },
    viewRFM: c.config['show_rfm'],
    viewCLV: c.config['show_clv'],
    trainingFrequency: c.config['training_frequency'],
    predictionFrequency: c.config['prediction_frequency'],
  };
};

const toEnvisionCountryConfiguration = compose(
  map(compose(toEnvisionConfiguration, head)),
  groupBy(prop('country_id'))
);

const toAudienceFeatures = (config) => ({
  enableLookalike: config.lookalike === 1,
  enableAudienceOverlap: config.audience_overlap,
  enableZeotapGraph: config.enable_zeotap_graph,
  enableABTest: config.ab_testing,
  enableAISegmentation: config.ai_segmentation,
  enableSegmentMembership: config.segment_membership,
  enableUserDeletion: config.enable_user_deletion,
  enableCPM: config.cpm_enabled,
  audienceTrend: config.audience_trend,
  refreshUnits: config?.refresh_units,
  minRefreshFrequencyWithExtension:
    config?.min_refresh_frequency_with_extension,
});
const toAudienceConfig = (c: ApiProductConfig): AudienceConfiguration => {
  return {
    features: toAudienceFeatures(c.config),
  };
};
export const toPredictiveAudienceConfig = (
  prodConfig: ApiProductConfig
): EnvisionConfiguration => {
  let features: ApiEnvisionProductConfig =
    prodConfig.config as ApiEnvisionProductConfig;
  return {
    countries: prodConfig.countries,
    viewRFM: features.show_rfm,
    viewCLV: features.show_clv,
    trainingFrequency: features.training_frequency,
    predictionFrequency: features.prediction_frequency,
  };
};

export const atleastOneModelSelected = (req: EnvisionConfiguration) =>
  req.viewCLV || req.viewRFM;

export const isEnvisionCountryConfigurationValid = (
  req: EnvisionCountryConfiguration
) => {
  return (
    Object.keys(req).length > 0 &&
    Object.keys(req).filter((c) => atleastOneModelSelected(req[c])).length ===
      Object.keys(req).length
  );
};
export const isPredictiveAudienceConfigValid = allPass([
  compose(isNotNullOrEmpty, prop('countries')),
  atleastOneModelSelected,
]);

export const toIDPConfiguration = (
  productConfig: ApiProductConfig
): IDPConfiguration => ({
  IDPEntity: productConfig.config['idp_entity'],
  publisherType: productConfig?.config?.['publisher_type'],
});

export const isIDPConfigurationValid = (
  req: IDPConfiguration,
  isIDPPublisherTypeEnabled: boolean
) =>
  isIDPPublisherTypeEnabled && req.IDPEntity === 'publisher'
    ? isNotNullOrEmpty(req?.publisherType)
    : isNotNullOrEmpty(req.IDPEntity);

export const isSymphonyV2ConfigValid = (config: SymphonyV2Configuration) =>
  !!config['channels']?.length && !!config['regions']?.length;

export const toProductConfiguration = (
  productConfig: ApiProductConfig[],
  productId: string
) => {
  if (productId === PRODUCTS.DPUI.id) {
    return toDPUIConfiguration(productConfig[0]);
  } else if (productId === PRODUCTS.ZAT.id) {
    return toTargetingCountryConfiguration(productConfig);
  } else if (productId === PRODUCTS.CONNECT.id) {
    return toConnectCountryConfiguration(productConfig);
  } else if (productId === PRODUCTS.DCR.id) {
    return toDCRConfiguration(productConfig[0]);
  } else if (productId === PRODUCTS.DATA_MANAGER.id) {
    return toDataManagerConfiguration(productConfig[0]);
  } else if (productId === PRODUCTS.ENVISION.id) {
    return toEnvisionCountryConfiguration(productConfig);
  } else if (productId === PRODUCTS.ID_PLUS.id) {
    return toIDPConfiguration(productConfig[0]);
  } else if (productId === PRODUCTS.SYMPHONY_V2.id) {
    return toSymphonyV2Configuration(productConfig[0]);
  } else if (productId === PRODUCTS.AUDIENCE.id) {
    return toAudienceConfig(productConfig[0]);
  } else {
    return {};
  }
};

export const toProductConfigurationV2 = (
  productConfig: ApiProductConfig[],
  productId: string
) => {
  switch (productId) {
    case PRODUCTS.DPUI.id:
      return toDPUIConfiguration(productConfig[0]);
    case PRODUCTS.ZAT.id:
      return toTargetingCountryConfiguration(productConfig);
    case PRODUCTS.CONNECT.id:
      return toConnectConfigurationV2(productConfig[0]);
    case PRODUCTS.DCR.id:
      return toDCRConfiguration(productConfig[0]);
    case PRODUCTS.ENVISION.id:
      return toPredictiveAudienceConfig(productConfig[0]);
    case PRODUCTS.ID_PLUS.id:
      return toIDPConfiguration(productConfig[0]);
    case PRODUCTS.SYMPHONY_V2.id:
      return toSymphonyV3Configuration(productConfig[0]);
    case PRODUCTS.AUDIENCE.id:
      return toAudienceConfig(productConfig[0]);
    default:
      return {};
  }
};

export const toProductConfigurationV3 = (
  productConfig: ApiProductConfig[],
  productId: string
) => {
  switch (productId) {
    case PRODUCTS.DPUI.id:
      return toDPUIConfiguration(productConfig[0]);
    case PRODUCTS.ZAT.id:
      return toTargetingConfigurationV2(productConfig[0]);
    case PRODUCTS.CONNECT.id:
      return toConnectConfigurationV2(productConfig[0]);
    case PRODUCTS.DCR.id:
      return toDCRConfiguration(productConfig[0]);
    case PRODUCTS.ENVISION.id:
      return toPredictiveAudienceConfig(productConfig[0]);
    case PRODUCTS.ID_PLUS.id:
      return toIDPConfiguration(productConfig[0]);
    case PRODUCTS.SYMPHONY_V2.id:
      return toSymphonyV3Configuration(productConfig[0]);
    case PRODUCTS.AUDIENCE.id:
      return toAudienceConfig(productConfig[0]);
    case PRODUCTS.DATA_MANAGER.id:
      return toDataManagerConfiguration(productConfig[0]);
    default:
      return {};
  }
};

// toProductConfigurationMap :: ApiProductConfig[] -> ProductConfigurationMap[]
export const toProductConfigurationMap = pipe(
  groupBy(prop('product_id')),
  mapObjIndexed(toProductConfiguration)
);

export const toProductConfigurationMapV2 = pipe(
  groupBy(prop('product_id')),
  mapObjIndexed(toProductConfigurationV2)
);

export const toProductConfigurationMapV3 = pipe(
  groupBy(prop('product_id')),
  mapObjIndexed(toProductConfigurationV3)
);
// addAllIdName :: IdName[] -> IdName[]
export const addAllIdName = concat([{ id: -1, name: 'All' }]);

const removeDuplicateIdNameById = (acc, curr) =>
  acc.find((val) => val.id === curr.id) ? acc : acc.concat(curr);

// getProductConfigCountries :: ProductConfigMap -> IdName[]
export const getProductConfigCountriesWithoutAll = compose(
  reduce(removeDuplicateIdNameById, []),
  reject(isNil),
  pluck('country'),
  flatten,
  map(values),
  values
);

export const getProductConfigCountries = compose(
  addAllIdName,
  getProductConfigCountriesWithoutAll
);

export const hasDestinationsDependency = (ids: number[]): boolean => {
  return (
    intersection(ids, [
      +PRODUCTS.DPUI.id,
      +PRODUCTS.ZAT.id,
      +PRODUCTS.CONNECT.id,
      +PRODUCTS.SYMPHONY_V2.id,
      +PRODUCTS.AUDIENCE.id,
    ]).length > 0
  );
};

export const hasDataManagerDependency = (ids: number[]): boolean => {
  return (
    intersection(ids, [
      +PRODUCTS.CONNECT.id,
      +PRODUCTS.SYMPHONY_V2.id,
      +PRODUCTS.CUSTOMER_360.id,
      +PRODUCTS.AUDIENCE.id,
    ]).length > 0
  );
};

export const isDataManager = (p: Product): boolean =>
  p.id === +PRODUCTS.DATA_MANAGER.id;

export const isConnect = (p: Product): boolean => p.id === +PRODUCTS.CONNECT.id;

export const isEnvision = (p: Product): boolean =>
  p.id === +PRODUCTS.ENVISION.id;

export const isSymphonyV2 = (p: Product): boolean =>
  p.id === +PRODUCTS.SYMPHONY_V2.id;

export const isCustomer360 = (p: Product): boolean =>
  p.id === +PRODUCTS.CUSTOMER_360.id;

export const isAudience = (p: Product): boolean =>
  p.id === +PRODUCTS.AUDIENCE.id;

export const isCollectAndConnectSelected = compose(
  equals(2),
  length,
  filter(allPass([anyPass([isDataManager, isConnect]), prop('selected')]))
);

export const setDisabledRegion = (collectRegions) => (v, k, o) => ({
  region: {
    ...v.region,
    disabled: !includes(k, collectRegions),
  },
});
export const setSymphonyAvailableRegions = (collectRegions) =>
  compose(mapObjIndexed(setDisabledRegion(collectRegions)), clone);

export const isNotPresentInCollectRegions = (
  region: string,
  collectConfig: DataManagerConfiguration
) => compose(not, includes(region), collectRegionsPath)(collectConfig);
