<div class="flex flex-row w-full">
  <div class="w-[10px] min-w-[10px]"></div>
  <!-- if you change this with you need to change the navbar with too. -->

  <div class="p-3 bg-primary-1-900 rounded-lg shrink-0 flex justify-center items-center w-[50px] h-[50px]" [routerLink]="'dashboard'" routerLinkActive="bg-white bg-opacity-10 border border-white" [ngClass]="{ 'cursor-pointer': collapsed }">
    <img class="object-fill h-7 w-7" src="assets/img/logo2x.png" />
  </div>
  <div class="flex flex-row overflow-hidden w-full transition-width ease-in-out delay-200 duration-500" [ngClass]="{ '!w-0': collapsed }">
    <div class="flex flex-col ml-3">
      <span class="font-primary font-bold text-2xl text-white">Zeotap</span>
      <div *ngIf="showCDPUsage" class="font-primary text-grey-300 flex items-center mt-1 text-xs"><a class="text-grey-300 hover:text-grey-300 underline decoration-grey-300" [routerLink]="'dashboard'"> Dashboard </a> <span class="ml-1 !text-xs unity-icons-dropdown -before-rotate-90"></span></div>
    </div>
  </div>
  <div class="w-[10px] min-w-[10px]"></div>
</div>
