import { ConfigService } from '@zeotap-ui/config';
import { Injectable } from '@angular/core';
import { compose } from 'ramda';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { handleAsyncError, HttpService } from '../../http';
import { CountryAPIResponse } from '../types';
import { toAllCountryAPIResponse } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(
    private httpService: HttpService,
    private config: ConfigService
  ) {}

  getAllCountries(): Observable<CountryAPIResponse> {
    return from(
      this.httpService.doGet(
        `${this.config.appConfig.userSvcUrlV3}country/`,
        this.httpService.getHeader('')
      )
    ).pipe(
      map(toAllCountryAPIResponse),
      catchError(
        compose(
          of,
          handleAsyncError<CountryAPIResponse>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }

  getProductAndOrgSpecificCountries(payload): Observable<CountryAPIResponse> {
    return from(
      this.httpService.doPost(
        `${this.config.appConfig.userSvcUrlV3}organization/products/countries`,
        payload,
        this.httpService.getHeader('')
      )
    ).pipe(
      map(toAllCountryAPIResponse),
      catchError(
        compose(
          of,
          handleAsyncError<CountryAPIResponse>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }
}
