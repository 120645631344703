import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorStatusComponent } from './error-status/error-status.component';
import { CoreDirectivesModule } from '@zeotap-ui/core';

@NgModule({
  declarations: [ErrorStatusComponent],
  imports: [CommonModule, CoreDirectivesModule],
  exports: [ErrorStatusComponent],
})
export class LoaderModule {}
