import { Component, EventEmitter, Input, Output } from '@angular/core';
export const GET_STARTED = 'GET STARTED';
export const TOOLTIP_POSITIONS = [
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: 20,
  },
];

@Component({
  selector: 'zap-welcome-page',
  templateUrl: './welcome-page.component.html',
})
export class WelcomePageComponent {
  @Input() header: string;
  @Input() subtext: string;
  @Input() imageURL: string;
  @Input() label = GET_STARTED;
  @Input() isButtonEnabled = false;
  @Input() isDisabled = false;
  @Input() disabledTooltipText: string;
  @Input() tooltipPositions = TOOLTIP_POSITIONS;
  @Output() onClick = new EventEmitter();
}
