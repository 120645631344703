import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(inputString: string, regex: string, replaceWith: string): string {
    // Check if the input value is null or undefined
    if (inputString == null) {
      return '';
    }

    // Check if the regex is empty
    if (regex === '') {
      return inputString;
    }

    // Replace all occurrences of regex with replaceWith
    return inputString.replace(new RegExp(regex, 'g'), replaceWith);
  }
}
