import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  ERROR_MSGS,
  hasDuplicateValue,
  isNilOrEmpty,
  isNotNullOrEmpty,
  removeDuplicateErrorIfPresent,
  InputFormField,
  InputProperties,
} from '@zeotap-ui/core';

@Directive({ selector: '[icon-tmp]' })
export class IconTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'zap-key-value-list-input',
  templateUrl: './key-value-list-input.component.html',
  styleUrls: ['./key-value-list-input.component.scss'],
})
export class KeyValueListInputComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() arrayName: string = 'details';
  @Input() minRow: number = 0; // for later purposes
  @Input() leftInputField: InputFormField;
  @Input() leftInputFieldOptions: any[];
  @Input() rightInputField: InputFormField;
  @Input() rightInputFieldOptions: any[];
  @Input() addBtnName: string = 'Row';
  @Input() maxRow: number = null;
  @Input() defaultValues: { [key: string]: any };
  @Input() savedFormValue: any[] = [];
  @Input() isViewState: boolean = false;
  @Input() properties: InputProperties[] = [];
  @Input() showLatestTooltip: boolean = false;
  @Input() tooltipClass: string = 'tool-tip-custom';
  @Input() closeIcon: string = 'close';
  @Input() showTooltipForNullValues = false;
  @Input() tooltipPositions: ConnectedPosition[] = [
    {
      // tooltip towards bottom
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 5,
    },
  ];
  @ContentChild(IconTemplateDirective, {
    read: TemplateRef,
  })
  iconTemplate: TemplateRef<any>;

  @Output() scroll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() leftFormValueSelected: EventEmitter<number> = new EventEmitter();
  @Output() rightFormValueSelected: EventEmitter<number> = new EventEmitter();
  @Output() rowRemoved: EventEmitter<number> = new EventEmitter();
  @Output() propertyChanged: EventEmitter<any> = new EventEmitter();
  openSelector: NgSelectComponent;
  scroll$ = new Subject<string>();
  isSelectorOpen$ = new BehaviorSubject<boolean>(false);
  unsubscribe$ = new Subject();
  constructor(private formBuilder: UntypedFormBuilder) {
    this.scroll$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((targetClassName) => {
        if (
          this.isSelectorOpen$.value &&
          !(targetClassName?.indexOf('ng-dropdown-panel-items') > -1)
        ) {
          this.openSelector?.close();
        }
      });
  }

  ngOnInit() {
    window.addEventListener('scroll', this.onScroll, true);
    this.formGroup.addControl(this.arrayName, this.buildFormArray());
  }

  ngAfterViewInit() {
    this.setDuplicateValidation();
  }

  setDuplicateValidation() {
    this.formArray.valueChanges
      .pipe(debounceTime(600), takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        value.forEach((__, index) => {
          this.duplicateInputValueValidator(index);
        });
      });
  }

  buildFormArray() {
    return this.formBuilder.array(
      this.buildFormArrayItems(),
      this.minRow ? [Validators.required] : []
    );
  }

  buildFormArrayItems() {
    return isNotNullOrEmpty(this.savedFormValue)
      ? this.savedFormValue.map((item) => this.createItem(item))
      : Array.from({ length: this.minRow }, () => this.createItem({}));
  }

  get formArray() {
    return this.formGroup.get(this.arrayName) as UntypedFormArray;
  }

  addRow(add: HTMLElement) {
    this.formArray.push(this.createItem({}));
    setTimeout(() => {
      add.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      });
    }, 0);
    if (this.maxRow === this.formArray.length) this.scroll.emit(true);
  }

  removeRow(index) {
    this.formArray.removeAt(index);
    this.formGroup.updateValueAndValidity();
    this.rowRemoved.next(index);
  }

  createItem(item): UntypedFormGroup {
    const obj = {
      [this.leftInputField.formKey]: [
        isNotNullOrEmpty(item) ? item[this.leftInputField.formKey] : null,
        this.leftInputField.validations,
      ],
      [this.rightInputField.formKey]: [
        {
          value: isNotNullOrEmpty(item)
            ? item[this.rightInputField.formKey]
            : null, // this is a way to set the value to the rightInputFieldFormKey control and also to disable the control
          disabled: !!item.disableValue,
        },
        this.rightInputField.validations,
      ],
    };
    this.properties.forEach((prop: InputProperties) => {
      prop.type === 'checkbox' || 'radio' || 'toggle'
        ? (obj[prop.key] = [
            {
              value: item[prop.key] === undefined ? false : item[prop.key],
              disabled: item['disabled'] === undefined ? false : item.disabled,
            },
            prop.validations !== undefined ? prop.validations : [],
          ])
        : '';
    });
    return this.formBuilder.group(obj);
  }

  dropDownChanged(row, leftOrRight: string = 'left') {
    if (this.defaultValues) {
      this.formArray
        .at(row)
        .get(this.rightInputField.formKey)
        .setValue(
          this.defaultValues[
            this.formArray.at(row).get(this.leftInputField.formKey).value
          ] ?? null
        );
    }
    leftOrRight === 'left'
      ? this.leftFormValueSelected.emit(row)
      : this.rightFormValueSelected.emit(row);
  }

  onPropertyChange(row) {
    this.propertyChanged.emit(row);
  }

  getFormControl(row: number, key: string) {
    return this.formArray.at(row).get(key);
  }

  showLeftInputFieldErrors(ind: number) {
    return this.shouldShowErrorMessage(ind, this.leftInputField.formKey);
  }

  shouldShowErrorMessage(row: number, key: string) {
    return (
      this.getFormControl(row, key).touched &&
      this.getFormControl(row, key).errors
    );
  }

  getErrorMessage(row: number, key: string) {
    return this.getFormControl(row, key).errors
      ? Object.values(this.getFormControl(row, key).errors)[0]
      : null;
  }

  showNullTooltip(row: number, key: string) {
    return (
      this.showTooltipForNullValues &&
      isNilOrEmpty(this.getFormControl(row, key).value)
    );
  }

  onDropDownOpen(selector) {
    this.openSelector = selector;
    setTimeout(() => this.isSelectorOpen$.next(true), 100);
  }

  onDropDownClose() {
    this.openSelector = null;
    this.isSelectorOpen$.next(false);
  }

  onScroll = (event: any) => {
    this.scroll$.next(event.target.className as string);
  };

  duplicateInputValueValidator(row: number) {
    const hasDuplicateError = hasDuplicateValue(
      this.getFormControl(row, this.leftInputField.formKey).value,
      this.leftInputField.formKey
    )(this.formArray.value);
    if (hasDuplicateError) {
      this.getFormControl(row, this.leftInputField.formKey).setErrors({
        duplicate: ERROR_MSGS.DUPLICATE_VALUE_ERROR,
      });
    } else {
      const errors = this.getFormControl(row, this.leftInputField.formKey)
        .errors;
      const updatedErrors = errors && removeDuplicateErrorIfPresent(errors);
      this.getFormControl(row, this.leftInputField.formKey).setErrors(
        updatedErrors
      );
    }
  }
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    window.removeEventListener('scroll', this.onScroll, true);
  }
}
