import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ZuiLibModule } from '@zeotap.infra/zui-lib';
import { CustomFooterModule } from '../custom-footer';

import { EmailModalComponent } from './email-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, ZuiLibModule, CustomFooterModule],
  exports: [EmailModalComponent],
  declarations: [EmailModalComponent],
  providers: [],
})
export class EmailModalModule {}
