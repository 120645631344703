<div class="ribbon flex items-center">
  <span
    class="icon"
    [className]="ribbonIcon"
    [ngClass]="{
      'text-state-error-700 zui-error-text-color': colorScheme === 'error',
      'text-primary-1 zui-primary-text-color': colorScheme === 'brand',
      'text-state-success zui-success-text-color': colorScheme === 'success'
    }"
  >
  </span>
  <div
    class="font-tertiary"
    [ngClass]="{
      'text-state-error-700 zui-error-text-color': colorScheme === 'error',
      'text-primary-1 zui-primary-text-color': colorScheme === 'brand',
      'text-state-success zui-success-text-color': colorScheme === 'success'
    }"
  >
    {{ label }}
  </div>
</div>
