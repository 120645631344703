import { Injectable, inject } from '@angular/core';
import {
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  Router,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, share, shareReplay } from 'rxjs/operators';

interface RoutingState {
  isRouting: boolean;
}

/**
 * This service is to be used to not rewrite listners  for routing events in every routing home component
 * For using this , add this service to the providers of your component
 */
@Injectable()
export class RoutingEventsFacadeService {
  store$ = new BehaviorSubject<RoutingState>({
    isRouting: false,
  });
  state$: Observable<RoutingState> = this.store$.asObservable();
  isRouting$ = this.state$.pipe(
    map((state) => state.isRouting),
    distinctUntilChanged(),
    shareReplay(1)
  );

  update(value: Partial<RoutingState>) {
    this.store$.next({
      ...this.store$.value,
      ...value,
    });
  }

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckStart) {
        this.update({ isRouting: true });
      }
      if (
        event instanceof GuardsCheckEnd ||
        event instanceof NavigationCancel
      ) {
        this.update({ isRouting: false });
      }
    });
  }
}
