<div class="h-full pb-[52px]">
  <div class="bg-black p-5 h-full">
    <div class="text-grey-400 text-xs py-5 break-all">{{ textFromParent }}</div>
  </div>
  <div class="flex justify-end px-5 py-2 border border-solid border-grey-200">
    <button (click)="copyTextToClipboard()" class="border border-solid rounded border-primary-1-500 py-2 px-4 flex items-center">
      <span class="unity-icons-copy text-primary-1-500 pr-1"></span>
      <span class="text-primary-1-500 text-xs font-semibold" *ngIf="buttonText$ | async as buttonText">{{ buttonText }}</span>
    </button>
  </div>
</div>
