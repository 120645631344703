import validate from 'ip-validator';
import { isInteger } from './numberUtils';

export const nameValidator = {
  isNameFormat: (nameRegex, name: string) => {
    return nameRegex.test(name);
  },
  maxLength: (name: string, maxLength = 50) => {
    return !!name ? name.trim().length <= maxLength : true;
  },
};

export const emailValidator = (email: string) => {
  return new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/).test(
    email
  ); //TODO: move regex to const
};

export const ipValidator = {
  isIp: (value) => {
    if (value && value.includes(' ')) return false;
    return validate.ipv4(value) || validate.ipv6(value);
  },
  isIpListValid: (list) => !list.some((v) => !ipValidator.isIp(v)),
  isDistinct: (array) => {
    return !array || new Set(array).size == array.length;
  },
};

export const integerValidation = (value: number) =>
  !isInteger(value) ? 'This field should contain only digits' : null;

export const minMaxValidation = (
  value: number,
  minValue: number,
  maxValue: number
) =>
  !(value >= minValue && value <= maxValue)
    ? `Please enter a number (${minValue}-${maxValue})`
    : null;

export const ttlValidation = (value: number) =>
  integerValidation(value) || minMaxValidation(value, 1, 3650);

export const lengthValidation = (value: number) =>
  integerValidation(value) || minMaxValidation(value, 1, 256);

export const rangeValidation = (
  minLength: number,
  maxLength: number,
  type: string
) => {
  if (!!lengthValidation(minLength) && type === 'minLength') {
    return lengthValidation(minLength);
  } else if (!!lengthValidation(maxLength) && type === 'maxLength') {
    return lengthValidation(maxLength);
  }
  return !(minLength < maxLength)
    ? 'Minimum value should be less than maximum value'
    : null;
};
