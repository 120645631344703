<div class="card {{styleClass}}" (click)="toggleCardSelectState($event)"  [ngClass]="{'active' : cardActiveFlag}" [style.backgroundColor]="styleBg" >
    <div *ngIf="cardActiveFlag" class="activeContainer">
        <label class="circle"></label>
        <span class="icon tick check-icon"></span>
    </div>
    <div class="imageContainer">
        <img src="{{image}}" />
    </div><div class="contentContainer">
        <h3 class="zui-heading5">{{header}}</h3>
        <p class="zui-italictext">{{description}}</p>
        <ng-content></ng-content>
    </div>
</div>

