<section class="relative flex mb-6 w-full">
  <div *ngFor="let tab of tabs">
    <ng-container *ngIf="tab?.show === undefined ? true : tab.show">
      <div
        *ngIf="{
          active: tab.id === currentTab && !disabled ? stateStyles.active : '',
          nonActive: tab.id !== currentTab && !disabled ? stateStyles.nonActive : '',
          disabled: disabled ? stateStyles.disabled : ''
        } as stateStyle"
        [ngClass]="[stateStyle.active, stateStyle.nonActive, stateStyle.disabled, textStyle]"
        [routerLink]="tab?.routerLink"
        [routerLinkActive]="stateStyles.active"
        class="inline-block w-max align-top cursor-pointer px-4 pb-2"
        (click)="disabled || tab.id === currentTab ? $event.stopPropagation() : onActive.emit(tab.id)"
      >
        {{ tab.label || tab.name | translate }}
      </div>
    </ng-container>
  </div>
  <div class="w-full border-b-2 border-solid border-grey-100" [ngClass]="[fullLengthBorderStyle]"></div>
</section>
