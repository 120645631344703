import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@zeotap-ui/core';
import { ALLOWED_PRODUCTS } from './usage.const';
import { ConfigService } from '@zeotap-ui/config';

@Injectable()
export class UsageRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private configService: ConfigService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const hasAccess = ALLOWED_PRODUCTS.some((product) =>
      this.authService.hasProductAccess(product.name)
    );
    return of(
      this.configService.getFeatureValue('SISENSE_CONFIG').enabled && hasAccess
    ).pipe(
      map((hasAccess) => {
        if (hasAccess) {
          return true;
        }
        this.routeToBasePage(route.data?.defaultRedirect ?? '');
        return false;
      })
    );
  }

  routeToBasePage(route) {
    this.router.navigate([route]);
  }
}
