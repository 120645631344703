<ng-container *ngIf="showModal">
  <div class="dialog-prompt fixed z-[999] w-full h-full top-0 left-0 right-0 bottom-0 bg-primary-1-900/70 flex justify-center items-center">
    <ng-container *ngTemplateOutlet="contentTemplate ? contentTemplate : defaultContentTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #defaultContentTemplate>
  <div class="p-6 bg-white min-h-[280px] w-[37.5rem] rounded shadow flex items-center flex-col gap-6" [ngClass]="promptClass">
    <ng-container *ngIf="iconWarning || iconSuccess || iconTemplate">
      <ng-container *ngTemplateOutlet="iconTemplate ? iconTemplate : defaultIconTemplate"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="promptTextTemplate ? promptTextTemplate : defaultPromptTextTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="descriptionTemplate ? descriptionTemplate : defaultDescTemplate"></ng-container>
    <div class="border-b border-solid border-b-grey-300/30 w-full"></div>
    <ng-container *ngTemplateOutlet="footerTemplate ? footerTemplate : defaultFooterTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #defaultIconTemplate>
  <ng-container *ngIf="iconWarning">
    <span class="unity-icons-warning text-state-error-700 !text-6xl" [ngClass]="{ '!text-state-warning-1-500': actionButtonType === 'Warning' }"></span>
  </ng-container>
  <ng-container *ngIf="iconSuccess">
    <span class="unity-icons-check text-state-success-700 !text-6xl"></span>
  </ng-container>
</ng-template>

<ng-template #defaultDescTemplate>
  <div class="font-primary text-sm text-grey-900 text-center leading-5 w-full break-words" [innerHTML]="promptDesc"></div>
</ng-template>

<ng-template #defaultPromptTextTemplate>
  <div class="font-secondary-bold text-2xl text-primary-1-900 text-center" [innerHTML]="promptText"></div>
</ng-template>

<ng-template #defaultFooterTemplate>
  <div class="grid grid-cols-2 gap-2 w-full h-12">
    <button class="bg-grey-200 text-primary-1-900 flex items-center justify-center font-primary-medium text-sm border-none rounded" (click)="onCancel.emit()">{{ cancelActionName | titlecase }}</button>
    <button
      class="flex items-center justify-center font-primary-medium text-sm border-none rounded text-primary-1-900"
      [ngClass]="disableProceed ? '!text-grey/52 !bg-grey-100' : { '!text-state-error-700 bg-state-error-700/10': actionButtonType === 'Error', '!text-state-success-700 bg-state-success-700/10': actionButtonType === 'Success', '!text-state-warning-1-500 bg-state-warning-1-500/10': actionButtonType === 'Warning', '!text-primary-1 bg-primary-1/8': actionButtonType === 'Default' }"
      (click)="onProceed.emit()"
      [disabled]="disableProceed"
    >
      {{ proceedActionName | titlecase }}
    </button>
  </div>
</ng-template>
