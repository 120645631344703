import { PosterComponent } from './poster.component';
import { PosterHeaderComponent } from './poster-header.component';
import { PosterContentComponent } from './poster-content.component';
import { PosterFooterComponent } from './poster-footer.component';

export {
  PosterComponent,
  PosterHeaderComponent,
  PosterContentComponent,
  PosterFooterComponent
};
