<ng-container *ngIf="showStepper()">
  <div class="zui-stepper-wrapper" *ngFor="let step of steps; index as i">
    <ng-container [ngTemplateOutlet]="stepTemplate" [ngTemplateOutletContext]="{ stepData: step, index: i, lastChild: i === steps.length - 1 }"> </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="allStepsCompleted()" [ngTemplateOutlet]="showWhenComplete"></ng-container>

<ng-template #stepTemplate let-stepData="stepData" let-index="index" let-lastChild="lastChild">
  <div class="text-sm font-primary text-grey-900 zui-step">
    <div class="flex items-center zui-step-title-wrapper">
      <div class="font-secondary-bold zui-step-icon mr-[13px]">
        <div class="w-6 h-6 leading-6 text-center text-white step-icon rounded-3xl bg-grey/52" [ngClass]="{ 'active !bg-primary-1': isActiveStep(index) && !stepData.disabled, 'disabled !bg-grey/52': stepData.disabled }" *ngIf="!stepData.isComplete; else completedIcon">
          {{ index + 1 }}
        </div>

        <!-- Check Icon: shows when the configuration in a step is complete -->
        <ng-template #completedIcon>
          <div class="completed icon tick"></div>
        </ng-template>
      </div>
      <div class="leading-6 font-secondary-bold zui-step-heading text-grey-800">{{ stepData.title }}</div>
    </div>
    <div class="zui-step-description flex relative leading-[14px] text-grey/52">
      <div class="absolute h-full border-l border-dashed zui-vertical-line border-l-grey/52 left-3" *ngIf="!lastChild"></div>
      <div class="zui-description-content ml-[42px] pt-2 pb-[46px]">
        <div class="text-xs font-primary description text-grey/52" [ngClass]="{ 'description-margin mb-[26px]': isActiveStep(index) && !stepData.disabled }">
          {{ stepData.description }}
        </div>
        <div class="font-secondary-bold action-buttons" *ngIf="isActiveStep(index) && !stepData.disabled">
          <div class="submit-btn text-primary-1 bg-primary-1/10 h-10 inline-flex items-center cursor-pointer rounded mr-[19px] px-[0.8125rem] py-2.5 border-0" (click)="this.submitClick.emit(stepData.id)">
            {{ stepData.submitBtnText }}
          </div>
          <div class="reset-btn bg-grey-50 text-grey-800 h-10 inline-flex items-center cursor-pointer rounded mr-[19px] px-[0.8125rem] py-2.5 border-0" (click)="this.resetClick.emit(stepData.id)">
            {{ stepData.resetBtnText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
