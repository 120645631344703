import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  distinctUntilChanged,
  pluck,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isError } from '../../http';
import { SelectedOrgFacadeService } from '../../org';
import { UserInfo, UsersInfoAPIResponse } from '../types';
import { UserService } from './user.service';

export type UsersForOrgState = {
  users: UserInfo[];
  inProgress: boolean;
  error: string;
};
const _initialState: UsersForOrgState = {
  users: [],
  inProgress: false,
  error: null,
};

@Injectable({
  providedIn: 'any',
})
export class UsersForOrgFacade {
  private store$ = new BehaviorSubject<UsersForOrgState>(_initialState);
  private state$ = this.store$.asObservable();

  users$ = this.state$.pipe(
    pluck('users'),
    distinctUntilChanged(),
    shareReplay(1)
  );
  error$ = this.state$.pipe(
    pluck('error'),
    distinctUntilChanged(),
    shareReplay(1)
  );

  loading$ = this.state$.pipe(
    pluck('inProgress'),
    distinctUntilChanged(),
    shareReplay(1)
  );
  constructor(
    private selectedOrgFacade: SelectedOrgFacadeService,
    private userService: UserService
  ) {
    this.selectedOrgFacade.selectedOrg$
      .pipe(
        tap(() => this.resetUsers()),
        switchMap((org) => this.userService.fetchUsersForOrg(org.id))
      )
      .subscribe((res: UsersInfoAPIResponse) => {
        if (isError(res?.status)) {
          this.onLoadUsersrror(res?.error);
        } else {
          this.onLoadUsersSuccess(res.data);
        }
      });
  }

  resetUsers() {
    this.store$.next({
      ..._initialState,
      inProgress: true,
    });
  }
  private onLoadUsersrror(error) {
    this.store$.next({
      ...this.store$.value,
      inProgress: false,
      error,
    });
  }
  private onLoadUsersSuccess(users: UserInfo[]) {
    this.store$.next({
      ...this.store$.value,
      inProgress: false,
      users,
    });
  }
}
