import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  ValidatorErrorDescription,
  defaultToEmptyArray,
} from '@zeotap-ui/core';
import { values, reduce, compose, filter } from 'ramda';
import { Subject } from 'rxjs';

@Directive()
export class BaseControl {
  @Input() public placeholderText: string = '';
  @Input() public controlKey: string;
  @Input() public id = `base-${
    Date.now().toString() + performance.now().toString().split('.').join('')
  }`;
  @Input() public tooltipPositions = [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 5,
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 5,
      offsetX: 10,
    },
  ];
  @Output() onChange = new EventEmitter<any>();
  private unsubscribe$ = new Subject<void>();
  public groupErrorsByType = (
    control: AbstractControl
  ): Record<string, (string | ValidatorErrorDescription)[]> => {
    return compose(
      reduce(
        (acc, error) => ({
          ...acc,
          [error.type ?? 'message']: [
            ...defaultToEmptyArray(acc[error.type ?? 'message']),
            error,
          ],
        }),
        {}
      ),
      filter((error) => !error.showOnceTouched || control.touched),
      values
    )(control?.errors);
  };
  listenToControlChanges(control: AbstractControl) {
    control?.valueChanges?.subscribe((value) => {
      this.onChange.emit(value);
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
