<ng-template #special>
  <div class="flex gap-1 h-9 justify-center items-center rounded px-5 py-2 cursor-pointer text-xs" [ngClass]="[widthClass, customSizeCSS, !disabled ? 'bg-gradient-to-r from-secondary-2-500 to-primary-2-500' : '', customClass]" [class.bg-grey-300]="disabled" [class.bg-primary-1]="!disabled" (click)="onClicked()">
    <ng-container *ngTemplateOutlet="btnLeftTemplate ? btnLeftTemplate : iconTemplate"></ng-container>
    <span class="text-white font-primary btn-text" [ngClass]="textStyle"> {{ text }}</span>
    <ng-container *ngTemplateOutlet="btnRightTemplate ? btnRightTemplate : ''"></ng-container>
  </div>
</ng-template>

<ng-template #regular>
  <div class="flex gap-1 h-12 justify-center items-center rounded px-6 py-3 cursor-pointer" [ngClass]="[widthClass, customSizeCSS, customButtonCSS, customClass]" (click)="onClicked()">
    <ng-container *ngTemplateOutlet="btnLeftTemplate ? btnLeftTemplate : iconTemplate"></ng-container>
    <span class="text-white font-primary btn-text" [ngClass]="textStyle"> {{ text }}</span>
    <ng-container *ngTemplateOutlet="btnRightTemplate ? btnRightTemplate : ''"></ng-container>
  </div>
</ng-template>
<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'special'">
    <ng-container *ngTemplateOutlet="special"> </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="regular"> </ng-container>
  </ng-container>
</ng-container>

<ng-template #iconTemplate>
  <ng-container *ngIf="icon">
    <zap-icon [icon]="icon" [size]="size" [type]="type" [iconStyle]="iconStyle" [disabled]="disabled" [customIconClass]="customIconClass"></zap-icon>
  </ng-container>
</ng-template>
