import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ContentDetectorComponent,
  DetectableDirective,
} from './content-detector/content-detector.component';

@NgModule({
  declarations: [ContentDetectorComponent, DetectableDirective],
  imports: [CommonModule],
  exports: [ContentDetectorComponent, DetectableDirective],
})
export class ContentDetectionModule {}
