import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoRibbonComponent } from './info-ribbon/info-ribbon.component';

@NgModule({
  declarations: [InfoRibbonComponent],
  imports: [
    CommonModule
  ],
  exports: [InfoRibbonComponent]
})
export class InfoRibbonModule { }
