import { AsyncCallResponse } from '../../http';

export type ProductInfo = { [productKey: number]: any };

export type UserMetadata = {
  firstName: string;
  lastName: string;
  emailId: string;
  productInfo?: ProductInfo;
};

export type UserInfo = {
  name: string;
  email: string;
  selected?: boolean;
};

export type UsersInfoAPIResponse = AsyncCallResponse<UserInfo[]>;
