import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'zap-floating-panel',
  templateUrl: './floating-panel.component.html',
})
export class FloatingPanelComponent implements OnInit {
  @Input() backText = 'Back';
  @Input() nextText = 'Next';
  @Input() showBackBtn = true;
  @Input() showNextBtn = true;
  @Input() saveInprogress = false;
  @Input() disableNextBtn = false;
  @Output() nextClicked = new EventEmitter();
  @Output() backClicked = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onNext() {
    if (!this.disableNextBtn) {
      this.nextClicked.emit();
    }
  }
}
