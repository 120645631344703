import { Component, Input } from '@angular/core';
import { BaseControl, getControlValueAccessor } from '../base';

@Component({
  selector: 'zui-image-chart',
  styleUrls: ['./image-chart.scss'],
  templateUrl: './image-chart.html',
  providers: [getControlValueAccessor(ImageChartComponent)]
})
export class ImageChartComponent {
  @Input() public data: any;
  @Input() public colors = [];
  public random = 0;
  public enableRendering = false;
  public classNameCircle1: string;
  public classNameCircle2: string;
  constructor() {
    this.classNameCircle1 = `circle-container-big${Math.floor(
      Math.random() * 2
    )}`;
    this.classNameCircle2 = `circle-container${Math.floor(Math.random() * 2)}`;
  }
  public setClassName(classIndex) {
    return classIndex === 1 ? this.classNameCircle1 : this.classNameCircle2;
  }
}
