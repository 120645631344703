<div class="column-list-container flex justify-end">
  <span cdkOverlayOrigin #optionListTrigger="cdkOverlayOrigin" class="icon column-listing cursor-pointer text-xs text-grey/52 rotate-90 transition-all ease-in-out duration-300" data-testid="toggle-btn" [ngClass]="{ '!rotate-0': showColumnList }" (click)="showList()"></span>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="optionListTrigger" [cdkConnectedOverlayOpen]="showColumnList" [cdkConnectedOverlayPositions]="positions">
  <div class="column-list rounded p-3 shadow-1 bg-white font-primary text-xs" [ngClass]="selectionClass">
    <div class="font-primary-medium column-list-header text-grey-800 text-[13px]">{{ 'UU_COLUMNS_TO_SHOW' | translate }}</div>
    <div *ngIf="isNotNullOrEmpty(maxColumnCount)" class="max-count-header mt-2 text-grey/52 text-xs">{{ 'UU_MAX_COLUMN_COUNT' | translate : { MAX_COULMN_COUNT: maxColumnCount } }}</div>
    <ng-content></ng-content>
    <ul class="max-h-80 min-h-8 overflow-scroll">
      <ng-container *ngIf="listingColumns | toggleListLength; else noData">
        <ng-container *ngFor="let col of listingColumns">
          <li *ngIf="isNotNullOrEmpty(col?.toggleBasedOn) ? col?.toggleBasedOn : true" class="column-container mt-[0.05rem] first:mt-[0.9375rem] last:mb-[0.4375rem]">
            <zui-checkbox class="zui-checkbox-preset-1 [&_label]:flex [&_label]:items-center [&_span]:truncate [&_div]:!w-full" [id]="col.id || col.name" [label]="col.name | translate | titlecase" [labelPosition]="'right'" [ngModel]="col.selected" (ngModelChange)="toggle(col)" [disabled]="col.mandatory || (isNotNullOrEmpty(maxColumnCount) && checkSelectedCount() && !col.selected)" customToolTip [text]="col.name | translate | titlecase" [customTooltipClass]="'!w-full !p-2 !max-w-fit'" [positions]="tooltipPositions"></zui-checkbox>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</ng-template>
<ng-template #noData>
  <div class="relative w-full">
    <p class="text-grey-900 font-primary text-xs mt-5">No results found.</p>
  </div>
</ng-template>
