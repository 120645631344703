import { Pipe, PipeTransform } from '@angular/core';
import { ListingColumn, isNotNullOrEmpty } from '@zeotap-ui/core';

@Pipe({
  name: 'toggleListLength',
})
export class ToggleListLengthPipe implements PipeTransform {
  transform(
    array: Partial<ListingColumn>[],
    property: string = 'toggleBasedOn'
  ): boolean {
    return (
      array.filter((obj) =>
        isNotNullOrEmpty(obj?.[property]) ? obj?.[property] : true
      ).length > 0
    );
  }
}
