import { ConfigService } from '@zeotap-ui/config';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { compose } from 'ramda';
import { handleAsyncError, HttpService, Status } from '../../http';
import { CurrencyAPIResponse } from '../types';
import { toCurrency, toCurrencyAPIResponse } from '../helper';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(
    private httpService: HttpService,
    private config: ConfigService
  ) {}
  getCurrencies(): Observable<CurrencyAPIResponse> {
    return from(
      this.httpService.doGet(
        `${this.config.appConfig.userSvcUrlV3}currency/`,
        this.httpService.getHeader('')
      )
    ).pipe(
      map(toCurrencyAPIResponse),
      catchError(
        compose(
          of,
          handleAsyncError<CurrencyAPIResponse>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }
}
