export type ResponseCodeHandlerMap = { [key: number]: string };

export enum Status {
  Success = 'Success',
  Error = 'Error',
}

export type AsyncCallResponse<ValueType> = {
  status: Status;
  data?: ValueType;
  error?: any;
};
