import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ConfigService } from '@zeotap-ui/config';
import { Observable } from 'rxjs';

@Injectable()
export class DataAppRedirectService implements CanActivate {
  constructor(private configService: ConfigService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const { product = '' } = route.data;
    if (product === 'zat') {
      const targetingLink = this.configService.appConfig.targetingUnityUrl;
      window.open(targetingLink, '_blank');
    }
    if (product === 'envision') {
      const link = this.configService.appConfig.predictiveAudienceDataUrl;
      window.open(link, '_blank');
    }
    return false;
  }
}
