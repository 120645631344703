import { defaultToEmptyArray, isNotNullOrEmpty } from './utils';
import {
  prop,
  compose,
  includes,
  keys,
  reduce,
  __,
  curry,
  filter,
  equals,
  gte,
  pluck,
  has,
  ifElse,
  always,
  dissoc,
  identity,
  length,
  indexBy,
  map,
} from 'ramda';

export const getLookupFromArray = (keyField: string, valueField: string) =>
  reduce((acc, curr) => ({ ...acc, [curr[keyField]]: curr[valueField] }), {});

export const getArrayFromLookup = curry(
  (keyField: string, valueField: string, lookup: { [key: string]: any }) =>
    keys(lookup).map((key) => {
      return { [keyField]: key, [valueField]: lookup[key] };
    })
);

export const isArray = (x) => Array.isArray(x);

export const reduceArrayToMap = (keyField: string) =>
  reduce((acc, curr) => ({ ...acc, [curr[keyField]]: curr }), {});

export const includesKey = (key: string) => compose(includes(key), keys);

export const hasDuplicateValue = (name: string, property: string) =>
  compose(
    gte(__, 2),
    length,
    filter(equals(name)),
    pluck(property),
    defaultToEmptyArray
  );

export const removeDuplicateErrorIfPresent = ifElse(
  has('duplicate'),
  compose(
    ifElse(isNotNullOrEmpty, identity, always(null)),
    dissoc('duplicate')
  ),
  identity
);
// TOODO : This function is not in use but may be needed in future
export const indexedFill = (pickFn, projectFn) => (superList: any[]) => (
  list: any[]
) => {
  const superListIndex = indexBy(pickFn, superList);

  return map((item) => {
    const key = pickFn(item);
    if (superListIndex[key])
      return { ...item, ...projectFn(superListIndex[key]) };
    return item;
  })(list);
};
