import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchableHeaderLabelComponent } from './searchable-header-label.component';
import { FormsModule } from '@angular/forms';
import { CustomTooltipModule } from '@zeotap-ui/core';

@NgModule({
  declarations: [SearchableHeaderLabelComponent],
  imports: [CommonModule, FormsModule, CustomTooltipModule],
  exports: [SearchableHeaderLabelComponent],
})
export class SearchableHeaderLabelModule {}
