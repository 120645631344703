import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
/**
 *  This component should have the following features
 * 1. should toggle on collapsed(side)(true) and uncollapsed(down)(false)
 * 2. On toggle should output the current state
 * 3. should take an init state: default=collapsed
 * 4. The button should be resizable to the outer container
 *
 */

@Component({
  selector: 'zap-collapse-button',
  templateUrl: './collapse-button.component.html',
  styleUrls: ['./collapse-button.component.scss'],
})
export class CollapseButtonComponent implements OnInit {
  openValue: boolean = false;
  @Input() openDirection: string = 'right';
  @Input() closeDirection: string = 'down';

  constructor() {}
  @Input() get open() {
    return this.openValue;
  }
  @Output() openChange = new EventEmitter<boolean>();

  set open(val) {
    this.openValue = val;
    this.openChange.emit(val);
  }
  ngOnInit() {}
  toggle() {
    this.open = !this.open;
  }
}
