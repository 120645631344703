import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorePipesModule, CustomTooltipModule } from '@zeotap-ui/core';
import { CustomFooterComponent } from './custom-footer/custom-footer.component';

@NgModule({
  declarations: [CustomFooterComponent],
  imports: [
    CommonModule,
    CustomTooltipModule,
    CorePipesModule,
  ],
  exports: [CustomFooterComponent]
})
export class CustomFooterModule { }
