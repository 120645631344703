import {
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  CloseIconTemplateDirective,
  ModalFooterTemplateDirective,
  HeaderLabelTemplateDirective,
  HeaderTemplateDirective,
} from '../directives/modal-holder.directive';
import { ConfigService } from '@zeotap-ui/config';
import { isNilOrEmpty, isNotNullOrEmpty } from '@zeotap-ui/core';

export interface State {
  invisible?: boolean;
  disabled?: boolean;
}
@Component({
  selector: 'zap-modal-holder',
  templateUrl: './modal-holder.component.html',
  styleUrls: ['./modal-holder.component.scss'],
})
export class ModalHolderComponent {
  @ContentChild(ModalFooterTemplateDirective, { read: TemplateRef })
  footerTemplate: TemplateRef<any>;
  @ContentChild(HeaderTemplateDirective, { read: TemplateRef })
  headerTemplate: TemplateRef<any>;
  @ContentChild(HeaderLabelTemplateDirective, {
    read: TemplateRef,
  })
  headerLabelTemplate: TemplateRef<any>;
  @ContentChild(CloseIconTemplateDirective, {
    read: TemplateRef,
  })
  closeIconTemplate: TemplateRef<any>;
  @Input() header: string;
  @Input() state: State = {
    invisible: false,
    disabled: false,
  };
  @Input() helpDocUrl;
  @Input() nextLabel: string;
  @Input() cancelLabel: string = 'Cancel';
  @Input() showModal: boolean;
  @Input() sideOverlay: boolean = false;
  @Input() displayFooter: boolean = true;
  @Input() displayHeader = true;
  @Input() closeModalOnEsc = true;
  @Output() close = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() cancel = new EventEmitter();
  isHelpDocEnabled = false;
  isNotNullOrEmpty = isNotNullOrEmpty;
  isNilOrEmpty = isNilOrEmpty;
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(evnt: KeyboardEvent) {
    if (this.closeModalOnEsc) {
      this.close.emit();
    }
  }

  constructor(public configService: ConfigService) {
    this.isHelpDocEnabled = this.configService.getFeatureValue(
      'ENABLE_CLICK_HELP_NEW_TAB'
    );
  }

  closeModal() {
    this.close.emit();
  }
  cancelModal() {
    this.cancel.emit();
  }

  nextAction() {
    this.next.emit();
  }
}
