import {
  Directive,
  AfterViewInit,
  OnDestroy,
  DoCheck,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  KeyValueDiffers,
} from '@angular/core';
import 'daterangepicker';
import $ from 'jquery';
import { DateRangePickerOptionsService } from './date-range-picker.service';

@Directive({
  selector: '[daterangepicker]',
})
export class DateRangePickerDirective implements AfterViewInit {
  datePicker: any;
  @Output() selected = new EventEmitter();

  constructor(
    private input: ElementRef,
    private config: DateRangePickerOptionsService
  ) {}

  ngAfterViewInit(): void {
    const jqueryElement: any = $(this.input.nativeElement);

    jqueryElement
      .daterangepicker(this.config.settings)
      .on('apply.daterangepicker', (ev: any, picker: any) => {
        try {
          const selectedDates = {
            start: picker.startDate,
            end: picker.endDate,
          };
          this.selected.emit(selectedDates);
        } catch (error) {
          console.error('Error handling daterangepicker apply event:', error);
        }
      });
    this.datePicker = jqueryElement.data('daterangepicker');
  }

  ngOnDestroy() {
    this.destroyPicker();
  }

  private destroyPicker(): void {
    try {
      $(this.input.nativeElement).data('daterangepicker').remove();
    } catch (e) {
      console.log(e.message);
    }
  }
}
