import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IconStyle } from '../button/types';

export type IconType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'special'
  | 'negative'
  | 'negative-secondary';
export type IconSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'zap-icon',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  private _type: IconType = 'primary';
  private _disabled = false;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.customIconCSS = this.updateCustomIconCSS();
  }

  get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  set type(value: IconType) {
    this._type = value;
    this.customIconCSS = this.updateCustomIconCSS();
  }

  get type(): IconType {
    return this._type;
  }
  @Input() iconStyle: IconStyle;
  @Input() icon: string;
  @Input() alt: string = '';
  @Input() size: IconSize;
  @Input() customIconClass: string = '';
  customIconCSS: string;
  customSizeCSS: string;

  constructor() {}

  ngOnInit(): void {
    this.customSizeCSS = this.updateCustomSizeCSS();
    this.customIconCSS = this.updateCustomIconCSS();
  }

  updateCustomSizeCSS(): string {
    switch (this.size) {
      case 'medium':
      case 'large':
        return 'h-6 w-6';
      case 'small':
        return 'h-4 w-4';
      default:
        return '';
    }
  }
  updateCustomIconCSS(): string {
    if (this.iconStyle) {
      return '';
    }
    switch (this.type) {
      case 'secondary':
      case 'tertiary':
        return this.disabled ? 'text-grey-300' : 'text-primary-1-500';
      case 'primary':
        return 'text-white';
      case 'special':
        return 'text-white';
      case 'negative-secondary':
        return this.disabled ? 'text-grey-500' : 'text-grey-600';
      case 'negative':
        return this.disabled
          ? 'text-grey-500 bg-grey-200'
          : 'text-state-error-900 bg-state-error-100';
      default:
        return '';
    }
  }
}
