import { NgModule } from '@angular/core';
import { MessageComponent } from './message/message.component';
import { ZuiLibModule } from '@zeotap.infra/zui-lib';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [MessageComponent],
  imports: [CommonModule],
  exports: [MessageComponent],
})
export class DisplayMessageModule {}
