import { Inject, Injectable } from '@angular/core';
import {
  set as Rset,
  clone,
  defaultTo,
  forEach,
  lensPath,
  propOr,
  map,
  view,
} from 'ramda';
import { AppConstantsToken, EnvironmentConfigToken } from '../tokens/tokens';
import { AppConfig, AppConstants, EnvironmentConfig } from '../types';
import { getFeatureValue } from '../utils/helper';
import { WindowRefService } from './window-ref.service';

type ConfigOverride = {
  path: string[];
  value: any;
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  counter = 0;
  private _modifiedAppConfig: AppConfig;
  private _appConfig: AppConfig;
  private _constants: AppConstants;
  constructor(
    @Inject(EnvironmentConfigToken) environment: EnvironmentConfig,
    @Inject(AppConstantsToken) constants: AppConstants,
    windowRefService: WindowRefService
  ) {
    this._appConfig = environment.unityConfig || {};

    this._constants = constants || {};

    if (!environment.production) {
      windowRefService.window.addConfigOveride = (
        overrides: ConfigOverride[]
      ) => {
        return this.modifyAppConfig(overrides);
      };
      windowRefService.window.revertConfigOveride = () => {
        this.revertOverrides();
      };
      windowRefService.window.getConfigValue = (paths: string[][]) => {
        return this.getAppConfig(paths);
      };
    }
  }

  getAppConfig(paths: string[][]) {
    if (!paths || paths.length === 0) return this.appConfig;
    const defaultToNotFound = defaultTo('Not Found');
    return map((path) => {
      const pathLens = lensPath(path);
      return defaultToNotFound(view(pathLens, this.appConfig));
    }, paths);
  }

  modifyAppConfig(overrides: ConfigOverride[]) {
    this._modifiedAppConfig = clone(this._appConfig);
    forEach((override) => {
      const pathLens = lensPath(override.path);
      this._modifiedAppConfig = Rset(
        pathLens,
        override.value,
        this._modifiedAppConfig
      );
    }, overrides);
    return this._modifiedAppConfig;
  }
  revertOverrides() {
    this._modifiedAppConfig = null;
  }

  get appConfig(): AppConfig {
    return this._modifiedAppConfig || this._appConfig;
  }

  get constants(): AppConstants {
    return this._constants;
  }

  getFeatureValue(featureName, withConfig = false) {
    return getFeatureValue(featureName, this.appConfig.features, withConfig);
  }

  getFeatureConfigValue(
    featureName: string,
    configProperty: string = null,
    defaultConfigValue = null
  ) {
    const featureWithConfig = this.getFeatureValue(featureName, true);
    if (!configProperty) {
      return featureWithConfig.enabled ?? featureWithConfig ?? false;
    }
    return featureWithConfig.enabled
      ? configProperty
        ? propOr(defaultConfigValue, configProperty, featureWithConfig.config)
        : true
      : defaultConfigValue;
  }

  getConfigValue<T>(defaultValue: T, configName: string) {
    return defaultTo(defaultValue, this.appConfig.configs[configName]) as T;
  }

  async setAppConfig() {
    const featureConfig = await this.getFeatureConfig();
    this._appConfig = { ...this.appConfig, ...featureConfig };
  }

  async init() {
    await this.setAppConfig();
  }

  private getFeatureConfig(): Promise<AppConfig> {
    const version = this.appConfig.version || '';
    const timestamp = new Date().getTime();
    // adding this to avoid caching of config.json
    const url = this.appConfig.unityConfigPath || '';
    const config = fetch(
      `${url}?v=${version}&t=${timestamp}`
    ).then((response) => response.json());
    return config;
  }
  getBaseUrl() {
    const iconDNS = this.appConfig.iconDNS;
    if (iconDNS.length) {
      if (this.counter < iconDNS.length) {
        return iconDNS[this.counter++];
      } else {
        this.counter = 0;
        return iconDNS[this.counter++];
      }
    }
  }
}
