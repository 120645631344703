import { Pipe, PipeTransform } from '@angular/core';
import { compose, prop, sortBy, toLower, propIs, reverse } from 'ramda';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(array: any[], sortParameter?: string, orderBy?: string): any[] {
    if (!array || array.length < 2) {
      return array;
    }
    const sortedArr: [] = propIs(String, sortParameter, array[0])
      ? sortBy(compose(toLower, prop(sortParameter)))(array)
      : sortBy(prop(sortParameter))(array);
    return !!orderBy && orderBy === 'desc' ? reverse(sortedArr) : sortedArr;
  }
}
