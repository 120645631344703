import { Pipe, PipeTransform } from '@angular/core';
import { includes, pathOr, equals, compose } from 'ramda';
import { isNilOrEmpty, toLowerCaseString } from '../../utils';

@Pipe({
  name: 'filterByPath',
})
export class FilterByPathPipe implements PipeTransform {
  transform(
    items: any[],
    properties: string[], //[path1, path2]
    searchText: string,
    fullCheck = true
  ): any[] {
    if (!items) {
      return [];
    } else {
      const compareFn = fullCheck ? equals : includes;

      if (!searchText || isNilOrEmpty(properties)) {
        return items;
      } else {
        return items.filter((item) => {
          return properties.some((property) => {
            const value = pathOr('', property.split('.'), item);
            return compareFn(
              toLowerCaseString(searchText),
              toLowerCaseString(value?.toString())
            );
          });
        });
      }
    }
  }
}
