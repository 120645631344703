import {
  Component,
  OnInit,
  AfterContentInit,
  Input,
  ViewContainerRef,
  ViewChild,
  Output,
  ContentChildren,
  QueryList,
  ElementRef,
  TemplateRef,
  OnChanges,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { Observable, timer, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseControl, getControlValueAccessor } from '../base';

import { DropdownOptionsStaticComponent } from './dropdownOptionsStatic';

const dataOptions = {
  name: '',
  value: '',
  selected: 'selected',
  noOfOptionsToShow: 5,
  noOfChipsToShow: 5,
  enableNullSelection: false
};

@Component({
  selector: 'zui-dropdown-static',
  templateUrl: './dropdownStatic.html',
  styleUrls: ['./dropdownStatic.scss'],
  providers: [getControlValueAccessor(DropdownStaticComponent)]
})
export class DropdownStaticComponent extends BaseControl
  implements AfterContentInit, OnInit, OnChanges, OnDestroy {
  public showMenu = false;
  public searchText = '';
  public dataType: 'string' | 'object' = 'string';
  public optionsArr: DropdownOptionsStaticComponent[] = [];
  public outsideClickCapture;
  public selectAll = false;
  public showPlaceholder = true;
  public filteredData: any[] = [];
  public processedFilteredData: any[] = [];
  public filteredDataObservable: Observable<any>;
  public clickUnsubscribe;

  @Input() public data: any[];
  @Input() public filterFn: Function;
  @Input() public multiple = false;
  @Input() public dataOptions = dataOptions;
  @Input() public autocomplete = false;
  @Input() public hiddenOptionsText = 'more';
  @Input() public placeholder = 'Select your choices';
  @Input() public disabled = false;
  @Input() public showClear = false;
  @Output() public onSearchTextChange = new EventEmitter<string>();
  @ContentChildren(DropdownOptionsStaticComponent) public options: QueryList<
    DropdownOptionsStaticComponent
  >;

  constructor(private elementRef: ElementRef) {
    super();
    this.toggleOptionSelection = this.toggleOptionSelection.bind(this);
    this.filteredDataObservable = timer(0, 1000).pipe(
      map(i => {
        return this.filteredData.slice(0, (i + 1) * 200 - 1);
      })
    );
  }

  public ngOnInit() {
    this.dataType = typeof this.data[0] === 'string' ? 'string' : 'object';
    this.dataOptions = Object.assign(dataOptions || {}, this.dataOptions);
    let clicks = fromEvent(document, 'click');
    this.clickUnsubscribe = clicks.subscribe(event => {
      if (!this.elementRef.nativeElement.contains(event['target'])) {
        this.handleDocumentClick(event);
      }
    });
  }

  public ngOnDestroy() {
    this.clickUnsubscribe.unsubscribe();
  }

  public ngOnChanges() {
    this.filteredData = this.data;
    this.processedFilteredData = this.filteredData.slice(0, 300);
  }

  public ngAfterContentInit() {
    this.optionsArr = this.options.toArray();
    this.optionsArr.forEach(o => {
      o.selectionChange.subscribe(this.toggleOptionSelection);
    });
  }

  public handleDocumentClick(event) {
    this.showMenu = false;
    this.onBlur();
  }

  public modelChanged() {
    this.onSearchTextChange.emit(this.searchText);
    let visibleCount = 0;
    let proceed = true;

    if (this.searchText.length) {
      this.showMenu = true;
      if (this.optionsArr.length) {
        this.data.forEach((d, i: number) => {
          let value = d;
          if (this.dataType === 'object') {
            value = d[this.dataOptions.name];
          }
          if (proceed && this.filterFn(value, this.searchText)) {
            visibleCount += 1;
            proceed = this.dataOptions.noOfOptionsToShow > visibleCount;
            this.optionsArr[i].showChild();
          } else {
            this.optionsArr[i].destroyChild();
          }
        });
      } else {
        this.filteredData = this.data.filter(d => {
          let value = d;
          if (this.dataType === 'object') {
            value = d[this.dataOptions.name];
          }
          return this.filterFn(value, this.searchText);
        });
        this.processedFilteredData = this.filteredData.slice(0, 300);
      }
    } else {
      this.showMenu = false;
      this.optionsArr.forEach(o => o.destroyChild());
    }
  }

  public toggleOptionSelection(option: DropdownOptionsStaticComponent) {
    if (this.multiple) {
      option.selected = !option.selected;
      if (this.dataType === 'object') {
        option.data[this.dataOptions.selected] = !option.data[
          this.dataOptions.selected
        ];
      }
      this.updateValue();
    } else {
      this.showMenu = false;
      this.onBlur();
      if (this.dataType === 'object') {
        const newVal = !option.data[this.dataOptions.selected];
        this.data.forEach(d => {
          d[this.dataOptions.selected] = false;
        });
        option.data[this.dataOptions.selected] = newVal;
      }
      option.selected = true;
      this.value =
        this.dataType === 'object' && this.dataOptions.value
          ? option.data[this.dataOptions.value]
          : option.data;
      this.searchText = '';
      this.placeholder = this.value;
    }
  }

  public toggleAllOptionsSelection() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.filteredData.forEach(d => (d[this.dataOptions.selected] = true));
    } else {
      this.optionsArr.forEach(o => (o.selected = false));
      this.data.forEach(d => (d[this.dataOptions.selected] = false));
    }
    this.updateValue();
  }

  public updateValue() {
    if (this.optionsArr && this.optionsArr.length) {
      this.value = this.optionsArr
        .filter(o => o.selected)
        .map(o => {
          if (this.dataType === 'object' && this.dataOptions.value) {
            return o.data[this.dataOptions.value];
          }
          return o.data;
        });
    } else {
      this.value = this.data
        .filter(d => d.selected)
        .map(d => {
          if (this.dataType === 'object' && this.dataOptions.value) {
            return d[this.dataOptions.value];
          }
          return d;
        });
    }
    if (this.value.length > 0) {
      this.showPlaceholder = false;
      this.selectAll = true;
    } else {
      this.showPlaceholder = true;
      this.selectAll = false;
    }
  }

  public removeItem(v, event) {
    event.stopPropagation();
    if (this.optionsArr.length) {
      if (this.dataType === 'object' && this.dataOptions.value) {
        this.toggleOptionSelection(
          this.optionsArr.find(o => o.data[this.dataOptions.value] === v)
        );
      } else {
        this.toggleOptionSelection(this.optionsArr.find(o => o.data === v));
      }
    }
    if (this.dataType === 'object') {
      const d = this.data.find(
        d => d[this.dataOptions.name] === v[this.dataOptions.name]
      );
      // const d = this.data.find(d => JSON.stringify(d) === JSON.stringify(v));
      // const d = this.data.find(d => {console.log(Object.is(d,v)); return Object.is(d,v);});
      if (d) {
        d[this.dataOptions.selected] = false;
      }
    }
    this.updateValue();
  }

  public showDropdownMenu() {
    this.showMenu = !this.showMenu;
    this.showMenu ? this.onFocus() : this.onBlur();
    let visibleCount = 0;
    let proceed = true;
    if (!this.autocomplete) {
      this.optionsArr.forEach(o => {
        o.showChild();
      });
    } else {
      if (this.optionsArr.length) {
        this.data.forEach((d, i: number) => {
          if (proceed) {
            visibleCount += 1;
            proceed = this.dataOptions.noOfOptionsToShow > visibleCount;
            this.optionsArr[i].showChild();
          } else {
            this.optionsArr[i].destroyChild();
          }
        });
      }
    }
  }

  public writeValue(value: any) {
    super.writeValue(value);
    // console.log(this.value);
    if (this.value) {
      if (this.multiple) {
        this.optionsArr.forEach(
          o => (o.selected = !!this.value.find(v => v === o.data))
        );
        this.value.forEach(v => {
          const dataItem =
            this.dataType === 'object'
              ? this.processedFilteredData.find(
                  d => d[this.dataOptions.name] === v[this.dataOptions.name]
                )
              : this.processedFilteredData.find(d => d === v);
          if (dataItem) {
            dataItem[this.dataOptions.selected] = true;
          }
        });
      } else {
        this.optionsArr.forEach(o => (o.selected = this.value === o.data));
      }
    }
  }

  public checkForLoadMore() {
    const menuContainer = this.elementRef.nativeElement.querySelector(
      '.dropdown-menu-container'
    );
    if (
      menuContainer &&
      menuContainer.scrollHeight - menuContainer.scrollTop < 1000 &&
      this.filteredData.length > this.processedFilteredData.length
    ) {
      this.processedFilteredData = this.filteredData.slice(
        0,
        this.processedFilteredData.length + 300
      );
    }
  }

  public onFocus() {
    this.elementRef.nativeElement.firstElementChild.firstElementChild.classList.add(
      'focused'
    );
  }

  public onBlur() {
    if (!this.showMenu)
      this.elementRef.nativeElement.firstElementChild.firstElementChild.classList.remove(
        'focused'
      );
  }
}
