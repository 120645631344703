import {
  compose,
  reduce,
  flatten,
  equals,
  pluck,
  any,
  prop,
  gt,
  __,
} from 'ramda';

export const getTotalsAcrossXAxis = compose(
  reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: (acc[curr.name] || 0) + curr.value,
    }),
    {}
  ),
  flatten,
  pluck('series')
);

export const isStackedChart = equals('stacked');
export const isBarChart = equals('bar');

export const hasValueGreaterThan0 = any(compose(gt(__, 0), prop('value')));

export const shouldDisplaySeriesReport = any(
  compose(hasValueGreaterThan0, prop('series'))
);
