<ng-container *ngIf="{ value: groupErrorsByType(checkboxControl) } as error">
  <div class="flex gap-3 items-center">
    <input
      type="checkbox"
      id="{{ id }}-checkbox-control"
      [formControlName]="controlKey"
      class="!mr-0 w-4 h-4 cursor-pointer checkbox-btn icon tick focus:!outline-none before:text-white before:rounded-sm before:border before:border-solid before:border-grey-300 before:text-base appearance-none [&:checked:before]:!text-white [&:checked:before]:!bg-primary-1 [&:checked:before]:border-primary-1"
      [ngClass]="{ 'before:border-state-error-700': error.value?.highlight?.length }"
      customToolTip
      [contentTemplate]="error.value?.highlight?.length ? errors : null"
      [value]="{ errors: error.value?.highlight, errorTextColor: 'primary' }"
      [customTooltipClass]="'!p-[.625rem] !w-max [&_.simpleText]:!text-xs !rounded'"
      [positions]="tooltipPositions"
    />
    <label class="text-sm font-primary text-grey-900 cursor-pointer leading-3" for="{{ id }}-checkbox-control" [ngClass]="{ '-order-1': labelPosition === 'left' }">{{ label }}</label>
  </div>
  <div class="mt-1" *ngIf="error.value?.message?.length">
    <ng-container *ngTemplateOutlet="errors; context: { errors: error.value?.message, errorTextColor: 'secondary' }"></ng-container>
  </div>
</ng-container>

<ng-template #errors let-errors="errors" let-errorTextColor="errorTextColor">
  <zap-control-errors [errors]="errors" [errorTextColor]="errorTextColor"></zap-control-errors>
</ng-template>
