import * as moment from 'moment';
import {
  any,
  chain,
  compose,
  concat,
  defaultTo,
  either,
  eqProps,
  equals,
  filter,
  head,
  ifElse,
  includes,
  invoker,
  isEmpty,
  isNil,
  keys,
  lensPath,
  map,
  not,
  pluck,
  prop,
  propEq,
  propOr,
  reduce,
  uniqWith,
  unnest,
  values,
  view,
  zipObj,
  mapObjIndexed,
  sort,
  flatten,
  type,
  groupBy,
  find,
} from 'ramda';
import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { removeWhiteSpaceAndCapitaizeWordBoundary } from './stringUtils';

export const isNotNull = compose(not, isNil);

export const isNilOrEmpty = either(isNil, isEmpty);

export const isNotNullOrEmpty = compose(not, isNilOrEmpty);

export const isEqualToFalse = equals(false);

// flattenValues :: { id: any[] } -> any[]
export const flattenValues = compose(unnest, values);

export const defaultToEmptyObject = defaultTo({});

export const defaultToNull = defaultTo(null);

export const defaultToFalse = defaultTo(false);

export const defaultToTrue = defaultTo(true);

export const defaultToEmptyArray = defaultTo([]);

export const notEquals = compose(not, equals);

export const getDate = (value: any, format = 'Do MMM YYYY'): string =>
  moment(value).format(format);

// uniqueById :: IdName[] -> IdName[]
export const uniqueById = uniqWith(eqProps('id'));

export const isPresentInsideContainer = (
  className: string,
  target: EventTarget
) =>
  compose(
    ifElse(isNotNullOrEmpty, invoker(1, 'contains')(target), defaultTo(false)),
    getElementByClassName(className)
  );

export const getElementByClassName = (className: string) =>
  compose(
    head,
    invoker(1, 'getElementsByClassName')(className),
    prop('nativeElement')
  );

export const getByProp = (propName: string, value: any) =>
  compose(propOr({}, 0), filter(propEq(propName, value)));

export const groupByProp = (propName: string) =>
  chain(zipObj, map(prop(propName)));

// allows to check if has path , where paths can be regular expression
export const objectHasWithRegex = (obj: any, path: RegExp[]) => {
  const hasPath = (obj: any, path: RegExp[]) => {
    if (path.length === 0) {
      return true;
    }
    const [first, ...rest] = path;
    const keys = Object.keys(obj);
    const matches: string[] = keys.filter((key) => first.test(key));
    return (
      isNotNullOrEmpty(matches) &&
      any((match) => {
        return hasPath(obj[match], rest);
      }, matches)
    );
  };
  return hasPath(obj, path);
};

// check if object has paths , where paths can be regular expression
export const checkObjectForPathExpressions = (obj: any, path: string[]) => {
  return objectHasWithRegex(
    obj,
    path.map((p) => new RegExp(p))
  );
};

export const reorderProps = (obj: { [key: string]: any }, replacer) =>
  JSON.parse(JSON.stringify(obj, replacer));

export const filterTabsBasedOnFeaturesAndPermissions =
  (permissions, features) => (tabLink) =>
    permissions[tabLink.permission] &&
    propOr(true, propOr('', 'feature')(tabLink))(features);

export const getUrlPath = view(
  lensPath(['_urlSegment', 'segments', 1, 'path'])
);
export const getActionEmitterName = compose(
  concat('on'),
  removeWhiteSpaceAndCapitaizeWordBoundary
);

export const getSelectedColumnsID = compose(
  pluck('id'),
  filter(propEq('selected', true))
);

export const getPropertiesFromActiveScreen = (obj: any) => {
  const defaultToZero = defaultTo(0);
  const activeProperties = flatten(
    values(
      map(
        (s) => values(s),
        filter((screen) => !isEmpty(screen), obj)
      )
    )
  );
  return head(
    sort(
      (a, b) =>
        defaultToZero(b?.properties?.level) -
        defaultToZero(a?.properties?.level),
      values(activeProperties)
    )
  )?.properties;
};

export const isTypeArray = compose(equals(type([])), type);

export const isTypeObject = compose(equals(type({})), type);

// getPermissionByCountry :: string[] -> PermissionByCountry
export const getPermissionByCountry = groupBy((permission: string) => {
  return permission.substring(0, 2);
});

export const getDateFromUnixTime = (time: number, keepLocal = true): Date => {
  return !time ? null : moment.unix(time).utc(keepLocal).toDate();
};

// getUnixEndtimeInSeconds :: Date|Moment -> number
export const getUnixEndtimeInSeconds = (
  date: Date | moment.Moment,
  keepLocal = true
) => {
  return moment(date).utc(keepLocal).unix();
};

export function getAnimationState(
  closeTimer: number = 500,
  openPosition: string = 'left',
  openPositionLeft: string = '0',
  closePositionLeft: string = '100%'
): AnimationTriggerMetadata {
  return trigger('changeState', [
    state(
      'open',
      style({
        [openPosition]: openPositionLeft,
      })
    ),
    state(
      'close',
      style({
        [openPosition]: closePositionLeft,
      })
    ),
    transition(
      '*=>open',
      animate(`${closeTimer}ms 250ms cubic-bezier(0.165, 0.84, 0.44, 1)`)
    ),
    transition(
      '*=>close',
      animate(`${closeTimer}ms cubic-bezier(0.165, 0.84, 0.44, 1)`)
    ),
  ]);
}

export const findById = (id) => find(propEq('id', id));

export const findByProp = (propName) => (value) =>
  find(propEq(propName, value));
