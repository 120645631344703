import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'zap-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() public steps: {
    id: string;
    title: string;
    description: string;
    submitBtnText: string;
    resetBtnText: string;
    isComplete: boolean;
    disabled: boolean;
  }[];
  @Input() public activeIndex: number = 0;
  @Input() public showWhenComplete: any;
  @Input() public removeWhenComplete: boolean = false;

  @Output() submitClick = new EventEmitter<string>();
  @Output() resetClick = new EventEmitter<string>();

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.steps) {
      this.activeIndex = this.steps.findIndex((step) => !step.isComplete);
      this.steps = this.steps.map((item, i) => {
        return {
          ...item,
          isActive: i === this.activeIndex,
          id: item.id,
        };
      });
    }
  }

  isActiveStep(index: number) {
    return index === this.activeIndex;
  }

  allStepsCompleted() {
    return this.steps.filter((item) => !item.isComplete).length === 0;
  }

  showStepper() {
    if (this.allStepsCompleted() && this.removeWhenComplete) {
      return false;
    }
    return true;
  }
}
