import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'zap-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {
  @Input() text = 'No data to display';
  @Input() showText = true;
  @Input() smallDots = false;
  @Input() type: 'noData' | 'error' | 'progress' = 'noData';
  @Input() hideReload = false;
  @Output() reload: EventEmitter<any> = new EventEmitter();

  emitEvent() {
    this.reload.emit();
  }
}
