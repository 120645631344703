import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ActionContentTemplateDirective } from './directives/action-message-box.directive';

@Component({
  selector: 'zap-action-message-box',
  templateUrl: './action-message-box.component.html',
  styleUrls: ['./action-message-box.component.scss'],
})
export class ActionMessageBoxComponent implements OnInit {
  @ContentChild(ActionContentTemplateDirective, { read: TemplateRef })
  actionContentTemplate: TemplateRef<any>;
  @Input() showIcon: boolean = true;
  @Input() messageIcon: string = 'informationIcon';
  @Input() message: string = '';
  @Input() headerMessage: string = '';
  @Input() actionText: string = '';
  @Input() actionIcon: string = '';
  @Input() disableButton: boolean = false;
  @Input() showToggle: boolean = false;
  @Input() closeDirection: string = 'down';
  @Input() openDirection: string = 'up';
  @Input() openInfoContainer: boolean = false;
  @Output() onActionButtonClick = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
