export interface Tab {
  label: string;
  show?: boolean;
  id: string;
}
export interface StateStyles {
  active?: string;
  disabled?: string;
  nonActive?: string;
}
