import { Pipe, PipeTransform } from '@angular/core';
import { FilterItem, FilterState } from '../types';
import { any, defaultTo } from 'ramda';

@Pipe({
  name: 'areAnyFiltersSelected',
})
export class AreAnyFiltersSelectedPipe implements PipeTransform {
  transform(filterItems: FilterItem[], currentFilterState: FilterState): any {
    return any(
      (filterItem) => filterItem?.isSelected(currentFilterState, filterItem),
      defaultTo(
        [
          {
            isSelected: () => false,
          },
        ],
        filterItems
      )
    );
  }
}
