import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[desc-tmp]' })
export class PromptDescriptionTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[prompt-text-tmp]' })
export class PromptTextTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}
