let buildVersion = '';
try {
  buildVersion = require('../../../../version.json')?.version;
} catch (e) {}
const version = require('../../../../package.json')['version:cdp'];

export const environment_base = {
  buildVersion,
  version,
  unityConfig: {
    testSvcUrl: 'https://account{{env}}.zeotap.com/api/v2/',
    unityConfigPath: 'assets/config/config.json',
    userSvcUrl: 'https://account{{env}}.zeotap.com/api/v2/',
    userSvcUrlV3: 'https://account{{env}}.zeotap.com/api/v3/',
    userSvcUrlV4: 'https://account{{env}}.zeotap.com/api/v4/',
    userSvcUrlV5: 'https://account{{env}}.zeotap.com/api/v5/',
    segmentSvcUrl: 'https://unity{{env}}.zeotap.com/zeosphere/api/v2/',
    segmentSvcUrlV2: 'https://unity{{env}}.zeotap.com/zeosphere/api/v2/',
    segmentSvcUrlV3: 'https://unity{{env}}.zeotap.com/zeosphere/api/v3/',
    channelSettingsSvcUrl:
      'https://unity{{env}}.zeotap.com/channelSettings/api/v1/',
    channelSettingsSvcUrlV2:
      'https://unity{{env}}.zeotap.com/channelSettings/api/v2/',
    reportingSvcUrl: 'https://unity{{env}}.zeotap.com/reporting/api/v1/',
    loginAppUrl: 'https://unity{{env}}.zeotap.com/app/login',
    connectSvcUrl: 'https://unity{{env}}.zeotap.com/connect/api/v1/',
    connectSvcUrlV2: 'https://unity{{env}}.zeotap.com/connect/api/v2/',
    connectSvcUrlV3: 'https://unity{{env}}.zeotap.com/connect/api/v3/',
    connectSvcUrlV4: 'https://unity{{env}}.zeotap.com/connect/api/v4/',
    connectSvcUrlV5: 'https://unity{{env}}.zeotap.com/connect/api/v5/',
    insightsSvcUrl: 'https://unity{{env}}.zeotap.com/insights/api/v1/',
    connectInsightSvcUrl:
      'https://unity{{env}}.zeotap.com/insights/connect/api/v1/',
    connectOverallInsightSvcUrl:
      'https://unity{{env}}.zeotap.com/insights/org/api/v1/',
    connectDashboardSvcUrl:
      'https://unity{{env}}.zeotap.com/connect/api/v1/org/',
    iconBaseUrl: 'https://content.zeotap.com/',
    iconDNS: ['https://c1.zeotap.com/'],
    rbacUrl: 'https://account{{env}}.zeotap.com/access/',
    studioSvcUrl: 'https://unity{{env}}.zeotap.com/studio/v1/',
    googleTagManagerDoc: 'https://www.google.com/analytics/tag-manager/',
    categoriesSvcUrl: 'https://unity{{env}}.zeotap.com/categories',
    dpmcConfigSvcUrl: 'https://unity{{env}}.zeotap.com/configservice/v1/',
    ingestionConfigUrl: 'https://unity{{env}}.zeotap.com/dpmc/v1/',
    clientEtlSvcUrl: 'https://unity{{env}}.zeotap.com/connect/launch/',
    dataManagerSvcUrl: 'https://unity{{env}}.zeotap.com/datamanager/api/v1/',
    dataManagerSvcUrlV2: 'https://unity{{env}}.zeotap.com/datamanager/api/v2/',
    dataManagerSvcUrlV3: 'https://unity{{env}}.zeotap.com/datamanager/api/v3/',
    gdprToolSvcUrl: 'https://unity{{env}}.zeotap.com/ucms/api/v1/',
    symphonyStudioCdapUrl: 'https://symphony{{env}}.zeotap.com/login',
    tableauServerEndpoint: 'https://tableau{{env}}.zeotap.com/trusted/',
    dpmcPreviewSvcUrl: 'https://unity{{env}}.zeotap.com/datapreview/v1/',
    envisionSvcUrl: 'https://unity{{env}}.zeotap.com/envision/api/v1/',
    symphonySvcUrl: 'https://unity{{env}}.zeotap.com/symphony/api/v1/',
    amplitudeAPIKey: '58846e6f4463ae6e8081377c7b42ea61',
    jsSdkCDNUrl: 'https://content.zeotap.com/sdk/{{sdkPath}}zeotap.min.js',
    splUrl: 'https://spl{{splEnv}}.zeotap.com/',
    sourceAnalyticsUrl: 'https://unity{{env}}.zeotap.com/source-analytics/v1/',
    androidNativeSdk:
      'https://content.zeotap.com/android-sdk/{{sdkPath}}android-collect-sdk{{env}}.zip',
    iosNativeSdk:
      'https://content.zeotap.com/ios-sdk/{{sdkPath}}ios-collect-sdk{{env}}.zip',
    idGraphUrl: 'https://unity{{env}}.zeotap.com/graph-manager/v1/',
    firebaseUrl: 'https://unity{{env}}.zeotap.com/firebase/',
    oAuthRedirectUrl: `${window.location.origin}/app/oauth-callback`,
    catalogUrl: 'https://unity{{env}}.zeotap.com/canon/api/v1/',
    catalogUrlV2: 'https://unity{{env}}.zeotap.com/canon/api/v2/',
    catalogUrlV3: 'https://unity{{env}}.zeotap.com/canon/api/v3/',
    canonApiUrl: 'https://unity-mock{{env}}.zeotap.com/canon/api/v1/',
    workflowApiUrl: 'https://unity{{env}}.zeotap.com/symphony/api/v2/',
    customer360SvcUrl:
      'https://unity{{env}}.zeotap.com/graph-manager/v1/customer360/',
    streamingSourcesApiUrl:
      'https://watson-the-webhook{{env}}.zeotap.com/webhook/api/v1/batch',
    audienceSvcUrl: 'https://unity{{env}}.zeotap.com/audiences/',
    canonApiUrlV2: 'https://unity{{env}}.zeotap.com/canon/api/v2/',
    targetingUnityUrl: 'https://data{{env}}.zeotap.com/app/targeting/segments',
    sisenseTokenUrl: 'https://unity{{env}}.zeotap.com/sisense/api/token',
    sisenseTokenUrlV2: 'https://unity{{env}}.zeotap.com/sisense/api/v2/token',
    predictiveAudienceDataUrl:
      'https://data{{env}}.zeotap.com/app/predictive-audiences',
    audienceAIUrl: 'https://hermes{{env}}.zeotap.com/',
    helpLinks: {
      audience_apply_attributes:
        'https://docs.zeotap.com/articles/#!segment-customer/apply-attributes-to-your-audience',
      audience_activation:
        'https://docs.zeotap.com/articles/#!segment-customer/activate-your-audience',
      audience_insights:
        'https://docs.zeotap.com/articles/#!segment-customer/audience-insights',
      customer_360_deletedProf:
        'https://docs.zeotap.com/articles/#!protect-customer/delete-a-profile/a/h2__158433617',
      audience_overview:
        'https://docs.zeotap.com/articles/#!segment-customer/audience ',
      segment_module:
        'https://docs.zeotap.com/articles/#!segment-customer/audience ',
      connect_app:
        'https://docs.zeotap.com/articles/#!segment-customer/2734162118',
      sources_app:
        'https://docs.zeotap.com/articles/#!integrate-customer/sources',
      destinations_app:
        'https://docs.zeotap.com/articles/#!integrate-customer/destinations',
      id_strategy_app:
        'https://docs.zeotap.com/articles/unify-customer/identity-resolution',
      catalogue_app:
        'https://docs.zeotap.com/articles/#!unify-customer/catalogue',
      calculated_attribute_app:
        'https://docs.zeotap.com/articles/#!unify-customer/calculated-attributes',
      audience_app:
        'https://docs.zeotap.com/articles/#!segment-customer/audience',
      journey_app:
        'https://docs.zeotap.com/articles/#!orchestrate-customer/create-a-workflow',
      consent_app:
        'https://docs.zeotap.com/articles/#!protect-customer/consent-orchestration-2830401643',
      customer_360_app:
        'https://docs.zeotap.com/articles/#!protect-customer/customer-360',
      data_lifecycle_app:
        'https://docs.zeotap.com/articles/#!protect-customer/data-lifecycle', // TODO: update link
      target_app:
        'https://docs.zeotap.com/articles/#!targeting-customer/2969141372',
      admin_app: 'https://docs.zeotap.com/articles/#!admin-customer/admin',
      admin_organizations:
        'https://docs.zeotap.com/articles/#!admin-customer/creating-an-organisation',
      admin_users:
        'https://docs.zeotap.com/articles/#!admin-customer/add-an-user',
      dashboard_app:
        'https://docs.zeotap.com/articles/#!dashboard-customer/overview',
      support: 'https://support.zeotap.com/',
      feedback: 'https://support.zeotap.com/',
      primary_key_for_object:
        'https://docs.zeotap.com/articles/#!unify-customer/choose-your-data-type',
    },
    globalHelpInfos: [
      {
        label: 'Support',
        link: 'support',
        icon: 'unity-icons-phone',
      },
      {
        label: 'Feedback',
        link: 'feedback',
        icon: 'unity-icons-message_square',
      },
    ],
  },
};

import { mergeDeepRight } from 'ramda';

export type EnvVars = { [envVar: string]: string };

export const replaceEnv = (env: EnvVars) => (environment_value: string) => {
  let result = environment_value;
  for (const key in env) {
    result = result.replace(`{{${key}}}`, env[key]);
  }
  return result;
};

function applyFunctionToStringValues(
  obj: any,
  fn: (value: string) => string
): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const result: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      result[key] = fn(obj[key]);
    } else if (typeof obj[key] === 'object') {
      result[key] = applyFunctionToStringValues(obj[key], fn);
    } else {
      result[key] = obj[key];
    }
  }

  return result;
}

export const createEnvironment = (envVars: EnvVars) =>
  mergeDeepRight(
    applyFunctionToStringValues(environment_base, replaceEnv(envVars))
  );
