import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { UserPreference, UserPreferenceFacade } from '../../../preferences';
import { StorageService } from '../../../storage';
import { Organization } from '../../types';
import { OrgsListFacadeService } from '../orgs-list-facade';
import { getOrgById, getSelectedOrg, getValueFromData } from './utils';
import { isNotNull } from '../../../utils';
import { NgEventBus } from 'ng-event-bus';
import { LoggingService } from '../../../log';
import { equals } from 'ramda';

export interface SelectOrgState {
  selectedOrg: Organization; // list of selected org
}

const _orgState: SelectOrgState = {
  selectedOrg: null,
};

@Injectable({
  providedIn: 'any',
})
export class SelectedOrgFacadeService implements OnDestroy {
  // private variables/subjects to manage state
  private store$ = new BehaviorSubject<SelectOrgState>(_orgState);
  private state$: Observable<SelectOrgState> = this.store$.asObservable(); // keeping this private as this need not be a open to everyone
  private resolveOrg$ = new BehaviorSubject<void>(null);
  private destroy$ = new Subject<void>();
  selectedOrg$ = this.state$.pipe(
    map((state) => state.selectedOrg),
    filter((org) => isNotNull(org)),
    distinctUntilChanged(),
    shareReplay(1)
  );
  setSelectedOrg(selectedOrg: Organization) {
    this.store$.next({
      ...this.store$.value,
      selectedOrg,
    });
  }

  constructor(
    orgsListFacade: OrgsListFacadeService,
    private userPreferenceService: UserPreferenceFacade,
    storageService: StorageService,
    private eventBus: NgEventBus,
    private loggingService: LoggingService
  ) {
    const userDocListener$ = this.userPreferenceService.getUserPreference();

    // This is to determine the initial selectedOrg during Module load
    // Given the fact that selected org is a module level state , once the module is initialized ,
    // we need not try to change the selected org based on user preference apart from when the module stops being active and comes back to being active.
    combineLatest([
      orgsListFacade.orgs$,
      getValueFromData(this.resolveOrg$, userDocListener$),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([orgs, userPref]: [Organization[], UserPreference]) => {
        const { selectedOrg } = this.store$.value;
        const userOrg = storageService.getUserInfo().organization; // Provides user org id
        const _selectedOrgId = getSelectedOrg(
          userPref?.lastSelectedOrganizationId,
          selectedOrg?.id,
          userOrg
        )(orgs);
        const _selectedOrg = getOrgById(_selectedOrgId)(orgs);
        this.store$.next({
          ...this.store$.value,
          ...{
            selectedOrg: _selectedOrg,
          },
        });
      });
    this.selectedOrg$.pipe(takeUntil(this.destroy$)).subscribe((org) => {
      this.loggingService.logContext({
        orgId: org?.id,
      });
      this.userPreferenceService.updateUserPreference({
        lastSelectedOrganizationId: org?.id,
      });
    });
  }

  triggerOrgResolution() {
    this.resolveOrg$.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
