<ng-container *ngIf="{ state: state$ | async } as streams">
  <div class="pl-5 py-5 h-full transition-width duration-700 delay-150 ease-in-out" [ngClass]="{ 'w-[90px]': streams.state.isCollapsed, 'w-60': !streams.state.isCollapsed }">
    <div class="h-full bg-gradient-to-b from-primary-1-700 to-primary-3-700 rounded-[10px] relative flex flex-col pt-4">
      <div (click)="onToggleClicked()" class="bg-white rounded-full h-8 w-8 absolute bottom-[6.5rem] -right-4 text-sm flex justify-center shadow-[1px_1px_8px_rgba(0,0,0,0.15)] z-50">
        <span class="unity-icons-dropdown my-auto before-rotate-90 transform-with-ease" [ngClass]="{ '-before-rotate-90': streams.state.isCollapsed }"></span>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="defaultHeaderTemplate; context: { collapsed: streams.state.isCollapsed }"></ng-container>
      </div>
      <div class="w-full h-4 flex-shrink-0"></div>
      <div class="my-2 px-2.5 h-px w-full bg-white/[0.1] transition-opacity ease-in-out duration-700" [ngClass]="{ 'opacity-0': streams.state.isCollapsed }"></div>

      <div class="flex-1 overflow-x-hidden overflow-y-scroll scrollbar-hide">
        <ng-container *ngIf="!hideProductNavigation">
          <app-navbar-items-tree [collapsed]="streams.state.isCollapsed"></app-navbar-items-tree>
        </ng-container>
      </div>
      <!-- <div class="bottom-0 left-0">
        <ng-container *ngTemplateOutlet="defaultFooterTemplate; context: { collapsed: streams.state.isCollapsed }"></ng-container>
      </div> -->
      <div class="h-[56px] px-[10px]"></div>
      <!-- inner container-->
      <div class="w-full flex flex-col h-[100px] mb-2">
        <div class="h-[82px]">
          <!--Max height to prevent div taking full height during nav opening transition-->
          <ng-container *ngIf="!hideProductNavigation">
            <ng-container *ngTemplateOutlet="defaultFooterTemplate; context: { collapsed: streams.state.isCollapsed }"></ng-container>
          </ng-container>
        </div>
        <div class="h-4 text-xxs text-white opacity-100 w-full flex flex-col items-center justify-center">
          <div class="rounded-lg px-2.5 py-1 bg-primary-3-900/50 w-[164px] overflow-hidden text-center transition-all ease-linear delay-200 duration-500" [ngClass]="{ '!w-0 !px-0': streams.state.isCollapsed }">
            <span class="text-no-wrap font-primary-medium wrap transition-all ease-linear duration-500 opacity-100" [ngClass]="{ 'opacity-0': streams.state.isCollapsed }">Version {{ version }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #defaultHeaderTemplate let-collapsed="collapsed">
  <app-header-menu-item [collapsed]="collapsed"></app-header-menu-item>
</ng-template>

<ng-template #defaultFooterTemplate let-collapsed="collapsed">
  <app-user-menu-item [collapsed]="collapsed" (logOutEvent)="this.logOut()"></app-user-menu-item>
</ng-template>
