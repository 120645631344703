import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ifElse, compose, equals, type, includes } from 'ramda';

@Directive({
  selector: '[replaceWithNull]',
})
export class ReplaceWithNullDirective {
  @Input() replaceWithNull: string | string[];
  constructor(private control: NgControl) {}

  @HostListener('focusout', ['$event.target'])
  onEvent(target: HTMLInputElement) {
    const shouldValueBeReplaced = ifElse(
      compose(equals(type([])), type),
      includes(target.value),
      equals(target.value)
    )(this.replaceWithNull);
    this.control.control.setValue(shouldValueBeReplaced ? null : target.value);
  }
}
