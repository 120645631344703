import { CommonModule } from '@angular/common';
import { NgModule, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgEventBus } from 'ng-event-bus';
import { CorePipesModule } from '../pipes';
import { OrganisationTreeSelectionComponent } from './components';
import { OrgSelectionComponent } from './org-selection/org-selection.component';

@NgModule({
  declarations: [OrganisationTreeSelectionComponent, OrgSelectionComponent],
  imports: [CommonModule, FormsModule, NgSelectModule, CorePipesModule],
  exports: [OrganisationTreeSelectionComponent, OrgSelectionComponent],
})
export class OrgModule {
  constructor(@Optional() ngEventBus: NgEventBus) {
    if (!ngEventBus)
      console.warn(
        "Make sure you add 'NgEventBus' in your app's module providers. \n ... \n , providers:[...\n ,NgEventBus,\n... ] "
      );
  }
}
