<div class="flex flex-col gap-2 flex-wrap" [ngClass]="{ '!flex-row': orientation === 'column' }">
  <span class="control-name flex gap-1 text-primary-1-900 text-sm font-primary h-max">{{ controlName }} <span *ngIf="isMandatory" class="mandatory text-state-error-500">*</span><span *ngIf="controlInfoTooltipValue" customToolTip [text]="controlInfoTooltipValue" [customTooltipClass]="'!p-[.625rem] !w-max [&_.simpleText]:!text-xs !rounded'" class="icon informationIcon control-info text-grey/52 self-center text-4xs h-[14px] w-[14px] p-[2px] border-grey-700 border-[2px] border-solid rounded-[50%] inline-block"></span></span>
  <div class="flex flex-col gap-2">
    <div class="control-desc font-primary text-xs text-grey-700" *ngIf="controlDescPosition">{{ controlDescription }}</div>
    <div [ngClass]="{ '-order-1': controlDescPosition === 'bottom' }">
      <ng-content></ng-content>
    </div>
    <span class="control-additional-info font-primary text-xs text-grey-400">{{ additionalInfo }}</span>
  </div>
</div>
