import { LocaleService } from './locale.service';
import { Optional, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate',
})
export class LocalizationPipe implements PipeTransform {
  // making this optional to be able to use this without any service setup
  constructor(@Optional() private localeService: LocaleService) {}

  transform(value: any, args?: any, returnNullIfNotPresent = false): any {
    if (!value) return value;
    // adding this to basically make this a simple formatting only usecase.
    let isUUFormat = true; // value.startsWith('UU_'); // Removing this

    let localeString =
      this.localeService && isUUFormat
        ? this.localeService.getValue(value)
        : value;

    if (args && localeString) {
      for (let key of Object.keys(args)) {
        localeString = localeString.replace(`{{${key}}}`, args[key]);
      }
    }
    return localeString
      ? this.localeService.translateString(localeString)
      : returnNullIfNotPresent
      ? null
      : value;
  }
}
