import { Component, Input } from '@angular/core';

@Component({
  selector: 'z-loader',
  templateUrl: './loader.html',
  styleUrls: ['./loader.scss']
})
export class LoaderComponent {
  @Input() showLoader: Boolean;
  @Input() showIntermediateLoader: Boolean;
  @Input() loaderText: String;
  constructor() {}
}
