import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UserMetadata, UserService } from '@zeotap-ui/core';

@Component({
  selector: 'app-user-menu-item',
  templateUrl: './user-menu-item.component.html',
  styleUrls: ['./user-menu-item.component.scss'],
})
export class UserMenuItemComponent implements AfterContentChecked {
  constructor(private userService: UserService) {}

  @Input() collapsed: boolean = false;
  @Output() logOutEvent = new EventEmitter();
  userName: string = null;
  userEmail: string = null;

  ngAfterContentChecked(): void {
    const userInfo: UserMetadata = this.userService.getUserInfo();
    if (userInfo) {
      this.userName = userInfo
        ? (userInfo.firstName ?? '') + ' ' + (userInfo.lastName ?? '')
        : '';
      this.userEmail = userInfo ? userInfo.emailId : '';
    }
  }

  menuPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetX: 12,
    },
  ];
}
