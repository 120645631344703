export interface Organization {
  name: string;
  id: number;
  parent_org_id: number;
  zeo_org_uuid: string;
  isoAlpha3countryCode: string;
  currency: string;
  config?: object;
  org_type?: string[];
  view_uuid?: boolean;
  regions?: string[];
  vertical?: string;
  zeo_org_id?: number;
}
