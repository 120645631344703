import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CorePipesModule } from '@zeotap-ui/core';
import { CustomTabsComponent } from './custom-tabs.component';

@NgModule({
  declarations: [CustomTabsComponent],
  imports: [CommonModule, CorePipesModule, RouterModule],
  exports: [CustomTabsComponent],
})
export class CustomTabsModule {}
