import { Component, OnInit } from '@angular/core';
import { AuthFacadeService, AuthService } from '@zeotap-ui/core';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
  constructor(
    private authFacade: AuthFacadeService,
    private authService: AuthService
  ) {}
  isAuthInProgress$ = this.authFacade.isAuthInProgress$;

  ngOnInit(): void {}
  logout() {
    this.authService.logout();
  }
}
