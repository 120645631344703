import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../storage';

@Component({
  selector: 'zap-oauth-callback',
  template: '',
})
export class OauthCallbackComponent implements OnInit {
  params: any;
  constructor(
    private route: ActivatedRoute,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams,
      message = queryParams.code
        ? { ...queryParams, type: 'oauth_code' }
        : queryParams;
    this.storageService.setItem('oauth_code', JSON.stringify(message));
    window.close();
  }
}
