import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@zeotap-ui/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { CountChipComponent } from './count-chip/count-chip.component';



@NgModule({
  declarations: [CountChipComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
  ],
  exports: [CountChipComponent]
})
export class CountChipModule { }
