import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigModule, EvaluatorModule } from '@zeotap-ui/config';
import { CorePipesModule, CustomTooltipModule } from '@zeotap-ui/core';
import { CheckBoxGroupModule } from '../checkbox-group/checkbox-group.module';
import { DyanamicFormQuestionComponent } from './dyanamic-form-question/dyanamic-form-question.component';

import { DynamicFormComponent } from './dynamic-form.component';
import { QuestionDirective } from './question.directive';
import { QuestionControlService } from './services/question-control.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CheckBoxGroupModule,
    NgSelectModule,
    CustomTooltipModule,
    CorePipesModule,
    ConfigModule,
  ],
  exports: [DynamicFormComponent, QuestionDirective],
  declarations: [
    DynamicFormComponent,
    DyanamicFormQuestionComponent,
    QuestionDirective,
  ],
  providers: [QuestionControlService],
})
export class DynamicFormModule {}
