<div class="inProgressText h-full text-center relative">
  <div class="text absolute max-h-[65px] text-grey/52 text-sm leading-normal m-auto inset-0 font-primary-light" [ngClass]="{ 'leftAlignText text-left': smallDots }">
    <span *ngIf="showText" [ngClass]="{ 'errorText text-state-error-700': type === 'error', 'italicText italic': type === 'noData' || type === 'progress' }">
      {{ text.split('\n')[0] }}<br />
      {{ text.split('\n')[1] }}
    </span>
    <ng-container *ngIf="type === 'progress'">
      <div class="dots" [ngClass]="{ 'padding pt-[20px]': showText, 'smallDots text-start ': smallDots }">
        <span class="animate-[dots_0.6s_infinite_alternate] inline-block rounded-[10px] bg-primary-1" [ngClass]="{ 'animate-[smallDots_0.6s_infinite_alternate]': smallDots }"></span>
        <span class="animate-[dots_0.6s_infinite_alternate] inline-block rounded-[10px] bg-primary-1 animation-delay-200" [ngClass]="{ 'animate-[smallDots_0.6s_infinite_alternate]': smallDots }"></span>
        <span class="animate-[dots_0.6s_infinite_alternate] inline-block rounded-[10px] bg-primary-1 animation-delay-[400ms]" [ngClass]="{ 'animate-[smallDots_0.6s_infinite_alternate]': smallDots }"></span>
      </div>
    </ng-container>
    <ng-container *ngIf="this.type === 'error' && !hideReload">
      <label class="unity-icons-reload text-state-error-700 reloadIcon cursor-pointer block text-xl ml-[5px] mt-[25px]" (click)="emitEvent()"></label>
    </ng-container>
  </div>
</div>
