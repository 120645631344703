import {
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  ElementRef,
} from '@angular/core';
import { BaseControl, getControlValueAccessor } from '../base';
import * as d3 from 'd3';
import * as d3Shape_ from 'd3-shape';

const d3Shape = d3Shape_;

import d3Tip from 'd3-tip';
import { on } from 'events';

const getTip = () =>
  d3Tip().attr(
    'class',
    'd3-tip chart-tooltip tooltip zui-bgcolor-white zui-content'
  );
// tslint:disable:max-line-length

@Component({
  selector: 'zui-pie-chart',
  styleUrls: ['./pieChart.scss'],
  templateUrl: './pieChart.html',
  providers: [getControlValueAccessor(PieChartComponent)],
})
export class PieChartComponent
  extends BaseControl
  implements AfterViewInit, OnChanges
{
  public _tip = null;
  get tip() {
    if (!this._tip) {
      this._tip = getTip();
    }
    return this._tip;
  }
  public defaultChartConfig = {
    title: {
      enable: false,
      text: '',
    },
    skewCorrection: false,
    sort: false,
    backgroundColor: '#ffff',
    type: 'pie',
    innerRadius: 0,
    cornerRadius: 0,
    color: ['#5AEDC2', '#E0FFF6', '#F4DA64', '#FF6496', '#4D5A74', '#7EDEDE'],
    stroke: {
      opacity: 1,
      stroke: 'none',
      strokeWidth: 0,
      strokeColor: ['#A9F8E1 ', 'blue'],
    },
    margin: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
    },
    legend: {
      enable: true,
      font: 10,
      type: {
        name: 'circle',
        width: '',
        height: '',
        radius: 4,
      },
      formatter() {
        return this.point.name;
      },
    },
    shadow: {
      enable: false,
      color: '#e1e1e1',
    },
    selection: '',
    tooltip: {
      enable: false,
      formatter() {},
      defaultText: '',
    },
    transition: {
      delay: 1000,
      duration: 500,
    },
  };
  @Input() public data = [];
  @Input() public options = this.defaultChartConfig;

  public arc;
  public color;
  public cornerRadius;
  public customWidth;
  public height;
  public innerRadius;
  public pie;
  public radius;
  public svg;
  public width;

  constructor(public eleRef: ElementRef) {
    super();
    this.svg = d3.select(this.eleRef.nativeElement);
  }

  public ngAfterViewInit() {
    this.svg = this.svg.select('svg');
    this.options = Object.assign(this.defaultChartConfig || {}, this.options);
    this.refresh();
  }

  public ngOnChanges() {
    if (this.data && this.data.length) {
      if (this.options.sort) {
        this.sortData();
      }
      this.initPie();
      this.drawPie(this.data);
    }
  }

  public cleanData() {
    if (!this.options.skewCorrection) {
      const total = d3.sum(
        this.data.map((obj) => {
          return obj.value;
        })
      );
      this.data = this.data.map((d) => {
        d['percentage'] = parseFloat(((d.value / total) * 100).toFixed(2));
        return d;
      });
    }
  }

  public initPie() {
    this.cleanData();
    this.color = d3.scaleOrdinal().range(this.options['color']);

    this.pie = d3Shape
      .pie()
      .sort(null)
      .value((d) => {
        return d['percentage'];
      });
  }

  public refresh() {
    this.svg = d3.select(this.eleRef.nativeElement).select('svg');
    if (this.options.sort) {
      this.sortData();
    }
    this.initPie();
    let percentage = 0.6;
    let container = d3.select(this.svg.node().parentNode.parentNode);
    let margin = this.options['margin'];
    this.width =
      parseInt(container.style('width'), 10) - margin.left - margin.right;
    this.height =
      parseInt(container.style('height'), 10) - margin.top - margin.bottom;
    this.radius = (Math.min(this.width, this.height) * percentage) / 2;
    this.customWidth = Math.max(this.width, this.height) * percentage;
    this.checkChartType();

    this.arc = d3Shape
      .arc()
      .innerRadius(this.innerRadius)
      .outerRadius(this.radius);
    this.initChart();
  }

  public sortData() {
    this.data.sort((a, b) => {
      return d3.ascending(a.percentage, b.percentage);
    });
  }

  public checkChartType() {
    this.innerRadius =
      this.options &&
      this.options['type'].toLowerCase() === 'donut' &&
      this.options['innerRadius'] !== 0
        ? this.radius - this.options['innerRadius']
        : this.options['innerRadius'];
    this.cornerRadius =
      this.options &&
      this.options['type'].toLowerCase() === 'donut' &&
      this.options['innerRadius'] !== 0
        ? this.options['cornerRadius']
        : this.options['cornerRadius'];
  }

  public initChart() {
    let container = this.svg
      .attr('width', '100%')
      .attr('height', '100%')
      .call(() => this.responsivefy(this.svg));

    this.svg = container
      .append('g')
      .attr(
        'transform',
        `translate(${this.customWidth / 2}, ${this.height / 2})`
      );

    this.initLegend(container);
    this.svg.call(this.tip);
    this.checkDataLength(this.data);

    let pieArcs = this.svg.append('g').attr('class', 'arcs');

    this.drawPie(this.data);
  }

  public initLegend(container) {
    let yPosition = 0;
    if (this.data.length === 2) {
      yPosition = -15;
    }
    if (this.data.length > 2 && this.data.length <= 5) {
      yPosition = -40;
    }
    if (this.data.length > 5) {
      yPosition = -65;
    }
    let legend = container
      .append('g')
      .attr('class', 'chart-legend')
      .attr(
        'transform',
        `translate(${
          (Math.min(this.height, this.width) - this.customWidth) / 2
        }, ${yPosition})`
      );
  }

  public drawPie(data) {
    this.checkDataLength(data);
    this.showLegend(data);

    let arc = d3Shape
      .arc()
      .innerRadius(this.innerRadius)
      .outerRadius(this.radius)
      .cornerRadius(this.checkCornerRadius(data));

    let update = this.svg
      .select('.arcs')
      .selectAll('path')
      .data(this.pie(data));

    update.exit().remove();

    let enter = update.enter().append('path');
    let path = update
      .merge(enter)
      .attr('class', 'pie')
      .attr('fill', (d) => this.color(d.data.name))
      .style('opacity', this.options['stroke'].opacity)
      .style('stroke', this.options['stroke'].stroke)
      .style('stroke-width', this.options['stroke'].strokeWidth + 'px');

    path
      .transition()
      .delay(function (d, i) {
        return 50 + i * 200;
      })
      .duration(this.options['transition'].duration)
      .attrTween('d', function (d) {
        let i = d3.interpolate(d.startAngle, d.endAngle);
        return (t) => {
          d.endAngle = i(t);
          return arc(d);
        };
      });

    this.showTooltip(path);
  }

  public checkCornerRadius(data) {
    return (this.cornerRadius =
      data && data.length === 2 ? this.cornerRadius : 0);
  }

  public checkDataLength(data) {
    if (
      this.options &&
      this.options['type'].toLowerCase() === 'donut' &&
      data &&
      data.length === 2
    ) {
      let arcWithoutCornerRadius = d3Shape
        .arc()
        .innerRadius(this.innerRadius)
        .outerRadius(this.radius)
        .cornerRadius(this.radius - this.innerRadius)
        .startAngle(0);

      let sharpArc = this.svg.append('g').attr('class', 'sharp-arc');

      let update = this.svg
        .select('.sharp-arc')
        .selectAll('path')
        .data(this.pie(data));

      update.exit().remove();

      let enter = update.enter().append('path');

      let path = update
        .merge(enter)
        .attr('class', 'pieArc')
        .attr('fill', (d) => this.color(d.data.name))
        .style('opacity', this.options['stroke'].opacity)
        .style('stroke', this.options['stroke'].stroke)
        .style('stroke-width', this.options['stroke'].strokeWidth + 'px');

      path
        .transition()
        .delay(function (d, i) {
          return 50 + i * 200;
        })
        .duration(this.options['transition'].duration)
        .attrTween('d', function (d) {
          const c = Object.assign({}, d);
          c.startAngle = 0.5;
          let i = d3.interpolate(c.startAngle, c.endAngle);
          return (t) => {
            c.endAngle = i(Math.min(t, 1));
            return arcWithoutCornerRadius(c);
          };
        });
    }
  }

  public responsivefy(svg) {
    let container = d3.select(this.svg.node().parentNode.parentNode);
    let width = parseInt(svg.style('width'), 10);
    let height = parseInt(svg.style('height'), 10);

    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('preserveAspectRatio', 'xMinYMid');
  }

  public showLegend(data) {
    if (this.options && this.options['legend'].enable) {
      let textSpacing = 13;
      let svg = d3.select(this.eleRef.nativeElement).select('svg');
      let filteredData = data.filter((d) => d.percentage >= 0.01);
      let legend = svg
        .select('.chart-legend')
        .selectAll('.legendGrp')
        .data(filteredData);
      legend.exit().remove();
      legend.selectAll('*').remove();

      let legendGrp = legend
        .enter()
        .append('g')
        .attr('transform', (d, i) => {
          let legendSize = 18;
          let legendSpacing = 4;
          let height = legendSize + legendSpacing;
          let offset = (height * this.color.domain().length) / 2;
          let horz = -2.5 * legendSize;
          let vert = i * height - offset;
          return `translate(${horz}, ${vert})`;
        })
        .attr('class', 'legendGrp');

      legendGrp = legendGrp.merge(legend);

      this.hasLegendType(legendGrp);
      legendGrp.exit().remove();
      legendGrp
        .append('text')
        .attr('x', `${this.customWidth + textSpacing}`)
        .attr('y', `${this.height / 2}`)
        .attr('dy', '.35em')
        .attr('class', 'zui-normaltext')
        .style('font-size', this.options['legend'].font)
        .transition()
        .delay(this.options['transition'].delay)
        .style('text-anchor', 'start')
        .text((d) => {
          let Formatter = this.options['legend'].formatter;
          let point = d;
          return Formatter ? Formatter.call({ point }) : d.data.name;
        });
    }
  }

  public hasLegendType(legendGrp) {
    this.options['legend'].type.name.toLowerCase() === 'circle'
      ? this.initLegendCircle(legendGrp)
      : this.initLegendRect(legendGrp);
  }

  public initLegendCircle(legendGrp) {
    if (this.options['legend'].type.name) {
      legendGrp
        .append(this.options['legend'].type.name.toLowerCase())
        .transition()
        .delay(this.options['transition'].delay)
        .attr('cx', `${this.customWidth}`)
        .attr('cy', `${this.height / 2}`)
        .attr('r', this.options['legend'].type.radius)
        .attr('fill', (d) => {
          return this.color(d.name);
        });
    }
  }

  public initLegendRect(legendGrp) {
    if (this.options['legend'].type.name) {
      legendGrp
        .append(this.options['legend'].type.name.toLowerCase())
        .transition()
        .delay(this.options['transition'].delay)
        .attr('width', this.options['legend'].type.width)
        .attr('height', this.options['legend'].type.height)
        .attr('x', `${this.customWidth}`)
        .attr('y', `${this.height / 2 - 5}`)
        .style('fill', (d) => {
          return this.color(d.name);
        });
    }
  }

  public showTooltip(path) {
    if (this.options && this.options['tooltip'].enable) {
      let Formatter = this.options['tooltip'].formatter;
      let options = this.options;
      const onMouseMove = (tip) => (d) => {
        let point = d.data;
        tip.show(d);
        const tipDom = <HTMLElement>(
          document.querySelector('.d3-tip.chart-tooltip')
        );
        tip.html(Formatter ? Formatter.call({ point, options }) : '');
        tipDom.style.left = d3.event.pageX - tipDom.clientWidth / 2 + 'px';
        tipDom.style.top = d3.event.pageY - tipDom.clientHeight - 10 + 'px';
      };
      path
        .on('mousemove', onMouseMove(this.tip))
        .on('mouseout', this.hideToolTip);
    }
  }

  public hideToolTip() {
    this.tip?.hide();
  }
}
