<form *ngIf="dynamicGraphForm" [formGroup]="dynamicGraphForm" class="flex flex-col gap-2">
  <ng-container *ngFor="let node of formGraph">
    <div class="w-full h-full order-{{ node.details?.order || 1 }}" *ngIf="!node.details?.isHidden">
      <ng-container *ngIf="{name: node.details.name, displayName: node.details.displayName, metadata: node.details.metadata, activeEdgeForNode: activeEdges?.[node.details.name]} as nodeDetails" [formGroup]="dynamicGraphForm.get(nodeDetails.name)">
        <ng-container [ngSwitch]="node.details.nodeType">
          <zap-control-wrapper *ngSwitchCase="'dropdown'" class="block mt-4" [controlName]="nodeDetails.displayName" [controlDescription]="node.details.description" [isMandatory]="node.details.isMandatory" [controlDescPosition]="'top'">
            <zap-dropdown-control
              [controlKey]="'value'"
              [placeholderText]="nodeDetails.metadata?.placeholderText || nodeDetails.displayName"
              [items]="node.details.items"
              [labelKey]="nodeDetails.metadata?.labelKey"
              [valueKey]="nodeDetails.metadata?.valueKey"
              [clearAll]="nodeDetails.metadata?.clearAll"
              [multiple]="nodeDetails.metadata?.multiple"
              [closeOnSelect]="true"
              [itemsLoading]="nodeDetails.metadata?.itemsLoading"
              [maxNoOfLabelChips]="nodeDetails.metadata?.maxNoOfLabelChips"
              [allowSelectAllAction]="nodeDetails.metadata?.allowSelectAllAction"
              [actionItem]="nodeDetails.metadata?.actionItem"
              (onChange)="setActiveEdge($event, node)"
            ></zap-dropdown-control>
          </zap-control-wrapper>
          <zap-checkbox-control *ngSwitchCase="'checkbox'" class="mt-4 block" [controlKey]="'value'" [label]="nodeDetails.displayName" (onChange)="setActiveEdge($event, node)"></zap-checkbox-control>
          <zap-control-wrapper *ngSwitchDefault class="block mt-4" [controlName]="nodeDetails.displayName" [controlDescription]="node.details.description" [isMandatory]="node.details.isMandatory" [controlDescPosition]="'top'">
            <zap-plain-input-control [placeholderText]="nodeDetails.metadata?.placeholderText || nodeDetails.displayName" [controlKey]="'value'" [type]="node.details?.type" (onChange)="setActiveEdge($event, node)"></zap-plain-input-control>
          </zap-control-wrapper>
        </ng-container>
        <ng-container *ngIf="node.edges && nodeDetails.activeEdgeForNode">
          <zap-dynamic-graph-form [formGraph]="nodeDetails?.activeEdgeForNode?.nodes" [controlKey]="nodeDetails?.activeEdgeForNode?.name" [viewMode]="viewMode"></zap-dynamic-graph-form>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</form>
