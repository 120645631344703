<div class="organization-selection-container">
  <!--TODO: Add a alternate view for readonly org-->
  <ng-select
    class="zui-content ng-select-theme"
    [ngClass]="{
      'disable-arrow': disabled
    }"
    [readonly]="disabled"
    [clearable]="false"
    [items]="organizations | sortBy: 'name'"
    bindLabel="name"
    [selectableGroup]="false"
    [closeOnSelect]="true"
    placeholder="Select Organization"
    [(ngModel)]="selectedOrganization"
    (ngModelChange)="changeSelectedOrganization($event)"
  >
  </ng-select>
</div>
