<div *ngFor="let stepTmpl of stepTemplates; let i = index">
  <div class="w-full pb-5 pl-5 pr-2 font-primary" [ngClass]="{ 'action-container border-l border-solid': i < actionStepsSelected.length -1, 'viewMode !border-grey-600': i < actionStepsSelected.length -1 && (!actionStepsSelected?.[i] || viewMode), 'action-selected border-primary-1': actionStepsSelected?.[i+1] }">
    <div class="bg-grey-600 w-4 h-4 rounded-[50%] text-white text-xxs flex justify-center items-center relative -left-7 z-10" [ngClass]="{ '!bg-grey-300': viewMode, '!bg-primary-1': actionStepsSelected?.[i] && !viewMode }">
      {{ i + 1 }}
    </div>
    <div class="relative -top-10 w-full min-h-[128px] h-max p-6 rounded-lg border border-solid border-grey-150" *ngIf="actionStepsSelected?.[i]">
      <ng-container [ngTemplateOutlet]="stepTmpl"></ng-container>
    </div>
  </div>
</div>
