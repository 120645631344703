import { AbstractControl } from '@angular/forms';

export type FormNode = {
  details: {
    displayName: string;
    description?: string;
    metadata?: TextNodeMetadata | DropdownNodeMetadata | TextAreaNodeMetadata;
    type: string; // type to preserve wrapper component type. Eg:- nestedDropdown
    nodeType: 'text' | 'dropdown' | 'checkbox' | 'textarea'; //type corresponding to the dynamic graph form control
    name: string;
    items?: any[];
    activeEdge?: FormEdge;
    order?: number;
    value?: any;
    isHidden?: boolean;
  };
  edges?: FormEdge[];
};

export type FormEdge = {
  name: string;
  activeFn: (value) => boolean;
  nodes?: FormNode[];
};

export type BaseFormNodeMetadata = Partial<{
  placeholderText: string;
  validators: ValidatorFn[];
  disabled: boolean;
  defaultValue: any;
}>;

export type TextNodeMetadata = BaseFormNodeMetadata & {};

export type TextAreaNodeMetadata = BaseFormNodeMetadata &
  Partial<{
    allowCopy: boolean;
    enableResize: boolean;
    rows: number;
    cols: number;
  }>;

export type DropdownNodeMetadata = BaseFormNodeMetadata &
  Partial<{
    valueKey: string;
    labelKey: string;
    clearAll: boolean;
    multiple: boolean;
    itemsLoading: boolean;
    maxNoOfLabelChips: number;
    allowSelectAllAction: boolean;
    actionItem: any;
  }>;

export type ValidatorFn = (control: AbstractControl) => any;
