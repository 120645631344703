import { ifElse, find, propEq, compose, always, head, prop } from 'ramda';
import { Organization } from '../../types';
import { isNotNullOrEmpty } from '../../../utils';
import { Observable, catchError, of, switchMap, take, timeout } from 'rxjs';

// getSelectedOrg :: (string) -> Organization[] -> Organization
export const getOrgById = (orgId) => find(propEq('id', orgId));

export const includesOrgById =
  (searchOrgId: number) =>
  (orgs: Organization[]): boolean => {
    if (isNotNullOrEmpty(searchOrgId)) {
      return compose(isNotNullOrEmpty, find(propEq('id', searchOrgId)))(orgs);
    } else {
      return false;
    }
  };
// getSelectedOrg :: (string, string, string)-> Organization[] -> string
export const getSelectedOrg = (lastActiveOrg, savedOrg, userOrg) =>
  ifElse(
    includesOrgById(lastActiveOrg),
    always(lastActiveOrg),
    ifElse(
      includesOrgById(savedOrg),
      always(savedOrg),
      ifElse(
        includesOrgById(userOrg),
        always(userOrg),
        compose(prop('id'), head)
      )
    )
  );

// write code that given two observables trigger  and data , returns a pipe that on event from trigger ,  takes 1  value from data and returns that value . if data has no value it waits for max 10 seconds before resolving to null
export function getValueFromData(
  trigger: Observable<any>,
  data: Observable<any>,
  timeoutDuration = 10000
): Observable<any> {
  return trigger.pipe(
    switchMap(() => data.pipe(timeout(timeoutDuration), take(1))),
    catchError(() => of(null))
  );
}
