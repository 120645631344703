import { Component, Input, OnInit } from '@angular/core';
import { getPercentageValue } from '@zeotap-ui/core';

@Component({
  selector: 'zap-progress-chart',
  templateUrl: './progress-chart.component.html',
  styleUrls: ['./progress-chart.component.scss'],
})
export class ProgressChartComponent implements OnInit {
  @Input() data: { [key: string]: number };
  @Input() total: number;
  @Input() colors: string[];
  @Input() showLabels = true;
  placeholderCount = 0;
  noSortFnPlaceholder = (_, __) => 0;

  constructor() {}

  ngOnInit(): void {}

  getChartItemWidth(chartItemCount: number) {
    return getPercentageValue(chartItemCount, this.total);
  }
  getTotalWidth() {
    const totalActive = Object.values(this.data).reduce(
      (prevCount, currentCount) => prevCount + currentCount,
      0
    );
    this.placeholderCount = 2 * this.total - totalActive;
    return `${(this.placeholderCount * 100) / this.total}%`;
  }

  get placeholderKey() {
    return Object.keys(this.data)[0];
  }
}
