import { AsyncCallResponse } from '../http';

export type Currency = {
  id: number;
  code: string;
  name: string;
};

export type CurrencyAPIResponse = AsyncCallResponse<Currency[]>;

export type CurrencyState = {
  loading: boolean;
  currencies: Currency[];
  errors?: any;
};
