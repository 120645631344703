import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClipboardCopyComponent } from './clipboard-copy.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [ClipboardCopyComponent],
  imports: [CommonModule, MatTooltipModule, ClipboardModule],
  exports: [ClipboardCopyComponent],
})
export class ClipBoardCopyModule {}
