import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

import { prop } from 'ramda';
import { isNotNullOrEmpty } from '../../utils';
import { AuthResponseData } from '../types';
import { AuthService } from './auth.service';
import { FirebaseAuthService } from './firebase-auth.service';
import { StorageService } from '../../storage';
interface AuthState {
  inProgress: boolean;
  error?: string;
  yetToFetchUserData?: boolean;
  userInfo?: any; // TODO : Improve the user modeling here
}

const initAuthState: AuthState = {
  inProgress: true,
  userInfo: null,
  yetToFetchUserData: true,
};

@Injectable({
  providedIn: 'root',
})
export class AuthFacadeService {
  private store$ = new BehaviorSubject<AuthState>(initAuthState);
  state$ = this.store$.asObservable();

  error$ = this.state$.pipe(
    map((state) => state?.error),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isAuthInProgress$ = this.state$.pipe(
    map((state) => state.inProgress),
    distinctUntilChanged(),
    shareReplay(1)
  );

  userInfo$ = this.state$.pipe(
    map(prop('userInfo') as (s: AuthState) => any),
    filter(isNotNullOrEmpty),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private firebaseAuth: FirebaseAuthService,
    private authService: AuthService,
    public storageService: StorageService
  ) {}

  initAuthState() {
    this.firebaseAuth.signIn();
    this.refreshUserData();
  }

  refreshUserData() {
    this.store$.next({
      ...this.store$.value,
      inProgress: true,
      error: null,
    });
    this.authService.fetchUserData().subscribe((res: AuthResponseData) => {
      if (res.error) {
        this.store$.next({
          ...this.store$.value,
          inProgress: false,
          error: res.error,
          yetToFetchUserData: false,
        });
      } else {
        this.authService.storeUserData(res);
        this.authService.logUserInfo(res);
        this.store$.next({
          ...this.store$.value,
          error: null,
          inProgress: false,
          userInfo: res.value,
          yetToFetchUserData: false,
        });
      }
    });
  }

  sendTermsAndConditionsAcceptance(response: boolean) {
    this.authService.sendTermsAndConditionsAcceptance(
      response,
      this.storageService.getUserId()
    );
  }
}
