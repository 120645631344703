<div class="bg-white rounded-lg shadow-6 flex justify-between p-4 relative w-full mx-auto">
  <div *ngIf="showBackBtn" class="cursor-pointer p-3 rounded font-primary flex justify-center items-center text-sm text-primary-1 border border-solid border-primary-1 min-h-[3rem] min-w-[6.25rem] cancel-button hover:shadow-10 capitalize" (click)="backClicked.emit()" [ngClass]="{ 'pointer-events-none': saveInprogress }">
    {{ backText }}
  </div>
  <div *ngIf="showNextBtn" class="cursor-pointer p-3 px-8 rounded font-primary flex justify-center items-center text-sm bg-primary-1 text-white min-h-[3rem] min-w-[6.25rem] next-button hover:shadow-11 capitalize" [ngClass]="{ '!bg-grey/52 pointer-events-none hover:shadow-none': disableNextBtn }" (click)="onNext()">
    <ng-container *ngIf="!saveInprogress; else loader">{{ nextText }}</ng-container>
  </div>
  <ng-template #loader>
    <mat-spinner class="[&_circle]:!stroke-white" diameter="20"></mat-spinner>
  </ng-template>
</div>
