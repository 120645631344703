import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { takeUntil, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'zap-code-snippet',
  templateUrl: './code-snippet.component.html',
})
export class CodeSnippetComponent implements OnInit, OnDestroy {
  @Input() textFromParent: string = '';
  buttonText$ = new BehaviorSubject<string>('Copy Snippet');
  private destroy$ = new Subject<void>();

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {}

  copyTextToClipboard() {
    this.clipboard.copy(this.textFromParent);
    this.buttonText$.next('Copied!');

    interval(3000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.buttonText$.next('Copy Snippet');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
