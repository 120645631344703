import { Pipe, PipeTransform } from '@angular/core';
import { reverse } from 'ramda';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(array: any[], orderBy?: string): any[] {
    if (!array || array.length < 2) {
      return array;
    }
    const sortedArr: any[] = array.sort();
    return !!orderBy && orderBy === 'desc' ? reverse(sortedArr) : sortedArr;
  }
}
