import { Injectable } from '@angular/core';
import { ConfigService } from '@zeotap-ui/config';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  from,
  of,
} from 'rxjs';
import { catchError, filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import {
  AsyncCallResponse,
  Status,
  handleAsyncError,
  isError,
} from '../../http';
import { isNotNullOrEmpty } from '../../utils';
import { SisenseTokenGeneratorService } from './sisense-token-generator.service';

@Injectable({
  providedIn: 'root',
})
export class SisenseJSAppService {
  private sisenseConfig: any;
  private sisenseURL: string;
  private sisenseTheme: string;
  private sisenseApp$ = new ReplaySubject<any>(1);
  private sisenseConnectionError$ = new BehaviorSubject<string>(null);
  private isSisenseAppReady$ = new BehaviorSubject<boolean>(false);
  get isSisenseAppReady(): Observable<boolean> {
    return this.isSisenseAppReady$.asObservable();
  }
  constructor(
    private config: ConfigService,
    private sisenseTokenGeneratorService: SisenseTokenGeneratorService
  ) {
    this.sisenseConfig = this.config.getFeatureValue(
      'SISENSE_CONFIG',
      true
    ).config;
    this.sisenseTheme = this.sisenseConfig?.themeId;
    this.sisenseURL = this.sisenseConfig?.url;

    combineLatest([
      this.sisenseTokenGeneratorService.sisenseWat$,
      this.isSisenseAppReady$,
    ])
      .pipe(
        filter(([wat, isAppReady]) => isNotNullOrEmpty(wat) && !isAppReady),
        switchMap(([wat, _]) => this.connectToSisense(wat)),
        tap((res) => {
          if (isError(res.status)) {
            this.sisenseConnectionError$.next(res.error);
          } else {
            this.sisenseConnectionError$.next(null);
            this.onSisenseAppReady(res.data);
            this.sisenseApp$.next(res.data);
          }
        }),
        pluck('data')
      )
      .subscribe();
  }

  private connectToSisense(wat: any): Observable<AsyncCallResponse<any>> {
    const Sisense = window['Sisense'];
    return from(Sisense.connect(this.sisenseURL, false, wat)).pipe(
      map((app) => ({ status: Status.Success, data: app })),
      catchError((error) => {
        return of(
          handleAsyncError<AsyncCallResponse<any>>(
            'Something bad happened. Please try again later.'
          )
        );
      })
    );
  }

  private onSisenseAppReady(app: any) {
    app.setTheme(this.sisenseTheme);
    this.isSisenseAppReady$.next(true);
  }

  loadDashboard(dashboardId): Observable<any> {
    return this.sisenseApp$.pipe(
      filter(isNotNullOrEmpty),
      switchMap((app) => from(app?.dashboards.load(dashboardId))),
      catchError((error) => {
        return of(
          handleAsyncError<AsyncCallResponse<any>>(
            'Something bad happened. Please try again later.'
          )
        );
      })
    );
  }
}
