import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideIndicatorComponent } from './side-indicator/side-indicator.component';

@NgModule({
  declarations: [],
  imports: [CommonModule, SideIndicatorComponent],
  exports: [SideIndicatorComponent, SideIndicatorComponent],
})
export class VectorsModule {}
