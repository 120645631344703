<ng-container *ngIf="{ value: groupErrorsByType(dropdownControl) } as error">
  <ng-select
    class="dropdown-control font-primary text-sm ng-select-theme [&_.ng-placeholder]:!top-0 [&_.ng-select-container]:!h-10 [&_.ng-dropdown-panel]:!z-[999] [&_.ng-select-container]:!z-[998] [&_.ng-dropdown-panel_.ng-dropdown-header]:!h-7 [&_.ng-dropdown-header]:flex transition-all ease-in-out [&.ng-select-focused>.ng-select-container]:!shadow-[inset_0_1px_1px_rgba(0,0,0,0.075),0_0_8px_rgba(102,175,233,0.6)] [&.ng-select-focused>.ng-select-container]:!outline-none [&.ng-select-opened_>_.ng-select-container]:!border-primary-1"
    [items]="items"
    [bindLabel]="labelKey"
    [bindValue]="valueKey"
    [multiple]="multiple"
    [closeOnSelect]="closeOnSelect"
    [clearable]="clearAll"
    [clearSearchOnAdd]="true"
    [placeholder]="placeholderText"
    [loading]="itemsLoading"
    [ngClass]="{ '[&.ng-select_>_.ng-select-container]:!border-state-error-700': error.value?.highlight?.length }"
    [formControlName]="controlKey"
    customToolTip
    [contentTemplate]="error.value?.highlight?.length ? errors : null"
    [value]="{ errors: error.value?.highlight, errorTextColor: 'primary' }"
    [customTooltipClass]="'!p-[.625rem] !w-max [&_.simpleText]:!text-xs !rounded'"
    [positions]="tooltipPositions"
    [placeholder]="placeholderText"
  >
    >
    <ng-template *ngIf="(multiple && allowSelectAllAction) || actionItem" ng-header-tmp>
      <div *ngIf="multiple && allowSelectAllAction" class="cursor-pointer font-primary ml-auto text-primary-1" [ngClass]="{ '!text-grey/52 pointer-events-none cursor-not-allowed': dropdownControl.value?.length >= items.length }" (click)="selectAll()">
        {{ 'Select all' }}
      </div>
      <div *ngIf="actionItem" class="font-primary text-sm text-primary-1 cursor-pointer ml-[0.1875rem] mr-[1.4375rem] mt-0 mb-0" (click)="setCustomValue(valueKey ? actionItem[valueKey] : actionItem)">+ {{ labelKey ? actionItem[labelKey] : (actionItem | translate) }}</div>
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value items-center flex" *ngFor="let item of items | slice : 0 : maxNoOfLabelChips" customToolTip [text]="labelKey ? item[labelKey] : item" [customTooltipClass]="'!p-[.625rem] !w-max [&_.simpleText]:!text-xs !rounded'" [positions]="tooltipPositions">
        <span class="ng-value-label truncate"> {{ labelKey ? item[labelKey] : item }}</span>
        <span *ngIf="!item.disabled" class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
      <div class="ng-value" *ngIf="items.length > maxNoOfLabelChips" customToolTip [contentTemplate]="moreInfoTooltip" [value]="{ items: items | slice : maxNoOfLabelChips }" [customTooltipClass]="'!p-[.625rem] !w-max [&_.simpleText]:!text-xs !rounded'" [positions]="tooltipPositions">
        <span class="ng-value-label">+ {{ items.length - maxNoOfLabelChips }} more...</span>
      </div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" let-item$="item$" let-index="index" let-clear="clear">
      <div class="flex flex-row items-center h-full w-full z-10 relative" customToolTip [text]="labelKey ? item[labelKey] : item" [customTooltipClass]="'!w-max !leading-4 !py-[0.3125rem] !px-[0.625rem]'" [positions]="tooltipPositions">
        <span class="truncate">{{ labelKey ? item[labelKey] : item }}</span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <div *ngIf="multiple; else singleSelectOption" class="option-holder" customToolTip [text]="labelKey ? item[labelKey] : item" [customTooltipClass]="'!p-[.625rem] !w-max [&_.simpleText]:!text-xs !rounded'" [positions]="tooltipPositions">
        <input id="option-{{ index }}" class="cursor-pointer" type="checkbox" [ngModel]="item$.selected" [disabled]="item$.disabled" [ngModelOptions]="{ standalone: true }" />
        <label class="truncate w-full cursor-pointer">{{ labelKey ? item[labelKey] : item }}</label>
      </div>
      <ng-template #singleSelectOption>
        <span id="option-{{ index }}" class="truncate w-full h-full block" customToolTip [text]="labelKey ? item[labelKey] : item" [customTooltipClass]="'!p-[.625rem] !w-max [&_.simpleText]:!text-xs !rounded'" [positions]="tooltipPositions">{{ labelKey ? item[labelKey] : item }}</span>
      </ng-template>
    </ng-template>
  </ng-select>
  <div class="mt-1" *ngIf="error.value?.message?.length">
    <ng-container *ngTemplateOutlet="errors; context: { errors: error.value?.message, errorTextColor: 'secondary' }"></ng-container>
  </div>
</ng-container>
<ng-template #moreInfoTooltip let-items="items">
  <div *ngFor="let item of items" class="font-primary text-xs text-white">
    {{ labelKey ? item[labelKey] : item }}
  </div>
</ng-template>
<ng-template #errors let-errors="errors" let-errorTextColor="errorTextColor">
  <zap-control-errors [errors]="errors" [errorTextColor]="errorTextColor"></zap-control-errors>
</ng-template>
