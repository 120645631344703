import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import {
  AuthCheckResolver,
  LoginComponent,
  OauthCallbackComponent,
  ProductAccessAuthGuard,
  lastSelectedProductGuard,
} from '@zeotap-ui/core';
import { BaseComponent } from './default-pages/base/base.component';
import { ErrorComponent } from './default-pages/error/error.component';
import { NotFoundComponent } from './default-pages/not-found/not-found.component';
import { UnauthorizedComponent } from './default-pages/unauthorized/unauthorized.component';
import { MainMenuItem } from './navbar';
import { DataAppRedirectService } from './services/data-app-redirect.service';
import { RedirectNavigationGuard } from './services/navigation.service';
import { PermissionGuard } from './services/permission-guard.service';
import { ForgotPasswordComponent } from '@zeotap-ui/components';
import { environment } from '@cdp/environments/environment';
import { CDP_PRODUCTS } from './consts';

export const reduceMenuItemsToRoutes = (menuItems: MainMenuItem[]) => {
  return menuItems.reduce((acc, curr) => {
    if (curr.children && curr.children.length > 0) {
      acc = [
        ...reduceMenuItemsToRoutes(curr.children),
        ...[curr.route],
        ...acc,
      ];
    } else {
      // Child nodes that are representative of terminal routes
      acc = [curr.route, ...acc];
    }
    return acc;
  }, []);
};

export const getlevelOneRedirects = (menuItems: MainMenuItem[]) => {
  return menuItems.reduce((acc, current) => {
    if (current.route && current.route.data && current.route.data.redirects) {
      acc = [...acc, ...current.route.data.redirects];
    }
    return acc;
  }, []);
};

// Update all the guards based on new functions available with okta sdk upgrade
export const mainMenuItems: MainMenuItem[] = [
  {
    id: 1,
    iconClass: 'unity-icons-integrate',
    label: 'Integrate',
    route: {
      path: 'integrate',
      pathMatch: 'full',
      canActivate: [RedirectNavigationGuard],
      data: {
        redirects: [
          {
            to: 'integrate/sources',
            product: 'data_manager',
          },
          {
            to: 'integrate/destinations',
            product: 'destinations',
          },
        ],
      },
      component: NotFoundComponent, // should never come to this component
    },
    children: [
      {
        id: 2,
        label: 'Sources',
        route: {
          path: 'integrate/sources',
          loadChildren: () =>
            import('./integrate/sources/sources.module').then(
              (m) => m.SourcesModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard],
          pathMatch: 'full',
          data: { product: 'data_manager' },
        },
        helpInfo: [
          {
            label: 'Sources',
            link: 'sources_app',
          },
        ],
      },
      {
        id: 3,
        label: 'Destinations',
        route: {
          path: 'integrate/destinations',
          loadChildren: () =>
            import('./integrate/channel-platform/channel-platform.module').then(
              (m) => m.ChannelPlatformModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard],
          pathMatch: 'full',
          data: { product: 'destinations' },
        },
        helpInfo: [
          {
            label: 'Destinations',
            link: 'destinations_app',
          },
        ],
      },
    ],
    description: '',
  },
  {
    id: 4,
    iconClass: 'unity-icons-unify',
    label: 'Unify',
    route: {
      path: 'unify',
      pathMatch: 'full',
      canActivate: [RedirectNavigationGuard],
      data: {
        redirects: [
          {
            to: 'unify/catalogue',
            product: 'data_manager',
          },
          {
            to: 'unify/calculated-attributes',
            product: 'data_manager',
          },
          {
            to: 'unify/id-strategy',
            product: 'data_manager',
          },
        ],
      },
      component: NotFoundComponent, // should never come to this component
    },
    children: [
      {
        id: 12,
        label: 'Catalogue',
        route: {
          path: 'unify/catalogue',
          loadChildren: () =>
            import('./catalog/catalog.module').then((m) => m.CatalogBaseModule),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard],
          pathMatch: 'full',
          data: { product: 'data_manager' },
        },
        helpInfo: [{ label: 'Catalogue', link: 'catalogue_app' }],
      },
      {
        id: 13,
        label: 'Calculated Attributes',
        route: {
          path: 'unify/calculated-attributes',
          loadChildren: () =>
            import('./calculated-attributes/calculated-attributes.module').then(
              (m) => m.CalculatedAttributesModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard],
          pathMatch: 'full',
          data: { product: 'data_manager' },
        },
        helpInfo: [
          { label: 'Calculated Attributes', link: 'calculated_attribute_app' },
        ],
      },
      {
        // iconClass: 'unity-icons-id-strategy',
        id: 19,
        label: 'ID Strategy',
        route: {
          path: 'unify/id-strategy',
          loadChildren: () =>
            import('./id-strategy/id-strategy.module').then(
              (m) => m.IdStrategyBaseModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard],
          pathMatch: 'full',
          data: { product: 'data_manager' },
        },
        helpInfo: [{ label: 'ID Strategy', link: 'id_strategy_app' }],
      },
    ],
    description: '',
  },
  {
    id: 6,
    iconClass: 'unity-icons-segment-cdp',
    label: 'Segment',
    route: {
      path: 'segment',
      pathMatch: 'full',
      canActivate: [RedirectNavigationGuard],
      data: {
        redirects: [
          {
            to: 'segment/audience',
            product: 'audience',
          },
          {
            to: 'segment/connect',
            product: 'connect',
          },
        ],
      },
      component: NotFoundComponent, // should never come to this component
    },
    children: [
      {
        id: 7,
        // iconClass: 'unity-icons-audience',
        label: 'Audiences',
        featureToggle: 'ENABLE_SEGMENT',

        route: {
          path: 'segment/audience',
          loadChildren: () =>
            import('./audience/audience-base.module').then(
              (m) => m.AudienceBaseModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard, PermissionGuard],
          data: { product: 'audience', permissions: ['enableSegment'] },
        },
        helpInfo: [
          {
            label: 'Audience',
            link: 'audience_app',
          },
        ],
      },
      {
        id: 20,
        label: 'Connect',
        route: {
          path: 'segment/connect',
          loadChildren: () =>
            import('./connect/connect-base.module').then(
              (m) => m.ConnectBaseModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard],
          data: { product: 'connect' },
        },
        helpInfo: [
          {
            label: 'Connect',
            link: 'connect_app',
          },
        ],
      },
      {
        id: 22,
        label: 'Predictive Audience',
        route: {
          path: 'segment/predictive-audience',
          pathMatch: 'full',
          canActivate: [DataAppRedirectService],
          data: {
            product: 'envision',
          },
          component: NotFoundComponent, // should never come to this component
        },
        description: '',
      },
    ],
    description: '',
  },
  {
    id: 8,
    iconClass: 'unity-icons-symphony',
    label: 'Orchestrate',
    route: {
      path: 'orchestrate',
      pathMatch: 'full',
      canActivate: [RedirectNavigationGuard],
      data: {
        redirects: [
          {
            to: 'orchestrate/journeys',
            product: 'symphony_v2',
          },
        ],
      },
      component: NotFoundComponent, // should never come to this component
    },
    children: [
      {
        id: 21,
        label: 'Journeys',
        route: {
          path: 'orchestrate/journeys',
          loadChildren: () =>
            import('./orchestrate/orchestrate.module').then(
              (m) => m.OrchestrateBaseModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard],
          data: { product: 'symphony_v2' },
        },
        helpInfo: [
          {
            label: 'Journey',
            link: 'journey_app',
          },
        ],
      },
    ],
    description: '',
  },
  {
    id: 9,
    iconClass: 'unity-icons-rbac',
    label: 'Protect',
    route: {
      path: 'protect',
      pathMatch: 'full',
      canActivate: [RedirectNavigationGuard],
      data: {
        redirects: [
          {
            to: 'protect/consent',
            product: 'data_manager',
          },
          {
            to: 'protect/customer-360',
            product: 'user_persona',
          },
          {
            to: 'protect/' + CDP_PRODUCTS.DATA_LIFECYCLE.route,
            product: CDP_PRODUCTS.DATA_LIFECYCLE.route,
          },
        ],
      },
      component: NotFoundComponent, // should never come to this component
    },
    children: [
      {
        id: 10,
        // iconClass: 'unity-icons-audience',
        label: 'Consent',
        route: {
          path: 'protect/consent',
          loadChildren: () =>
            import('./consent/consent-base.module').then(
              (m) => m.ConsentBaseModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard],
          pathMatch: 'full',
          data: { product: 'data_manager' },
        },
        helpInfo: [
          {
            label: 'Consent',
            link: 'consent_app',
          },
        ],
      },
      {
        id: 11,
        iconClass: 'unity-icons-customer360',
        label: 'Customer 360',
        route: {
          path: 'protect/customer-360',
          loadChildren: () =>
            import('./customer360/customer360.module').then(
              (m) => m.Customer360BaseModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard],
          data: { product: 'user_persona' },
        },
        helpInfo: [
          {
            label: 'Customer 360',
            link: 'customer_360_app',
          },
        ],
      },
      {
        id: CDP_PRODUCTS.DATA_LIFECYCLE.id,
        iconClass: 'unity-icons-data-lifecycle',
        label: CDP_PRODUCTS.DATA_LIFECYCLE.title,
        route: {
          path: 'protect/' + CDP_PRODUCTS.DATA_LIFECYCLE.route,
          loadChildren: () =>
            import('./data-lifecycle/data-lifecycle.module').then(
              (m) => m.DataLifecycleBaseModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard, PermissionGuard],
          data: {
            product: 'data_lifecycle',
            permissions: ['enableDataLifecycle'],
          },
        },
        helpInfo: [
          {
            label: CDP_PRODUCTS.DATA_LIFECYCLE.title,
            link: 'data_lifecycle_app',
          },
        ],
      },
    ],
    description: '',
  },
  {
    id: 14,
    iconClass: 'unity-icons-admin',
    label: 'Target',
    route: {
      path: 'target',
      pathMatch: 'full',
      canActivate: [DataAppRedirectService],
      data: {
        product: 'zat',
      },
      component: NotFoundComponent, // should never come to this component
    },
    children: [],
    description: '',
    isExternal: true,
  },
  {
    id: 15,
    iconClass: 'unity-icons-admincdp',
    label: 'Admin',
    route: {
      path: 'admin',
      pathMatch: 'full',
      canActivate: [RedirectNavigationGuard],
      data: {
        redirects: [
          {
            to: 'admin/organisations',
            product: 'rbac',
          },
        ],
        permissions: ['enableAdmin'],
      },
      component: NotFoundComponent,
    },
    // helpInfo: [
    //   {
    //     label: 'Admin',
    //     link: 'admin_app',
    //   },
    // ],
    children: [
      {
        id: 16,
        label: 'My Organisations',
        route: {
          path: 'admin/organisations',
          loadChildren: () =>
            import('./organizations/organizations.module').then(
              (m) => m.OrganizationsModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard, PermissionGuard],
          data: { product: 'rbac', permissions: ['enableMyOrganisations'] },
        },
        helpInfo: [
          {
            label: 'My Organisations',
            link: 'admin_organizations',
          },
        ],
      },
      {
        id: 18,
        label: 'My Users',
        route: {
          path: 'admin/users',
          loadChildren: () =>
            import('./users/users-base.module').then((m) => m.UsersBaseModule),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard, PermissionGuard],
          data: { product: 'rbac', permissions: ['enableMyUsers'] },
        },
        helpInfo: [
          {
            label: 'My Users',
            link: 'admin_users',
          },
        ],
      },
      {
        id: 17,
        label: 'Request Access',
        route: {
          path: 'admin/request-access',
          loadChildren: () =>
            import('./request-access/request-access-base.module').then(
              (m) => m.RequestAccessBaseModule
            ),
          canLoad: [OktaAuthGuard],
          canActivate: [ProductAccessAuthGuard, PermissionGuard],
          data: {
            product: 'rbac',
            permissions: ['enableRequestAccessModule'],
            userInfoPermission: 'requestaccess_tab',
          },
        },
        helpInfo: [
          {
            label: 'Request Access',
            link: 'admin_app',
          },
        ],
      },
    ],
    description: '',
  },
];

export const predefaultRoutes = [
  {
    path: 'token',
    component: OktaCallbackComponent,
  },
  {
    path: 'oauth-callback',
    component: OauthCallbackComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: [AuthCheckResolver],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'error',
    component: ErrorComponent,
    pathMatch: 'full',
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    pathMatch: 'full',
  },
  {
    path: 'redirectByDefault',
    component: NotFoundComponent, // should never come to this component
    pathMatch: 'full',
    canActivate: [RedirectNavigationGuard],
    data: {
      redirects: getlevelOneRedirects(mainMenuItems),
    },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [OktaAuthGuard],
  },
];

export const postDefaultRoutes = [
  ...(!environment.production
    ? [
        {
          path: 'test',
          loadChildren: () =>
            import('./test/test.module').then((m) => m.TestModule),
        },
      ]
    : []),
  {
    path: '',
    pathMatch: 'full',
    component: BaseComponent,
    canActivate: [OktaAuthGuard, lastSelectedProductGuard],
    data: {
      checkPreviouslySelectedProduct: true,
      defaultRedirect: 'dashboard',
    },
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
];
