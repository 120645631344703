import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '../models/question';

@Component({
  selector: 'zap-dyanamic-form-question',
  templateUrl: './dyanamic-form-question.component.html',
  styleUrls: ['./dyanamic-form-question.component.scss'],
})
export class DyanamicFormQuestionComponent {
  @Input() question: QuestionBase<any>;
  @Input() form: UntypedFormGroup;
  tooltipPositions = [
    {
      originX: 'center',
      originY: 'top', //tooltip towards top
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -5,
    },
  ];

  get isValid() {
    return this.form.controls[this.question.key].valid;
  }
  get errorMessage() {
    return this.question.validations &&
      this.question.validations.length &&
      !this.form.controls[this.question.key].valid &&
      Object.keys(this.form.controls[this.question.key].errors).length
      ? this.createErrorMessage()
      : '';
  }
  createErrorMessage() {
    if (!Object.keys(this.form.controls[this.question.key].errors).length)
      return '';
    if (
      Object.keys(this.form.controls[this.question.key].errors)[0] ===
      'required'
    )
      return `${this.question.label} is required`;
    if (
      Object.keys(this.form.controls[this.question.key].errors)[0] ===
      'maxlength'
    )
      return `Length of ${this.question.label} should be less than or equal to ${this.question.maxLength}`;
    return Object.values(this.form.controls[this.question.key].errors)[0];
  }
  get shouldShow() {
    return this.question.displayConditionFn(this.form);
  }

  get isTouched() {
    return this.form.controls[this.question.key]
      ? this.form.controls[this.question.key].touched
      : false;
  }

  get optionType(): string {
    return !!this.question.valueOptions && this.question.valueOptions[0]
      ? typeof this.question.valueOptions[0]
      : 'string';
  }

  dropDownOpen() {
    setTimeout(() => {
      document
        .getElementsByClassName('ng-dropdown-panel-items')[0]
        .scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
    }, 0);
  }
}
