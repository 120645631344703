import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  EVENT_DATE_FORMAT,
  EventsUsagePeriod,
  getRangeFilter,
} from '@zeotap-ui/components';
import { ConfigService } from '@zeotap-ui/config';
import {
  AmplitudeService,
  DisplayMessageService,
  LocaleService,
  PRODUCTS,
  UserService,
  getWidget,
  isNotNullOrEmpty,
} from '@zeotap-ui/core';
import dayjs from 'dayjs';
import { NgEventBus } from 'ng-event-bus';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { updateDateRangeFilter, updatePeriodFilterOnGraph } from '../helper';
import { UsageFacade } from '../services/usage.facade';
import { EventDateRange } from '../types/usage';
import {
  CDP_ORG_JAQL_FILTER,
  LOCALE_MAP,
  USAGE_AMPLITUDE_EVENTS,
} from '../usage.const';
import { DEFAULT_INBOUND_EVENTS_START_DATE } from './../usage.const';

@Component({
  selector: 'zap-usage-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [UsageFacade],
})
export class UsageDashboardComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  products = PRODUCTS;

  widgetIdsMap: Record<string, string>;
  isNotNullOrEmpty = isNotNullOrEmpty;
  dashboard$: Observable<any>;
  cdpWidgets$: Observable<any>;
  loading$: Observable<boolean>;
  widgetsLoading$ = new BehaviorSubject<boolean>(true);
  cdpUsageConfig = this.configService.getFeatureValue('SISENSE_CONFIG');
  eventDateFormat = EVENT_DATE_FORMAT;
  minStartDate = new Date('2020-01-01');
  toggleCumulative$ = new BehaviorSubject<boolean>(false);

  allowedProductIds$: Observable<string[]>;
  userHasAccessToProduct$: Observable<{ [productId: string]: boolean }>;
  orgHasAccessToProduct$: Observable<{ [productId: string]: boolean }>;
  selectedEventsPeriod$ = new Subject<EventsUsagePeriod>();
  eventsDateRange$ = new Subject<EventDateRange>();
  unsubscribe$ = new Subject();
  childOrgs$: Observable<number[]> = this.usageFacade.childOrgs$;
  showToggle =
    this.configService.getFeatureValue('SISENSE_CONFIG')?.config?.usage?.config
      ?.showCumulativeToggle &&
    (this.configService.getFeatureValue('SISENSE_CONFIG')?.config?.usage?.config
      ?.allowCumulativeForExternalUsers ||
      this.userService.isInternalUser());
  constructor(
    private localeService: LocaleService,
    private usageFacade: UsageFacade,
    private configService: ConfigService,
    private amplitudeService: AmplitudeService,
    private msgService: DisplayMessageService,
    private eventBus: NgEventBus,
    private el: ElementRef,
    private userService: UserService
  ) {
    this.localeService.setLocaleData(LOCALE_MAP);
  }

  ngOnInit(): void {
    const startDate = new Date(
      this.configService.getConfigValue(
        DEFAULT_INBOUND_EVENTS_START_DATE,
        'INBOUND_EVENTS_START_DATE'
      )
    );
    this.widgetIdsMap = this.cdpUsageConfig.config.usage.config.widgetIdsMap;
    this.loading$ = combineLatest([
      this.widgetsLoading$,
      this.usageFacade.loading$,
    ]).pipe(
      map((arr: boolean[]) => arr.some((v) => !!v)),
      distinctUntilChanged()
    );
    this.usageFacade.selectedOrg$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((org) => {
        this.toggleCumulative$.next(false);
        this.amplitudeService.amplitudeHelper(
          USAGE_AMPLITUDE_EVENTS.USAGE_MODULE_VIEW,
          { org: org.id }
        );
      });

    this.dashboard$ = this.usageFacade.dashboard$;

    this.allowedProductIds$ = this.usageFacade.userProductList$;
    this.userHasAccessToProduct$ = this.usageFacade.productAccess$.pipe(
      take(1),
      tap((_) => this.usageFacade.loadDashboard())
    );
    this.orgHasAccessToProduct$ = this.usageFacade.selectedOrgProductAccess$;

    this.cdpWidgets$ = this.dashboard$.pipe(
      map((dashboard) => dashboard.widgets.$$widgetsMap)
    );

    this.eventsDateRange$
      .pipe(
        map(getRangeFilter),
        withLatestFrom(
          this.dashboard$.pipe(
            map((dashboard) =>
              getWidget(dashboard, this.widgetIdsMap.inboundEventsBreakdown)
            )
          ),
          this.dashboard$.pipe(
            map((dashboard) =>
              getWidget(dashboard, this.widgetIdsMap.inboundEventsOvertime)
            )
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([dateRange, breakdownWidget, timeWidget]) => {
        updateDateRangeFilter(breakdownWidget, dateRange);
        updateDateRangeFilter(timeWidget, dateRange);
      });

    this.selectedEventsPeriod$
      .pipe(
        withLatestFrom(
          this.dashboard$.pipe(
            map((dashboard) =>
              getWidget(dashboard, this.widgetIdsMap.inboundEventsOvertime)
            )
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([period, widget]) => {
        updatePeriodFilterOnGraph(widget, period);
      });

    combineLatest([this.usageFacade.selectedOrg$, this.dashboard$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.eventsDateRange$.next({
          startDate: dayjs(startDate).format(this.eventDateFormat),
          endDate: dayjs(new Date()).format(this.eventDateFormat),
        });
        this.selectedEventsPeriod$.next(EventsUsagePeriod.Monthly);
      });

    this.eventBus
      .on('navbar:toggled')
      .pipe(
        delay(700),
        withLatestFrom(this.dashboard$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([_, dashboard]) => {
        dashboard.widgets['$$widgets'].forEach((w) => w.refresh());
      });
  }

  ngAfterViewInit() {
    this.cdpWidgets$
      .pipe(
        map(Object.keys),
        withLatestFrom(this.dashboard$),
        debounceTime(0),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        ([widgets, dashboard]) => {
          widgets.forEach((widgetId: string) => {
            const widget = dashboard.widgets.get(widgetId);
            if (widget) {
              widget.container = this.el.nativeElement.querySelector(
                '#uu-widget-' + widgetId
              );
            }
          });
          dashboard.refresh(); // once all the widgets you wish to display have been assigned their containers, refresh the dashboard
          this.widgetsLoading$.next(false);
        },
        (e) => {
          this.widgetsLoading$.next(false);
          this.msgService.displayErrorMsg(
            'Some error occurred. Please try again.'
          );
        }
      );

    combineLatest([this.toggleCumulative$, this.childOrgs$, this.dashboard$])
      .pipe(
        debounceTime(0),
        withLatestFrom(this.usageFacade.selectedOrg$),
        tap(([[toggle, childOrgs, dashboard], org]) => {
          const _filter = {
            ...CDP_ORG_JAQL_FILTER,
            jaql: {
              ...CDP_ORG_JAQL_FILTER.jaql,
              filter: {
                explicit: true,
                multiSelection: true,
                members: toggle ? childOrgs : [org.id],
              },
            },
          };
          dashboard.$$model.filters.clear();
          dashboard.$$model.filters.update(_filter, {
            refresh: true,
            save: false,
          });
          this.amplitudeService.amplitudeHelper(
            USAGE_AMPLITUDE_EVENTS.USAGE_MODULE_VIEW,
            { org: org.id }
          );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([[_, childOrgs, dashboard]]) => {
        dashboard.refresh();
      });
  }

  toggleCumulative(event) {
    this.toggleCumulative$.next(event.checked);
  }
  updateDateRange(dateRange: EventDateRange) {
    this.eventsDateRange$.next(dateRange);
  }

  updateEventsPeriod(value: EventsUsagePeriod) {
    this.selectedEventsPeriod$.next(value);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
