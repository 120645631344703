import { Injectable } from '@angular/core';
import { compose, pathOr } from 'ramda';
import { from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { isNotNullOrEmpty } from '../../utils';
import { Status } from '../../http/types';
import { ConfigService } from '@zeotap-ui/config';
import { UserMetadata, UsersInfoAPIResponse } from '../types/user';
import { StorageService } from '../../storage';
import { handleAsyncError, HttpService } from '../../http';
import {
  UserPermissionAPIResponse,
  UserPermissionAPIV2Response,
} from '../types';
import {
  getAllCountries,
  isZeotapDomainUser,
  toUserPermissions,
  toUserPermissionsV2,
} from '../utils';
import { getIsoAlpha3 } from '../../country';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private storageService: StorageService,
    private httpService: HttpService,
    private config: ConfigService
  ) {}
  public getUserOrg() {
    const userOrg: any = this.storageService.getOrgInfo();
    if (isNotNullOrEmpty(userOrg)) {
      return {
        id: userOrg.zeo_org_id,
        zeo_org_uuid: userOrg.zeo_org_uuid,
        name: userOrg.display_name,
        parent_org_id: userOrg.parent_org_id,
        isoAlpha3countryCode: getIsoAlpha3(userOrg.country),
        currency: userOrg.currency,
        org_type: userOrg.org_type,
        vertical: userOrg.vertical,
        regions: userOrg.regions,
      };
    }
  }

  public getUserRole(productName: string) {
    return this.storageService.getUserRole(productName);
  }

  hasProductAccess(prodName = '') {
    return !!this.storageService.getProdInfo(prodName);
  }

  //TODO: Remove once UserPermissionFacade is integrated with all products
  getUserPermission(
    orgId: number,
    productId: number,
    country_ids: string[] = []
  ): Observable<UserPermissionAPIResponse> {
    return from(
      this.httpService.doPost(
        `${this.config.appConfig.userSvcUrlV3}user/permissions/`,
        {
          org_id: orgId,
          product_ids: [productId],
          country_ids,
        }
      )
    ).pipe(
      map(pathOr([], [productId, 'details'])),
      map(
        (permissions): UserPermissionAPIResponse => ({
          status: Status.Success,
          data: toUserPermissions(permissions),
        })
      ),
      catchError(
        compose(
          of,
          handleAsyncError<UserPermissionAPIResponse>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }

  getUserPermissionV2(
    orgId: number,
    productId: number[],
    country_ids: string[] = [] // do we need this still , we will any way load all
  ): Observable<UserPermissionAPIV2Response> {
    return from(
      this.httpService.doPost(
        `${this.config.appConfig.userSvcUrlV3}user/permissions/`,
        {
          org_id: orgId,
          product_ids: productId,
          country_ids,
        }
      )
    ).pipe(
      map(
        (permissions): UserPermissionAPIV2Response => ({
          status: Status.Success,
          data: {
            permissions: toUserPermissionsV2(permissions),
            countries: getAllCountries(permissions[productId[0]]),
          },
        })
      ),
      catchError(
        compose(
          of,
          handleAsyncError<UserPermissionAPIV2Response>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }
  public getUserInfo(): UserMetadata {
    const userInfo: any = this.storageService.getUserInfo();

    return {
      firstName: userInfo?.first_name,
      lastName: userInfo?.last_name,
      emailId: userInfo?.email,
      productInfo: this.storageService.getProdInfoByName(),
    };
  }

  public getUserGSuiteId(): string {
    return this.storageService.getUserInfo()?.gsuite_email;
  }

  isInternalUser(): boolean {
    return isZeotapDomainUser(this.getUserInfo()?.emailId);
  }

  public getUserId(): string {
    return this.storageService.getUserId();
  }
  fetchUsersForOrg(orgId: number): Observable<UsersInfoAPIResponse> {
    return from(
      this.httpService.doGet(
        `${this.config.appConfig.userSvcUrlV3}user/?orgId=${orgId}`,
        this.httpService.getHeader('')
      )
    ).pipe(
      map((users: any[]) => {
        return {
          status: Status.Success,
          data: isNotNullOrEmpty(users)
            ? users.map((u) => ({
                name: u.first_name + ' ' + u.last_name,
                email: u.email,
              }))
            : [],
        };
      }),
      catchError(
        compose(
          of,
          handleAsyncError<UsersInfoAPIResponse>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }
}
