import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'permissionPipe',
})
export class PermissionPipe implements PipeTransform {
  transform(featureList: any[], PERMISSIONS: {}) {
    if (featureList && featureList.length) {
      const permissions = {};
      const global_access_all = featureList.indexOf('global_access_all') > -1;
      Object.keys(PERMISSIONS).forEach((key) => {
        permissions[key] = global_access_all;
      });
      featureList.forEach((permission) => {
        Object.keys(PERMISSIONS).forEach((key) => {
          if (PERMISSIONS[key] === permission) {
            permissions[key] = true;
          }
        });
      });
      return permissions;
    }
  }
}
