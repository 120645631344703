import {
  Component,
  Input,
  AfterContentInit,
  ContentChild
} from '@angular/core';

import { TreeContentComponent } from './treeContent.component';
import { TreeHeaderComponent } from './treeHeader.component';

@Component({
  selector: 'z-tree',
  styleUrls: ['./tree.component.scss'],
  template: `
    <div class="tree">
      <ng-content></ng-content>
    </div>
  `
})
export class TreeComponent implements AfterContentInit {
  @ContentChild(TreeHeaderComponent)
  public header: TreeHeaderComponent;
  @ContentChild(TreeContentComponent)
  public content: TreeContentComponent;
  @Input() public defaultOpen = true;

  public clicked() {
    this.header.open = !this.content.show;
    this.content.show = this.header.open;
  }

  public ngAfterContentInit() {
    this.content.show = this.defaultOpen;
    this.header.open = this.defaultOpen;
    this.header.clicked.subscribe(this.clicked.bind(this));
  }
}
