<ng-container *ngIf="shouldShow">
  <div class="dynamic-question-container" [formGroup]="form">
    <label class="dynamic-question-label font-primary text-sm text-grey-400" [attr.for]="question.key"
      >{{ question.label }}<span *ngIf="question.required" class="required-field text-state-error-700">*</span>
      <ng-container *ngIf="question.tooltip">
        <span class="icon informationIcon infoIcon bg-[#ececf1] text-grey/52" customToolTip [text]="question.tooltip" [customTooltipClass]="'tooltip-dynamic-form'" [positions]="tooltipPositions"></span>
      </ng-container>
    </label>
    <div class="textBox">
      <div class="question" [ngClass]="{ disabled: question.disabled }" [ngSwitch]="question.controlType">
        <input class="dynamic-question-input bg-white border-[0.0625rem] border-solid border-grey-300 font-primary text-sm text-grey-900" *ngSwitchDefault [formControlName]="question.key" [id]="question.key" type="text" [disabled]="question.disabled" />
        <ng-container *ngSwitchCase="'dropdown'">
          <ng-container [ngSwitch]="optionType">
            <ng-container *ngSwitchDefault>
              <ng-select class="dropdown-btn font-primary text-sm text-grey-900" [id]="question.key" formControlName="{{ question.key }}" [items]="question.valueOptions" [multiple]="false" [clearable]="false" [searchable]="false" [disabled]="question.disabled" (open)="dropDownOpen()"> </ng-select>
            </ng-container>
            <ng-container *ngSwitchCase="'object'">
              <ng-select class="dropdown-btn font-primary text-sm text-grey-900" [id]="question.key" formControlName="{{ question.key }}" [items]="question.valueOptions" bindLabel="name" bindValue="name" [multiple]="false" [clearable]="false" [searchable]="false" [disabled]="question.disabled"> </ng-select>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'radio'">
          <ng-container *ngFor="let opt of question.valueOptions">
            <label>
              <input class="font-primary text-sm radio-btn text-grey-900" [formControlName]="question.key" [value]="opt" type="radio" />
              <span class="font-primary text-sm radio-btn-value text-grey-900">{{ opt }}</span>
            </label>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'grp-checkbox'">
          <zap-checkbox-group [formControl]="form.get(question.key)" [options]="question.valueOptions" [disabled]="question?.disabled"></zap-checkbox-group>
        </ng-container>
      </div>
    </div>
    <div class="errorMessage text-state-error-700 font-primary text-[13px]" *ngIf="isTouched && !isValid">{{ errorMessage }}</div>
    <div *ngIf="question.subtext" class="font-primary info-text text-grey/52" [innerHTML]="question.subtext"></div>
  </div>
</ng-container>
