import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CorePipesModule, CustomTooltipModule } from '@zeotap-ui/core';
import { ZuiLibModule } from '@zeotap.infra/zui-lib';
import { ControlErrorsComponent, ControlWrapperComponent } from './base';
import {
  PlainInputControlComponent,
  DropdownControlComponent,
  CheckboxControlComponent,
  TextAreaControlComponent,
} from './components';
import { DynamicGraphFormComponent } from './dynamic-graph-form.component';
import { ClipBoardCopyModule } from '../clipboard-copy';

@NgModule({
  declarations: [
    ControlWrapperComponent,
    PlainInputControlComponent,
    ControlErrorsComponent,
    DropdownControlComponent,
    CheckboxControlComponent,
    DynamicGraphFormComponent,
    TextAreaControlComponent,
  ],
  imports: [
    CorePipesModule,
    ZuiLibModule,
    FormsModule,
    NgSelectModule,
    CommonModule,
    CustomTooltipModule,
    ReactiveFormsModule,
    ClipBoardCopyModule,
  ],
  exports: [
    ControlWrapperComponent,
    PlainInputControlComponent,
    DropdownControlComponent,
    CheckboxControlComponent,
    DynamicGraphFormComponent,
    TextAreaControlComponent,
  ],
})
export class DynamicGraphFormModule {}
