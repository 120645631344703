import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputFieldMetaData } from './types';

@Component({
  selector: 'zap-dynamic-input-field',
  templateUrl: './dynamic-input-field.component.html',
})
export class DynamicInputFieldComponent {
  @Input() inputFormGroup: FormGroup;
  @Input() inputFieldsMetaData: InputFieldMetaData<any>;
  @Input() customStyles: string;
  @Input() disabled: boolean;
  ngOnInit() {}
}
