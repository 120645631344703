<div class="dropdown-container" #dropdownContainer [ngClass]="{disabled: disabled}">
  <div *ngIf="multiple" class="multi-container">
    <div class="chipsContainer" (click)="clickChipsContainer($event)">
      <ul>
        <li *ngFor="let v of selectedObj; let i=index" class="inlineBlock">
          <div *ngIf="i < dataOptionsObj.noOfChipsToShow" class="zui-chip zui-chip-color" title="{{dataOptionsObj.name ? v[dataOptionsObj.name] : v}}">
            <span class="zui-chip-text zui-gray1-text-color">
              {{dataOptionsObj.name ? v[dataOptionsObj.name] : v}}
            </span>
            <div class="zui-close-chip zui-clickable-text" (click)="removeItem(v, $event)">
              <span class="icon cancel iconColor"></span>
            </div>
          </div>
        </li>
        <li *ngIf="!!value && value.length > dataOptionsObj.noOfChipsToShow" class="inlineBlock">
          <span class="hiddenValuesText zui-primary-text-color zui-normaltext">+{{value.length - dataOptionsObj.noOfChipsToShow}} {{hiddenOptionsText}}</span>
        </li>
        <li *ngIf="autocomplete" class="input-container multiInput" [ngClass]="{setSize: !!value && value.length}">
          <input type="text" class="zui-autocomplete-input-field zui-placeholder multiInput" [ngClass]="{'zui-content': searchText, 'setSize': !!value && value.length}"
            [(ngModel)]="searchText" (ngModelChange)="modelChanged()" (focus)="onFocus()" (blur)="onBlur()"
            placeholder="{{placeholder}}" />
        </li>
      </ul>
      <div class="valueLabel zui-placeholder cursorPointer" *ngIf="showPlaceholder && !autocomplete">{{placeholder}}</div>
      <span class="liner left-line zui-highlight-outline"></span>
      <span class="liner top-line zui-highlight-outline"></span>
      <span class="liner right-line zui-highlight-outline"></span>
      <span class="liner bottom-line zui-highlight-outline"></span>
    </div>
    <span *ngIf="!autocomplete" (click)="$event.stopPropagation();showDropdownMenu()" class="icon down-triangle customIcon iconColor" [ngClass]="{custom: showMenu}"></span>
    <span *ngIf="autocomplete" class="icon search searchIcon iconColor"></span>
  </div>
  
  <div *ngIf="!multiple" class="dropdown-label inputBox cursorPointer" (click)="$event.stopPropagation();showDropdownMenu()" >
    <ng-container *ngIf="autocomplete">
      <input type="text" class="customInput zui-placeholder" (focus)="onFocus()" (blur)="onBlur()"
      placeholder="{{value ? displayName : placeholder}}" [(ngModel)]="searchText" (ngModelChange)="modelChanged()">
      <!-- <div *ngIf="value" class="valueLabel customInput zui-placeholder" [ngClass]="{'zui-gray1-text-color': value}">{{value ? displayName : placeholder}}</div> -->
      <span class="icon search searchIcon iconColor"></span>
    </ng-container>
    <div *ngIf="!autocomplete" class="valueLabel zui-placeholder cursorPointer" [ngClass]="{'zui-gray1-text-color': value}" title="{{value ? displayName : placeholder}}">{{value ? displayName : placeholder}}</div>
    <span class="liner left-line zui-highlight-outline"></span>
    <span class="liner top-line zui-highlight-outline"></span>
    <span class="liner right-line zui-highlight-outline"></span>
    <span class="liner bottom-line zui-highlight-outline"></span>
  </div>
  <span *ngIf="!autocomplete" (click)="$event.stopPropagation();showDropdownMenu()" class="icon down-triangle customIcon iconColor" [ngClass]="{custom: showMenu}"></span>
  
  <div class="dropdown-options-container zui-bgcolor-white" *ngIf="showMenu && !disabled">
    <!-- <div class="dropdownOverlay"></div> -->
    <div *ngIf="multiple && !hideSelectAll" class="actionText zui-clickable-text zui-primary-text-color" 
      (click)="$event.stopPropagation();toggleAllOptionsSelection()">
      {{clearAll ? 'Clear all' : 'Select all'}}
    </div>
    <div *ngIf="showClear && (!multiple && value)" class="actionText zui-clickable-text zui-primary-text-color" 
      (click)="$event.stopPropagation();toggleAllOptionsSelection()">
      Clear
    </div>
    <ng-content select="zui-dropdown-option"></ng-content>
    <ng-container *ngIf="dataType === 'string' && optionsArr.length === 0">
      <zui-dropdown-option *ngFor="let d of data">{{d}}</zui-dropdown-option>
    </ng-container>
    <ng-container *ngIf="dataType === 'object' && optionsArr.length === 0">
      <zui-dropdown-option *ngFor="let d of data">{{d[dataOptionsObj.name]}}</zui-dropdown-option>
    </ng-container>
    <ng-content></ng-content>
  </div>
</div>