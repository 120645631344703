import { EventsUsagePeriod } from './constants/const';

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface TimestampRange {
  id: number;
  name: string;
  disabled: boolean;
  type: EventsUsagePeriod;
  range: DateRange;
}
