<ng-container
  *ngIf="{
    organizations: organizations$ | async,
    selectedOrganization: selectedOrganization$ | async
  } as data"
>
  <div class="organization-list-container">
    <zap-organization-tree-selection [disabled]="disabled" *ngIf="data.selectedOrganization" [organizations]="data.organizations" [selectedOrganization]="data.selectedOrganization" (selectOrganization)="selectOrganization($event)"></zap-organization-tree-selection>
  </div>
</ng-container>
