import { Component, Input } from '@angular/core';

@Component({
  selector: 'zui-snackbar',
  templateUrl: './snackbar.html',
})
export class SnackBarComponent {
  @Input() msg: string;
  @Input() type: string;
  @Input() options: object;

  styleMap = {
    success: 'success bg-state-success',
    error: 'error bg-state-error-700',
    warning: 'warning bg-state-warning-1',
  };
}
