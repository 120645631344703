import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CheckboxGroupComponent } from './checkbox-group.component';

@NgModule({
  imports: [CommonModule],
  exports: [CheckboxGroupComponent],
  declarations: [CheckboxGroupComponent],
  providers: [],
})
export class CheckBoxGroupModule {}
