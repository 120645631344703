import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from '@zeotap-ui/config';
import {
  AsyncCallResponse,
  Organization,
  ProductIds,
  ProductOrgsFacadeService,
  ProductService,
  SelectedOrgFacadeService,
  SisenseJSAppService,
  UserService,
  isSuccess,
} from '@zeotap-ui/core';
import { keys } from 'ramda';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import {
  filter,
  map,
  share,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { UserProductAccess } from '../types/usage';
import { ALLOWED_PRODUCTS } from '../usage.const';
import { UsageService } from './usage.service';

@Injectable()
export class UsageFacade implements OnDestroy {
  loading$ = new BehaviorSubject<boolean>(false);
  dashboard$ = new Subject<AsyncCallResponse<any>>();
  loadDashboard$ = new Subject<void>();
  selectedOrg$: Observable<Organization>;
  private fetchingOrgsProductAccess$ = new BehaviorSubject<boolean>(false);
  private readonly userProductAccessRef$: Observable<UserProductAccess>;
  private dashboardId = this.configService.getFeatureValue(
    'SISENSE_CONFIG',
    true
  ).config.usage.config.dashboardId;
  private unsubscribe$ = new Subject<void>();
  public userProductList$: Observable<string[]>;
  public productAccess$: Observable<{ [productId: string]: boolean }>;
  public selectedOrgProductAccess$: Observable<{
    [productId: string]: boolean;
  }>;
  childOrgs$: Observable<number[]>;
  constructor(
    private usageService: UsageService,
    private selectedOrgFacade: SelectedOrgFacadeService,
    private userService: UserService,
    private productService: ProductService,
    private sisenseService: SisenseJSAppService,
    private productOrgsFacade: ProductOrgsFacadeService,
    private configService: ConfigService
  ) {
    this.selectedOrg$ = this.selectedOrgFacade.selectedOrg$;

    this.childOrgs$ = combineLatest([
      this.selectedOrg$,
      this.productOrgsFacade.childOrgsMap$,
    ]).pipe(map(([org, childOrgsMap]) => childOrgsMap[org.id]));

    combineLatest([
      this.loadDashboard$,
      this.sisenseService.isSisenseAppReady.pipe(filter((isReady) => isReady)),
    ])
      .pipe(
        tap(() => this.loading$.next(true)),
        switchMap((_) => this.sisenseService.loadDashboard(this.dashboardId)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res: any) => {
        this.loading$.next(false);
        this.dashboard$.next(res);
      });

    this.userProductAccessRef$ = this.usageService
      .getUserProductAccessDetails(this.userService.getUserId())
      .pipe(
        map((res) => {
          return isSuccess(res.status) ? res.data : {};
        })
      );

    this.selectedOrgProductAccess$ = this.selectedOrg$.pipe(
      tap((_) => {
        this.fetchingOrgsProductAccess$.next(true);
      }),
      switchMap((org) =>
        this.productService
          .getProductConfigurationsForOrg(org.id, false, ProductIds)
          .pipe(
            map((res) => {
              const productIds = isSuccess(res.status) ? keys(res.data) : [];
              return productIds.reduce(
                (acc, curr) => ({ ...acc, [curr]: true }),
                {}
              );
            })
          )
      ),
      tap(() => this.fetchingOrgsProductAccess$.next(false)),
      share()
    );

    this.productAccess$ = this.selectedOrgProductAccess$.pipe(
      withLatestFrom(this.userProductAccessRef$, this.selectedOrg$),
      map(([orgAccess, userAccess, org]) => {
        const allOrgsProductAccess = userAccess['null'] ?? {};
        const selectedOrgProductAccess = userAccess[org.id] ?? {};
        return ALLOWED_PRODUCTS.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]:
              !!orgAccess[curr.id] &&
              (!!selectedOrgProductAccess[curr.id] ||
                !!allOrgsProductAccess[curr.id]),
          }),
          {}
        );
      }),
      share()
    );
  }

  loadDashboard() {
    this.loadDashboard$.next(null);
  }

  getFetchingOrgsProductAccess(): Observable<boolean> {
    return this.fetchingOrgsProductAccess$.asObservable();
  }
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
