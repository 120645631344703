<div [formGroup]="inputFormGroup">
  <div *ngFor="let input of inputFieldsMetaData | ObjNgFor">
    <div class="grid grid-rows-[1fr_max-content] gap-y-7 p-2 m-2 pb-0 mb-0">
      <div class="font-primary-bold text-sm">{{ inputFieldsMetaData[input]?.name }}</div>
      <div [ngSwitch]="inputFieldsMetaData[input]?.type" [ngClass]="customStyles">
        <input *ngSwitchCase="'number'" [placeholder]="'Enter ' + inputFieldsMetaData[input]?.name" class="h-full w-full pl-3 border border-solid border-grey-300 rounded text-sm text-primary-1-900" [formControlName]="input" type="number" [ngClass]="{ '!pointer-events-none !bg-grey-100': disabled }" />
        <ng-select *ngSwitchCase="'nestedDropDown'" class="h-full w-full text-sm text-primary-1-900 [&>_.ng-select-container]:!h-full" placeholder="Select your choice" dropdownPosition="top" [items]="inputFieldsMetaData[input]?.possible_values" [multiple]="false" [selectableGroup]="false" bindLabel="name" [closeOnSelect]="true" [clearable]="false" [formControlName]="input" [ngClass]="{ '[&>_.ng-select-container]:!pointer-events-none [&>_.ng-select-container]:!bg-grey-100': disabled }">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <span class="text-grey-900">{{ item.name }}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
</div>
