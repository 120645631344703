import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button';
import { BarChartComponent } from './bar-chart';
import { CardComponent } from './card';
import { Card2Component } from './card2';
import { CheckboxComponent } from './checkbox';
import { DropdownComponent, DropdownOptionsComponent } from './dropdown';
import {
  DropdownOptionsStaticComponent,
  DropdownStaticComponent,
} from './dropdownStatic';
import { ImageChartComponent } from './image-chart';
import { InputComponent } from './input';
import { LineChartComponent } from './lineChart';
import { LineChart2Component } from './lineChart2';
import { MapComponent } from './map';
import { LoaderComponent } from './loader';
import { MinMaxSliderComponent } from './minMaxSlider';
import { PieChartComponent } from './pieChart';
import {
  PosterComponent,
  PosterContentComponent,
  PosterFooterComponent,
  PosterHeaderComponent,
} from './poster';
import { RadioButtonComponent, RadioGroupComponent } from './radioButton';
import { SliderComponent } from './slider';
import { SnackBarComponent } from './snackbar';
import { SunburstChartComponent } from './sunburstChart';
import { SwitchComponent } from './switch';
import {
  TableComponent,
  TableContentComponent,
  TableHeaderComponent,
  TableRowComponent,
} from './table';
import { TooltipComponent, TooltipContainerDirective } from './tooltip';
import {
  TreeComponent,
  TreeContentComponent,
  TreeHeaderComponent,
} from './tree';
import { MilestoneComponent } from './milestone';

const ZUICOMPONENTS = [
  ButtonComponent,
  BarChartComponent,
  CardComponent,
  Card2Component,
  CheckboxComponent,
  DropdownComponent,
  DropdownOptionsComponent,
  DropdownStaticComponent,
  DropdownOptionsStaticComponent,
  ImageChartComponent,
  InputComponent,
  LineChartComponent,
  LineChart2Component,
  MapComponent,
  LoaderComponent,
  MinMaxSliderComponent,
  PieChartComponent,
  PosterComponent,
  PosterFooterComponent,
  PosterHeaderComponent,
  PosterContentComponent,
  RadioButtonComponent,
  RadioGroupComponent,
  SliderComponent,
  SnackBarComponent,
  SunburstChartComponent,
  SwitchComponent,
  TableComponent,
  TableHeaderComponent,
  TableRowComponent,
  TableContentComponent,
  TooltipComponent,
  TooltipContainerDirective,
  TreeComponent,
  TreeContentComponent,
  TreeHeaderComponent,
  MilestoneComponent,
];

const AngularModules = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  declarations: [...ZUICOMPONENTS],
  imports: AngularModules,
  exports: [...ZUICOMPONENTS],
})
export class ZuiLibModule {}
