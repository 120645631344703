import {
  Component,
  Directive,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { TooltipContainerDirective } from './tooltipDirective.component';

@Component({
  template: `
    <div
      class="tooltip-container zui-content"
      [ngStyle]="{ top: top, left: left }"
    >
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  public top: string;
  public left: string;
  public offset = 10;
  @ViewChild(TooltipContainerDirective)
  private tooltipContainer;
  constructor(@Inject('tooltipConfig') private config) {}

  public ngOnInit() {
    // const {top} = this.config.host.getBoundingClientRect();
    // const {height} = this.tooltipContainer.nativeElement.getBoundingClientRect();
    // this.top = `${top - height}px`;
    const hostPos = this.tooltipContainer.nativeElement.getBoundingClientRect();
    const tooltipPos = this.config.host.getBoundingClientRect();

    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    this.top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
    this.left = hostPos.right + this.offset;
  }
}
