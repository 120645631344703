import { defaultToEmptyArray } from '../../utils/utils';
import { ConfigService } from '@zeotap-ui/config';
import { Injectable } from '@angular/core';
import { flatten, values, compose } from 'ramda';
import { from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ProductIds } from '../product.const';
import { ProductConfigurationAPIResponse, ProductGroup } from '../types';
import { toProductGroup } from '../helpers/product';
import { handleAsyncError, HttpService } from '../../http';
import {
  toProductConfigurationAPIResponse,
  toProductConfigurationAPIResponseV2,
  toProductConfigurationAPIResponseV3,
} from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private httpService: HttpService,
    private toggleConfig: ConfigService
  ) {}

  public getProductGroups(
    orgId: number,
    pIds: number[]
  ): Observable<ProductGroup[]> {
    return from(
      this.httpService.doPost(
        `${this.toggleConfig.appConfig.userSvcUrlV3}product/group/`,
        {
          org_id: orgId,
          product_ids: pIds,
        }
      )
    ).pipe(
      map((res: any) =>
        flatten(values(res['Product Groups'])).map(toProductGroup)
      ),
      catchError(
        compose(
          of,
          handleAsyncError<ProductGroup[]>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }
  public getProductConfigurationsForOrg(
    orgId: number,
    showError: boolean = true,
    productIds = ProductIds
  ): Observable<ProductConfigurationAPIResponse> {
    return from(
      this.httpService.doPost(
        `${this.toggleConfig.appConfig.userSvcUrlV3}product-configuration/get_info/`,
        { org_id: orgId, product_id: productIds }
      )
    ).pipe(
      map(toProductConfigurationAPIResponse),
      catchError((err) => {
        return of(
          showError
            ? handleAsyncError<ProductConfigurationAPIResponse>(
                'Something bad happened. Please try again later.',
                err
              )
            : ({} as ProductConfigurationAPIResponse)
        );
      })
    );
  }

  public getProductConfigurationsV2(
    orgId: number,
    showError: boolean = true,
    productIds = ProductIds
  ): Observable<ProductConfigurationAPIResponse> {
    return from(
      this.httpService.doPost(
        `${this.toggleConfig.appConfig.userSvcUrlV3}product-configuration/get_info/`,
        { org_id: orgId, product_id: ['1'], product_id_v2: productIds }
      )
    ).pipe(
      map(toProductConfigurationAPIResponseV2),
      catchError((err) => {
        return of(
          showError
            ? handleAsyncError<ProductConfigurationAPIResponse>(
                'Something bad happened. Please try again later.',
                err
              )
            : ({} as ProductConfigurationAPIResponse)
        );
      })
    );
  }

  getProductConfigurationsForOrgV2(
    orgId: number,
    showError: boolean = true,
    productIds = ProductIds
  ): Observable<ProductConfigurationAPIResponse> {
    let productIdsV2 = [];
    productIds = productIds.filter((id) => {
      const hasUpdatedProdConfig = defaultToEmptyArray(
        this.toggleConfig.getFeatureValue('RBAC_IA', true).config
          .updatedProdConfigs
      ).includes(id);
      if (hasUpdatedProdConfig) {
        productIdsV2.push(id);
      }
      return !hasUpdatedProdConfig;
    });
    return from(
      this.httpService.doPost(
        `${this.toggleConfig.appConfig.userSvcUrlV3}product-configuration/get_info/`,
        { org_id: orgId, product_id: productIds, product_id_v2: productIdsV2 }
      )
    ).pipe(
      map(toProductConfigurationAPIResponseV2),
      catchError((err) => {
        return of(
          showError
            ? handleAsyncError<ProductConfigurationAPIResponse>(
                'Something bad happened. Please try again later.',
                err
              )
            : ({} as ProductConfigurationAPIResponse)
        );
      })
    );
  }

  getProductConfigurationsForOrgV3(
    orgId: number,
    productIds = []
  ): Observable<ProductConfigurationAPIResponse> {
    let productIdsV2 = [];
    productIds = productIds.filter((id) => {
      const hasUpdatedProdConfig = defaultToEmptyArray(
        this.toggleConfig.getFeatureValue('RBAC_IA', true).config
          .updatedProdConfigs
      ).includes(id);
      if (hasUpdatedProdConfig) {
        productIdsV2.push(id);
      }
      return !hasUpdatedProdConfig;
    });
    return from(
      this.httpService.doPost(
        `${this.toggleConfig.appConfig.userSvcUrlV5}product-configuration/get_info/`,
        { org_id: orgId }
      )
    ).pipe(
      map(toProductConfigurationAPIResponseV3),
      catchError(
        compose(
          of,
          handleAsyncError<ProductConfigurationAPIResponse>(
            'Something bad happened. Please try again later.'
          )
        )
      )
    );
  }
}
