import { updateWidgetFilter } from '@zeotap-ui/core';
import { compose, groupBy, map, mergeAll, prop, props, reduce } from 'ramda';

export const groupByOrg = groupBy(prop('org_id'));
export const getProductAccess = reduce(
  (acc, productAccess) => ({ ...acc, [productAccess['product_id']]: true }),
  {}
);
export const getUserProductAccess = compose(map(getProductAccess), groupByOrg);
export const getOrgProductAccessAlongWithAll = (orgId) =>
  compose(mergeAll, props([orgId, 'null']));

export const sisenseIsLoaded = () => {
  return (
    typeof window['Sisense'] !== 'undefined' &&
    typeof window['prism'] !== 'undefined'
  );
};

export const updateDateRangeFilter = (widget, rangeFilter) =>
  updateWidgetFilter(widget, 'filters', rangeFilter);

export const updatePeriodFilterOnGraph = (widget, period) => {
  const panel = 'x-axis';
  const periodFilter = {
    format: {
      mask: {
        weeks: "'Wk' ww",
        months: 'MMM yy',
      },
    },
    hierarchies: {},
    jaql: {
      datatype: 'datetime',
      dim: '[date_dim.date (Calendar)]',
      level: period,
    },
  };
  updateWidgetFilter(widget, panel, periodFilter);
};
