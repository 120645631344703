import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BaseComponent } from './base/base.component';

@NgModule({
  declarations: [
    ErrorComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    BaseComponent,
  ],
  imports: [CommonModule],
})
export class DefaultPagesModule {}
