export const PRODUCTS = {
  RBAC: {
    id: '1',
    path: 'access',
    name: 'rbac',
    title: 'ACCESS CONTROL',
    icon: 'rbac',
    order: 7,
  },
  AGENCY_API: {
    id: '2',
    name: 'agency_api',
    order: 10,
  },
  DPUI: {
    id: '3',
    path: 'dpui',
    name: 'data_partner_ui',
    title: 'DATA PARTNER CONSOLE',
    icon: 'dpui',
    order: 3,
  },
  ZAT: {
    id: '5',
    path: 'targeting',
    name: 'zat',
    title: 'TARGETING',
    icon: 'segments',
    order: 1,
  },
  STUDIO: {
    id: '9',
    path: 'studio',
    name: 'Studio',
    title: 'STUDIO',
    icon: 'studio',
    order: 5,
  },
  CONNECT: {
    id: '11',
    path: 'connect',
    name: 'connect',
    title: 'CONNECT',
    icon: 'connect',
    order: 2,
  },
  DESTINATIONS: {
    id: '12',
    path: 'destinations',
    name: 'destinations',
    title: 'DESTINATIONS',
    icon: 'destinations',
    order: 6,
  },
  DCR: {
    id: '14',
    path: 'dcr',
    name: 'data_clean_room',
    title: 'DATA CLEAN ROOM',
    icon: 'dcr',
    order: 8,
  },
  DATA_MANAGER: {
    id: '15',
    path: 'collect',
    name: 'data_manager',
    title: 'COLLECT',
    icon: 'data-manager',
    order: 11,
  },
  TOOLS: {
    id: '16',
    path: 'tools',
    name: 'tools',
    title: 'ZEOTAP TOOLS',
    icon: 'unity-icons-filled-tools',
    order: 12,
  },
  ENVISION: {
    id: '18',
    path: 'predictive-audiences',
    name: 'envision',
    title: 'PREDICTIVE AUDIENCES',
    icon: 'envision',
    order: 14,
  },
  ID_PLUS: {
    id: '19',
    path: 'id+',
    name: 'id+',
    title: 'ID+',
    icon: 'id-plus',
    order: 15,
  },
  CUSTOMER_360: {
    id: '21',
    path: 'customer-360',
    name: 'user_persona',
    title: 'CUSTOMER 360',
    icon: 'customer-360',
    order: 16,
  },
  SYMPHONY_V2: {
    id: '22',
    path: 'symphony',
    name: 'symphony_v2',
    title: 'SYMPHONY',
    icon: 'symphony',
  },
  AUDIENCE: {
    id: '23',
    path: 'audience',
    name: 'audience',
    title: 'AUDIENCES',
    icon: 'unity-icons-audience',
  },
  DESTINATIONS_V2: {
    id: '25',
    path: 'destinationsV2',
    name: 'destinationsV2',
    title: 'DESTINATIONS',
    icon: 'flag',
    order: 21,
  },
  API_ACCESS: {
    id: '20',
    path: 'api-access',
    name: 'api-access',
    title: 'API ACCESS',
    icon: 'flag',
    order: 22,
  },
  CONNECT_V2: {
    id: '26',
    path: 'connectV2',
    name: 'connectV2',
    title: 'CONNECT',
    icon: 'unity-icons-productivity',
    order: 26,
  },
};

export const PRODUCT_PRIORITY_LIST = [
  PRODUCTS.DATA_MANAGER.id,
  PRODUCTS.AUDIENCE.id, // TODO: update priority
  PRODUCTS.SYMPHONY_V2.id,
  PRODUCTS.CONNECT.id,
  PRODUCTS.ENVISION.id,
  PRODUCTS.CUSTOMER_360.id,
  PRODUCTS.ZAT.id,
  PRODUCTS.DESTINATIONS.id,
  PRODUCTS.DCR.id,
  PRODUCTS.RBAC.id,
  PRODUCTS.STUDIO.id,
  PRODUCTS.DPUI.id,
  PRODUCTS.TOOLS.id,
  PRODUCTS.DESTINATIONS_V2.id,
  PRODUCTS.CONNECT_V2.id,
];

export const DATA_PRODUCT_PRIORITY_LIST = [
  PRODUCTS.ZAT.id,
  PRODUCTS.STUDIO.id,
  PRODUCTS.DPUI.id,
  PRODUCTS.RBAC.id,
  PRODUCTS.TOOLS.id,
  PRODUCTS.ENVISION.id,
];

export const productsInfo = [
  {
    id: PRODUCTS.ZAT.id,
    name: 'zat',
    title: 'TARGETING',
    url: 'targeting',
    permissions: [],
    icon: 'segments',
  },
  {
    id: PRODUCTS.STUDIO.id,
    name: 'Studio',
    title: 'STUDIO',
    url: 'studio',
    permissions: [],
    icon: 'studio',
  },
  {
    id: PRODUCTS.CONNECT.id,
    name: 'connect',
    title: 'CONNECT',
    url: 'connect',
    permissions: [],
    icon: 'connect',
  },
  {
    id: PRODUCTS.DESTINATIONS.id,
    name: 'destinations',
    title: 'DESTINATIONS',
    url: 'destinations',
    permissions: [],
    icon: 'destinations',
  },
  {
    id: PRODUCTS.RBAC.id,
    name: 'rbac',
    title: 'USER MANAGEMENT',
    url: 'access',
    permissions: [],
    icon: 'rbac',
  },
  {
    id: PRODUCTS.DPUI.id,
    name: 'data_partner_ui',
    title: 'DATA PARTNER CONSOLE',
    url: 'dpui',
    permissions: [],
    icon: 'dpui',
  },
  {
    id: PRODUCTS.DCR.id,
    name: 'data_clean_room',
    title: 'DATA CLEAN ROOM',
    icon: 'dcr',
    url: 'dcr',
  },
  {
    id: PRODUCTS.DATA_MANAGER.id,
    name: 'data_manager',
    title: 'COLLECT',
    icon: 'data-manager',
    url: 'collect',
  },
  {
    id: PRODUCTS.TOOLS.id,
    name: 'tools',
    title: 'ZEOTAP TOOLS',
    icon: 'unity-icons-filled-tools',
    url: 'tools',
  },
  {
    id: PRODUCTS.ENVISION.id,
    name: 'envision',
    title: 'PREDICTIVE AUDIENCES',
    icon: 'envision',
    url: 'predictive-audiences',
  },
  {
    id: PRODUCTS.SYMPHONY_V2.id,
    name: 'symphony_v2',
    title: 'SYMPHONY',
    icon: 'symphony',
    url: 'symphony',
  },
  {
    id: PRODUCTS.CUSTOMER_360.id,
    name: 'user_persona',
    title: 'CUSTOMER 360',
    icon: 'customer-360',
    permissions: [],
    url: 'customer-360',
  },
  {
    ...PRODUCTS.AUDIENCE,
    permissions: [],
    url: PRODUCTS.AUDIENCE.path,
  },
  {
    id: PRODUCTS.DESTINATIONS_V2.id,
    name: 'destinationsV2',
    title: 'DESTINATIONS',
    icon: 'flag',
    url: 'destinationsV2',
  },
  {
    id: PRODUCTS.CONNECT_V2.id,
    name: 'connectV2',
    title: 'CONNECT',
    icon: 'unity-icons-productivity',
    url: 'connectV2',
  },
];

export const excludedProductIds = [3, 8, 9, 5, 15, 16];

export const ProductIds = [
  PRODUCTS.RBAC.id,
  PRODUCTS.DPUI.id,
  PRODUCTS.ZAT.id,
  PRODUCTS.STUDIO.id,
  PRODUCTS.CONNECT.id,
  PRODUCTS.AUDIENCE.id,
  PRODUCTS.DESTINATIONS.id,
  PRODUCTS.DCR.id,
  PRODUCTS.DATA_MANAGER.id,
  PRODUCTS.TOOLS.id,
  PRODUCTS.SYMPHONY_V2.id,
  PRODUCTS.ENVISION.id,
  PRODUCTS.ID_PLUS.id,
  PRODUCTS.CUSTOMER_360.id,
  PRODUCTS.API_ACCESS.id,
];

export const AUDIENCE_PROD_CONFIG_DEFAULT = {
  features: {
    lookalike: false,
    externalGraph: { enabled: false, externalGraphList: [] },
  },
};

export const COUNTRY_BASED_PRODUCTS = [11];

export const CONFIG_V2_PRODUCTS = [11];
