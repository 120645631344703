import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    public http: HttpClient,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {}

  private handleError(err) {
    if (err.status === 401) {
      // Session timedout or token is invalid
      console.log(err);
      this.oktaAuth.signOut().then(() => {
        localStorage.clear();
      });
    } else {
      return Promise.reject(err);
    }
  }
  private handleResponse(promise) {
    return promise.catch(this.handleError.bind(this));
  }

  public setMultiFormData() {
    return new HttpHeaders().append('Accept', 'application/json');
  }

  public getHeaderWithMultiFormData(tokenPrefix = '') {
    const jwt = tokenPrefix + this.oktaAuth.getAccessToken();
    let headers = this.setMultiFormData();
    if (jwt) {
      headers = headers.append('Authorization', jwt);
    }
    return { headers };
  }

  getHeaderWithMultiFormDataAndAllAccept(tokenPrefix = '') {
    const jwt = tokenPrefix + this.oktaAuth.getAccessToken();
    let headers = new HttpHeaders().append('Accept', '*/*');
    if (jwt) {
      headers = headers.append('Authorization', jwt);
    }
    return { headers };
  }

  public getHeaderWithoutToken() {
    return new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json');
  }

  public getHeader(tokenPrefix = ''): any {
    const jwt = tokenPrefix + this.oktaAuth.getAccessToken();
    let headers = this.getHeaderWithoutToken();
    if (jwt) {
      headers = headers.append('Authorization', jwt);
    }
    return { headers };
  }

  public getHeaderWithCustomToken(customToken): any {
    let headers = this.getHeaderWithoutToken();
    if (customToken) {
      headers = headers.append('Authorization', customToken);
    }
    return { headers };
  }

  public getHeaderWithoutContentType(tokenPrefix = '') {
    const jwt = tokenPrefix + this.oktaAuth.getAccessToken();
    let headers;
    if (jwt) {
      headers = new HttpHeaders().append('Authorization', jwt);
    }
    return { headers };
  }

  public getHeaderWithoutPrefix() {
    const jwt = this.oktaAuth.getAccessToken();
    let headers = this.getHeaderWithoutToken();
    if (jwt) {
      headers = headers.append('Authorization', jwt);
    }
    return { headers };
  }

  public getHeaderDownload(tokenPrefix = '') {
    const headers = <any>this.getHeader(tokenPrefix);
    headers.responseType = 'blob' as 'blob';
    return headers;
  }

  public doGet(url, header = this.getHeader()) {
    return this.handleResponse(this.http.get(url, header).toPromise());
  }

  public doPost(url, json, header = this.getHeader()) {
    return this.handleResponse(this.http.post(url, json, header).toPromise());
  }

  /* Invoke custom error handling functionality */
  public doPostWithCustomError(url, json, header = this.getHeader()) {
    return this.http.post(url, json, header).toPromise();
  }

  public doPut(url, json, header = this.getHeader()) {
    return this.handleResponse(this.http.put(url, json, header).toPromise());
  }

  public doDelete(url, header = this.getHeader()) {
    return this.handleResponse(this.http.delete(url, header).toPromise());
  }

  public doPatch(url, json, header = this.getHeader()) {
    return this.handleResponse(this.http.patch(url, json, header).toPromise());
  }

  public doPostRequest(req) {
    return this.http.request(req).toPromise();
  }
}
