<section class="h-full flex flex-col gap-8 justify-center items-center overflow-y-scroll no-scroll-bar">
  <div class="w-52 h-52 mx-auto">
    <img class="w-full" src="https://content.zeotap.com/img/unauthorized.png" />
  </div>
  <p class="font-primary-medium text-2xl text-primary-1-900">Not Authorised</p>
  <p class="font-primary text-lg text-grey/52">Apologies, but you do not have the necessary permissions to access this page.</p>
  <div class="bg-grey-200/30 p-5 rounded-lg flex flex-col font-primary text-sm w-2/3 gap-3 text-primary-1-900">
    <p class="font-primary-bold">If you believe you should have access, kindly perform the following checks:</p>
    <ol type="1" class="list-inside list-decimal">
      <li class="mb-4">Ensure you are logged in with the correct user account.</li>
      <li class="mb-4">Verify that your user role grants access to this particular section.</li>
      <li class="mb-4">Reach out to your administrator or our <a class="text-primary-1-500" [href]="supportUrl" target="_blank">support team</a> for assistance.</li>
    </ol>
    <div>Alternatively, you may navigate back to the <span class="cursor-pointer text-primary-1-500" (click)="routeToHome()">Home Page</span>.</div>
  </div>
  <div class="font-primary-medium h-12 w-max cursor-pointer p-3 rounded flex justify-center items-center text-sm bg-primary-1 text-white font-bold" (click)="routeToHome()">{{ 'Back to Home' }}</div>
</section>
