import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
} from '@angular/router';
import { ConfigService } from '@zeotap-ui/config';
import { StorageService } from '../../storage';
import { getRedirectPath, isNotNullOrEmpty } from '../../utils';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  isSegmentEnabled = this.configService.getFeatureValue('ENABLE_SEGMENT', true)
    ?.enabled;

  constructor(
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
    private configService: ConfigService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    const hasAccess = this.authService.hasProductAccess(route.data.product);

    if (!hasAccess) {
      const redirectPath = getRedirectPath(route);
      if (isNotNullOrEmpty(redirectPath)) {
        this.storageService.setItem('redirectPath', redirectPath);
      }
    }
    if (route.data && hasAccess) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}
