import {
  Component,
  OnInit,
  AfterContentInit,
  Input,
  OnDestroy,
  Output,
  ContentChildren,
  QueryList,
  ElementRef,
  ViewChild,
  OnChanges,
  EventEmitter
} from '@angular/core';

import { BaseControl, getControlValueAccessor } from '../base';

import { DropdownOptionsComponent } from './dropdownOptions';

import { Observable, fromEvent } from 'rxjs';

const dataOptionsDefault = {
  name: '',
  value: '',
  selected: 'selected',
  noOfOptionsToShow: 5,
  noOfChipsToShow: 5,
  enableNullSelection: false
};

@Component({
  selector: 'zui-dropdown',
  templateUrl: './dropdown.html',
  styleUrls: ['./dropdown.scss'],
  providers: [getControlValueAccessor(DropdownComponent)]
})
export class DropdownComponent extends BaseControl
  implements AfterContentInit, OnInit, OnChanges, OnDestroy {
  public showMenu = false;
  public searchText = '';
  public dataType: 'string' | 'object' = 'string';
  public optionsArr: DropdownOptionsComponent[] = [];
  public clickUnsubscribe;
  public clearAll = false;
  public showPlaceholder = true;
  public oldPlaceholder;
  public clickSubscription;
  public onDocumentClickCapture;
  public selectedObj;
  public displayName;
  public filtered = [];
  public dataOptionsObj;

  @Input() public data: any[];
  @Input() public filterFn: Function;
  @Input() public multiple = false;
  @Input() public dataOptions = dataOptionsDefault;
  @Input() public autocomplete = false;
  @Input() public hiddenOptionsText = 'more';
  @Input() public placeholder = 'Select your choices';
  @Input() public disabled = false;
  @Input() public showClear = false;
  @Input() public hideSelectAll = false;
  @Input() public isStopPropagation = true;
  @Output() public onSearchTextChange = new EventEmitter<string>();
  @ContentChildren(DropdownOptionsComponent) public options: QueryList<
    DropdownOptionsComponent
  >;
  @ViewChild('dropdownContainer') dropdownContainer;

  constructor(private elementRef: ElementRef) {
    super();
    this.toggleOptionSelection = this.toggleOptionSelection.bind(this);
  }

  public ngOnChanges() {
    if (this.options) {
      this.optionsArr = this.options.toArray();
    }
    if (!this.autocomplete && this.optionsArr.length) {
      this.optionsArr.forEach(o => {
        this.showMenu ? o.showChild() : o.destroyChild();
      });
    }
    // this.writeValue(this.value);
  }

  public ngOnInit() {
    this.dataType = typeof this.data[0] === 'string' ? 'string' : 'object';
    let clicks = fromEvent(document, 'click');
    this.clickUnsubscribe = clicks.subscribe(event => {
      if (!this.elementRef.nativeElement.contains(event['target'])) {
        this.handleDocumentClick(event);
      }
    });
    this.dataOptions = Object.assign(
      dataOptionsDefault || {},
      this.dataOptions
    );
    this.dataOptionsObj = Object.assign({}, this.dataOptions);
  }

  public ngOnDestroy() {
    this.clickUnsubscribe.unsubscribe();
  }

  public createOptions() {
    this.optionsArr = this.options.toArray();
    this.optionsArr.forEach(o => {
      o.selectionChange.subscribe(this.toggleOptionSelection);
    });
  }

  public ngAfterContentInit() {
    this.createOptions();
    this.options.changes.subscribe(() => {
      this.createOptions();
    });
    this.oldPlaceholder = this.placeholder;
  }

  public modelChanged() {
    this.onSearchTextChange.emit(this.searchText);
    let visibleCount = 0;
    let proceed = true;
    this.filtered = [];
    if (this.searchText.length) {
      this.showMenu = true;
      this.data.forEach((d, i: number) => {
        let value = d;
        if (this.dataType === 'object') {
          value = d[this.dataOptionsObj.name];
        }
        if (proceed && this.filterFn(value, this.searchText)) {
          visibleCount += 1;
          proceed = this.dataOptionsObj.noOfOptionsToShow > visibleCount;
          this.optionsArr[i].showChild();
          this.filtered.push(d);
        } else if (this.filterFn(value, this.searchText)) {
          this.filtered.push(d);
        } else {
          if (this.optionsArr[i]) this.optionsArr[i].destroyChild();
        }
      });
    } else {
      this.showMenu = false;
      this.optionsArr.forEach(o => o.destroyChild());
    }
  }

  public toggleOptionSelection(option: DropdownOptionsComponent) {
    if (this.multiple) {
      option.selected = !option.selected;
      this.updateValue();
    } else {
      this.showMenu = false;
      this.onBlur();
      this.optionsArr.forEach(o => (o.selected = false));
      option.selected = true;
      this.value =
        this.dataType === 'object' && this.dataOptionsObj.value
          ? option.data[this.dataOptionsObj.value]
          : option.data;
      this.selectedObj = this.optionsArr.find(o => o.selected).data;
      this.displayName = this.dataOptionsObj.name
        ? this.selectedObj[this.dataOptionsObj.name]
        : this.selectedObj;
      this.searchText = '';
    }
    if (this.dataOptions.selected && this.dataType === 'object') {
      this.optionsArr.forEach((o, i) => {
        this.data[i][this.dataOptions.selected] = o.selected;
      });
    }
  }

  public toggleAllOptionsSelection() {
    if (this.multiple) {
      this.clearAll = !this.clearAll;
      if (this.clearAll) {
        if (this.autocomplete) {
          if (this.filtered.length) {
            this.filtered.forEach(d => {
              d[this.dataOptionsObj.selected] = true;
              this.optionsArr.find(o => d == o.data)[
                this.dataOptionsObj.selected
              ] = true;
            });
          } else {
            this.data.forEach(d => {
              d[this.dataOptionsObj.selected] = true;
              this.optionsArr.find(o => d == o.data)[
                this.dataOptionsObj.selected
              ] = true;
            });
          }
        } else {
          this.optionsArr.forEach(o => {
            o[this.dataOptionsObj.selected] = true;
          });
          this.selectedObj = this.optionsArr
            .filter(o => o.selected)
            .map(o => o.data);
          if (this.dataType === 'object')
            this.data.forEach(d => (d[this.dataOptionsObj.selected] = true));
        }
      } else {
        this.optionsArr.forEach(o => (o.selected = false));
        if (this.dataType === 'object')
          this.data.forEach(d => (d[this.dataOptionsObj.selected] = false));
      }
      this.updateValue();
    } else {
      this.optionsArr.forEach(o => (o.selected = false));
      this.value = '';
    }
  }

  public updateValue() {
    this.value = this.optionsArr
      .filter(o => o.selected)
      .map(o => {
        if (this.dataType === 'object' && this.dataOptionsObj.value) {
          return o.data[this.dataOptionsObj.value];
        }
        return o.data;
      });
    this.selectedObj = this.optionsArr
      .filter(o => o.selected)
      .map(o => {
        return o.data;
      });
    if (this.value.length > 0) {
      this.showPlaceholder = false;
      this.clearAll = true;
    } else {
      this.placeholder = this.oldPlaceholder;
      this.showPlaceholder = true;
      this.clearAll = false;
    }
  }

  public removeItem(v, event) {
    event.stopPropagation();
    if (this.dataType === 'object' && this.dataOptionsObj.value) {
      this.toggleOptionSelection(
        this.optionsArr.find(
          o =>
            o.data[this.dataOptionsObj.value] === v[this.dataOptionsObj.value]
        )
      );
    } else {
      this.toggleOptionSelection(this.optionsArr.find(o => o.data === v));
    }
    if (this.dataType === 'object')
      this.data.find(d => {
        return this.dataOptionsObj.name
          ? d[this.dataOptionsObj.name] === v[this.dataOptionsObj.name]
          : d === v;
      })[this.dataOptionsObj.selected] = false;
  }

  public clickChipsContainer(event) {
    if (this.isStopPropagation) {
      event.stopPropagation();
    }
    this.showDropdownMenu();
  }

  public showDropdownMenu() {
    this.showMenu = !this.showMenu;
    this.showMenu ? this.onFocus() : this.onBlur();
    let visibleCount = 0;
    let proceed = true;
    if (!this.autocomplete) {
      this.optionsArr.forEach(o => {
        this.showMenu ? o.showChild() : o.destroyChild();
      });
    } else {
      this.data.forEach((d, i: number) => {
        if (proceed) {
          visibleCount += 1;
          proceed = this.dataOptionsObj.noOfOptionsToShow > visibleCount;
          this.optionsArr[i].showChild();
        }
      });
    }
  }

  public handleDocumentClick(event) {
    this.showMenu = false;
    this.onBlur();
  }

  public writeValue(value: any) {
    super.writeValue(value);
    if (this.value) {
      if (this.multiple) {
        this.optionsArr.forEach(
          o =>
            (o.selected = !!this.value.find(v =>
              this.dataOptionsObj.value
                ? v === o.data[this.dataOptionsObj.value]
                : v === o.data
            ))
        );
        this.selectedObj = this.optionsArr
          .filter(o => o.selected)
          .map(o => o.data);
        if (this.selectedObj.length) {
          this.selectedObj.forEach(obj => {
            this.data.find(d => d === obj)[this.dataOptionsObj.selected] = true;
          });
          this.showPlaceholder = false;
        } else {
          this.showPlaceholder = true;
        }
      } else {
        this.optionsArr.forEach(
          o =>
            (o.selected = this.dataOptionsObj.value
              ? this.value === o.data[this.dataOptionsObj.value]
              : this.value === o.data)
        );
        if (this.dataOptions.selected && this.dataType === 'object') {
          this.optionsArr.forEach((o, i) => {
            this.data[i].selected = o.selected;
          });
        }
        let selected = this.optionsArr.find(o => o.selected);
        if (selected) this.selectedObj = selected.data;
        if (this.selectedObj)
          this.displayName = this.dataOptionsObj.name
            ? this.selectedObj[this.dataOptionsObj.name]
            : this.selectedObj;
        else this.value = '';
      }
    } else {
      if (this.dataType === 'object')
        this.data.forEach(d => (d.selected = false));
    }
  }

  public onFocus() {
    this.elementRef.nativeElement.firstElementChild.firstElementChild.classList.add(
      'focused'
    );
  }

  public onBlur() {
    if (!this.showMenu)
      this.elementRef.nativeElement.firstElementChild.firstElementChild.classList.remove(
        'focused'
      );
  }
}
