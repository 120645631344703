import { Inject, Injectable, Optional } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  compose,
  forEachObjIndexed,
  groupBy,
  head,
  map as rMap,
  mergeAll,
  pluck,
  prop,
  whereEq,
} from 'ramda';
import {
  combineLatest,
  iif,
  interval,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import {
  buffer,
  catchError,
  debounceTime,
  filter,
  map,
  skip,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { defaultToEmptyObject, isNotNullOrEmpty } from '../../utils/utils';
import { StorageService } from '../../storage';
import { ConfigService } from '@zeotap-ui/config';
import {
  ProductPreference,
  UserOrgLevelPreference,
  UserPreference,
} from '../types';
import { FirebaseAuthService } from '../../auth';
import { UserPreferencePathToken } from '../tokens';

export const DefaultAppPreferencePath = 'unity';
@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  private readonly paths = {
    users: 'users',
    appPreferences: 'appPreferences',
    userPreference: 'userPref',
    productPreferences: 'productPreferences',
    orgPreferences: 'orgPreferences',
  };
  private updateProductPref$ = new Subject<{
    data: Partial<ProductPreference>;
    productId: string;
  }>();
  private updateUserPref$ = new Subject<Partial<UserPreference>>();
  private updateUserOrgLevelPref$ = new Subject();
  private bufferInterval =
    this.configService.appConfig.configs['FIREBASE_BUFFER_INTERVAL'];
  private appPreferencePath: String;
  constructor(
    private afs: AngularFirestore,
    private storageService: StorageService,
    private firebaseAuth: FirebaseAuthService,
    private configService: ConfigService,
    @Optional()
    @Inject(UserPreferencePathToken)
    userPreferencePath: String
  ) {
    this.appPreferencePath = !!userPreferencePath
      ? `${userPreferencePath}`
      : `${DefaultAppPreferencePath}`;
    this.updateUserPref$
      .pipe(
        buffer(
          combineLatest([
            this.firebaseAuth.isAuthenticated().pipe(skip(1)),
            interval(this.bufferInterval),
          ])
        ),
        filter(isNotNullOrEmpty),
        map(mergeAll),
        withLatestFrom(
          this.firebaseAuth.isAuthenticated(),
          this.getUserPreference()
        ),
        filter(
          ([newUserPreference, isAuthenticated, currUserPreference]) =>
            isNotNullOrEmpty(this.storageService.getUserId()) &&
            isAuthenticated &&
            !whereEq(newUserPreference, currUserPreference)
        )
      )
      .subscribe(([updateData]) => this.setUserPreference(updateData));

    this.updateProductPref$
      .pipe(
        withLatestFrom(this.firebaseAuth.isAuthenticated()),
        filter(
          ([pref, isAuthenticated]) =>
            isNotNullOrEmpty(this.storageService.getUserId()) &&
            isAuthenticated &&
            isNotNullOrEmpty(pref)
        ),
        map(head),
        debounceTime(this.bufferInterval),
        map(
          compose(
            rMap(compose(mergeAll, pluck('data'))),
            groupBy(prop('productId'))
          )
        )
      )
      .subscribe(
        forEachObjIndexed((preference, productId) =>
          this.setProductConfig(preference, productId)
        )
      );

    this.updateUserOrgLevelPref$
      .pipe(
        withLatestFrom(this.firebaseAuth.isAuthenticated()),
        filter(
          ([pref, isAuthenticated]) =>
            isNotNullOrEmpty(this.storageService.getUserId()) &&
            isAuthenticated &&
            isNotNullOrEmpty(pref)
        ),
        map(head)
      )
      .subscribe((pref: UserOrgLevelPreference) => {
        this.setUserOrgLevelConfig(pref);
      });
  }

  getUserPreference(): Observable<UserPreference> {
    return this.firebaseAuth.isError().pipe(
      switchMap((isError) => {
        return iif(
          () => !isError,
          this.firebaseAuth.isAuthenticated().pipe(
            filter((isAuthenticated) => isAuthenticated),
            switchMap(() => this.getUserConfig(this.storageService.getUserId()))
          ),
          of({} as UserPreference)
        );
      })
    );
  }

  getUserId() {
    return this.storageService.getUserId();
  }

  getUserPreferenceDoc(): Observable<UserPreference> {
    return this.firebaseAuth.isError().pipe(
      take(1),
      switchMap((isError) => {
        return iif(
          () => !isError,
          this.firebaseAuth.isAuthenticated().pipe(
            take(1),
            filter((isAuthenticated) => isAuthenticated),
            switchMap(() =>
              this.getUserConfigDoc(this.storageService.getUserId())
            )
          ),
          of({} as UserPreference)
        );
      })
    );
  }

  getProductPreference(productName: string): Observable<ProductPreference> {
    return this.firebaseAuth.isError().pipe(
      switchMap((isError) => {
        return iif(
          () => !isError,
          this.firebaseAuth.isAuthenticated().pipe(
            filter((isAuthenticated) => isAuthenticated),
            switchMap(() =>
              this.getProductConfig(
                this.storageService.getUserId(),
                productName
              )
            )
          ),
          of({} as ProductPreference)
        );
      })
    );
  }

  getUserOrgLevelPreference(
    productName: string,
    orgId: number
  ): Observable<any> {
    return this.firebaseAuth.isError().pipe(
      switchMap((isError) => {
        return iif(
          () => !isError,
          this.firebaseAuth.isAuthenticated().pipe(
            filter((isAuthenticated) => isAuthenticated),
            switchMap(() =>
              this.getUserOrgLevelConfig(
                this.storageService.getUserId(),
                productName,
                orgId + ''
              )
            )
          ),
          of({} as ProductPreference)
        );
      })
    );
  }

  updateUserPreference(data: Partial<UserPreference>) {
    this.updateUserPref$.next(data);
  }

  updateProductConfig(data: any, productId: string) {
    this.updateProductPref$.next({
      data,
      productId,
    });
  }

  updateUserOrgLevelConfig(data: any, productId: string, orgId: string) {
    this.updateUserOrgLevelPref$.next({
      data,
      productId,
      orgId,
    });
  }

  private setUserPreference(data: Partial<UserPreference>) {
    // Create or overwrite a single document where 'merge' used for Changes the behavior
    // of a set() call to only replace the values specified in its data argument. Fields omitted from the set() call remain untouched.
    const userId = this.storageService.getUserId();
    this.afs
      .collection(`${this.paths.users}`)
      .doc(`${userId}`)
      .collection(`${this.paths.appPreferences}`)
      .doc(`${this.appPreferencePath}`)
      .set({ ...data }, { merge: true });
  }

  private setProductConfig(data: any, productId: string) {
    const userId = this.storageService.getUserId();
    this.afs
      .collection(`${this.paths.users}`)
      .doc(`${userId}`)
      .collection(`${this.paths.appPreferences}`)
      .doc(`${this.appPreferencePath}`)
      .collection(`${this.paths.productPreferences}`)
      .doc(productId)
      .set(
        {
          ...data,
        },
        { merge: true }
      );
  }

  private setUserOrgLevelConfig(preferenceData: UserOrgLevelPreference) {
    const userId = this.storageService.getUserId();
    const { orgId, productId, data } = preferenceData;
    this.afs
      .collection(`${this.paths.users}`)
      .doc(`${userId}`)
      .collection(`${this.paths.appPreferences}`)
      .doc(`${this.appPreferencePath}`)
      .collection(`${this.paths.productPreferences}`)
      .doc(productId)
      .collection(`${this.paths.orgPreferences}`)
      .doc(orgId)
      .set(
        {
          ...data,
        },
        { merge: true }
      );
  }

  private getUserConfig(userId: string): Observable<UserPreference> {
    return this.afs
      .doc<UserPreference>(
        `${this.paths.users}/${userId}/${this.paths.appPreferences}/${this.appPreferencePath}`
      )
      .valueChanges()
      .pipe(
        map(defaultToEmptyObject),
        catchError((e) => of({}))
      );
  }

  private getUserConfigDoc(userId: string): Observable<UserPreference> {
    return this.afs
      .doc<UserPreference>(
        `${this.paths.users}/${userId}/${this.paths.appPreferences}/${this.appPreferencePath}`
      )
      .get()
      .pipe(
        map((v) => {
          return defaultToEmptyObject(v.data());
        }),
        catchError((e) => of({}))
      );
  }

  private getProductConfig(
    userId: string,
    productId: string
  ): Observable<ProductPreference> {
    return this.afs
      .doc<ProductPreference>(
        `${this.paths.users}/${userId}/${this.paths.appPreferences}/${this.appPreferencePath}/${this.paths.productPreferences}/${productId}`
      )
      .valueChanges()
      .pipe(
        map(defaultToEmptyObject),
        catchError((e) => of({}))
      );
  }

  private getUserOrgLevelConfig(
    userId: string,
    productId: string,
    orgId: string
  ): Observable<any> {
    return this.afs
      .doc<ProductPreference>(
        `${this.paths.users}/${userId}/${this.paths.appPreferences}/${this.appPreferencePath}/${this.paths.productPreferences}/${productId}/${this.paths.orgPreferences}/${orgId}`
      )
      .valueChanges()
      .pipe(
        map(defaultToEmptyObject),
        catchError((e) => of({}))
      );
  }
}
