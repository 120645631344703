<div class="component-container">
  <div id="sequence" class="zui-gray4-text-color zui-mediumtext">
    <div class="slice header'">{{options.title}}</div><br>
    <span id="trail"></span>
    <span id="tip" class="zui-content"></span>
  </div>
  <div class="chart-legend zui-normaltext zui-gray8-text-color legendWrapper">
    <span [hidden]="item.children" *ngFor="let item of selected">
      <span class="icon" [ngClass]="{'male maleIcon zui-blue-color': item.name.toLowerCase() == 'male', 'female femaleIcon zui-rose-color': item.name.toLowerCase() == 'female'}"></span>{{formatter(item.size, true)}}
    </span>
  </div>
  <div id="{{id}}" class="chart">
    <div id="explanation">
        <span id="percentage" class="zui-mediumtext zui-gray1-text-color"></span>
        <br/>
        <span id="name" class="zui-content">{{options.title}}</span>
    </div>
  </div>
</div>