import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'zap-popupbox',
  templateUrl: './popupbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class PopupboxComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Input() closeOnEsc = true;
  @Input() hide = false;
  constructor() {}

  // detect esc click and generate event to close the popup
  @HostListener('document:keydown.escape', ['$event'])
  onEscapeHandler(event: KeyboardEvent) {
    this.cancel.emit();
  }

  ngOnInit(): void {}
}
