<ng-container
  *ngIf="{
    files: fileItems$ | async,
    allowUpload: allowUpload$ | async
  } as data"
>
  <div class="file-upload-container bg-grey-50 border border-solid border-grey-300/30 text-center w-[40rem] p-2.5 rounded-[0_0_0.25rem_0.25rem group-[.full-width]:!w-[100%]" *ngIf="data.files?.length === 0">
    <div class="add-files-btn well bg-transparent rounded-[.25rem] border-[0.062rem] border-dashed border-grey-300/30 min-h-0 leading-6 mx-auto my-0 shadow-[inset_0_1px_1px_rgba(0,0,0,0.05)] p-[19px]">
      <label class="add-files font-secondary text-primary-1 bg-primary-1/10 rounded-[.25rem] text-[0.87rem] cursor-pointer pt-[0.1875rem] pb-0.5 px-2.5" for="file-upload" [ngClass]="{ 'disabled !text-grey/52 !bg-grey-100 !cursor-not-allowed': disableUploadBtn }">
        + ADD FILES
      </label>
      <input #fileInput id="file-upload" type="file" [accept]="accept" (change)="fileChange($event)" [disabled]="disableUploadBtn" />
      <div class="text-xs max-size-header font-primary-italic text-grey-400">Max file size - {{ maxSize / 1048576 }} MB</div>
      <div class="text-xs max-size-header font-primary-italic text-grey-400">{{ uploadInstruction }}</div>
    </div>
  </div>
  <section class="file-list-section w-[40rem]" *ngIf="data.files.length">
    <section class="grid px-4 py-2 file-list h-max" *ngIf="data.files.length">
      <ng-container *ngFor="let item of data.files">
        <div class="file-item-container inline-grid grid-cols-[0.07fr_0.7fr_0.5fr_0.1fr] h-auto pl-[0.1rem] border-b-[0.06rem] border-b-primary-1-900/5 border-solid">
          <div class="icon-container text-center flex flex-col justify-center text-grey-500 rounded-[0.625rem]">
            <span class="mr-0 icon iframe-event"> </span>
          </div>
          <div class="file-info leading-[1.2rem] mx-0 my-auto">
            <div class="text-sm break-all font-primary cursor-text text-grey/52 name">{{ item.name || item.file.name }}</div>
            <div class="text-xs font-primary file-item text-grey/52">{{ (item?.size || item?.file?.size || 0) / 1024 / 1024 | number: '.2' }} MB</div>
          </div>
          <div class="file-info font-primary invalid-file text-state-error-700 leading-[1.2rem] mx-0 my-auto">
            <ng-container *ngIf="item.sizeError">
              <div>
                <span class="icon error warning-icon text-state-error-700 text-[0.8rem];"></span>
                {{ item.sizeError }}
              </div>
            </ng-container>
            <ng-container *ngIf="item.typeError">
              <div>
                <span class="icon error warning-icon text-state-error-700 text-[0.8rem];"></span>
                {{ item.typeError }}
              </div>
            </ng-container>
          </div>
          <div class="flex-col justify-center h-full action-button text-state-error-700">
            <label class="icon delete delete-icon flex cursor-pointer rounded w-[1.4rem] m-4 p-[0.2rem] bg-state-error-500/10" (click)="deleteFileList()"></label>
          </div>
        </div>
      </ng-container>
    </section>
    <div *ngIf="data.files.length" class="upload-bg">
      <div class="flex items-center justify-center text-base rounded cursor-pointer upload-files font-secondary text-primary-1 bg-primary-1/10 h-7 w-28" (click)="handleFileUpload()" [ngClass]="{ '!disabled !text-grey/52 !bg-grey-100 !cursor-not-allowed': !data.allowUpload, 'p-3 !font-primary !text-sm !bg-primary-1 text-white min-h-[3rem] min-w-[6.25rem] next-button hover:shadow-11 absolute bottom-2 left-[150px]': uploadBottonPosition === 'BOTTOM' }">
        Upload Files
      </div>
    </div>
  </section>
</ng-container>
