import { pathOr } from 'ramda';
import {
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  OnInit,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { getControlValueAccessor } from '../base';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
const getTip = () => {
  const tip = d3Tip()?.attr(
    'class',
    'd3-tip tooltip zui-content zui-bgcolor-white'
  );
  return () => tip;
};

const defaultOptions = {
  title: {
    enable: true,
    text: 'Chart',
  },
  type: 'column',
  height: 600,
  width: 600,
  colors: ['red', 'yellow'],
  differentColors: false,
  onClick: function () {},
  onMouseHover: function () {},
  xAxis: {
    enable: true,
    noStroke: false,
    hideCategories: false,
    label: {
      enable: true,
      text: 'Name',
      color: '#969699',
    },
    values: {
      formatter: function () {
        return this.point.data;
      },
      rotate: true,
      rotationDegree: '-65',
    },
  },
  yAxis: {
    label: {
      enable: true,
      text: 'Values',
      color: '#969699',
    },
    values: {
      formatter: function () {
        return this.point.data;
      },
    },
    hideTickValues: false,
    enable: true,
    noStroke: false,
    hideCategories: false,
  },
  stacked: true,
  sort: {
    enable: false,
    order: 'asc',
  },
  selection: '',
  tooltip: {
    enable: true,
    text: '',
    formatter: function () {
      return this.point.name + '<br>' + this.point.data;
    },
  },
  dataLabels: {
    enable: false,
    formatter: function () {},
  },
  roundedBars: false,
  margin: {
    top: 50,
    bottom: 100,
    left: 100,
    right: 100,
  },
  legend: {
    enable: true,
    formatter: function () {
      return this.point;
    },
  },
  totalAvailable: false,
};

@Component({
  selector: 'zui-bar-chart',
  styleUrls: ['./bar-chart.scss'],
  templateUrl: './bar-chart.html',
  providers: [getControlValueAccessor(BarChartComponent)],
})
export class BarChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() options = defaultOptions;
  @Input() data: any[];
  getTip = getTip();
  public chartDimensions: any = [];
  public svg: any;
  public t: any;
  public scales: any = [];
  public chartData: any;
  public orientation: any;
  public dataAggregates: any = [];
  public svgCreated: boolean = false;

  constructor(public eleRef: ElementRef) {
    this.t = d3.transition().duration(500).ease(d3.easeLinear);
  }
  ngAfterViewInit() {
    if (!!this.svg) {
      this.svg.call(this.getTip());
    }
    window.addEventListener('resize', () => {
      this.initSVG();
      this.onWindowResize();
    });
  }

  public _tip = null;
  get tip() {
    if (!this._tip) {
      this._tip = this.getTip();
    }
    return this._tip;
  }

  ngOnChanges() {
    this.options = Object.assign({}, defaultOptions, this.options);
    this.orientation = this.options.type.toLowerCase();
    if (
      this.data &&
      this.data.length &&
      this.data.filter((d) => d.name !== '__total').length
    ) {
      this.dataAggregates.keys = d3
        .keys(this.data[0])
        .filter((key) => key.toLowerCase() != 'name');
      if (this.svgCreated) {
        if (Object.keys(this.data[0]).length > 2 && !this.options.stacked) {
          this.drawGroupedBar();
        } else {
          this.setDimensions();
          this.drawStackedBar();
        }
      } else {
        this.initSVG();
        if (Object.keys(this.data[0]).length > 2 && !this.options.stacked) {
          this.drawGroupedBar();
        } else {
          this.drawStackedBar();
        }
      }
    }
  }

  ngOnDestroy() {
    window.addEventListener('resize', null);
  }

  onWindowResize() {
    if (this.data && this.data.length && this.data[0]) {
      if (Object.keys(this.data[0]).length > 2 && !this.options.stacked) {
        this.addScales('grouped');
        this.addXaxis('grouped');
        this.addYaxis('grouped');
        this.drawBars('grouped', true);
      } else {
        this.addScales('stacked');
        this.addXaxis('stacked');
        this.addYaxis('stacked');
        this.drawBars('stacked', true);
      }
    }
  }

  public initSVG() {
    this.svgCreated = true;
    this.svg = d3.select(this.eleRef.nativeElement).select('svg');
    this.svg.attr('width', '100%').attr('height', '100%');
    let margin = this.options.margin || defaultOptions.margin;
    this.setDimensions();
    this.svg = this.svg
      .select('.bar-container')
      .attr(
        'transform',
        `translate(${margin.left || 100}, ${margin.top || 50})`
      );
  }

  public setDimensions() {
    var container = d3.select(this.svg.node().parentNode);
    var margin = this.options.margin || defaultOptions.margin;
    this.chartDimensions.width =
      (parseInt(container.style('width'), 10) || 350) -
      (margin.left || 50) -
      (margin.right || 50);
    this.chartDimensions.height =
      (parseInt(container.style('height'), 10) || 350) -
      (margin.top || 50) -
      (margin.bottom || 50);
  }

  public setTitle(svg) {
    let title = svg
      .select('.chartTitle')
      .classed('zui-gray4-text-color zui-mediumtext', true)
      .attr(
        'transform',
        'translate(' +
          '-' +
          (this.options.margin.left - 10 || 90) +
          ',' +
          '-' +
          (this.options.margin.top / 2 || 25) +
          ')'
      )
      .text(this.options.title.text || 'Bar chart');
  }

  public addColors() {
    this.dataAggregates.colors = d3
      .scaleOrdinal()
      .domain(this.dataAggregates.keys)
      .range(this.options.colors);
  }

  public initChart(type) {
    if (this.options.title.enable || false) {
      this.setTitle(this.svg);
    }
    this.chartData = [];
    this.chartData = this.data
      .map((d) => Object.assign({}, d))
      .filter((d) => d.name !== '__total');
    const total = pathOr(
      null,
      ['value'],
      this.data.find((d) => d.name === '__total')
    );
    this.addColors();
    if (type === 'stacked') {
      this.dataAggregates.allSeriesSum = 0;
      let totalSum = 0;
      this.chartData.forEach((d) => {
        let y0 = 0;
        d.category = this.dataAggregates.keys.map((name) => {
          return {
            name: name,
            y0: y0,
            y1: (y0 += +(parseFloat(d[name]) < 0 ? 0 : parseFloat(d[name]))),
          };
        });
        d.total = d.category[d.category.length - 1].y1;
        d.category.forEach((cat) => {
          cat.sum = d.total;
          cat.label = d.name;
        });
        totalSum += parseFloat(d.total);
      });
      this.dataAggregates.allSeriesSum =
        !!this.options.totalAvailable && !!total ? parseFloat(total) : totalSum;
      this.chartData = this.chartData.filter(
        (d) =>
          this.getPercentage(d.total, this.dataAggregates.allSeriesSum) >= 0.01
      );
    }
    if (type === 'grouped') {
      this.dataAggregates.allSeriesSum = 0;
      let totalSum = 0;
      this.chartData.forEach((obj) => {
        let sum = 0;
        this.dataAggregates.keys.forEach((key) => {
          sum += parseFloat(obj[key]);
        });
        obj['sum'] = sum;
        totalSum += sum;
      });
      this.dataAggregates.allSeriesSum =
        !!this.options.totalAvailable && !!total ? parseFloat(total) : totalSum;
      this.chartData = this.chartData.filter(
        (d) =>
          this.getPercentage(d.sum, this.dataAggregates.allSeriesSum) >= 0.01
      );
    }
  }

  public getPercentage(partValue: number, wholeValue: number) {
    if (wholeValue && wholeValue > 0 && partValue) {
      return parseFloat(((partValue / wholeValue) * 100).toFixed(2));
    }
    return 0;
  }

  public sortByName(name: string) {
    return (this.options.sort && this.options.sort.enable) || false
      ? this.options.sort.order.toLowerCase() === 'asc'
        ? this.chartData.sort((a, b) => (a[name] > b[name] ? 1 : -1))
        : this.options.sort.order.toLowerCase() === 'desc'
        ? this.chartData.sort((a, b) => (b[name] > a[name] ? 1 : -1))
        : this.chartData
      : this.chartData;
  }

  public sortByTotal(total: string) {
    return (this.options.sort && this.options.sort.enable) || false
      ? this.options.sort.order.toLowerCase() === 'asc'
        ? this.chartData.sort((a, b) => a[total] - b[total])
        : this.options.sort.order.toLowerCase() === 'desc'
        ? this.chartData.sort((a, b) => b[total] - a[total])
        : this.chartData
      : this.chartData;
  }

  public addScales(type) {
    if (type === 'grouped') {
      this.scales.x0 = d3
        .scaleBand()
        .domain(
          this.chartData.map((d) => {
            return d.name;
          })
        )
        .rangeRound([0, this.chartDimensions.width])
        .paddingInner(0.1);

      this.scales.x1 = d3
        .scaleBand()
        .domain(this.dataAggregates.keys)
        .rangeRound([0, this.scales.x0.bandwidth()])
        .padding(0.1);

      this.scales.y = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(this.chartData, (d) => {
            return d3.max(this.dataAggregates.keys, (key) => {
              return parseFloat(d[key]) < 0 ? 0 : parseFloat(d[key]);
            });
          }),
        ])
        .rangeRound([this.chartDimensions.height, 0]);

      this.scales.y0 = d3
        .scaleBand()
        .domain(
          this.chartData.map((d) => {
            return d.name;
          })
        )
        .rangeRound([this.chartDimensions.height, 0])
        .paddingInner(0.2);

      this.scales.y1 = d3
        .scaleBand()
        .domain(this.dataAggregates.keys)
        .rangeRound([0, this.scales.y0.bandwidth()]);

      this.scales.x = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(this.chartData, (d) => {
            return d3.max(this.dataAggregates.keys, (key) => {
              return parseFloat(d[key]) < 0 ? 0 : parseFloat(d[key]);
            });
          }),
        ])
        .rangeRound([0, this.chartDimensions.width]);
    }

    if (type === 'stacked') {
      let scale1 = d3
        .scaleBand()
        .domain(
          this.chartData.map((d) => {
            return d.name;
          })
        )
        .padding(0.2);

      let scale2 = d3.scaleLinear().domain([
        0,
        d3.max(this.chartData, (d) => {
          return d.total
            ? d.total
            : parseFloat(d.data) < 0
            ? 0
            : parseFloat(d.data);
        }),
      ]);

      if (this.orientation === 'column') {
        this.scales.x = scale1.rangeRound([0, this.chartDimensions.width]);
        this.scales.y = scale2.rangeRound([this.chartDimensions.height, 0]);
      }
      if (this.orientation === 'bar') {
        this.scales.y = scale1.rangeRound([this.chartDimensions.height, 0]);
        this.scales.x = scale2.rangeRound([0, this.chartDimensions.width]);
      }
    }
  }

  public addXaxis(type) {
    if (this.options.xAxis.enable || false) {
      let xAxis = this.svg
        .select('.x')
        .attr('transform', 'translate(0,' + this.chartDimensions.height + ')');
      let axis =
        type === 'stacked'
          ? d3.axisBottom(this.scales.x)
          : d3.axisBottom(
              this.orientation === 'bar' ? this.scales.x : this.scales.x0
            );
      axis.tickSize(0).tickSizeOuter(0);
      if (this.orientation === 'bar') {
        let formatter = this.options.xAxis.values.formatter;
        xAxis.call(
          axis
            .ticks(4)
            .tickFormat((d) => {
              let data = d;
              return formatter ? formatter.call({ data }) : d;
            })
            .tickPadding(17)
        );
      }
      if (this.orientation === 'column') {
        xAxis.call(axis.tickPadding(15));
      }
      if (this.options.xAxis.values.rotate || false) {
        let rotateLabels = xAxis
          .selectAll('text')
          .style('text-anchor', 'end')
          .attr('dx', '-0.6em')
          .attr('dy', '-0.50em')
          .attr(
            'transform',
            `rotate(${this.options.xAxis.values.rotationDegree || '-65'})`
          );
      }
      if (this.options.xAxis.label.enable || false) {
        let xAxisLabel = xAxis
          .select('.xLabel')
          .attr(
            'transform',
            'translate(' + (this.chartDimensions.width + 40) + ' ,' + 5 + ')'
          )
          .style('fill', `${this.options.xAxis.label.color || '#969699'}`)
          .text(`${this.options.xAxis.label.text || 'Labels'}`);
      }
      if (this.options.xAxis.noStroke || false) {
        this.svg.selectAll('.x').attr('class', 'x axis noStroke');
      }
      if (this.options.xAxis.hideCategories || false) {
        d3.selectAll('.x').selectAll('.tick').style('display', 'none');
      }
    }
  }

  public addYaxis(type) {
    if (this.options.yAxis.enable || false) {
      let yAxis = this.svg.select('.y');
      let axis =
        type === 'stacked'
          ? d3.axisLeft(this.scales.y)
          : d3.axisLeft(
              this.orientation === 'column' ? this.scales.y : this.scales.y0
            );
      axis.tickSize(0).tickSizeOuter(0).tickPadding(15);

      if (this.orientation === 'column') {
        let formatter = this.options.yAxis.values.formatter;
        yAxis.call(
          axis.tickFormat((d) => {
            let data = d;
            return formatter ? formatter.call({ data }) : d;
          })
        );
        d3.selectAll('.tick')
          .filter((d) => {
            return d === 0;
          })
          .remove();
      }
      if (this.orientation === 'bar') {
        yAxis.call(axis);
      }
      if (this.options.yAxis.label.enable || false) {
        let yLabel = yAxis
          .select('.yLabel')
          .attr('y', this.chartDimensions.height - 25)
          .attr('x', 0)
          .attr('dx', '-1em')
          .attr('dy', '1.5em')
          .attr('fill', `${this.options.yAxis.label.color || '#969699'}`)
          .text(`${this.options.yAxis.label.text || 'Values'}`);
      }
      if (this.options.yAxis.hideTickValues || false) {
        yAxis.selectAll('.tick text').remove();
      }
      if (this.options.yAxis.noStroke || false) {
        this.svg.selectAll('.y').attr('class', 'y axis noStroke');
      }
      if (this.options.yAxis.hideCategories || false) {
        d3.selectAll('.y').selectAll('.tick').style('display', 'none');
      }
    }
  }

  public drawBars(type, onWindowResize = false) {
    let bar;
    if (type === 'stacked') {
      let update = this.svg.selectAll('.gBar').data(this.chartData);
      update.exit().remove();
      let enter = update.enter().append('g').attr('class', 'gBar');
      let updateRect = update
        .merge(enter)
        .attr('transform', (d) => {
          return this.orientation === 'column'
            ? 'translate(' + this.scales.x(d.name) + ',0)'
            : 'translate(0,' + this.scales.y(d.name) + ')';
        })
        .selectAll('rect')
        .data((d) => {
          return d.category;
        });
      updateRect.exit().remove();
      let enterRect = updateRect.enter();
      if (!this.options.roundedBars) {
        enterRect = enterRect.append('rect');
        bar = updateRect
          .merge(enterRect)
          .attr(
            'width',
            this.orientation === 'column' ? this.scales.x.bandwidth() : 0
          )
          .attr(
            'height',
            this.options.yAxis.hideTickValues === false
              ? this.orientation === 'column'
                ? 0
                : this.scales.y.bandwidth()
              : this.scales.y.bandwidth() - 20
          )
          .style('fill', (d) => {
            return this.dataAggregates.colors(d.name);
          });
      }
      if ((this.orientation === 'bar' && this.options.roundedBars) || false) {
        enterRect.selectAll('*').remove();
        enterRect = enterRect.append('path');
        let rightRoundedRect = function (x, y, width, height, radius) {
          return (
            'M' +
            x +
            ',' +
            y +
            'h' +
            (width - radius) +
            'a' +
            radius +
            ',' +
            radius +
            ' 0 0 1 ' +
            radius +
            ',' +
            radius +
            'v' +
            (height - 2 * radius) +
            'a' +
            radius +
            ',' +
            radius +
            ' 0 0 1 ' +
            -radius +
            ',' +
            radius +
            'h' +
            (radius - width) +
            'z'
          );
        };
        if (!onWindowResize) {
          bar = updateRect
            .merge(enterRect)
            .attr('d', (d) => {
              return rightRoundedRect(0, 0, 0, 15, 8);
            })
            .style('fill', (d) => {
              return this.dataAggregates.colors(d.name);
            });
          bar.transition(this.t).attr('d', (d) => {
            return rightRoundedRect(
              this.scales.x(d.y0),
              0,
              this.scales.x(d.y1) - this.scales.x(d.y0),
              15,
              this.scales.x(d.y1) - this.scales.x(d.y0) == 0 ? 0 : 8
            );
          });
        } else {
          bar = updateRect
            .merge(enterRect)
            .attr('d', (d) => {
              return rightRoundedRect(
                this.scales.x(d.y0),
                0,
                this.scales.x(d.y1) - this.scales.x(d.y0),
                15,
                this.scales.x(d.y1) - this.scales.x(d.y0) == 0 ? 0 : 8
              );
            })
            .style('fill', (d) => {
              return this.dataAggregates.colors(d.name);
            });
        }
      }
      if (this.options.differentColors || false) {
        let c = this.options.colors.length - 1;
        bar.style('fill', (d, i) => {
          return this.options.colors[c--] || 'red';
        });
      }
      if (this.orientation === 'column') {
        if (!onWindowResize) {
          bar.attr('y', this.chartDimensions.height);
          bar
            .transition(this.t)
            .attr('height', (d) => {
              return this.scales.y(d.y0) - this.scales.y(d.y1);
            })
            .attr('y', (d) => {
              return this.scales.y(d.y1);
            });
        } else {
          bar
            .attr('height', (d) => {
              return this.scales.y(d.y0) - this.scales.y(d.y1);
            })
            .attr('y', (d) => {
              return this.scales.y(d.y1);
            });
        }
      }
      if (this.orientation === 'bar') {
        if (!onWindowResize) {
          bar.attr('x', 0);
          bar
            .transition(this.t)
            .attr('width', (d) => {
              return this.scales.x(d.y1) - this.scales.x(d.y0);
            })
            .attr('x', (d) => {
              return this.scales.x(d.y0);
            });
        } else {
          bar
            .attr('width', (d) => {
              return this.scales.x(d.y1) - this.scales.x(d.y0);
            })
            .attr('x', (d) => {
              return this.scales.x(d.y0);
            });
        }
      }
      this.addTooltips(bar, 'stacked');
      this.addDataLabels();
    }

    if (type === 'grouped') {
      let update = this.svg.selectAll('.gBar').data(this.chartData);
      update.exit().remove();
      let enter = update.enter().append('g').attr('class', 'gBar');
      let updateRect = update
        .merge(enter)
        .attr('transform', (d) => {
          return this.orientation === 'column'
            ? 'translate(' + this.scales.x0(d.name) + ',0)'
            : 'translate( 0,' + this.scales.y0(d.name) + ')';
        })
        .selectAll('rect')
        .data((d) => {
          return this.dataAggregates.keys.map((key) => {
            return {
              key: key,
              value: parseInt(d[key]) < 0 ? 0 : parseInt(d[key]),
              sum: d.sum,
            };
          });
        });
      updateRect.exit().remove();
      let enterRect = updateRect.enter().append('rect').attr('class', 'bar');
      bar = updateRect
        .merge(enterRect)
        .attr('x', (d) => {
          return this.orientation === 'column'
            ? this.scales.x1(d.key < 0 ? 0 : d.key)
            : 0;
        })
        .attr('y', (d) => {
          return this.orientation === 'column'
            ? this.chartDimensions.height
            : this.scales.y1(d.key < 0 ? 0 : d.key);
        })
        .attr(
          'width',
          this.orientation === 'column' ? this.scales.x1.bandwidth() : 0
        )
        .attr(
          'height',
          this.orientation === 'column' ? 0 : this.scales.y1.bandwidth()
        )
        .attr('fill', (d) => {
          return this.dataAggregates.colors(d.key);
        });
      if (this.orientation === 'column') {
        if (!onWindowResize) {
          bar
            .transition(this.t)
            .attr('height', (d) => {
              return (
                this.chartDimensions.height -
                this.scales.y(parseInt(d.value) < 0 ? 0 : parseInt(d.value))
              );
            })
            .attr('y', (d) => {
              return this.scales.y(
                parseInt(d.value) < 0 ? 0 : parseInt(d.value)
              );
            });
        } else {
          bar
            .attr('height', (d) => {
              return (
                this.chartDimensions.height -
                this.scales.y(parseInt(d.value) < 0 ? 0 : parseInt(d.value))
              );
            })
            .attr('y', (d) => {
              return this.scales.y(
                parseInt(d.value) < 0 ? 0 : parseInt(d.value)
              );
            });
        }
      }
      if (this.orientation === 'bar') {
        if (!onWindowResize) {
          bar.transition(this.t).attr('width', (d) => {
            return this.scales.x(parseInt(d.value) < 0 ? 0 : parseInt(d.value));
          });
        } else {
          bar.attr('width', (d) => {
            return this.scales.x(parseInt(d.value) < 0 ? 0 : parseInt(d.value));
          });
        }
      }
      this.addTooltips(bar, 'grouped');
    }
    this.addLegend();
    this.onBarClick(bar);
    this.onBarHover(bar);
  }

  public addDataLabels() {
    if (
      (this.options.dataLabels.enable || false) &&
      !this.options.dataLabels['labelPostion']
    ) {
      let formatter = this.options.dataLabels.formatter;
      let c = this.options.colors.length - 1;
      this.svg.selectAll('.gBar text').remove();
      this.svg
        .selectAll('.gBar')
        .append('text')
        .attr('class', 'value')
        .style('font-family', 'GothamRound,FontAwesome,ZFont')
        .style('font-size', '12')
        .attr('x', (d) => {
          return this.scales.x(d.category[0].y1);
        })
        .attr('dx', '0.7em')
        .attr('dy', this.options.roundedBars ? '1em' : '1.5em')
        .attr('text-anchor', 'start')
        .html((d, i) => {
          let point = d;
          let percentage = (
            parseInt(d.category[0].y1)
              ? parseInt(d.category[0].y1) < 0
                ? 0
                : parseInt(d.category[0].y1)
              : parseInt(d.y1)
          ).toFixed(2);
          return formatter
            ? formatter.call({ point, percentage })
            : d.category[0].label;
        });
    } else if (
      (this.options.dataLabels.enable || false) &&
      this.options.dataLabels['labelPostion'].toLowerCase() === 'start'
    ) {
      let formatter = this.options.dataLabels.formatter;
      let c = this.options.colors.length - 1;
      this.svg.selectAll('.gBar text').remove();
      this.svg
        .selectAll('.gBar')
        .append('text')
        .attr('class', 'value')
        .style('font-family', 'GothamRound,FontAwesome,ZFont')
        .style('font-size', '12')
        .attr('x', '0')
        .attr('dx', '0.7em')
        .attr('dy', (d) => {
          return this.options.roundedBars
            ? '30'
            : this.scales.y.bandwidth() - 7;
        })
        .attr('text-anchor', 'start')
        .html((d, i) => {
          let point = d;
          let percentage = (
            parseInt(d.category[0].y1)
              ? parseInt(d.category[0].y1) < 0
                ? 0
                : parseInt(d.category[0].y1)
              : parseInt(d.y1)
          ).toFixed(2);
          return formatter
            ? formatter.call({ point, percentage })
            : d.category[0].label;
        });
    }
  }

  public onBarClick(bar) {
    let onClickFn = this.options.onClick;
    bar.on('click', (d) => {
      let point = { name: d.name, data: d.data };
      let event = d3.event;
      onClickFn ? onClickFn.call(null, d, event) : null;
    });
  }

  public onBarHover(bar) {
    let onHoverFn = this.options.onMouseHover;
    bar.on('mouseover', (d) => {
      let point = { name: d.name, data: d.data };
      let event = d3.event;
      onHoverFn ? onHoverFn.call(null, d, event) : null;
    });
  }

  public addTooltips(bar, type) {
    if (this.options.tooltip.enable || false) {
      let formatter = this.options.tooltip.formatter;
      let options = this.options;
      bar
        .on('mousemove', (d) => {
          let point = d;
          let percentage;
          let overallPercent;

          if (type === 'stacked') {
            point['data'] = (parseFloat(d.y1) - parseFloat(d.y0)).toFixed(2);
            percentage = ((100 * (d.y1 - d.y0)) / d.sum).toFixed(2);
            overallPercent = (
              (100 * (d.y1 - d.y0)) /
              this.dataAggregates.allSeriesSum
            ).toFixed(2);
          }
          if (type === 'grouped') {
            point['name'] = d.key;
            point['data'] = parseInt(d.value) < 0 ? 0 : parseFloat(d.value);
            percentage = (
              ((parseInt(d.value) < 0 ? 0 : parseFloat(d.value)) * 100) /
              d.sum
            ).toFixed(2);
            overallPercent = (
              (100 * parseInt(d.value)) /
              this.dataAggregates.allSeriesSum
            ).toFixed(2);
          }

          this.getTip()
            .show(d)
            .html(
              formatter
                ? formatter.call({
                    point,
                    percentage,
                    overallPercent,
                    options,
                  })
                : point.name + '<br>' + point.data
            );
        })
        .on('mouseout', this.getTip().hide);
    }
  }

  public addLegend() {
    if (this.options.legend.enable || false) {
      let legend = this.svg
        .select('.legend')
        .attr(
          'transform',
          'translate(' +
            '-' +
            (this.options.margin.right + 40 || 140) +
            ',' +
            '-' +
            (this.options.margin.top || 50) +
            ')'
        )
        .selectAll('.legendGrp')
        .data(
          this.orientation === 'bar'
            ? this.dataAggregates.colors.domain().slice()
            : this.dataAggregates.colors.domain().slice().reverse()
        );
      legend.exit().remove();
      legend.selectAll('*').remove();
      let legendGrp = legend.enter().append('g').attr('class', 'legendGrp');
      legendGrp = legendGrp.merge(legend);
      legendGrp.exit().remove();

      let formatter = this.options.legend.formatter;
      if (!!formatter) {
        legendGrp
          .append('text')
          .attr('x', this.chartDimensions.width - 12)
          .attr('y', 9.5)
          .attr('dy', '0.32em')
          .style('font-family', 'GothamRound-Medium,FontAwesome,ZFont')
          .style('font-size', '14px')
          .style('text-anchor', 'start')
          .attr('fill', this.dataAggregates.colors)
          .html((d) => {
            let point = d;
            let seriesTotal = 0;
            this.chartData.forEach(
              (obj) => (seriesTotal += parseFloat(obj[d]))
            );
            let percentage = (
              (100 * seriesTotal) /
              this.dataAggregates.allSeriesSum
            ).toFixed(2);
            return formatter.call({ point, seriesTotal, percentage });
          });
      } else {
        legendGrp
          .append('circle')
          .attr('cx', this.chartDimensions.width - 18)
          .attr('r', 5)
          .attr('cy', 10)
          .style('fill', this.dataAggregates.colors);
        legendGrp
          .append('text')
          .attr('class', 'label')
          .attr('x', this.chartDimensions.width - 8)
          .attr('y', 9)
          .attr('dy', '.35em')
          .style('text-anchor', 'start')
          .text((d) => {
            return d;
          });
      }

      let x_offset = 0;
      legendGrp.attr('transform', function (d, i) {
        let x_pos =
          d3.select(this).select('text').node().getComputedTextLength() + 30;
        x_offset = x_offset + x_pos;
        return 'translate(' + (x_offset - x_pos + 10) + ', 20)';
      });
    }
  }

  public drawGroupedBar() {
    this.initChart('grouped');
    this.orientation === 'column'
      ? this.sortByName('name')
      : this.sortByTotal('total');
    this.addScales('grouped');
    this.addXaxis('grouped');
    this.addYaxis('grouped');
    this.drawBars('grouped');
  }

  public drawStackedBar() {
    this.initChart('stacked');
    this.orientation === 'column'
      ? this.sortByName('name')
      : this.sortByTotal('total');
    this.addScales('stacked');
    this.addXaxis('stacked');
    this.addYaxis('stacked');
    this.drawBars('stacked');
  }
}
