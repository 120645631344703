import { TableComponent } from './table.component';
import { TableHeaderComponent } from './tableHeader.component';
import { TableRowComponent } from './tableRow.component';
import { TableContentComponent } from './tableContent.component';

export {
  TableComponent,
  TableHeaderComponent,
  TableRowComponent,
  TableContentComponent
};
