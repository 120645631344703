import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { clone } from 'ramda';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CountryModel } from '../../country';
import { isError } from '../../http';
import { SelectedOrgFacadeService } from '../../org';
import {
  ProductDependenciesToken,
  ProductId,
  SelectedProductsToken,
} from '../../product';
import { isNotNullOrEmpty } from '../../utils';
import {
  PermissionCountryState,
  UserPermissionAPIV2Response,
  UserPermissionState,
} from '../types';
import { UserService } from './user.service';

const USER_PERMISSION_INITIAL_STATE = {
  userPermission: [],
  userCountries: {},
  inProgress: false,
  error: null,
};

@Injectable({
  providedIn: 'any',
})
export class UserPermissionFacade implements OnDestroy {
  private store$ = new BehaviorSubject<UserPermissionState>(
    clone(USER_PERMISSION_INITIAL_STATE)
  );
  private state$ = this.store$.asObservable();

  loading$ = this.state$.pipe(
    map((state) => state.inProgress),
    distinctUntilChanged(),
    shareReplay(1)
  );
  error$ = this.state$.pipe(
    map((state) => state.error),
    distinctUntilChanged(),
    shareReplay(1)
  );
  userPermission$ = this.state$.pipe(
    map((state) => state.userPermission),
    filter(isNotNullOrEmpty),
    distinctUntilChanged(),
    shareReplay(1)
  );
  userCountries$: Observable<Record<string, CountryModel>> = this.state$.pipe(
    pluck('userCountries'),
    filter(isNotNullOrEmpty),
    distinctUntilChanged(),
    shareReplay(1)
  );
  private unsubscribe$ = new Subject();

  constructor(
    private selectedOrgFacade: SelectedOrgFacadeService,
    @Inject(SelectedProductsToken) private products: ProductId[],
    @Optional()
    @Inject(ProductDependenciesToken)
    private productDependencies: ProductId[],
    private userService: UserService
  ) {
    this.selectedOrgFacade.selectedOrg$
      .pipe(
        tap(() => this.resetPermissions()),
        switchMap((org) =>
          this.userService.getUserPermissionV2(org.id, [
            products?.[0],
            ...(this.productDependencies ?? []),
          ])
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res: UserPermissionAPIV2Response) => {
        if (isError(res?.status)) {
          this.onLoadPermissionError(res?.error);
        } else {
          this.onLoadPermissionSuccess(res.data);
        }
      });
  }

  resetPermissions() {
    this.store$.next({
      ...USER_PERMISSION_INITIAL_STATE,
      inProgress: true,
    });
  }
  private onLoadPermissionError(error) {
    this.store$.next({
      ...this.store$.value,
      inProgress: false,
      error,
    });
  }
  private onLoadPermissionSuccess(state: PermissionCountryState) {
    this.store$.next({
      ...this.store$.value,
      inProgress: false,
      userPermission: state.permissions,
      userCountries: state.countries,
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
