<div class="componentContainer" [ngClass]="{'disabled': disabled}">
  <span class="reader zui-content" *ngIf="!tooltip">{{getFormattedValue(value?.min)}}</span>
  <div class="sliderContainer">
    <span class="slideBar" (click)="activateDrag()"></span>
    <span class="activeBar"  *ngIf="tooltip && tooltipMsgFlag && dragMeFlag" [ngStyle]="{left: getMinBallPosition() + '%', width: '0%'}"></span>
    <span class="activeBar" *ngIf="tooltip && !dragMeFlag" [ngStyle]="{left: getMinBallPosition() + '%', width: (getMaxBallPosition()-getMinBallPosition()) + '%'}"></span>
    <span class="ball minball" [ngStyle]="{'z-index': minBallPressed, 'left': getMinBallPosition() + '%'}"  (mousedown)="onMouseDown($event)">
      <div class="tooltipContainer zui-content mintooltip icon down-triangle" [ngClass]="{'tooltipCloseWidth': closeFlag}" *ngIf="tooltip" [ngStyle]="{'top': '-44px', 'left': getMinBallPosition() + '%'}">{{getFormattedValue(value?.min)}} <div _ngcontent-c18="" *ngIf="tooltip && closeFlag && !dragMeFlag  && !disabled" (click)="resetFn()" class="deselect icon close"></div></div>
      <!-- <div class="tooltipContainer zui-content mintooltip tooltipMsgClass icon down-triangle" *ngIf="tooltip && (tooltipMsgFlag || dragMeFlag )" [ngStyle]="{'top': '-44px', 'left': getMinBallPosition() + '%'}">{{getFormattedValue(value?.min)}}</div> -->
    </span>
    <span class="ball maxball"  *ngIf="tooltip && !dragMeFlag"  [ngStyle]="{'z-index': !minBallPressed, 'left': getMaxBallPosition() + '%'}" (mousedown)="onMouseDown($event)">
      <div class="tooltipContainer zui-content maxtooltip icon down-triangle"   [ngClass]="{'tooltipCloseWidth': closeFlag}"[ngStyle]="{'top': '27px', 'left': getMaxBallPosition() + '%'}">{{getFormattedValue(value?.max)}} <div _ngcontent-c18="" *ngIf="tooltip && closeFlag && !disabled" (click)="resetFn()" class="deselect icon close"></div></div>
    </span>
  </div>
  <span class="reader zui-content" *ngIf="!tooltip">{{getFormattedValue(value?.max)}}</span>
  
  <div class="clearfix"></div>
</div>
