export type EnvironmentConfig = {
  production: boolean;
  firebaseConfig: unknown; // TODO: fill in this type later
  version: string;
  okta: unknown;
  unityConfig: AppConfig;
};

export type ProductData = {
  [product_name: string]: {
    id: string;
    path: string;
    name: string;
    title: string;
    icon: string;
    order?: number;
  };
};
export type Messages = {
  [messageLabel: string]: string;
}; // TODO: Add the messages structure from shared.const.ts

export type UnityProductAndCountryAccess = {
  // TODO : Populate this in the token in the app from shared.const.ts
  [key: string]: {
    hasOrgAccess: boolean;
    hasCountryAccess: boolean;
  };
};

export type AppConstants = {
  productData?: ProductData;
  messages?: Messages;
  productCountryAccessDetails?: UnityProductAndCountryAccess;
};

export type AppConfig = {
  [key: string]: any;
  features?: { [key: string]: any };
  configs?: { [key: string]: any };
  unityConfigPath?: string;
  firebaseUrl?: string;
  userSvcUrlV3?: string;
  audienceAIUrl?: string;
};

export type FeatureWithConfig = {
  enabled: boolean;
  config: { [k: string]: any };
};
