import { Injectable, Optional, SkipSelf } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LOCALE_MAP } from './locale.const';

interface LocaleMap {
  [key: string]: string;
}
@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private localeDataSub$: BehaviorSubject<LocaleMap> = new BehaviorSubject<
    LocaleMap
  >({});
  private localeKeyMap = LOCALE_MAP;
  constructor(
    @SkipSelf() @Optional() private parentLocaleService: LocaleService
  ) {
    const localeMap$ = this.getLocaleData();
    localeMap$.subscribe(
      (localeData) =>
        (this.localeKeyMap = { ...this.localeKeyMap, ...localeData })
    );
  }
  setLocaleData(localeData: LocaleMap) {
    this.localeDataSub$.next(localeData);
  }
  private getLocaleData(): Observable<LocaleMap> {
    return this.localeDataSub$.asObservable();
  }
  getValue(key: string) {
    if (this.localeKeyMap[key]) return this.localeKeyMap[key];
    return this.parentLocaleService?.getValue(key);
  }
  // TODO: add support to translate a string to some language based on localization applied.
  translateString(value: string) {
    return value;
  }
}
