export type FilterItem = {
  name: string;
  displayName: string;
  count?: number;
  isHeader: boolean;
  items?: FilterItem[];
  applyFilter?: FilterUtilFunction;
  clearFilter?: FilterUtilFunction;
  isSelected?: FilterUtilFunction;
};

export type FilterState = {
  [key: string]: string | string[];
};

export type FilterUtilFunction = (
  copyOfIncomingState: FilterState,
  filterItems?: FilterItem
) => FilterState;
