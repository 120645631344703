import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateFormatPipe } from './dateFormatter/dateFormatter.pipe';
import { LocalizationPipe } from './locale/locale.pipe';
import { LocaleService } from './locale/locale.service';
import { NumberFormatterPipe } from './numberFormatter/numberFormatter.pipe';
import { SortPipe } from './sort/sort.pipe';
import { SortByPipe } from './sortBy/soryBy.pipe';
import { PermissionPipe } from './permissions/permissions.pipe';
import { ObjNgFor } from './objNgFor/objectNgFor.pipe';
import { SimpleSearchFilterPipe } from './simpleSearch/SimpleSearchFilter.pipe';
import { FileSizePipe } from './fileSize/FileSize.pipe';
import { ContainsKeyPipe, ContainsPipe } from './contains/containsPipe.pipe';
import { isNilOrEmptyPipe } from './isNilOrEmpty/isNilOrEmpty.pipe';
import {
  FilterWithConditionPipe,
  FilterByPathPipe,
  FilterPipe,
} from './filter';
import { ReplacePipe } from './replace/replace.pipe';
import { JoinPipe } from './join/join.pipe';
import { IsTypePipe } from './isType/isType.pipe';
import { IsNotNullPipe } from './isNotNull/IsNotNull.pipe';

const declarations = [
  SortByPipe,
  SortPipe,
  NumberFormatterPipe,
  LocalizationPipe,
  DateFormatPipe,
  PermissionPipe,
  FilterPipe,
  ObjNgFor,
  ContainsPipe,
  isNilOrEmptyPipe,
  ContainsKeyPipe,
  SimpleSearchFilterPipe,
  FileSizePipe,
  FilterWithConditionPipe,
  FilterByPathPipe,
  ReplacePipe,
  JoinPipe,
  IsTypePipe,
  IsNotNullPipe,
];
@NgModule({
  declarations: declarations,
  exports: declarations,
  imports: [CommonModule],
  providers: [LocaleService],
})
export class CorePipesModule {}
