<div [cdkMenuTriggerFor]="collapsed ? menu : empty" [cdkMenuPosition]="this.menuPosition" class="flex flex-row w-full py-3 cursor-default">
  <div class="w-[10px] min-w-[10px]"></div>
  <!-- if you change this with you need to change the navbar with too. -->

  <span class="bg-secondary-1 shrink-0 rounded-full text-white flex text-sm h-[40px] w-[40px] mx-[4px] text-center p-1 items-center justify-center">{{ userName?.charAt(0) }}</span>
  <div class="flex flex-row overflow-hidden text-no-wrap w-full transition-all ease-linear duration-500" [ngClass]="{ '!w-0': collapsed, '!break-all': !collapsed }">
    <div class="flex flex-col px-3 h-14 w-full overflow-hidden">
      <span class="font-primary text-sm text-white overflow-ellipsis">{{ this.userName }}</span>
      <div class="font-primary text-grey-300 flex items-center mt-1 text-2xs w-full overflow-hidden">
        <span class="text-grey-300 hover:text-grey-300 !cursor-text overflow-ellipsis [overflow-clip-margin:padding-box] h-3"> {{ this.userEmail }} </span>
      </div>
      <div class="overflow-hidden font-primary text-grey-300 flex items-center mt-1 text-2xs pt-1 gap-2 !cursor-pointer hover:font-bold hover:!text-white" (click)="logOutEvent.emit()">
        <!-- <span class="unity-icons-logout text-grey-300"></span> -->
        <span class="text-grey-300 hover:text-grey-300 underline decoration-grey-300"> Logout</span>
      </div>
    </div>
    <div class="w-[10px] min-w-[10px]"></div>
  </div>

  <ng-template #empty></ng-template>

  <ng-template #menu>
    <div class="floating-menu">
      <div cdkMenu>
        <!--User header card (Make this a standalone component if used in many places)-->
        <div class="flex bg-primary-3-500 py-4 pr-4 rounded-t-lg max-w-[17rem]">
          <div class="bg-secondary-1 rounded-full text-white flex text-sm min-w-[40px] min-h-[40px] !w-10 !h-10 mx-[16px] text-center p-1 items-center justify-center">
            {{ userName?.charAt(0) }}
          </div>
          <div class="flex flex-col gap-2 overflow-hidden">
            <div class="flex gap-2 text-white text-sm break-words">
              <div>{{ userName }}</div>
            </div>
            <div class="text-white/50 text-xs break-words">{{ userEmail }}</div>
          </div>
        </div>
        <button cdkMenuItem (click)="logOutEvent.emit()">
          <span class="unity-icons-logout text-state-error"></span>
          Log Out
        </button>
      </div>
    </div>
  </ng-template>
</div>
