import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { IconStyle } from './types';
import {
  CustomBtnLeftTemplateDirective,
  CustomBtnRightTemplateDirective,
} from './directives';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'special'
  | 'negative'
  | 'negative-secondary';
export type ButtonSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'zap-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZeotapButtonComponent implements OnInit {
  constructor() {}
  private _type: ButtonType = 'primary';
  private _disabled = false;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.customButtonCSS = this.updateCustomButtonCSS();
  }

  get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  set type(value: ButtonType) {
    this._type = value;
    this.customButtonCSS = this.updateCustomButtonCSS();
  }

  get type(): ButtonType {
    return this._type;
  }
  @Input() size: ButtonSize;
  @Input() icon: string = null;
  @Input() iconStyle: IconStyle;
  @Input() textStyle: string;
  @Input() text: string = '';
  @Input() tooltipText = '';
  /**
   * @description
   * This is the width class of the button.
   * by default it fits the button text , but you can set it to 'w-full' to make it match the parent container or w-[100px] to set a fixed width
   */
  @Input() widthClass = 'w-fit';
  @Input() customClass: string = '';
  @Input() customIconClass: string = '';
  @Output() onClick = new EventEmitter<void>();
  @ContentChild(CustomBtnLeftTemplateDirective, { read: TemplateRef })
  btnLeftTemplate: TemplateRef<any>;
  @ContentChild(CustomBtnRightTemplateDirective, { read: TemplateRef })
  btnRightTemplate: TemplateRef<any>;
  customSizeCSS: string;
  customButtonCSS: string;

  ngOnInit(): void {
    this.customSizeCSS = this.updateCustomSizeCSS();
    this.customButtonCSS = this.updateCustomButtonCSS();
  }

  updateCustomSizeCSS(): string {
    switch (this.size) {
      case 'small':
        return 'py-2 px-4 h-8 text-xs [&_.btn-text]:text-xs [&_.btn-text]:font-bold';
      case 'medium':
        return 'py-3 px-6 h-12 text-sm [&_.btn-text]:text-base [&_.btn-text]:font-bold';
      case 'large':
        return 'py-3 px-8 h-[52px] text-base [&_.btn-text]:text-lg [&_.btn-text]:font-bold';
      default:
        return '';
    }
  }
  updateCustomButtonCSS(): string {
    switch (this.type) {
      case 'primary':
        return this.disabled ? 'bg-grey-300' : 'bg-primary-1';
      case 'secondary':
        return this.disabled
          ? 'border border-solid border-grey-300 bg-white [&_.btn-text]:text-grey-300'
          : 'border border-solid border-primary-1-500 bg-white [&_.btn-text]:text-primary-1-500';
      case 'tertiary':
        return this.disabled
          ? 'bg-white [&_.btn-text]:text-grey-300'
          : 'bg-white [&_.btn-text]:text-primary-1-500';
      // case  'special': return this.disabled ? 'bg-grey-300' : 'bg-gradient-to-r from-secondary-2-500 to-primary-2-500';
      case 'negative':
        return this.disabled
          ? ' bg-grey-150 [&_.btn-text]:text-grey-500'
          : ' bg-state-error-100 [&_.btn-text]:text-state-error-900';
      case 'negative-secondary':
        return this.disabled
          ? ' border border-solid border-grey-400 bg-white [&_.btn-text]:text-grey-500'
          : ' border border-solid border-grey-600 bg-white [&_.btn-text]:text-grey-600';
      default:
        return '';
    }
  }

  onClicked() {
    if (this.disabled) return;
    this.onClick.emit();
  }
}
