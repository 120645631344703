import { Pipe, PipeTransform } from '@angular/core';
import { pipe, toString, replace, split, join, over, lensIndex } from 'ramda';

@Pipe({
  name: 'numberFormatter',
})
export class NumberFormatterPipe implements PipeTransform {
  transform(value: number, format?: string) {
    const formatNumberWithCommas = pipe(
      toString,
      split('.'),
      over(lensIndex(0), replace(/(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')),
      join('.')
    );
    if (format === 'commaSeparated') {
      return formatNumberWithCommas(value);
    }
    if (value !== 0 && (!value || isNaN(value))) {
      return '';
    } else if (value > 999999999) {
      return (value / 1000000000).toFixed(1).replace(/\.0+$/, '') + ' B';
    } else if (value > 999999) {
      return (value / 1000000).toFixed(1).replace(/\.0+$/, '') + ' M';
    } else if (value > 999) {
      return (value / 1000).toFixed(1).replace(/\.0+$/, '') + ' K';
    } else if (value <= 0) {
      return value.toFixed(0);
    }
    return value.toString();
  }
}
