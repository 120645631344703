<div class="input-comp" [ngClass]="{'full-width': fullWidth}" (click)="$event.stopPropagation()">
  <div class="main-container bg-white border border-solid border-grey-300 rounded-[.25rem]" [ngClass]="{'disabled !bg-grey-100': disabled}">
    <div class="prefix text-grey/52" [ngClass]="prefixStyle">
      <span class="text-sm text-grey-900 font-primary prefixText" *ngIf="prefixText">{{prefixText}}</span>
      <span class="nonFocusedIcon icon" [ngClass]="prefixIcon" *ngIf="prefixIcon"></span>
      <span class="focusedIcon icon" [ngClass]="prefixIcon" *ngIf="prefixIcon"></span>
    </div>
    <div class="content">
      <input type="{{type}}" id="{{id}}" class="control text-sm text-grey-900 font-primary" [(ngModel)]="value" [disabled]="disabled" (focus)="onFocus()" (blur)="onBlur()" *ngIf="!showTextarea" />
      <textarea id="{{id}}" class="control text-sm text-grey-900 font-primary" [(ngModel)]="value" [disabled]="disabled" (focus)="onFocus()" (blur)="onBlur()" *ngIf="showTextarea"></textarea>
      <label class="zui-placeholder font-primary cursor-text text-sm text-grey/52" [ngClass]="{'float-placeholder': hasValue, hide: (hasValue && hideFloatingPlaceholder)}" for="{{id}}">{{label}}</label>
    </div>
    <div class="suffix text-grey/52">
      <span class="nonFocusedIcon icon" [ngClass]="suffixIcon" *ngIf="suffixIcon"></span>
      <span class="focusedIcon icon text-primary-1" [ngClass]="suffixIcon" *ngIf="suffixIcon"></span>
    </div>
    <span class="liner left-line outline outline-1 outline-primary-1"></span>
    <span class="liner top-line outline outline-1 outline-primary-1"></span>
    <span class="liner right-line outline outline-1 outline-primary-1"></span>
    <span class="liner bottom-line outline outline-1 outline-primary-1"></span>
  </div>
  <div class="msgs-container">
    <span class="msg zui-helptext" *ngIf="hint && !errors.length">{{hint}}</span>
    <span class="msg font-primary text-[13px] text-[#eb415d]" *ngFor="let error of errors">{{error}}</span>
  </div>
</div>
